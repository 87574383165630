import React from 'react';
import { Layout } from 'react-admin';
import { Menu } from '../CustomMenu';
import Header from '../../admin-header/Header';
import CustomSidebar from '../../admin/CustomSidebar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    root: { paddingTop: 40, backgroundColor: '#f8fbfd' },
});

const MyLayout = props => {
    const classes = useStyles();
    return (
        <Layout
            className={classes.root}
            appBar={Header}
            {...props}
            menu={Menu}
            sidebar={CustomSidebar}
        />
    );
};

export default MyLayout;
