import React, { FC } from 'react';
import './style.sass';
import { FormControlLabel, FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
    label: string | JSX.Element;
    name: string;
    formikProps: any;
    labelSm?: boolean;
    [p: string]: any;
}

const BasicCheckboxGroup: FC<IProps> = props => {
    const {
        currentValue,
        label,
        options,
        error = false,
        helperText = '',
        onChange,
        onBlur,
        hints,
        formikProps,
        ...restProps
    } = props;

    const { name } = restProps;
    const isChecked = (value: string | number) =>
        currentValue?.includes(value) || currentValue?.includes(value + '');

    return (
        <div className="control-group-checkbox">
            <div className="control-group-checkbox__label">{label}</div>
            {options.map(({ value, label }: any, index: number) => {
                return (
                    <div className="control-checkbox-wrap" key={index}>
                        <FormControlLabel
                            label={label}
                            value={currentValue}
                            className="admin-control-root"
                            control={
                                <Checkbox
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    name={name}
                                    color="secondary"
                                    value={value}
                                    checked={isChecked(value)}
                                    classes={{
                                        root:
                                            'admin-control-checkbox__checkbox',
                                    }}
                                />
                            }
                            {...restProps}
                            classes={{
                                label: 'admin-control-checkbox__label',
                            }}
                        />
                    </div>
                );
            })}
            {hints && currentValue?.length < 2 ? (
                <div
                    className="hints"
                    dangerouslySetInnerHTML={{
                        __html: hints[currentValue[0]],
                    }}
                />
            ) : name === 'VECTOR' ? (
                <>
                    <div className="hints">
                        Подробное описание направлений по{' '}
                        <a href="/#direction" target="_blank" rel="noopener">
                            ссылке
                        </a>
                    </div>
                </>
            ) : null}

            <FormHelperText error={error}>
                {error ? helperText : ''}
            </FormHelperText>
        </div>
    );
};

export default BasicCheckboxGroup;
