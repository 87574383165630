import React, { useEffect, useState } from 'react';
import './style.sass';
import axios from 'axios';
import uniqBy from 'lodash.uniqby';
import ExpertiseCollapseBox from '../ExpertiseCollapseBox';
import LKRepository from '../../../../models/LKRepository';
import ModalWrap from '../ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import { Notification, useNotify } from 'react-admin';
import User from '../../../../models/User';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ExpertiseCardView = ({ permissions, ...props }) => {
    const notify = useNotify();
    const [items, setItems] = useState([]);
    const [coeff, setCoeff] = useState({});
    const [requestData, setRequestData] = useState([]);

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnairebyidwithestimate`,
                props.generateFormData({ UF_ID_REQUEST: props.id })
            )
            .then(response => {
                // const r = response.data.data.estimate;
                // const uniqEstimate = uniqBy(r, 'UF_CRIT');
                setItems(response.data.data.estimate);
            })
            .catch(err => console.log(err));
        handleExpUpd();
    }, [props.id]);

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.getexpcoeff`,
                {}
            )
            .then(res => {
                setCoeff(res.data.data);
            })
            .catch(err => console.log(err));
    }, [props.id]);

    const handleExpUpd = () => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.estimateandexpertbyid`,
                props.generateFormData({ UF_ID_REQUEST: props.id })
            )
            .then(response => {
                setRequestData(response.data.data.EXPERT);
            })
            .catch(err => console.log(err));
    };

    const uniqExpertsEstimateUniq = uniqBy(
        props.record.estimate_expert_type,
        'TYPE_EXPERT'
    );
    const uniqExpertsEstimate = () => {
        if (uniqExpertsEstimateUniq.length > 0) {
            return uniqExpertsEstimateUniq;
        } else if (
            props.record.estimate_expert_type &&
            props.record.estimate_expert_type.length > 0
        ) {
            return props.record.estimate_expert_type;
        } else {
            return [];
        }
    };

    const toFixedNoRounding = function (num, n) {
        const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g');
        const a = num.toString().match(reg)[0];
        const dot = a.indexOf('.');
        if (dot === -1) {
            return a + '.' + '0'.repeat(n);
        }
        const b = n - (a.length - dot) + 1;
        return b > 0 ? a + '0'.repeat(b) : a;
    };

    const getAverageEstimate = () => {
        const businessExpertsRate = props.record.estimate_expert_type.find(
            ({ TYPE_EXPERT, UF_CRIT }) =>
                TYPE_EXPERT === '3' && UF_CRIT === 'Итоговая оценка'
        ).UF_RATING;
        const techExpertsRate = props.record.estimate_expert_type.find(
            ({ TYPE_EXPERT, UF_CRIT }) =>
                TYPE_EXPERT === '4' && UF_CRIT === 'Итоговая оценка'
        ).UF_RATING;

        return {
            businessEstimate: Number(businessExpertsRate),
            techEstimate: Number(techExpertsRate),
        };
    };

    const itogRating = () => {
        const ber = getAverageEstimate().businessEstimate;
        const nter = getAverageEstimate().techEstimate;
        const result = ber * coeff.beCoeff + nter * coeff.nteCoeff;
        return toFixedNoRounding(result, 3);
    };

    const [isOpen, setToggleModal] = useState(false);

    const expertType =
        (uniqExpertsEstimate &&
            uniqExpertsEstimate().some(item => item.TYPE_EXPERT === '3')) ||
        false;
    const expertTechCheck =
        (uniqExpertsEstimate &&
            uniqExpertsEstimate().some(item => item.TYPE_EXPERT === '4')) ||
        false;

    const modalHandle = active => {
        setToggleModal(active);
    };

    const ModalExpert = props => {
        const classes = useStyles();
        const [success, setSuccess] = React.useState('');
        const [error, setError] = React.useState('');
        const [expertBiz, setExpertBiz] = React.useState('');
        const [expertTech, setExpertTech] = React.useState('');
        const [experts, setExperts] = React.useState('');
        const [expertsBiz, setExpertsBiz] = React.useState('');
        const [expertsTech, setExpertsTech] = React.useState('');

        const handleChange = (event, type) => {
            if (type === 'biz') {
                setExpertBiz(event.target.value);
            } else if (type === 'tech') {
                setExpertTech(event.target.value);
            }
        };

        const handleSave = type => {
            const dataObj =
                type === 'biz'
                    ? {
                          EXPERT_BUSSNESS: expertBiz,
                          ID_QUESTIONNARE: props.id,
                      }
                    : {
                          EXPERT_TECH: expertTech,
                          ID_QUESTIONNARE: props.id,
                      };
            if (
                (type === 'biz' && expertBiz === '') ||
                (type === 'tech' && expertTech === '')
            ) {
                setError('Выберите эксперта');
            } else {
                setError('');
                axios
                    .post(
                        `/bitrix/services/main/ajax.php?action=webpractik:main.forms.setexpert`,
                        LKRepository.generateFormData(dataObj)
                    )
                    .then(response => {
                        console.log(response.data.data);
                        if (response.data.data.length) {
                            setSuccess('Эксперт назначен');
                        } else {
                            setError('Что-то пошло не так');
                        }
                    })
                    .catch(err => console.log(err));
            }
        };

        useEffect(() => {
            axios
                .post(
                    `/bitrix/services/main/ajax.php?action=webpractik:main.forms.expertlist`,
                    {}
                )
                .then(response => {
                    setExperts(response.data.data);
                    setExpertsBiz(
                        Object.values(response.data.data).filter(
                            i => i.type === 'Бизнесс'
                        )
                    );
                    setExpertsTech(
                        Object.values(response.data.data).filter(
                            i => i.type === 'Технологии'
                        )
                    );
                })
                .catch(err => console.log(err));
        }, []);
        return (
            <ModalWrap isOpen={isOpen} toggleModal={modalHandle}>
                <div
                    className={`expert-modal ${
                        success ? `expert-modal--success` : ``
                    }`}
                >
                    {!success ? (
                        <>
                            <div className="expert-modal__label">
                                {expertType
                                    ? 'Назначить технического-эксперта для'
                                    : 'Назначить бизнес-эксперта для'}
                            </div>
                            <div className="expert-modal__title">
                                {props.name}
                            </div>
                            <div className="expert-modal__row">
                                {!expertType ? (
                                    <div className="expert-modal__col-12">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                id="business"
                                                className={classes.formControl}
                                            >
                                                Бизнес-эксперт
                                            </InputLabel>
                                            <Select
                                                labelId="business"
                                                id="business-select"
                                                defaultValue=""
                                                className={classes.formControl}
                                                value={expertBiz}
                                                onChange={e => {
                                                    handleChange(e, 'biz');
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Не выбрано
                                                </MenuItem>
                                                {Object.keys(expertsBiz)
                                                    .sort((a, b) => {
                                                        return expertsBiz[
                                                            a
                                                        ].surname.localeCompare(
                                                            expertsBiz[b]
                                                                .surname
                                                        );
                                                    })
                                                    .map(key => (
                                                        <MenuItem
                                                            value={
                                                                expertsBiz[key]
                                                                    .id
                                                            }
                                                            key={key}
                                                        >
                                                            {expertsBiz[key]
                                                                .name
                                                                ? `${expertsBiz[key].name} ${expertsBiz[key].surname}`
                                                                : expertsBiz[
                                                                      key
                                                                  ].email}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : (
                                    <div className="expert-modal__col-12">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                id="tech"
                                                className={classes.formControl}
                                            >
                                                Технический-эксперт
                                            </InputLabel>
                                            <Select
                                                labelId="tech"
                                                id="tech-select"
                                                defaultValue=""
                                                value={expertTech}
                                                className={classes.formControl}
                                                onChange={e => {
                                                    handleChange(e, 'tech');
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Не выбрано
                                                </MenuItem>
                                                {Object.keys(expertsTech)
                                                    .sort((a, b) => {
                                                        return expertsTech[
                                                            a
                                                        ].surname.localeCompare(
                                                            expertsTech[b]
                                                                .surname
                                                        );
                                                    })
                                                    .map(key => (
                                                        <MenuItem
                                                            value={
                                                                expertsTech[key]
                                                                    .id
                                                            }
                                                            key={key}
                                                        >
                                                            {expertsTech[key]
                                                                .name
                                                                ? `${expertsTech[key].name} ${expertsTech[key].surname}`
                                                                : expertsTech[
                                                                      key
                                                                  ].email}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </div>
                            {error && (
                                <div className="expert-modal__error">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="expert-modal__bottom">
                                {!expertType ? (
                                    <RedButton
                                        size="sm"
                                        type="button"
                                        onClick={() => handleSave('biz')}
                                    >
                                        Сохранить
                                    </RedButton>
                                ) : (
                                    <RedButton
                                        size="sm"
                                        type="button"
                                        onClick={() => handleSave('tech')}
                                    >
                                        Сохранить
                                    </RedButton>
                                )}

                                <button
                                    className="expert-modal__cancel"
                                    onClick={() => {
                                        modalHandle(false);
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="expert-modal__success">
                                {success}
                            </div>
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        modalHandle(false);
                                        handleExpUpd();
                                    }}
                                >
                                    Закрыть
                                </RedButton>
                            </div>
                        </>
                    )}
                </div>
            </ModalWrap>
        );
    };

    const handleStatus = status => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                LKRepository.generateFormData({
                    ID: props.id,
                    STATUS: status,
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify('Заявка сохранена!');
                    setTimeout(() => {
                        props.history.push('/manager/request');
                    }, 1500);
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };
    console.log('-----');
    console.log(uniqExpertsEstimate());

    return (
        <>
            {uniqExpertsEstimate().length < 1 && (
                <div className="expertise-manager">
                    <div className="expertise-manager-item">
                        <div className="expertise-manager-item__title">
                            Бизнес-эксперт
                        </div>
                        <div
                            className={`expertise-manager-item__set-expert ${
                                requestData &&
                                requestData.find(item => item.UF_TYPE === '3')
                                    ? `disabled`
                                    : ``
                            } `}
                            onClick={() => {
                                modalHandle(
                                    requestData &&
                                        requestData.find(
                                            item => item.UF_TYPE === '3'
                                        )
                                        ? false
                                        : true
                                );
                            }}
                        >
                            {requestData &&
                            requestData.find(item => item.UF_TYPE === '3')
                                ? `Эксперт назначен, ожидаем оценки`
                                : `Назначить эксперта`}
                        </div>
                    </div>
                    {!expertTechCheck && (
                        <div className="expertise-manager-item">
                            <div className="expertise-manager-item__title">
                                Технический эксперт
                            </div>
                            <div
                                className={`expertise-manager-item__set-expert ${
                                    !expertTechCheck && !expertType
                                        ? `disabled`
                                        : ``
                                } `}
                                onClick={() => {
                                    modalHandle(
                                        !(!expertTechCheck && !expertType)
                                    );
                                }}
                            >
                                {requestData &&
                                requestData.find(i => i.UF_TYPE === '4') &&
                                items.find(i => i.TYPE_EXPERT !== '4')
                                    ? `Эксперт назначен, ожидаем оценки`
                                    : `Назначить эксперта`}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isOpen && <ModalExpert {...props.record} />}

            {uniqExpertsEstimate().length > 0 && (
                <div className="expertise-manager">
                    {uniqExpertsEstimate().map(item => {
                        return (
                            <>
                                <div className="expertise-manager-item">
                                    <div className="expertise-manager-item__type">
                                        {item.TYPE_EXPERT === '3'
                                            ? 'Бизнес-эксперт'
                                            : 'Технический эксперт'}
                                    </div>
                                    <div className="expertise-manager-item__title-rating">
                                        <div className="expertise-manager-item__title">
                                            {(items &&
                                                item.UF_USER_ID &&
                                                items.find(
                                                    i =>
                                                        i.UF_USER_ID ===
                                                        item.UF_USER_ID
                                                ) &&
                                                items.find(
                                                    i =>
                                                        i.UF_USER_ID ===
                                                        item.UF_USER_ID
                                                ).NAME) ||
                                                ''}
                                        </div>
                                        <div className="expertise-manager-item__rating">
                                            {/*{item.UF_RATING}*/}
                                            {item.TYPE_EXPERT &&
                                                items.find(
                                                    i =>
                                                        i.UF_CRIT ===
                                                            'Итоговая оценка' &&
                                                        i.TYPE_EXPERT ===
                                                            item.TYPE_EXPERT
                                                ).UF_RATING}
                                        </div>
                                    </div>

                                    {item.UF_COMMENT && (
                                        <>
                                            <div className="expertise-manager-item__comment-title">
                                                Общий комментарий:
                                            </div>
                                            <div className="expertise-manager-item__comment">
                                                {(items &&
                                                    item.TYPE_EXPERT &&
                                                    items.find(
                                                        i =>
                                                            i.UF_CRIT ===
                                                                'Итоговая оценка' &&
                                                            i.TYPE_EXPERT ===
                                                                item.TYPE_EXPERT
                                                    ).UF_COMMENT) ||
                                                    ''}
                                            </div>
                                        </>
                                    )}

                                    <ExpertiseCollapseBox
                                        items={
                                            items &&
                                            items.filter(
                                                i =>
                                                    i.UF_CRIT !==
                                                        'Итоговая оценка' &&
                                                    i.TYPE_EXPERT ===
                                                        item.TYPE_EXPERT
                                            )
                                        }
                                    />
                                </div>
                                {!expertTechCheck && (
                                    <div className="expertise-manager-item">
                                        <div className="expertise-manager-item__title">
                                            Технический эксперт
                                        </div>
                                        <div
                                            className={`expertise-manager-item__set-expert`}
                                            className={`expertise-manager-item__set-expert ${
                                                requestData &&
                                                requestData.find(
                                                    i => i.UF_TYPE === '4'
                                                ) &&
                                                items.find(
                                                    i => i.TYPE_EXPERT !== '4'
                                                )
                                                    ? `disabled`
                                                    : ``
                                            } `}
                                            onClick={() => {
                                                modalHandle(
                                                    requestData &&
                                                        requestData.find(
                                                            i =>
                                                                i.UF_TYPE ===
                                                                '4'
                                                        ) &&
                                                        items.find(
                                                            i =>
                                                                i.TYPE_EXPERT !==
                                                                '4'
                                                        )
                                                        ? false
                                                        : true
                                                );
                                            }}
                                        >
                                            {requestData &&
                                            requestData.find(
                                                i => i.UF_TYPE === '4'
                                            ) &&
                                            items.find(
                                                i => i.TYPE_EXPERT !== '4'
                                            )
                                                ? `Эксперт назначен, ожидаем оценки`
                                                : `Назначить эксперта`}
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })}
                    {expertTechCheck && (
                        <>
                            <div className="expertise-manager-itog">
                                <div className="expertise-manager-itog__t-r">
                                    <div className="expertise-manager-itog__title">
                                        Итоговая оценка
                                    </div>
                                    <div className="expertise-manager-itog__rating">
                                        {itogRating()}
                                    </div>
                                </div>
                                {User.getRole(permissions) !== 'functional' &&
                                    props.record.status !== 'Одобрена' &&
                                    props.record.status !== 'DemoDay' &&
                                    props.record.status !== 'Очный отбор' &&
                                    props.record.status !== 'Сопровождение' &&
                                    props.record.status !== 'Архив' && (
                                        <div className="expertise-manager-itog__actions">
                                            <RedButton
                                                size="lg-m"
                                                type="button"
                                                onClick={() => {
                                                    handleStatus(39);
                                                }}
                                            >
                                                Пригласить на очный отбор
                                            </RedButton>
                                            <button
                                                className="expert-modal__cancel"
                                                onClick={() => {
                                                    handleStatus(38);
                                                }}
                                            >
                                                Отказать
                                            </button>
                                        </div>
                                    )}

                                {User.getRole(permissions) !== 'functional' &&
                                    props.record.status === 'Одобрена' && (
                                        <div className="expertise-manager-itog__actions">
                                            <RedButton
                                                size="lg-m"
                                                type="button"
                                                onClick={() => {
                                                    handleStatus(42);
                                                }}
                                            >
                                                Пригласить на DemoDay
                                            </RedButton>
                                            <button
                                                className="expert-modal__cancel"
                                                onClick={() => {
                                                    handleStatus(38);
                                                }}
                                            >
                                                Отказать
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </>
                    )}
                </div>
            )}
            <Notification />
        </>
    );
};

export default ExpertiseCardView;
