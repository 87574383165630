import expert2 from 'assets/pic/experts/experts-02.jpg';
import expert5 from 'assets/pic/experts/experts-05.jpg';
import expert6 from 'assets/pic/experts/experts-06.jpg';
import expert7 from 'assets/pic/experts/experts-07.jpg';
import expert9 from 'assets/pic/experts/experts-09.jpg';
import expert14 from 'assets/pic/experts/experts-14.jpg';
import expert15 from 'assets/pic/experts/experts-15.jpg';
import expert19 from 'assets/pic/experts/experts-19.jpg';
import expert20 from 'assets/pic/experts/experts-20.jpg';
import expert22 from 'assets/pic/experts/experts-22.jpg';
import murzin from 'assets/pic/experts/murzin.jpeg';
import expert29 from 'assets/pic/experts/experts-29.jpg';
import expert32 from 'assets/pic/experts/experts-32.jpg';
import expert33 from 'assets/pic/experts/experts-33.jpg';
import chisnikov from 'assets/pic/experts/chisnikov.jpg';
import popov from 'assets/pic/experts/popov.jpg';
import skovorodnikov from 'assets/pic/experts/skovorodnikov.jpeg';
import polyakov from 'assets/pic/experts/polyakov.jpeg';
import golovin from 'assets/pic/experts/golovin.jpg';
import matveeva from 'assets/pic/experts/matveeva.jpg';
import jeleznov from 'assets/pic/experts/jeleznov.jpg';
import morozova from 'assets/pic/experts/morozova.jpg';
import stambrovskij from 'assets/pic/experts/stambrovskij.jpeg';
import petrov from 'assets/pic/experts/petrov.jpg';
import petrov_pgk from 'assets/pic/experts/petrov-pgk.jpg';
import metelkin from 'assets/pic/experts/metelkin.jpg';
import martinova from 'assets/pic/experts/martinova.jpg';
import petrova from 'assets/pic/experts/petrova.jpg';
import kurochkin from 'assets/pic/experts/kurochkin.jpg';
import zubkov from 'assets/pic/experts/zubkov.jpg';
import zobnin from 'assets/pic/experts/zobnin.jpg';
import gvozdev from 'assets/pic/experts/gvozdev.jpg';
import verbov from 'assets/pic/experts/verbov.JPG';
import mehedov from 'assets/pic/experts/mehedov.jpg';
import kulakov from 'assets/pic/experts/kulakov.jpg';
import aksenov from 'assets/pic/experts/aksenov.jpg';
import kojevnikov from 'assets/pic/experts/kojevnikov.jpg';
import pochta from 'assets/pic/experts/pochta.JPG';
import gorin from 'assets/pic/experts/gorin.png';
// import rahimjanov from 'assets/pic/experts/rahimjanov.jpg';
import harin from 'assets/pic/experts/harin.jpg';
import lazarev from 'assets/pic/experts/lazarev.jpg';
import smirnova from 'assets/pic/experts/smirnova.jpg';
import mamontov from 'assets/pic/experts/mamontov.jpg';
import poyarkov from 'assets/pic/experts/poyarkov.jpg';
import shilkov from 'assets/pic/experts/shilkov.jpg';
import noninaishvilli from 'assets/pic/experts/noninaishvilli.jpg';
import medvedev from 'assets/pic/experts/medvedev.jpeg';
import koltunova from 'assets/pic/experts/koltunova.jpeg';

export const ExpertsItems2022 = [
    {
        category: 'Эксперты&nbsp;&laquo;РЖД&raquo;',
        experts: [
            {
                img: verbov,
                name: `Дмитрий<br/>Вербов`,
                position: `Начальник Центра инновационного развития ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: noninaishvilli,
                name: `Давид<br/>Нониашвили`,
                position: `Начальник службы технической политики Дирекции железнодорожных вокзалов`,
                default: true,
                show: true,
            },
            {
                img: mamontov,
                name: `Сергей<br/>Мамонтов`,
                position: `И.о. первого заместителя<br/> начальника Центральной дирекции<br/> филиала ОАО РЖД пассажирских обустройств`,
                default: true,
                show: true,
            },
            {
                img: poyarkov,
                name: `Михаил<br/>Поярков`,
                position: `Заместитель генерального<br/> директора АО ФПК`,
                default: true,
                show: true,
            },
            {
                img: shilkov,
                name: `Валерий<br/>Шилков`,
                position: `Советник генерального<br/> директора ООО РЖД Тур`,
                default: true,
                show: true,
            },
            {
                img: expert19,
                name: `Алексей <br/>Лохматов`,
                position: `Первый заместитель <br/>Начальника Центра моделирования<br/> бизнес-процессов&nbsp;&mdash; структурное подразделение ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            // {
            //     img: expert5,
            //     name: `Алексей <br/>Пашинин`,
            //     position: `Главный инженер<br/> Дирекции скоростного<br/> сообщения&nbsp;&mdash; филиал<br/> ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            {
                img: expert2,
                name: `Александр<br/>Косарев`,
                position: `Первый заместитель<br/> Генерального директора<br/> АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                default: true,
                show: true,
            },
            {
                img: harin,
                name: `Олег<br/>Харин`,
                position: `И.о. заместителя<br/> Генерального директора<br/> - директор Центра технологических<br/> информационных систем`,
                default: true,
                show: true,
            },
            {
                img: expert7,
                name: `Денис<br/>Шикунов`,
                position: `Заместитель Генерального<br/> директора по&nbsp;экономике <br/>и&nbsp;финансам АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                default: true,
                show: true,
            },
            {
                img: expert15,
                name: `Александр <br/>Ададуров`,
                position: `Советник Генерального директора АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                default: true,
                show: true,
            },
            {
                img: mehedov,
                name: ` Михаил<br/>Мехедов`,
                position: `Заместитель Генерального директора&nbsp;&mdash; Директор Центра &laquo;Цифровые модели перевозок и&nbsp;технологии энергосбережения&raquo; АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                show: false,
            },
            {
                img: expert9,
                name: `Юрий <br/>Бабков`,
                position: `Первый заместитель<br/> генерального директора&nbsp;&mdash; <br/>главный инженер <br/>АО&nbsp;&laquo;ВНИКТИ&raquo;`,
                show: false,
            },
            {
                img: expert14,
                name: `Андрей<br/> Лунин`,
                position: `Заместитель генерального <br/>директора АО&nbsp;&laquo;ВНИКТИ&raquo;`,
                show: false,
            },
            // {
            //     img: rahimjanov,
            //     name: `Денис<br/> Рахимжанов`,
            //     position: `Заместитель Генерального директора по&nbsp;стратегии и&nbsp;маркетингу АО&nbsp;&laquo;РЖД Логистика&raquo;`,
            //     show: false,
            // },
        ],
    },
    {
        category: 'Эксперты&nbsp;партнеров',
        experts: [
            {
                img: morozova,
                name: `Елена<br/>Морозова`,
                position: `Руководитель направления внешних инноваций ПАО&nbsp;&laquo;Кировский завод&raquo;`,
                default: true,
                show: true,
            },
            {
                img: jeleznov,
                name: `Дмитрий<br/>Железнов`,
                position: `Советник первого вице-президента<br/>ПАО&nbsp;&laquo;ТрансКонтейнер&raquo;`,
                default: true,
                show: true,
            },
            {
                img: popov,
                name: `Антон<br/>Попов`,
                position: `Начальник управления по&nbsp;развитию&nbsp;ИТ сервисов портового дивизиона FESCO`,
                default: true,
                show: true,
            },
            {
                img: kulakov,
                name: `Дмитрий<br/>Кулаков`,
                position: `Главный инженер-исследователь отдела электрических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: gorin,
                name: `Антон<br/>Горин`,
                position: `Главный инженер-исследователь отдела механических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: aksenov,
                name: `Максим<br/>Аксенов`,
                position: `Главный инженер-исследователь отдела электрических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: kojevnikov,
                name: `Александр<br/>Кожевников`,
                position: `Главный инженер-исследователь отдела энергетических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: lazarev,
                name: `Никита<br/>Лазарев`,
                position: `Менеджер центра развития Бизнес Системы Open Innovation АО&nbsp;&laquo;Северсталь&nbsp;Менеджмент&raquo;`,
                default: true,
                show: true,
            },
            {
                img: medvedev,
                name: `Алексей<br/>Медведев`,
                position: `Директор по развитию<br/> Moscow Seed Fund`,
                default: true,
                show: true,
            },
            {
                img: koltunova,
                name: `Ксения<br/>Колтунова`,
                position: `Руководитель направления<br/> партнерств НТИ (АСИ)`,
                default: true,
                show: true,
            },
        ],
    },
    {
        category: 'Эксперты&nbsp;организаторов',
        experts: [
            {
                img: expert20,
                name: `Роман <br/>Кошелев`,
                position: `Начальник Центра &laquo;Корпоративный акселератор стартап-проектов<br/>ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: polyakov,
                name: `Александр<br/>Поляков`,
                position: `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: smirnova,
                name: `Ольга<br/>Смирнова`,
                position: `Эксперт Центра «Корпоративный акселератор стартап-проектов ОАО «РЖД» — АО «ВНИИЖТ»`,
                default: true,
                show: true,
            },
            {
                img: martinova,
                name: `Ирина <br/>Мартынова`,
                position: `Руководитель<br/> корпоративного<br/> направления GenerationS`,
                default: true,
                show: true,
            },
            {
                img: petrova,
                name: `Екатерина <br/>Петрова`,
                position: `Директор корпоративного <br/>акселератора GenerationS`,
                default: true,
                show: true,
            },
            {
                img: expert22,
                name: `Галина <br/>Дремова`,
                position: `Менеджер <br/>по&nbsp;интегрированным<br/> коммуникациям<br/> GenerationS`,
                default: true,
                show: true,
            },
            {
                img: murzin,
                name: `Михаил<br/>Мурзин`,
                position: `Менеджер акселерационной программы<br/>GenerationS`,
                default: true,
                show: true,
            },
        ],
    },
];
export const ExpertsItems2022En = [
    {
        category: 'Russian&nbsp;Railways&nbsp;experts',
        experts: [
            {
                img: verbov,
                name: `Dmitry<br/>Verbov`,
                position: `Head of&nbsp;the Center for Innovative Development, Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: noninaishvilli,
                name: `David<br/>Noniashvili`,
                position: `Head of the Technical Policy Service of the Directorate of Railway Stations`,
                default: true,
                show: true,
            },
            {
                img: mamontov,
                name: `Sergey<br/>Mamontov`,
                position: `Acting first deputy<br/> Head of the Central Directorate<br/> of the Passenger Facilities Branch of JSC Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: poyarkov,
                name: `Michael<br/>Poyarkov`,
                position: `Deputy General Director<br/> of JSC FPC`,
                default: true,
                show: true,
            },
            {
                img: shilkov,
                name: `Valery<br/>Shilkov`,
                position: `Advisor to the General Director<br/> of RZD Tour LLC`,
                default: true,
                show: true,
            },
            {
                img: expert19,
                name: `Alexey <br/>Lokhmatov`,
                position: `First Deputy<br/> Head of&nbsp;Business Processes<br/> Modeling Center&nbsp;&mdash; a&nbsp;brunch<br/> of&nbsp;Russian Railways`,
                default: true,
                show: true,
            },
            // {
            //     img: expert5,
            //     name: `Alexey <br/>Pashinin`,
            //     position: `Chief Engineer<br/> High-speed Service Department&nbsp;&mdash;<br/>a&nbsp;branch of&nbsp;Russian Railways<br/>`,
            //     default: true,
            //     show: true,
            // },
            {
                img: expert2,
                name: `Alexander<br/>Kosarev`,
                position: `First Deputy CEO,<br/>VNIIZhT`,
                default: true,
                show: true,
            },
            {
                img: harin,
                name: `Oleg<br/>Kharin`,
                position: `Acting Deputy <br/>General Director - Director of the Center for Technological<br/> Information Systems`,
                default: true,
                show: true,
            },
            {
                img: expert7,
                name: `Denis<br/>Shikunov`,
                position: `Deputy Director<br/> General for Economics and Finance,<br/>VNIIZhT`,
                default: true,
                show: true,
            },
            {
                img: expert15,
                name: `Alexander <br/>Adadurov`,
                position: `Deputy CEO,VNIIZhT `,
                default: true,
                show: true,
            },
            {
                img: mehedov,
                name: `Mikhail<br/>Mekhedov`,
                position: `Deputy General Director&nbsp;&mdash; Director of&nbsp;the Center for Digital Transportation Models and Energy Saving Technologies of&nbsp;VNIIZhT JSC`,
                show: false,
            },
            {
                img: expert9,
                name: `Yuri <br/>Babkov`,
                position: `First Deputy<br/> CEO and Chief Engineer,<br/>VNIKTI`,
                show: false,
            },

            {
                img: expert14,
                name: `Andrey<br/> Lunin`,
                position: `Deputy CEO, <br/>VNIKTI`,
                show: false,
            },
            // {
            //     img: rahimjanov,
            //     name: `Denis<br/> Rahimjanov`,
            //     position: `Deputy General Director for Strategy and Marketing, Russian Railways Logistics JSC`,
            //     show: false,
            // },
        ],
    },
    {
        category: 'Partner&nbsp;experts',
        experts: [
            {
                img: morozova,
                name: `Elena<br/>Morozova`,
                position: `Head of&nbsp;External Innovation Department of&nbsp;PJSC &laquo;Kirovsky Zavod&raquo;`,
                default: true,
                show: true,
            },
            {
                img: jeleznov,
                name: `Dmitry<br/>Zheleznov`,
                position: `Advisor to&nbsp;the first vice president<br/>PJSC &laquo;TransContainer&raquo;`,
                default: true,
                show: true,
            },
            {
                img: popov,
                name: `Anton<br/>Popov`,
                position: `Head of&nbsp;the Department for the Development of&nbsp;IT&nbsp;Services of&nbsp;the FESCO Port Division`,
                default: true,
                show: true,
            },
            {
                img: kulakov,
                name: `Dmitry <br/> Kulakov`,
                position: `Chief Research Engineer of the Electrical Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: gorin,
                name: `Anton <br/> Gorin`,
                position: `Chief Research Engineer of the Mechanical Systems Department of the central heating center&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: aksenov,
                name: `Maxim <br/> Aksenov`,
                position: `Chief Research Engineer of the Electrical Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: kojevnikov,
                name: `Alexander <br/> Kozhevnikov`,
                position: `Chief Research Engineer of the Energy Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: lazarev,
                name: `Nikita<br/>Lazarev`,
                position: `Manager of&nbsp;the Open Innovation Business System Development Center, Severstal Management JSC`,
                default: true,
                show: true,
            },
            {
                img: medvedev,
                name: `Alexei<br/>Medvedev`,
                position: `Development Director<br/> Moscow Seed Fund`,
                default: true,
                show: true,
            },
            {
                img: koltunova,
                name: `Ksenia <br/>Koltunova`,
                position: `Head of NTI<br/> partnerships (ASI)`,
                default: true,
                show: true,
            },
        ],
    },
    {
        category: 'Organizers&nbsp;experts',
        experts: [
            {
                img: expert20,
                name: `Roman <br/>Koshelev`,
                position: `Head of the Center<br/> for the Corporate<br/> Accelerator of Start-up Projects,<br/> Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: polyakov,
                name: `Alexander<br/>Polyakov`,
                position: `Expert,<br/> the Center &laquo;Corporate accelerator of&nbsp;start-up projects of&nbsp;JSC Russian Railways&raquo;`,
                default: true,
                show: true,
            },
            {
                img: smirnova,
                name: `Olga<br/>Smirnova`,
                position: `Specialist of&nbsp;the Center &laquo;Corporate accelerator of&nbsp;start-up projects of&nbsp;JSC Russian Railways&nbsp;&mdash; JSC VNIIZhT&raquo;`,
                default: true,
                show: true,
            },
            {
                img: martinova,
                name: `Irina <br/>Martynova`,
                position: `Director,<br/>Corporate Department,<br/>GenerationS`,
                default: true,
                show: true,
            },
            {
                img: petrova,
                name: `Ekaterina <br/>Petrova`,
                position: `CEO, <br/>Corporate Accelerator,<br/>GenerationS`,
                default: true,
                show: true,
            },
            {
                img: expert22,
                name: `Galina <br/>Dremova`,
                position: `Integrated <br/>Communications Manager,<br/> GenerationS`,
                default: true,
                show: true,
            },
            {
                img: murzin,
                name: `Michael<br/>Murzin`,
                position: `Acceleration Program Manager, GenerationS`,
                default: true,
                show: true,
            },
        ],
    },
];
