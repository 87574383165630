import React from 'react';
import './style.sass';

const ProfitItem = ({ item }) => (
    <div className="profit__col">
        <div className="profit__item" data-aos="fade-up" data-aos-delay="800">
            <div className="profit__img">
                <img src={item.img} alt="" />
            </div>
            <div
                className="profit__subtitle"
                dangerouslySetInnerHTML={{
                    __html: item.title,
                }}
            />
            <div
                className="profit__text"
                dangerouslySetInnerHTML={{
                    __html: item.text,
                }}
            />
        </div>
    </div>
);

export default ProfitItem;
