import React, { useState } from 'react';
import Modal from 'react-modal';
import './style.sass';

const ModalWrap = ({ isOpen, toggleModal, children }) => {
    Modal.setAppElement('#root');
    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            className={'modal'}
        >
            <i className="modal__close" onClick={() => toggleModal(false)} />
            {children}
        </Modal>
    );
};

export default ModalWrap;
