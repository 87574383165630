import React from 'react';
import IndustriesItem from './item';
import { IndustriesItems, IndustriesItemsEn } from '../../data/data';
import parse from 'html-react-parser';
import {
    IndustriesItems2021,
    IndustriesItems2021En,
} from '../../data/2021/industries';
import './style.sass';
import indBG from '../../assets/pic/ind-bg.jpg';
import indBG2021 from '../../assets/pic/ind-bg-2021.jpg';

const Industries = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return langRU ? IndustriesItems2021 : IndustriesItems2021En;
        }
        return langRU ? IndustriesItems : IndustriesItemsEn;
    };
    return (
        <section
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
            className="industries"
            style={{
                background: `url(${year === '2021' ? indBG2021 : indBG})`,
            }}
        >
            <div className="full-wrapper">
                <div className="industries__title">
                    {langRU
                        ? parse(`Отраслевые направления`)
                        : parse(`Specialist areas`)}
                </div>
                <div className="industries__row">
                    {getData().map((item, index) => {
                        return <IndustriesItem key={index} item={item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default Industries;
