import React from 'react';
import './style.sass';

const TechnoProjectsItem = ({ data }) => {
    return (
        <div className="techno-table-body__target">
            <div className="techno-table-body__row">
                <div className="techno-table-body__subtext">
                    {data.additionalClass}
                </div>
                {data.title.length > 0 && (
                    <div className="techno-table-body__con">{data.title}</div>
                )}
            </div>
        </div>
    );
};

export default TechnoProjectsItem;
