import React from 'react';
import AbstractField from './AbstractField';
import PhoneTextMask from '../../components/form/basic-components/MaskTextField/phone';
import EmailTextMask from '../../components/form/basic-components/MaskTextField/email';
import BasicTextRatingField from '../../components/form/basic-components/BasicTextRatingField';

class TextRatingField extends AbstractField {
    config: any;
    constructor(config: any) {
        super();
        this.config = config;
    }

    public renderWithFormik = (props: any) => {
        const { name } = this.config;
        const {
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
            calcEstimate,
        } = props;
        const error = errors[name] && touched[name];

        const newProps: { [p: string]: any } = {
            dependencyValue: values[this.config.dependency?.name],
            onChange: handleChange,
            onBlur: handleBlur,
            error,
            value: values[name],
            setFieldValue: setFieldValue,
            calcEstimate: calcEstimate,
            helperText: error ? errors[name] : '',
        };
        return this.render(newProps);
    };

    public switchCustomMask = (type: string) => {
        switch (type) {
            case 'phone':
                return PhoneTextMask;
            case 'email':
                return EmailTextMask;
            default:
                return 'input';
        }
    };

    public render = (props: any) => {
        const {
            name,
            rows,
            multiline,
            hintMessage,
            hidden,
            dependency,
            mask,
        } = this.config;
        /**
         * те свойства из конфига, которые нужно прокинуть в компонент
         */
        const propsFromConfig = {
            name,
            hintMessage,
            hidden,
            dependency,
            maskComponent: this.switchCustomMask(mask),
            label: this.getLabel(),
            ...(() => (multiline ? { multiline, rows } : {}))(),
        };

        return (
            <BasicTextRatingField key={name} {...propsFromConfig} {...props} />
        );
    };
}

export default TextRatingField;
