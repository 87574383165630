import React from 'react';
import './style.sass';
import iconOpen from '../../assets/img/svg/direction/open.svg';
import iconClose from '../../assets/img/svg/direction/close.svg';

import SlideToggle from 'react-slide-toggle';

const DirectionsItem = ({ langRU, item }) => (
    <div className="directions__col">
        <SlideToggle
            duration={250}
            collapsed
            render={({ toggle, setCollapsibleElement, progress }) => (
                <div className="directions__item">
                    <div
                        className={`directions__question ${
                            progress !== 0 ? 'active' : null
                        }`}
                        onClick={toggle}
                    >
                        <img
                            src={item.icon}
                            alt=""
                            className="directions__img"
                        />
                        <div
                            className="directions__text"
                            dangerouslySetInnerHTML={{
                                __html: item.question,
                            }}
                        />
                        <img
                            src={iconOpen}
                            alt=""
                            className="directions__arrow directions__arrow--open"
                        />
                        <img
                            src={iconClose}
                            alt=""
                            className="directions__arrow directions__arrow--close"
                        />
                    </div>
                    <div
                        className="directions__answer"
                        dangerouslySetInnerHTML={{
                            __html: item.answer,
                        }}
                        ref={setCollapsibleElement}
                    />
                </div>
            )}
        />
    </div>
);

export default DirectionsItem;
