export const HeaderMenuLinks2021 = [
    { id: '/#techno', link: 'Направления', old: false },
    { id: '/last-programs', link: 'Прошлые программы' },
    // { id: '/experts', link: 'Эксперты' },
];
export const HeaderMenuLinks2021En = [
    { id: '/en/#techno', link: 'Direction', old: false },
    { id: '/last-programs/en', link: 'Past programs' },
    // { id: '/experts/en', link: 'Experts' },
];

export const HeaderMenuLinks2021Old = [
    { id: '/', link: 'Акселератор 2021', old: true },
    { id: '/last-programs', link: 'Прошлые программы' },
    // { id: '/experts', link: 'Эксперты' },
];
export const HeaderMenuLinks2021EnOld = [
    { id: '/en', link: 'Accelerator 2021', old: true },
    { id: '/last-programs/en', link: 'Past programs' },
    // { id: '/experts/en', link: 'Experts' },
];
