import React, { useState, useEffect } from 'react';
import ModalWrap from '../../../ManagerList/ExpertModal';
import LKRepository from '../../../../../models/LKRepository';
import axios from 'axios';
import './style.sass';
import RedButton from '../../../../ui/RedButton';
import { Notification, useNotify, useRefresh } from 'react-admin';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        width: 260,
    },
}));

const ProtocolModal = ({ isOpen, toggle, id, push, location }) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [file, setFile] = React.useState(null);

    const handleChange = e => {
        setFile(e.target.files[0].name);
    };
    const removeFile = () => {
        setFile(null);
        reset();
    };

    const { register, handleSubmit, errors, reset } = useForm();
    const onSubmit = data => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnairmatadd
        `,
                LKRepository.generateFormData({
                    APP_ID: id,
                    type: 'meetingMinutes',
                    date: data.date,
                    FILES: [...data.file],
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    setSuccess('Протокол загружен!');
                    setTimeout(() => {
                        window.location.reload();
                    }, 300);
                    // let searchParams = new URLSearchParams(location.search);
                    // searchParams.set('r', 'y');
                    // push({
                    //     search: searchParams.toString(),
                    // });
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };
    return (
        <ModalWrap isOpen={isOpen} toggleModal={toggle}>
            <div
                className={`expert-modal ${
                    success ? `expert-modal--success` : ``
                }`}
            >
                {!success ? (
                    <>
                        <div className="expert-modal__title">
                            Протокол встречи
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="expert-modal__row">
                                <div className="expert-modal__col-12">
                                    <TextField
                                        id="date"
                                        type="date"
                                        name="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Дата встречи"
                                        inputRef={register({
                                            required: true,
                                        })}
                                        fullWidth
                                        error={errors.date ? true : false}
                                    />
                                    {errors.date && (
                                        <span className="error-field">
                                            Выберите дату
                                        </span>
                                    )}
                                </div>

                                <div className="expert-modal__col-12">
                                    <div className="expert-modal__file-title">
                                        Протокол
                                    </div>
                                    {!file ? (
                                        <label
                                            className="lk-file__label"
                                            htmlFor="materials-file"
                                        >
                                            <i />
                                            Прикрепить файл
                                        </label>
                                    ) : (
                                        <>
                                            <div className="expert-modal-d-file">
                                                <div className="expert-modal-d-file__title">
                                                    {file}
                                                </div>
                                                <div className="expert-modal-d-file__btn">
                                                    <span
                                                        onClick={() =>
                                                            removeFile()
                                                        }
                                                    >
                                                        Удалить
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <input
                                        name="file"
                                        id="materials-file"
                                        type="file"
                                        ref={register({
                                            required: true,
                                        })}
                                        onChange={e => handleChange(e)}
                                    />
                                    {errors.file && (
                                        <span className="error-field">
                                            Загрузите файл
                                        </span>
                                    )}
                                </div>
                            </div>
                            {error && (
                                <div className="expert-modal__error">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="expert-modal__bottom">
                                <RedButton size="sm" type="submit">
                                    Отправить
                                </RedButton>

                                <button
                                    className="expert-modal__cancel"
                                    onClick={() => {
                                        toggle(false);
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <div className="expert-modal__success">{success}</div>
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => {
                                    toggle(false);
                                }}
                            >
                                Закрыть
                            </RedButton>
                        </div>
                    </>
                )}
            </div>
        </ModalWrap>
    );
};

export default ProtocolModal;
