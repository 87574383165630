import * as React from 'react';

const EstimateField = ({ source, record, coeff = {} }) => {
    const itogEstimateType = type => {
        return record.estimate_expert_type &&
            record.estimate_expert_type.find(
                item => item.TYPE_EXPERT === type
            ) &&
            record.estimate_expert_type.find(item => item.TYPE_EXPERT === type)
                .UF_RATING.length
            ? +record.estimate_expert_type.find(
                  item => item.TYPE_EXPERT === type
              ).UF_RATING
            : 0;
    };

    const itogEstimate = () => {
        if (
            itogEstimateType('3') + itogEstimateType('4') === 0 ||
            itogEstimateType('3') === 0 ||
            itogEstimateType('4') === 0
        ) {
            return '–';
        } else {
            return (
                itogEstimateType('3') * coeff.beCoeff +
                itogEstimateType('4') * coeff.nteCoeff
            ).toFixed(1);
        }
    };

    return <div className={`estimate-field-itog`}>{itogEstimate()}</div>;
};

export default EstimateField;
