import React from 'react';
import { HistoryItems } from '../../data/data';
import { HistoryItemsEn } from '../../data/data';

import parse from 'html-react-parser';

import './style.sass';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';

const History = ({ langRU }) => {
    const data = langRU ? HistoryItems : HistoryItemsEn;
    return (
        <section
            className="history"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="history__title">
                    {langRU
                        ? parse('Истории успеха')
                        : parse('Success stories')}
                </div>
                <div className="history-navigation">
                    <div id="history-nav-prev">
                        <img src={arrowPrev} alt="" />
                    </div>
                    <div id="history-nav-next">
                        <img src={arrowNext} alt="" />
                    </div>
                </div>
                <div className="history__row history-slider">
                    {data.map((item, index) => {
                        return (
                            <div className="history__col" key={index}>
                                <div
                                    className="history__item"
                                    data-fancybox
                                    data-src={`#${item.id}`}
                                    href="javascript:;"
                                >
                                    <div className="history__img">
                                        <img
                                            src={item.img ? item.img : null}
                                            alt=""
                                        />
                                    </div>
                                    <div className="history__subtitle">
                                        {item.subtitle}
                                    </div>
                                    <div
                                        className="history__text"
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    />
                                    <a
                                        href="javascript:;"
                                        className="history__link"
                                    >
                                        {' '}
                                        {langRU
                                            ? parse('Подробнее')
                                            : parse('')}
                                    </a>
                                </div>

                                <div style={{ display: 'none' }} id={item.id}>
                                    <div className="history-modal">
                                        <div className="history-modal__row">
                                            <div className="history-modal__col history-modal__col--full">
                                                <div className="history-modal__item history-modal__item--img">
                                                    <img
                                                        src={item.img}
                                                        alt=""
                                                        className="history-modal__img"
                                                    />
                                                </div>
                                            </div>

                                            {item.modalLogo ? (
                                                <div className="history-modal__col">
                                                    <div className="history-modal__item history-modal__item--logo">
                                                        <img
                                                            src={item.modalLogo}
                                                            alt=""
                                                            className="history-modal__img"
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div
                                                className={`history-modal__col ${
                                                    !item.modalLogo
                                                        ? 'single'
                                                        : null
                                                }`}
                                            >
                                                <div
                                                    className={`history-modal__item ${
                                                        !item.modalLogo
                                                            ? 'single'
                                                            : null
                                                    }`}
                                                >
                                                    <div className="history-modal__title">
                                                        {item.subtitle}
                                                    </div>
                                                    <div
                                                        className="history-modal__text"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.modalDescription,
                                                        }}
                                                    />
                                                    <div className="history-modal__subtitle">
                                                        {' '}
                                                        {langRU
                                                            ? parse(
                                                                  'Функциональный заказчик:'
                                                              )
                                                            : parse(
                                                                  'End customer:'
                                                              )}
                                                    </div>
                                                    <div
                                                        className="history-modal__text"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.modalClient,
                                                        }}
                                                    />
                                                    <div className="history-modal__results">
                                                        <div className="history-modal__subtitle">
                                                            {langRU
                                                                ? parse(
                                                                      'Результаты:'
                                                                  )
                                                                : parse(
                                                                      'Results'
                                                                  )}
                                                        </div>
                                                        <div
                                                            className="history-modal__text"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item.modalResults,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default History;
