export const FastTrackItems2021 = [
    `Подайте заявку<br/>в&nbsp;акселератор`,
    `Пройдите<br/>\n отбор`,
    `Участвуйте<br/>в&nbsp;акселераторе`,
    `Проведите<br/>испытания`,
    `Станьте<br/>поставщиком РЖД`,
];
export const FastTrackItems2021En = [
    `Apply now<br/> to&nbsp;the accelerator`,
    `Undergo<br/> the selection process`,
    `Participate<br/> in&nbsp;the accelerator`,
    `Conduct<br/> tests`,
    `Become<br/> a&nbsp;supplier of&nbsp;Russian Railways`,
];
