import React, { useEffect } from 'react';
import './style.sass';
import BodyResource from '../../components/resources/BodyResource';
import ResourceTitlePage from '../../components/ui/ResourceTitlePage';
import links from '../../data/agreement/links.json';
import body from '../../data/agreement/body.json';
import Divider from '@material-ui/core/Divider';
const Agreement = () => {
    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document
                    .querySelector(this.getAttribute('href'))
                    .scrollIntoView({
                        behavior: 'smooth',
                    });
            });
        });
    });
    return (
        <section className="personal-data" id="personal-data">
            <div className="full-wrapper">
                <ResourceTitlePage
                    text="Соглашение о правомерности передачи персональных данных и об обеспечении конфиденциальности передаваемых персональных данных
(Пользовательское соглашение)"
                />
                <BodyResource>
                    <div className="personal-data__top">
                        <div className="personal-data__date">
                            Дата официальной публикации 23.07.2020
                        </div>
                    </div>
                    <Divider />
                    <ol className="personal-data__contents-list">
                        {links.map(({ link, label }, index) => (
                            <li>
                                <a
                                    key={index}
                                    href={`#${link}`}
                                    className="personal-data__link"
                                >
                                    {label}
                                </a>
                            </li>
                        ))}
                    </ol>
                    <Divider />
                    <ol className="personal-data__list">
                        {body.map(({ id, title, text }, index) => {
                            return (
                                <li
                                    className="personal-data__item"
                                    id={id}
                                    key={index}
                                >
                                    {title}
                                    <a
                                        href="#personal-data"
                                        className="personal-data__to-top"
                                    />
                                    <ol className="personal-data__inner-list">
                                        {text.map(text => {
                                            if (typeof text === 'string') {
                                                return (
                                                    <li
                                                        key={text}
                                                        className="personal-data__item personal-data__item--inner"
                                                        dangerouslySetInnerHTML={{
                                                            __html: text,
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <li className="personal-data__item personal-data__item--inner personal-data__item--no-before">
                                                        <ol className="personal-data__deep-list">
                                                            {text.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="personal-data__item personal-data__item--deep"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item,
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        </ol>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ol>
                                </li>
                            );
                        })}
                    </ol>
                </BodyResource>
            </div>
        </section>
    );
};

export default Agreement;
