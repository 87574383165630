import React, { FC } from 'react';
import './style.sass';
import ExpertsItem from '../ExpertsItem';

interface IProps {
    items: { name: string; img: string; position: string }[];
    defaultCount: number;
}

const ExpertsRow: FC<IProps> = ({ items, defaultCount }) => {
    return (
        <div className="archive-experts__row">
            {items?.length > 0 &&
                items.map(({ img, name, position }, index) => {
                    if (index + 1 <= defaultCount) {
                        return (
                            <ExpertsItem
                                key={index}
                                img={img}
                                name={name}
                                position={position}
                            />
                        );
                    }
                })}
        </div>
    );
};

export default ExpertsRow;
