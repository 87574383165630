import React, { useEffect } from 'react';
import './style.sass';
import Header from '../../components/2021/Header';
import AOS from 'aos';
import 'aos/src/sass/aos.scss';
import { YMInitializer } from 'react-yandex-metrika';
import DecoredBlock from '../../components/DecoredBlock';
import LastProgramBlock from '../../components/LastProgramsBlock';
import Footer from '../../components/2021/Footer';

const LastPrograms = props => {
    useEffect(() => {
        AOS.init();
        window.addEventListener('scroll', () => {
            AOS.refresh();
        });
    });

    return (
        <>
            <div className="ym-counter">
                <YMInitializer
                    accounts={[82711978]}
                    options={{ webvisor: true }}
                />
            </div>
            <Header langRU={true} year="2021" />
            <DecoredBlock title="Прошлые программы" />
            <LastProgramBlock langRU={true} />
            <Footer langRU={true} />
        </>
    );
};

export default LastPrograms;
