import React, { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import AOS from 'aos';

import Header from '../../components/2021/Header';
import FirstScreen from '../../components/2022/FirstScreen';
import IntroText from '../../components/IntroText';
import Organizers from '../../components/Organizers';
import Partners from '../../components/Partners';
import FastTrack from '../../components/FastTrack';
import Profit from '../../components/Profit';
// import Industries from '../../components/Industries';
// import Directions from '../../components/Directions';
import Criteria from '../../components/Criteria';
import Schedule from '../../components/2022/Schedule';
import FAQ from '../../components/FAQ';
import PartnersAlt from '../../components/PartnersAlt';
// import OnlineMap from '../../components/OnlineMap';
import ExpertsTabs from '../../components/ExpertsTabs';
import HistoryTabs from '../../components/HistoryTabs';
import NewsSlider from '../../components/NewsSlider';
import Footer from '../../components/2022/Footer';
import TechnoFocus from '../../components/2022/TechnoFocus';
import Scroller from '../../components/Scroller';
import { useWindowWidth } from '../../hooks/useWindowWidth';
// import promoBg from '../../assets/pic/promo2022.jpg';
import promoBg2022 from '../../assets/pic/promoBg2022.png';
import ProjectList from '../../components/ProjectsList';

import 'aos/src/sass/aos.scss';
import './style.sass';

const Page2021 = props => {
    const links = [
        { name: 'intro', label: 'Об&nbsp;Акселераторе' },
        { name: 'organizers', label: 'Организаторы&nbsp;и&nbsp;партнеры' },
        { name: 'news', label: 'Новости' },
        // { name: 'industries', label: 'Отраслевые&nbsp;направления' },
        { name: 'schedule', label: 'График' },
        { name: 'projects', label: 'Проекты' },
        { name: 'track', label: 'Процесс&nbsp;работы' },
        { name: 'techno', label: 'Технологический&nbsp;фокус' },
        { name: 'criteria', label: 'Критерии&nbsp;отбора' },
        { name: 'experts', label: 'Эксперты' },
        { name: 'history', label: 'Истории&nbsp;успеха' },
        { name: 'faq', label: 'FAQ' },
        { name: 'partners-alt', label: 'Инфопартнеры' },
        { name: 'contacts', label: 'Контакты' },
    ];

    const width = useWindowWidth();
    const isShow = width > 1740;
    useEffect(() => {
        AOS.init();
        window.addEventListener('scroll', () => {
            AOS.refresh();
        });
    });
    return (
        <>
            <div className="ym-counter">
                <YMInitializer
                    accounts={[82711978]}
                    options={{ webvisor: true }}
                />
            </div>
            {isShow && <Scroller links={links} />}
            <Header langRU={true} year="2021" />
            <FirstScreen
                langRU={true}
                type="full"
                bg={promoBg2022}
                year="2022"
            />
            <div name="intro">
                <IntroText langRU={true} year="2022" />
            </div>
            <div name="organizers">
                <Organizers langRU={true} year="2022" />
            </div>
            <div name="news">
                <NewsSlider langRU={true} year="2022" />
            </div>
            <div name="schedule">
                <Schedule langRU={true} year="2022" />
            </div>
            <div name="projects">
                <ProjectList langRU={true} />
            </div>
            <div name="techno">
                <TechnoFocus langRU={true} />
            </div>
            <div name="partners">
                <Partners langRU={true} year="2022" />
            </div>
            <div name="track">
                <FastTrack langRU={true} year="2021" />
            </div>
            <div name="profit">
                <Profit langRU={true} year="2022" />
            </div>
            <div name="criteria">
                <Criteria langRU={true} year="2021" />
            </div>
            <div name="experts">
                <ExpertsTabs langRU={true} year="2022" />
            </div>
            <div name="history">
                <HistoryTabs langRU={true} year="2022" />
            </div>
            <div name="faq">
                <FAQ langRU={true} year="2022" />
            </div>
            <div name="partners-alt">
                <PartnersAlt langRU={true} year="2022" />
            </div>

            {/*<div name="industries">*/}
            {/*    <Industries langRU={true} year="2021" />*/}
            {/*</div>*/}

            {/*<div name="directions">*/}
            {/*    <Directions langRU={true} year="2021" />*/}
            {/*</div>*/}

            {/*<div name="map">*/}
            {/*    <OnlineMap langRU={true} />*/}
            {/*</div>*/}

            <div name="contacts">
                <Footer langRU={true} year="2022" />
            </div>
        </>
    );
};

export default Page2021;
