import React, { FC } from 'react';
// @ts-ignore
import logoIcon from '../../../assets/img/svg/admin/logo-icon.svg';
// @ts-ignore
import logoText from '../../../assets/img/svg/admin/logo-text.svg';
// @ts-ignore
import redCorner from '../../../assets/img/svg/admin/red-corner.svg';
import './style.sass';

interface IProps {}

const HeaderAdminLeft: FC<IProps> = props => {
    return (
        <div className="header-admin-left">
            {/* здесь используется не компонент Link, потому что basename = '/admin'
             и я не нашла, как это проигнорировать*/}
            <a href="/" className="header-admin-left__logo">
                <div className="header-admin-left__logo-text">
                    <img
                        className="header-admin-left__logo-text-svg"
                        src={logoText}
                        alt=""
                    />
                </div>
                <div className="header-admin-left__logo-icon">
                    <img
                        className="header-admin-left__logo-icon-svg"
                        src={logoIcon}
                        alt=""
                    />
                </div>
            </a>
            <div className="header-admin-left__decor">
                <img
                    className="header-admin-left__decor-icon"
                    src={redCorner}
                    alt=""
                />
            </div>
        </div>
    );
};

export default HeaderAdminLeft;
