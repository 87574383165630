import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import './style.sass';
import axios from 'axios';
import LKRepository from '../../../../models/LKRepository';

const FileGroup: FC<any> = props => {
    const [files, setFiles] = useState<File[]>([]);
    const [fileNames, setFileNames] = useState<{ id: string; value: any }[]>(
        []
    );

    const {
        name,
        label,
        accept,
        error,
        onChange,
        helperText,
        multiple,
        value,
    } = props;

    const isShowLabel = multiple || fileNames.length < 1;

    const removeFilesRoute =
        '/bitrix/services/main/ajax.php?action=webpractik:main.forms.removefile';

    const handleChange = (e: ChangeEvent) => {
        const targetFiles = (e.target as HTMLInputElement).files;
        console.log(targetFiles);
        if (targetFiles) {
            setFiles(state => [...state, ...Array.from(targetFiles)]);
            setFileNames(prevState => {
                const newFileNames = Array.from(targetFiles).map(
                    (file, key) => ({
                        id: key + '',
                        value: file.name,
                    })
                );
                return [...prevState, ...newFileNames];
            });
        }
    };

    const handleRemove = (key: number) => {
        const copy = [...files];

        if (fileNames.length) {
            axios
                .post(
                    removeFilesRoute,
                    LKRepository.generateFormData({
                        PROP_NAME: 'ADDITION_FILES',
                        FILE_ID: fileNames[key].id,
                    })
                )
                .then(res => {
                    const copyfileNames = [...fileNames];
                    copyfileNames.splice(key, 1);
                    setFileNames(copyfileNames);
                });
        } else {
            copy.splice(key, 1);
            setFiles(copy);
        }
    };

    useEffect(() => {
        const valuesArr = value
            ? Object.keys(value).map(item => ({
                  id: item,
                  value: value[item],
              }))
            : [];

        if (valuesArr.length && valuesArr[0]) {
            onChange(name + '_EXIST', Object.keys(value));
        }

        setFileNames(valuesArr);
    }, []);

    useEffect(() => {
        onChange(name, fileNames);
    }, [fileNames]);

    useEffect(() => {
        onChange(name, files);
    }, [files]);

    return (
        <div className="lk-file">
            <div className="lk-file__title">{label}</div>
            <div className="lk-file__container">
                <div className="lk-file__wrap">
                    {fileNames?.map((item, key) => (
                        <div className="lk-file__item" key={key}>
                            <span className="lk-file__name">
                                {item?.value.includes('upload') ? (
                                    <a
                                        href={item?.value}
                                        target="_blank"
                                        download
                                    >
                                        {item?.value}
                                    </a>
                                ) : (
                                    item?.value || item
                                )}
                            </span>
                            <div
                                className="lk-file__remove"
                                onClick={() => handleRemove(key)}
                            >
                                <i />
                                Удалить
                            </div>
                        </div>
                    ))}
                </div>
                {(isShowLabel && (
                    <label className="lk-file__label" htmlFor={name}>
                        <i />
                        Прикрепить
                    </label>
                )) ||
                    null}

                <input
                    id={name}
                    type="file"
                    onChange={handleChange}
                    multiple={multiple}
                    accept={accept}
                />
            </div>
            {error && <div className="lk-file__error">{helperText}</div>}
        </div>
    );
};

export default FileGroup;
