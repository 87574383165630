import React, { FC } from 'react';
import './style.sass';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    [p: string]: any;
}

interface Option {
    value: string | number;
    label: string;
}

const useStyles = makeStyles({
    root: {
        color: 'blue',
    },
    control: {
        marginBottom: 30,
    },
    select: {
        '&:focus': {
            background: 'transparent',
        },
    },
});

const BasicSelect: FC<IProps> = props => {
    const {
        label,
        options,
        error = false,
        helperText = '',
        hints,
        ...restProps
    } = props;

    const { name } = restProps;
    const initialValue = options.find(
        (option: Option) => option.label === props.value
    )?.value;

    const classes = useStyles();

    return (
        <FormControl size="small" variant="filled" className={classes.control}>
            <InputLabel error={error} id={name}>
                {label}
            </InputLabel>
            <Select
                value={initialValue}
                classes={{ root: classes.select }}
                labelId={name}
                {...restProps}
            >
                {options.map(
                    ({
                        value,
                        label,
                    }: {
                        value: string | number;
                        label: string;
                    }) => {
                        return (
                            <MenuItem value={value} key={value}>
                                {label}
                            </MenuItem>
                        );
                    }
                )}
            </Select>
            {hints && (
                <div
                    className="hints"
                    dangerouslySetInnerHTML={{ __html: hints[restProps.value] }}
                />
            )}
            <FormHelperText error={error}>
                {error ? helperText : ''}
            </FormHelperText>
        </FormControl>
    );
};

export default BasicSelect;
