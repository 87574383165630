import * as React from 'react';

const ObjTextField = ({ source, record = {} }) => {
    const valuesSource = record[source] && Object.values(record[source]);
    if (record[source]) {
        return (
            <div className={`obj-field`}>
                {Array.isArray(valuesSource)
                    ? valuesSource.join(', ')
                    : valuesSource}
            </div>
        );
    } else {
        return null;
    }
};

export default ObjTextField;
