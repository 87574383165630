import React, { useState } from 'react';
import './style.sass';
import AdminTextField from '../../form/AdminTextField';
import { Form, Formik } from 'formik';
import RedButton from '../../ui/RedButton';
import FlexContainerCenter from '../../layout/FlexContainerCenter';
import * as Yup from 'yup';
import errorMessages from 'data/errorMessages.json';
import LKRepository from '../../../models/LKRepository';

const ForgetPassword = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { required, email } = errorMessages;

    const schema = Yup.object().shape({
        LOGIN: Yup.string().email(email).required(required),
    });

    const handleSubmit = values => {
        LKRepository.restorePassword(values)
            .then(res => {
                if (res.data.data.TYPE === 'OK') {
                    setSuccessMessage(
                        'Данные по регистрации были высланы на e-mail'
                    );
                } else {
                    setErrorMessage('Пользователь с таким e-mail не найден');
                }
            })
            .catch(err => console.error(err));
    };

    if (successMessage.length)
        return <div className="forget-success">{successMessage}</div>;

    return (
        <>
            <div className="forget-title">
                Для сброса пароля введите e-mail, указанный при регистрации
            </div>
            <Formik
                initialValues={{ LOGIN: '' }}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {props => (
                    <Form>
                        <FlexContainerCenter>
                            <div className="authorization-form-wrap">
                                <AdminTextField
                                    formikProps={props}
                                    label="E-mail"
                                    name="LOGIN"
                                    type="text"
                                />
                            </div>
                            <RedButton size="sm" type="submit">
                                Отправить ссылку
                            </RedButton>
                        </FlexContainerCenter>
                    </Form>
                )}
            </Formik>
            {(errorMessage.length && (
                <div className="forget-error">{errorMessage}</div>
            )) ||
                null}
        </>
    );
};

export default ForgetPassword;
