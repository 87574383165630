import { Repository } from './Repository';
import { AuthData, IRegistrationValues } from '../types/registration';
import axios, { AxiosResponse } from 'axios';
import User from './User';

class AuthRepository extends Repository {
    public auth = async (values: AuthData): Promise<AxiosResponse> => {
        const formData = this.generateFormData(values);
        return await axios.post(
            `${this.defaultUrl}:main.forms.authorize`,
            formData
        );
    };

    public checkAuth = async (): Promise<AxiosResponse> => {
        return await axios.post(`${this.defaultUrl}:main.forms.checkauth`);
    };

    public register = async (
        values: IRegistrationValues
    ): Promise<AxiosResponse> => {
        const data = {
            LOGIN: values.EMAIL,
            ...values,
        };
        const formData = this.generateFormData(data);
        return await axios.post(
            `${this.defaultUrl}:main.forms.adduser`,
            formData
        );
    };

    public logout = async (): Promise<AxiosResponse> => {
        return await axios.post(`${this.defaultUrl}:main.forms.logout`);
    };

    public saveUserData = async (): Promise<void> => {
        const isDataExists = User.checkUserData();

        if (!isDataExists) {
            const formData = this.generateFormData({
                USERID: User.getId(),
            });
            const result = await axios.post(
                `${this.defaultUrl}:main.forms.infouser`,
                formData
            );
            User.setUserData(result.data.data);
        }
    };
}

export default new AuthRepository();
