import React, { useEffect } from 'react';
import './style.sass';
import ResourceTitlePage from '../../components/ui/ResourceTitlePage';
import BodyResource from '../../components/resources/BodyResource';
import axios from 'axios';
import LKRepository from '../../models/LKRepository';
import RedButton from '../../components/ui/RedButton';

const ConfirmRegistration = props => {
    const getParamsPage = props.location.search;

    const handleConfirmReg = id => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.activeuser
`,
                LKRepository.generateFormData({
                    id: id,
                })
            )
            .then(response => {
                console.log(response.data.data);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (getParamsPage.includes('confirm_registration')) {
            const userIdParams = new URLSearchParams(getParamsPage).get(
                'confirm_user_id'
            );

            handleConfirmReg(userIdParams);
        }
    }, [props.location.search]);

    return (
        <section className="confirm-registration">
            <div className="full-wrapper">
                <ResourceTitlePage text="Подтверждение регистрации" />
                <BodyResource>
                    <div className="confirm-registration__desc">
                        Вы успешно подтвердили регистрацию
                    </div>
                    <RedButton size="lg-m" type="button" href="/lk">
                        Вход в личный кабинет
                    </RedButton>
                </BodyResource>
            </div>
        </section>
    );
};

export default ConfirmRegistration;
