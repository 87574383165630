import * as React from 'react';
import './style.sass';
import ResourceTitlePage from '../../../ui/ResourceTitlePage';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LKRepository from '../../../../models/LKRepository';

const ExpertsQuestionareTitle = props => {
    const [expertData, setExpertData] = useState('');
    const searchParamsPage = props.location.search;

    useEffect(() => {
        const userId = JSON.parse(
            new URLSearchParams(searchParamsPage).get('filter')
        ).id;

        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.infouser`,
                LKRepository.generateFormData({
                    USERID: userId,
                })
            )
            .then(response => {
                setExpertData(response.data.data);
            })
            .catch(err => console.log(err));
    }, [props.location.search]);

    return (
        <>
            <ResourceTitlePage
                text={
                    expertData.name
                        ? `${expertData.name} ${expertData.surname}`
                        : 'Нет данных о имени эксперта'
                }
            />
            <div className="subtitle-resource">
                {expertData.position ? expertData.position : ''}
            </div>
        </>
    );
};

export default ExpertsQuestionareTitle;
