import React from 'react';
import applicationConfig from '../data/application-config.json';
import FieldsCollection from './FieldsCollection';

class Application {
    public config: Array<any> = applicationConfig;

    public fieldsCollection: any = new FieldsCollection(this.config);
}

export default new Application();
