import React, { useEffect, useRef, useState } from 'react';
import TechnoProjectsItem from './TechnoProjectsItem';
import Collapse from '@kunukn/react-collapse';

const ProjectsItem = ({ item, btnText, active, handleToggle }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && active === item.id) {
            setTimeout(() => {
                ref.current.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }, 300);
        }
    }, [active]);

    const [arrowUp, setArrowUp] = useState(false);

    return (
        <div className="techno-item">
            <div
                className={`techno-item__category ${
                    arrowUp ? 'category-open' : ''
                }`}
                ref={ref}
                onClick={() => handleToggle(item.id) && setArrowUp}
            >
                <div className="techno-item__title"> {item.category}</div>
                <svg
                    className="techno-item__icon"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                >
                    <path
                        d="M14.6 1 8 8.2 1.4 1"
                        stroke="#2B2C3A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                    />
                </svg>
            </div>
            <Collapse
                isOpen={active === item.id}
                transition="height 300ms cubic-bezier(.4, 0, .2, 1)"
            >
                <div className="techno-table-body__wrapper">
                    {item.items.map(innerItem => (
                        <TechnoProjectsItem
                            additionalClass={item.additionalClass}
                            key={innerItem.id}
                            data={innerItem}
                            btnText={btnText}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default ProjectsItem;
