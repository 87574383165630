import User from '../models/User';
import AuthRepository from '../models/AuthRepository';

export const authProvider = {
    login: userData => {
        return AuthRepository.auth(userData)
            .then(res => {
                if (res.data.data.TYPE === 'ERROR') {
                    throw res.data.data.MESSAGE;
                }
                User.setLocalData({
                    id: res.data.data,
                });
            })
            .then(() => AuthRepository.saveUserData());
    },
    logout: () => {
        AuthRepository.logout()
            .then(() => User.removeLocalData())
            .catch(err => console.error(err));
        return Promise.resolve();
    },
    checkAuth: () => {
        return AuthRepository.checkAuth()
            .then(async res => {
                if (res.data.data) {
                    User.setLocalData({ id: res.data.data });
                    await AuthRepository.saveUserData();
                    await Promise.resolve();
                } else {
                    await Promise.reject();
                }
            })
            .catch(() => Promise.reject());
    },
    checkError: error => {
        console.log(error);
    },
    getPermissions: () => {
        const role = User.getUserData().permissions;
        return role ? Promise.resolve(role) : Promise.reject();
    },
};
