import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menuLink: {
        font: '16px/150% Roboto, Helvetica, Arial, sans-serif',
        color: '#858e9c',
        paddingLeft: '24px',
        '&[aria-current="page"]': { color: '#212121' },
    },
});

export const Menu = ({ onMenuClick /*, logout*/ }) => {
    const classes = useStyles();
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    const filterMenuItems = resource => {
        return !resource.options.shouldHideInMenu;
    };

    return (
        <>
            {
                // prettier-ignore
                resources
                    .filter(filterMenuItems)
                    .map(resource => {
                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) ||
                                    resource.name
                                }
                                className={classes.menuLink}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                            />
                        );
            })
            }
        </>
    );
};
