import React, { FC, ReactNode } from 'react';
import './style.sass';

interface IProps {
    children: ReactNode;
    paddingTop?: 'lg' | '';
    minHeight?: boolean;
    contentMaxWidth?: boolean;
    contentMaxWidthProfile?: boolean;
    requestEdit?: boolean;
}

const BodyResource: FC<IProps> = ({
    children,
    paddingTop = '',
    contentMaxWidth = false,
    requestEdit = false,
    contentMaxWidthProfile = false,
}) => {
    const classList = ['body-resource'];

    if (paddingTop) {
        classList.push('body-resource--pt-lg');
    }

    if (contentMaxWidth) {
        classList.push('body-resource--content-max-width');
    }
    if (contentMaxWidthProfile) {
        classList.push('body-resource--content-max-width-profile');
    }
    if (requestEdit) {
        classList.push('body-resource--request-edit');
    }
    return (
        <div className={classList.join(' ')}>
            <div className="body-resource__content">{children}</div>
        </div>
    );
};

export default BodyResource;
