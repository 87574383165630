import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

const EmailTextMask: FC<any> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={emailMask}
            showMask
        />
    );
};

export default EmailTextMask;
