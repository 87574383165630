import React, { FC } from 'react';
import './style.sass';
import { statusApplication } from '../../../types/application';

interface IProps {
    status: statusApplication;
}

class MetaEntryStatus extends React.Component<IProps> {
    getStatusText = () => {
        const { status } = this.props;
        const messages: { [index: string]: string } = {
            Черновик: 'Черновик (заявка не отправлена на рассмотрение)',
            'На доработке': 'Заявка требует доработки',
            'На премодерации': 'Заявка на премодерации',
            Отклонена: 'Заявка отклонена',
            Одобрена: 'Заявка одобрена',
            'На экспертизе': 'Заявка на экспертизе',
            'Очный отбор': 'Заявка на очном отборе',
            DemoDay: 'Заявка на DemoDay',
            Сопровождение: 'Заявка на сопровождении',
            Архив: 'Заявка в архиве',
        };
        return messages[status];
    };

    render() {
        const { status } = this.props;
        const classList = ['meta-entry-status'];

        switch (status) {
            case 'Одобрена':
                classList.push('meta-entry-status--green');
                break;
            case 'На премодерации':
            case 'На доработке':
                classList.push('meta-entry-status--red');
                break;
            case 'Черновик':
                classList.push('meta-entry-status--red');
                break;
            case 'На экспертизе':
                classList.push('meta-entry-status--blue');
                break;
            case 'Отклонена':
                classList.push('meta-entry-status--red');
                break;
            default:
                classList.push('meta-entry-status--blue');
        }

        return (
            <div className={classList.join(' ')}>{this.getStatusText()}</div>
        );
    }
}

export default MetaEntryStatus;
