import React, { FC } from 'react';

const FormGroupTitle: FC<{ label: string; id: string }> = ({ label, id }) => {
    // @ts-ignore
    return (
        <div id={id ? id : ''} className="lk-form-title">
            {label}
        </div>
    );
};

export default FormGroupTitle;
