import React from 'react';
import OrganizersItem from './item';
import { OrganizatorsItems, OrganizatorsItemsEn } from '../../data/data';
import parse from 'html-react-parser';
import orgBg from '../../assets/pic/organizers-bg.jpg';
import orgBg2021 from '../../assets/pic/organizers/bg-2021.jpg';
import trackBg2022 from '../../assets/pic/faq-bg-2022.jpg';
import './style.sass';
import {
    OrganizatorsItems2021,
    OrganizatorsItems2021En,
} from '../../data/2021/organizators';

import {
    OrganizatorsItems2022,
    OrganizatorsItems2022En,
} from '../../data/2022/organizators';

const Organizers = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2022'){
            return  langRU ? OrganizatorsItems2022 :OrganizatorsItems2022En;
        }
        if (year === '2021') {
            return langRU ? OrganizatorsItems2021 : OrganizatorsItems2021En;
        }
        return langRU ? OrganizatorsItems : OrganizatorsItemsEn;
    };


    return (
        <section
            className="organizers"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
            style={{
                background: `url(${year === '2022' ? trackBg2022 : orgBg2021})`,
            }}
        >
            <div className="full-wrapper">
                <div className="organizers__title">
                    {langRU
                        ? parse(
                              `Организаторы<span>корпоративной акселерационной программы ОАО&nbsp;&laquo;РЖД&raquo;</span>`
                          )
                        : parse(
                              `Organizers<span>Russian Railways Corporate Acceleration Program</span>`
                          )}
                </div>
                <div className="organizers__row">
                    {getData().map((item, index) => {
                        return (
                            <OrganizersItem
                                key={index}
                                langRU={langRU}
                                item={item}
                                alt={year === '2021'}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Organizers;
