import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import logoEn from '../../assets/img/svg/first-screen-logo-en.svg';
import logo from '../../assets/img/svg/first-screen-logo.svg';
import videoAlt from '../../assets/video/bg-movie-alt.mov';
import './style.sass';

const FirstScreen = ({ full, langRU, bg = '', outbox = '', type, year }) => {
    const regLink = langRU
        ? '/lk#/login'
        : 'https://www.f6s.com/russian-railways-accelerator-logistics/apply';

    useEffect(() => {
        if (type === 'video') {
            document.addEventListener('DOMContentLoaded', function (event) {
                Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
                    get: function () {
                        return (
                            this.currentTime > 0 &&
                            !this.paused &&
                            !this.ended &&
                            this.readyState > 2
                        );
                    },
                });
                document.getElementById('firstScreen-video').play();
            });
        }
    }, []);
    return (
        <section
            className={`first-screen ${
                type && type !== 'video' && `bg ${type}`
            } ${full && 'full'}`}
            style={{ backgroundImage: `url(${bg}` }}
        >
            {type === 'outbox' && (
                <div className="first-screen__decor">
                    <img src={outbox} alt="" />
                </div>
            )}
            {type === 'full' ||
                (full && <div className="first-screen__bg-decor" />)}
            {type === 'video' && (
                <video playsInline autoPlay muted id="firstScreen-video">
                    <source src={videoAlt} type="video/mp4" />
                </video>
            )}

            <div className="full-wrapper">
                <div className="first-screen__row">
                    <div className="first-screen__logo">
                        <img src={langRU ? logo : logoEn} alt="" />
                    </div>
                    {year === '2021' && (
                        <div className="first-screen__notify">
                            {langRU
                                ? parse(
                                      'Программа 2021 года завершена. Подробности в&nbsp;новостях.'
                                  )
                                : parse(
                                      'The 2021 program has been completed. Details in&nbsp;the news.'
                                  )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FirstScreen;
