import React, {
    Fragment,
    cloneElement,
    useMemo,
    useEffect,
    useState,
} from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    Responsive,
    TextField,
    TextInput,
    SearchInput,
    useListContext,
    TopToolbar,
    Button,
    sanitizeListRestProps,
    useRefresh,
    withDataProvider,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StatusTextField from './Fields/StatusTextField';
import StatusExpertIcon from './Fields/StatusExpertIcon';
import EstimateField from './Fields/EstimateField';
import ActionField from './Fields/ActionField';
import './style.sass';
import { makeStyles } from '@material-ui/core/styles';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import IconEvent from '@material-ui/icons/GetApp';
import axios from 'axios';
import LKRepository from '../../../models/LKRepository';
import ExportButton from '../../ui/ExportButton';
import User from '../../../models/User';
import DownloadAllButton from '../../ui/DownloadAllButton';
import ActionFieldSpectator from './Fields/ActionFieldSpectator';
import FileSaver from 'file-saver';
import ObjTextField from './Fields/ObjTextField';

const filterStyles = {
    status: { width: 150 },
};
export const styles = {
    widthHalf: { width: '329px' },
    width: { width: '674px' },
    widthFormGroup: { display: 'inline-block', marginRight: 16 },
    marginBox: { marginBottom: '20px' },
    boxShadow: { boxShadow: 'none' },
    root: { boxShadow: 'none' },
    indicator: { backgroundColor: 'red' },
};

const useStyles = makeStyles(styles);

export const ManagerListFilter = props => {
    const classes = useStyles();
    return (
        <Filter {...props} className={classes.marginBox}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};
const datagridStyles = {
    total: { fontWeight: 'bold' },
};

const ListActions = props => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    // const [exportList, setExportList] = useState('');
    //
    // useEffect(() => {
    //     axios
    //         .post(
    //             `/bitrix/services/main/ajax.php?action=webpractik:main.forms.generateexcelmoderation`,
    //             LKRepository.generateFormData({ STATUS: filterValues.status })
    //         )
    //         .then(response => {
    //             setExportList(response.data.data);
    //         })
    //         .catch(err => console.log(err));
    // }, [filterValues]);

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {/*<Button*/}
            {/*    href={exportList ? exportList : null}*/}
            {/*    label="Экспортировать"*/}
            {/*>*/}
            {/*    <IconEvent />*/}
            {/*</Button>*/}
            <ExportButton />
            <DownloadAllButton />
        </TopToolbar>
    );
};

const ExportArchiveByIds = withDataProvider(
    class extends React.Component {
        state = {
            archiveLink: '',
        };
        handleClick = () => {
            this.props.dataProvider
                .exportByIds(
                    'manager/request',
                    { ids: this.props.selectedIds, data: { isAdmin: false } },
                    { onSuccess: { unselectAll: true, refresh: true } }
                )
                .then(({ data }) => {
                    console.log(window.location);
                    const host = window.location.origin;
                    console.log(`${host}${data.data}`);
                    const url = `${host}${data.data}`;
                    FileSaver.saveAs(url);
                });
        };

        render() {
            return (
                <button
                    className="export-by-ids-btn"
                    onClick={this.handleClick}
                >
                    Экспорт выбранных заявок
                </button>
            );
        }
    }
);
const ListBulkActionButtons = props => {
    return (
        <>
            <ExportArchiveByIds {...props} />
        </>
    );
};
class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'Все', name: 'Все' },
        { id: 'На премодерации', name: 'Премодерация' },
        { id: 'Бизнес-экспертиза', name: 'БЭ' },
        { id: 'Техническая экспертиза', name: 'НТЭ' },
        { id: 'Очный отбор', name: 'Очный отбор' },
        { id: 'Одобрена', name: 'КАП' },
        { id: 'Demoday', name: 'Demoday' },
        { id: 'Сопровождение', name: 'Сопровождение' },
        { id: 'Отклонена', name: 'Отклоненные' },
        { id: 'Архив', name: 'Архив' },
    ];

    state = {
        'На премодерации': [],
        'Бизнес-экспертиза': [],
        'Техническая экспертиза': [],
        Все: [],
        Одобрена: [],
        Отклонена: [],
        'Очный отбор': [],
        Архив: [],
        Demoday: [],
        Сопровождение: [],
        coeff: {},
    };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    componentDidMount() {
        // const { filterValues } = this.props;
        // this.handleChange('', filterValues.status);

        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.getexpcoeff`,
                {}
            )
            .then(res => {
                this.setState({ coeff: res.data.data });
            })
            .catch(err => console.log(err));
    }

    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                            className="tab-list-manager"
                        />
                    ))}
                </Tabs>
                <Divider />
                <Responsive
                    medium={
                        <div>
                            {filterValues.status === 'Все' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Все']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}
                            {filterValues.status === 'На премодерации' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['На премодерации']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status === 'Бизнес-экспертиза' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Бизнес-экспертиза']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status ===
                                'Техническая экспертиза' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Техническая экспертиза']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status === 'Очный отбор' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Очный отбор']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status === 'Одобрена' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Одобрена']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status === 'Demoday' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Demoday']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}
                            {filterValues.status === 'Сопровождение' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Сопровождение']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}

                            {filterValues.status === 'Отклонена' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Отклонена']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}
                            {filterValues.status === 'Архив' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Архив']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <TextField source="state" label="Stage" />
                                    <StatusExpertIcon
                                        source="status"
                                        label="БЭ"
                                    />
                                    <StatusExpertIcon
                                        source="status"
                                        label="ТЭ"
                                    />
                                    <EstimateField
                                        source="status"
                                        label="Оценка"
                                        coeff={this.state.coeff}
                                    />
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) !==
                                            'spectator' && (
                                            <ActionField
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                    {this.props.permissions &&
                                        User.getRole(this.props.permissions) ===
                                            'spectator' && (
                                            <ActionFieldSpectator
                                                source="id"
                                                label="Действия"
                                            />
                                        )}
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const ManagerList = ({ classes, ...props }) => (
    <Fragment>
        <ResourceTitlePage text="Заявки" />
        <List
            {...props}
            filterDefaultValues={{ status: 'Все' }}
            sort={{ order: 'DESC' }}
            perPage={25}
            filters={<ManagerListFilter />}
            actions={<ListActions />}
            bulkActionButtons={<ListBulkActionButtons />}
        >
            <StyledTabbedDatagrid {...props} />
        </List>
    </Fragment>
);

export default ManagerList;
