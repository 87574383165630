import React, { Component } from 'react';
import './style.sass';
import ParticipantCabinetText from '../ParticipantCabinetText';
import ParticipantEntry from '../ParticipantEntry';
import ParticipantButtons from '../ParticipantButtons';
import { metaApplication } from '../../../types/application';

interface IState {}

interface IProps {
    metaApplication: metaApplication | null;
}

class ParticipantCabinetContent extends Component<IProps, IState> {
    render() {
        const { metaApplication }: IProps = this.props;

        let hasEntryTopPosition = false;

        if (metaApplication && metaApplication!.status !== 'Черновик') {
            hasEntryTopPosition = true;
        }

        const withoutNewEntryButton = Boolean(metaApplication);

        if (hasEntryTopPosition) {
            return (
                <>
                    <ParticipantEntry metaApplication={metaApplication} />
                    <ParticipantButtons
                        withoutNewEntryButton={withoutNewEntryButton}
                    />
                    <ParticipantCabinetText />
                </>
            );
        }

        return (
            <>
                <ParticipantCabinetText />
                <ParticipantEntry metaApplication={metaApplication} />
                <ParticipantButtons
                    withoutNewEntryButton={withoutNewEntryButton}
                />
            </>
        );
    }
}

export default ParticipantCabinetContent;
