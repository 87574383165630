import * as Yup from 'yup';
import errorMessages from 'data/errorMessages.json';

abstract class AbstractField {
    abstract config: any;

    abstract render: (props: any) => any;
    abstract renderWithFormik: (props: any) => any;

    public getInitValue = () => {
        const { name, value } = this.config;
        return { [name]: value };
    };

    public getScheme = () => {
        const {
            minPassword,
            minLowercase,
            minUppercase,
            passwordsMatch,
            minLengthCommentsSmall,
            minLengthCommentsBig,
            minLength200,
            minLength250,
            minLength300,
        } = errorMessages;
        const { name, validationRules, dependency } = this.config;
        const scheme = validationRules?.reduce((scheme: any, item: any) => {
            switch (item) {
                case 'dependency-pilot':
                    return Yup.lazy(value => {
                        if (value === 23) {
                            return scheme
                                .string()
                                .required('Поле обязательно для заполнения');
                        }
                        return scheme.string();
                    });
                case 'dependency-int':
                    return Yup.lazy(value => {
                        if (value === 20) {
                            return scheme
                                .string()
                                .required('Поле обязательно для заполнения');
                        }
                        return scheme.string();
                    });
                case 'dependency-sales':
                    return Yup.lazy(value => {
                        if (value === 25) {
                            return scheme
                                .string()
                                .required('Поле обязательно для заполнения');
                        }
                        return scheme.string();
                    });
                case 'string':
                    return scheme.string();
                case 'nullable':
                    return scheme.nullable();
                case 'stringMin100':
                    return scheme.min(100, minLengthCommentsSmall);
                case 'stringMin200':
                    return scheme.min(200, minLength200);
                case 'stringMin250':
                    return scheme.min(250, minLength250);
                case 'stringMin300':
                    return scheme.min(300, minLength300);
                case 'stringMin500':
                    return scheme.min(500, minLengthCommentsBig);
                case 'array':
                    return scheme.array();
                case 'required':
                    return scheme.required('Поле обязательно для заполнения');
                case 'password':
                    return scheme
                        .min(8, minPassword)
                        .matches(/[a-z]/, minLowercase)
                        .matches(/[A-Z]/, minUppercase);
                case 'password-confirm':
                    return scheme.oneOf(
                        [Yup.ref('password'), undefined],
                        passwordsMatch
                    );

                default:
                    return scheme;
            }
        }, Yup);
        return { [name]: scheme };
    };

    protected getLabel = () => {
        const { label, validationRules } = this.config;
        return validationRules.includes('required') ||
            validationRules.includes('presentation')
            ? `${label} *`
            : label;
    };
}

export default AbstractField;
