import React from 'react';
import { FAQItems, FAQItemsEn } from '../../data/data';
import './style.sass';
import FAQItem from './item';
import bg from '../../assets/pic/faq-bg.jpg';
import bg2021 from '../../assets/pic/faq-bg-2021.jpg';
import bg2022 from '../../assets/pic/faq-bg-2022.jpg';
import { FAQItems2021, FAQItems2021En } from '../../data/2021/faq';

const FAQ = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return langRU ? FAQItems2021 : FAQItems2021En;
        }
        return langRU ? FAQItems : FAQItemsEn;
    };
    return (
        <section
            className="faq"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
            style={{
                background: `url(${year === '2021' ? bg2021 : bg2022})`,
            }}
        >
            <div className="full-wrapper">
                <div className="faq__title">FAQ</div>
                <div className="faq__row">
                    {getData().map((item, index) => {
                        return <FAQItem key={index} item={item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
