import React from 'react';
import { PartnersAltItems } from '../../data/data';
import parse from 'html-react-parser/index';
import { PartnersAltItems2021 } from '../../data/2021/partners-alt';
import { PartnersAltItems2022 } from '../../data/2022/partners-alt';
import './style.sass';

const PartnersAlt = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') return PartnersAltItems2021;
        if (year === '2022') return PartnersAltItems2022;
        return PartnersAltItems;
    };

    return (
        <section
            className="partners-alt"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="partners-alt__title">
                    {langRU
                        ? parse('Информационные партнеры')
                        : parse('Media partners')}
                </div>
                <div className="partners-alt__row" style={{'justifyContent': 'center'}}>
                    {getData().map((item, index) => {
                        return (
                            <div className="partners-alt__col" key={index}>
                                <div className="partners-alt__item">
                                    <img
                                        src={item.img}
                                        alt=""
                                        className="partners-alt__img"
                                    />
                                    <div className="partners__overlay">
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            className="partners__link"
                                        >
                                            {item.link}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default PartnersAlt;
