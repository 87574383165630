import React from 'react';
import CriteriaItem from './item';
import { CriteriaItems, CriteriaItemsEn } from '../../data/data';
import {
    CriteriaItems2021,
    CriteriaItems2021En,
} from '../../data/2021/criteria';
import parse from 'html-react-parser';
import './style.sass';

const Criteria = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return langRU ? CriteriaItems2021 : CriteriaItems2021En;
        }
        return langRU ? CriteriaItems : CriteriaItemsEn;
    };

    return (
        <section
            id="experts"
            className="criteria"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="criteria__title">
                    {langRU
                        ? parse(`Критерии отбора`)
                        : parse(`Selection criteria`)}
                </div>

                <div className="criteria__row">
                    {getData().map((item, index) => {
                        return <CriteriaItem key={index} item={item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default Criteria;
