import React, { FC, forwardRef } from 'react';
import './style.sass';
// @ts-ignore
import { UserMenu, MenuItemLink, useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

interface IProps {}

/*const UserLogoutButton: FC<IProps> = props => {
    return <div className="user-logout-button">UserLogoutButton</div>;
};*/

const UserLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            // @ts-ignore
            ref={ref}
        >
            <ExitIcon /> <span className="user-logout-button-label">Выйти</span>
        </MenuItem>
    );
});

export default UserLogoutButton;
