import React, { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import './style.sass';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import StatusesExcel from '../../../data/statusesExcel.json';

const DownloadAllButton = props => {
    const [file, setFile] = useState();

    useEffect(() => {
        async function fetchData() {
            let response = await axios.post(
                '/bitrix/services/main/ajax.php?action=webpractik:main.forms.fullzipexchange',
                {}
            );
            let data = response.data.data;
            setFile(data);
        }

        fetchData().then(res => console.log(res));
    }, []);

    return (
        <a className="export-button" href={file ? file : null} download>
            <GetAppIcon /> Скачать все заявки
        </a>
    );
};

export default DownloadAllButton;
