import React, { FC } from 'react';
import './style.sass';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
    [prop: string]: any;
}

const Spinner: FC<IProps> = props => {
    return (
        <div className="spinner">
            <CircularProgress {...props} color="inherit" />
        </div>
    );
};

export default Spinner;
