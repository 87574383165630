import expert2 from 'assets/pic/experts/experts-02.jpg';
import expert6 from 'assets/pic/experts/experts-06.jpg';
import expert7 from 'assets/pic/experts/experts-07.jpg';
import expert9 from 'assets/pic/experts/experts-09.jpg';
import expert14 from 'assets/pic/experts/experts-14.jpg';
import expert15 from 'assets/pic/experts/experts-15.jpg';
import expert19 from 'assets/pic/experts/experts-19.jpg';
import expert20 from 'assets/pic/experts/experts-20.jpg';
import expert22 from 'assets/pic/experts/experts-22.jpg';
import murzin from 'assets/pic/experts/murzin.jpeg';
import expert29 from 'assets/pic/experts/experts-29.jpg';
import expert32 from 'assets/pic/experts/experts-32.jpg';
import expert33 from 'assets/pic/experts/experts-33.jpg';
import chisnikov from 'assets/pic/experts/chisnikov.jpg';
import popov from 'assets/pic/experts/popov.jpg';
import skovorodnikov from 'assets/pic/experts/skovorodnikov.jpeg';
import polyakov from 'assets/pic/experts/polyakov.jpeg';
import golovin from 'assets/pic/experts/golovin.jpg';
import matveeva from 'assets/pic/experts/matveeva.jpg';
import jeleznov from 'assets/pic/experts/jeleznov.jpg';
import morozova from 'assets/pic/experts/morozova.jpg';
import stambrovskij from 'assets/pic/experts/stambrovskij.jpeg';
import petrov from 'assets/pic/experts/petrov.jpg';
import petrov_pgk from 'assets/pic/experts/petrov-pgk.jpg';
import metelkin from 'assets/pic/experts/metelkin.jpg';
import martinova from 'assets/pic/experts/martinova.jpg';
import petrova from 'assets/pic/experts/petrova.jpg';
import kurochkin from 'assets/pic/experts/kurochkin.jpg';
import zubkov from 'assets/pic/experts/zubkov.jpg';
import zobnin from 'assets/pic/experts/zobnin.jpg';
import gvozdev from 'assets/pic/experts/gvozdev.jpg';
import verbov from 'assets/pic/experts/verbov.JPG';
import mehedov from 'assets/pic/experts/mehedov.jpg';
import kulakov from 'assets/pic/experts/kulakov.jpg';
import aksenov from 'assets/pic/experts/aksenov.jpg';
import kojevnikov from 'assets/pic/experts/kojevnikov.jpg';
import pochta from 'assets/pic/experts/pochta.JPG';
import gorin from 'assets/pic/experts/gorin.png';
import rahimjanov from 'assets/pic/experts/rahimjanov.jpg';
import lazarev from 'assets/pic/experts/lazarev.jpg';
import smirnova from 'assets/pic/experts/smirnova.jpg';

export const ExpertsItems2021 = [
    {
        category: 'Эксперты&nbsp;&laquo;РЖД&raquo;',
        experts: [
            {
                img: verbov,
                name: `Дмитрий<br/>Вербов`,
                position: `Начальник Центра инновационного развития ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: gvozdev,
                name: `Дмитрий<br/>Гвоздев`,
                position: `Начальник управления разработки и&nbsp;интеграции транспортных продуктов и&nbsp;услуг ЦФТО ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: kurochkin,
                name: `Алексей<br/>Курочкин`,
                position: `Главный инженер ЦМ&nbsp;ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: zobnin,
                name: `Валерий<br/>Зобнин`,
                position: `Заместитель начальника дирекции по&nbsp;развитию ЦД&nbsp;ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            // {
            //     img: zubkov,
            //     name: `Валерий<br/>Зубков`,
            //     position: `Заместитель начальника Департамента производственной инфраструктуры Московского представительства АО&nbsp;&laquo;ФГК&raquo;`,
            //     default: true,
            //     show: false,
            // },
            {
                img: expert6,
                name: `Григорий<br/>Козырь`,
                position: `Начальник Центра<br/> моделирования бизнес-процессов&nbsp;&mdash;<br/> структурное подразделение ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: expert32,
                name: `Иван<br/>Карюкин`,
                position: `Ведущий технолог Центра инновационного развития&nbsp;&mdash; филиал ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            // {
            //     img: expert3,
            //     name: `Дмитрий<br/>Хомченко `,
            //     position: `Начальник технической<br/> службы Дирекции тяги&nbsp;&mdash;<br/> филиал ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert5,
            //     name: `Алексей <br/>Пашинин`,
            //     position: `Главный инженер<br/> Дирекции скоростного<br/> сообщения&nbsp;&mdash; филиал<br/> ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert8,
            //     name: `Людмила <br/>Дюжакова`,
            //     position: `Заместитель начальника <br/>Центра инновационного<br/> развития&nbsp;&mdash; филиал <br/>ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert26,
            //     name: `Олег<br/>Назаров`,
            //     position: `Заместитель начальника<br/> Департамента технической политики<br/> ОАО&nbsp; &laquo;РЖД&raquo;`,
            // },
            // {
            //     img: expert13,
            //     name: `Дмитрий<br/>Крюков`,
            //     position: `Заместитель начальника<br/> Департамента<br/> пассажирских перевозок&nbsp;&mdash;<br/> начальник отдела развития<br/> цифровых сервисов<br/> и&nbsp;систем ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     show: false,
            // },
            // {
            //     img: expert28,
            //     name: `Максим<br/>Павлов`,
            //     position: `Главный инженер<br/> Центральной дирекции<br/> моторвагонного<br/> подвижного состава&nbsp;&mdash;<br/> филиал ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     show: false,
            // },
            // {
            //     img: expert18,
            //     name: `Дмитрий<br/>Чупахин`,
            //     position: `Главный инженер<br/> Управления вагонного<br/> хозяйства Центральной<br/> дирекции инфраструктуры&nbsp;&mdash; филиал ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     show: false,
            // },
            // {
            //     img: expert10,
            //     name: `Дмитрий <br/>Пронин`,
            //     position: `Главный инженер <br/>Дирекции по эксплуатации<br/> путевых машин <br/>ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     show: false,
            // },
            {
                img: expert2,
                name: `Александр<br/>Косарев`,
                position: `Первый заместитель<br/> Генерального директора<br/> АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                default: true,
                show: true,
            },
            {
                img: expert7,
                name: `Денис<br/>Шикунов`,
                position: `Заместитель Генерального<br/> директора по&nbsp;экономике <br/>и&nbsp;финансам АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                show: false,
            },
            {
                img: mehedov,
                name: ` Михаил<br/>Мехедов`,
                position: `Заместитель Генерального директора&nbsp;&mdash; Директор Центра &laquo;Цифровые модели перевозок и&nbsp;технологии энергосбережения&raquo; АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                show: false,
            },
            {
                img: expert15,
                name: `Александр <br/>Ададуров`,
                position: `Советник Генерального директора АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
                show: false,
            },
            {
                img: expert19,
                name: `Алексей <br/>Лохматов`,
                position: `Первый заместитель <br/>Начальника Центра моделирования<br/> бизнес-процессов&nbsp;&mdash; структурное подразделение ОАО&nbsp;&laquo;РЖД&raquo;`,
                show: false,
            },
            {
                img: expert9,
                name: `Юрий <br/>Бабков`,
                position: `Первый заместитель<br/> генерального директора&nbsp;&mdash; <br/>главный инженер <br/>АО&nbsp;&laquo;ВНИКТИ&raquo;`,
                show: false,
            },
            {
                img: expert14,
                name: `Андрей<br/> Лунин`,
                position: `Заместитель генерального <br/>директора АО&nbsp;&laquo;ВНИКТИ&raquo;`,
                show: false,
            },
            {
                img: rahimjanov,
                name: `Денис<br/> Рахимжанов`,
                position: `Заместитель Генерального директора по&nbsp;стратегии и&nbsp;маркетингу АО&nbsp;&laquo;РЖД Логистика&raquo;`,
                show: false,
            },
            // {
            //     img: expert27,
            //     name: `Роман <br/>Мурзин`,
            //     position: `Заместитель Генерального <br/>директора&nbsp;&mdash; директор<br/> Научного центра<br/> &laquo;Тяга поездов&raquo; АО&nbsp;&laquo;ВНИИЖТ&raquo;`,
            //     show: false,
            // },
        ],
    },
    {
        category: 'Эксперты&nbsp;партнеров',
        experts: [
            // {
            //     img: expert16,
            //     name: `Александр <br/>Кемеж`,
            //     position: `Заместитель начальника<br/> Управления технической<br/> политики ПАО&nbsp;«ПГК»`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert30,
            //     name: `Леонид <br/>Кузнецов`,
            //     position: `Заместитель директора<br/> инженерного центра<br/> по&nbsp;перспективным технологиям<br/> АО&nbsp;&laquo;СТМ&raquo;`,
            //     default: true,
            //     show: true,
            // },
            {
                img: chisnikov,
                name: `Дмитрий<br/>Чисников`,
                position: `Начальник отдела инновационных проектов ПАО&nbsp;&laquo;ПГК&raquo;`,
                default: true,
                show: true,
            },
            {
                img: morozova,
                name: `Елена<br/>Морозова`,
                position: `Руководитель направления внешних инноваций ПАО&nbsp;&laquo;Кировский завод&raquo;`,
                default: true,
                show: true,
            },
            {
                img: jeleznov,
                name: `Дмитрий<br/>Железнов`,
                position: `Советник первого вице-президента<br/>ПАО&nbsp;&laquo;ТрансКонтейнер&raquo;`,
                default: true,
                show: true,
            },
            {
                img: matveeva,
                name: `Анна<br/>Матвеева`,
                position: `Директор по&nbsp;операциям DPD&nbsp;в&nbsp;России`,
                default: true,
                show: true,
            },
            // {
            //     img: ",
            //     name: `Денис<br/>Рудаков`,
            //     position: `IT-директор DPD в&nbsp;России`,
            //     default: true,
            //     show: true,
            // },
            {
                img: stambrovskij,
                name: `Сергей<br/>Стамбровский`,
                position: `Начальник управления информационных технологий управляющей компании &laquo;Новотранс&raquo;`,
                default: true,
                show: true,
            },
            {
                img: petrov,
                name: `Илья<br/>Петров`,
                position: `Ревизор технического центра ремонтной компании &laquo;Новотранс&raquo;`,
                default: true,
                show: true,
            },
            {
                img: petrov_pgk,
                name: `Михаил<br/>Петров`,
                position: `Директор по логистике ПАО&nbsp;&laquo;ПГК&raquo;`,
                default: true,
                show: true,
            },
            {
                img: metelkin,
                name: `Александр<br/>Метелкин`,
                position: `Заместитель начальника департамента ПАО&nbsp;&laquo;ПГК&raquo;`,
                default: true,
                show: true,
            },
            {
                img: skovorodnikov,
                name: `Олег<br/>Сковородников`,
                position: `Директор департамента цифровой трансформации ООО&nbsp;&laquo;ПЭК&raquo;`,
                default: true,
                show: true,
            },
            {
                img: golovin,
                name: `Леонид<br/>Головин`,
                position: `Советник генерального директора по&nbsp;цифровой трансформации ООО&nbsp;&laquo;Газпромтранс&raquo;`,
                default: true,
                show: true,
            },
            {
                img: popov,
                name: `Антон<br/>Попов`,
                position: `Начальник управления по&nbsp;развитию&nbsp;ИТ сервисов портового дивизиона FESCO`,
                default: true,
                show: true,
            },
            {
                img: kulakov,
                name: `Дмитрий<br/>Кулаков`,
                position: `Главный инженер-исследователь отдела электрических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: gorin,
                name: `Антон<br/>Горин`,
                position: `Главный инженер-исследователь отдела механических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: aksenov,
                name: `Максим<br/>Аксенов`,
                position: `Главный инженер-исследователь отдела электрических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: kojevnikov,
                name: `Александр<br/>Кожевников`,
                position: `Главный инженер-исследователь отдела энергетических систем ЦПТ&nbsp;ТМХ`,
                default: true,
                show: true,
            },
            {
                img: pochta,
                name: `Никита<br/>Жучков`,
                position: `Руководитель проектного офиса открытых инноваций Дирекции по&nbsp;инновациям АО&nbsp;&laquo;Почта России&raquo;`,
                default: true,
                show: true,
            },
            {
                img: lazarev,
                name: `Никита<br/>Лазарев`,
                position: `Менеджер центра развития Бизнес Системы Open Innovation АО&nbsp;&laquo;Северсталь&nbsp;Менеджмент&raquo;`,
                default: true,
                show: true,
            },
        ],
    },
    {
        category: 'Эксперты&nbsp;организаторов',
        experts: [
            {
                img: expert20,
                name: `Роман <br/>Кошелев`,
                position: `Начальник Центра &laquo;Корпоративный акселератор стартап-проектов<br/>ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            // {
            //     img: expert29,
            //     name: `Настасья<br/>Хлыбова`,
            //     position: `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов<br/>ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            //
            // {
            //     img: expert33,
            //     name: `Анна<br/>Хвагина`,
            //     position: `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;`,
            //     default: true,
            //     show: true,
            // },
            {
                img: polyakov,
                name: `Александр<br/>Поляков`,
                position: `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;`,
                default: true,
                show: true,
            },
            {
                img: petrova,
                name: `Екатерина <br/>Петрова`,
                position: `Директор корпоративного <br/>акселератора GenerationS`,
                default: true,
                show: true,
            },
            {
                img: martinova,
                name: `Ирина <br/>Мартынова`,
                position: `Руководитель<br/> корпоративного<br/> направления GenerationS`,
                default: true,
                show: true,
            },
            {
                img: expert22,
                name: `Галина <br/>Дремова`,
                position: `Менеджер <br/>по&nbsp;интегрированным<br/> коммуникациям<br/> GenerationS`,
                default: true,
                show: true,
            },
            {
                img: murzin,
                name: `Михаил<br/>Мурзин`,
                position: `Менеджер акселерационной программы<br/>GenerationS`,
                default: true,
                show: true,
            },
            {
                img: smirnova,
                name: `Ольга<br/>Смирнова`,
                position: `Эксперт Центра «Корпоративный акселератор стартап-проектов ОАО «РЖД» — АО «ВНИИЖТ»`,
                default: true,
                show: true,
            },
        ],
    },
];
export const ExpertsItems2021En = [
    {
        category: 'Russian&nbsp;Railways&nbsp;experts',
        experts: [
            {
                img: verbov,
                name: `Dmitry<br/>Verbov`,
                position: `Head of&nbsp;the Center for Innovative Development, Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: gvozdev,
                name: `Dmitry<br/>Gvozdev`,
                position: `Head of&nbsp;the Department for the Development and Integration of&nbsp;Transport Products and Services of&nbsp;the Central Transport Department of&nbsp;Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: kurochkin,
                name: `Alexey<br/>Kurochkin`,
                position: `Chief Engineer of&nbsp;CM of&nbsp;JSC &laquo;Russian Railways&raquo;`,
                default: true,
                show: true,
            },
            {
                img: zobnin,
                name: `Valery<br/>Zobnin`,
                position: `Deputy Head of&nbsp;the Directorate for Development of&nbsp;the Central House of&nbsp;Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: zubkov,
                name: `Valery<br/>Zubkov`,
                position: `Deputy Head of&nbsp;the Production Infrastructure Department of&nbsp;the Moscow Representative Office of&nbsp;FGK JSC`,
                default: true,
                show: true,
            },
            {
                img: expert6,
                name: `Grigory<br/>Kozir`,
                position: `Director,<br/> Business Process Modeling Center&nbsp;&mdash;<br/> a&nbsp;branch of&nbsp;Russian Railways`,
                default: true,
                show: true,
            },
            // {
            //     img: expert3,
            //     name: `Dmitry<br/>Khomchenko `,
            //     position: `Head of&nbsp;Technical<br/> Services, Traction Department&nbsp;&mdash;<br/> a&nbsp;branch of&nbsp;Russian Railways`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert5,
            //     name: `Alexey <br/>Pashinin`,
            //     position: `Chief Engineer<br/> High-speed Service Department&nbsp;&mdash;<br/>a&nbsp;branch of&nbsp;Russian Railways<br/>`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert8,
            //     name: `Ludmila <br/>Dyuzhakova`,
            //     position: `Deputy Director,<br/>Center for Innovative Development&nbsp;&mdash;<br/>a&nbsp;branch of&nbsp;Russian Railways`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert26,
            //     name: `Oleg<br/>Nazarov`,
            //     position: `Deputy Director,<br/> Department of&nbsp;Technical Policy,<br/> Russian Railways`,
            //     show: false,
            // },
            // {
            //     img: expert13,
            //     name: `Dmitry<br/>Kryukov`,
            //     position: `Deputy Director,<br/> Passenger Transportation Department;<br/> Director,<br/>Digital Services and Systems Development Department, <br/>Russian Railways`,
            //     show: false,
            // },
            // {
            //     img: expert28,
            //     name: `Maxim<br/>Pavlov`,
            //     position: `Chief Engineer,<br/> Central Railcar Rolling Stock Department&nbsp;&mdash;<br/>a&nbsp;branch of&nbsp;Russian Railways`,
            //     show: false,
            // },
            // {
            //     img: expert18,
            //     name: `Dmitry<br/>Chupakhin`,
            //     position: `Chief Engineer,<br/> Rolling Stock Department,<br/>Central Infrastructure Department&nbsp;&mdash;<br/>a&nbsp;branch of&nbsp;Russian Railways `,
            //     show: false,
            // },
            // {
            //     img: expert10,
            //     name: `Dmitry <br/>Pronin`,
            //     position: `Chief Engineer, <br/>Track Machines Operations Department,<br/>Russian Railways`,
            //     show: false,
            // },
            {
                img: expert32,
                name: `Ivan<br/>Karyukin`,
                position: `Leading Technologist of&nbsp;the Center for Innovative Development&nbsp;&mdash; a&nbsp;branch of&nbsp;Russian Railways`,
                default: true,
                show: true,
            },
            {
                img: expert2,
                name: `Alexander<br/>Kosarev`,
                position: `First Deputy CEO,<br/>VNIIZhT`,
                default: true,
                show: true,
            },
            {
                img: expert7,
                name: `Denis<br/>Shikunov`,
                position: `Deputy Director<br/> General for Economics and Finance,<br/>VNIIZhT`,
                show: false,
            },
            {
                img: mehedov,
                name: `Mikhail<br/>Mekhedov`,
                position: `Deputy General Director&nbsp;&mdash; Director of&nbsp;the Center for Digital Transportation Models and Energy Saving Technologies of&nbsp;VNIIZhT JSC`,
                show: false,
            },
            {
                img: expert15,
                name: `Alexander <br/>Adadurov`,
                position: `Deputy CEO,VNIIZhT `,
                show: false,
            },
            {
                img: expert19,
                name: `Alexey <br/>Lokhmatov`,
                position: `First Deputy<br/> Head of&nbsp;Business Processes<br/> Modeling Center&nbsp;&mdash; a&nbsp;brunch<br/> of&nbsp;Russian Railways`,
                show: false,
            },
            {
                img: expert9,
                name: `Yuri <br/>Babkov`,
                position: `First Deputy<br/> CEO and Chief Engineer,<br/>VNIKTI`,
                show: false,
            },

            {
                img: expert14,
                name: `Andrey<br/> Lunin`,
                position: `Deputy CEO, <br/>VNIKTI`,
                show: false,
            },
            {
                img: rahimjanov,
                name: `Denis<br/> Rahimjanov`,
                position: `Deputy General Director for Strategy and Marketing, Russian Railways Logistics JSC`,
                show: false,
            },
            // {
            //     img: expert27,
            //     name: `Roman <br/>Murzin`,
            //     position: `Deputy CEO and Director, <br/>Traction of&nbsp;Trains Science Center,<br/>VNIIZhT`,
            //     show: false,
            // },
        ],
    },
    {
        category: 'Partner&nbsp;experts',
        experts: [
            // {
            //     img: expert16,
            //     name: `Alexander <br/>Kemezh`,
            //     position: `Deputy Director,<br/> Technical Policy Department,<br/> Pervaya Gruzovaya Kompaniya PJSC`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert30,
            //     name: `Leonid <br/>Kuznetsov`,
            //     position: `Deputy Director<br/> of&nbsp;the Engineering Center<br/> for Advanced Technologies<br/> of&nbsp;STM JSC.`,
            //     default: true,
            //     show: true,
            // },
            {
                img: chisnikov,
                name: `Dmitry<br/>Chisnikov`,
                position: `Head of&nbsp;Innovation Projects Department, Freight One PJSC`,
                default: true,
                show: true,
            },
            {
                img: morozova,
                name: `Elena<br/>Morozova`,
                position: `Head of&nbsp;External Innovation Department of&nbsp;PJSC &laquo;Kirovsky Zavod&raquo;`,
                default: true,
                show: true,
            },
            {
                img: jeleznov,
                name: `Dmitry<br/>Zheleznov`,
                position: `Advisor to&nbsp;the first vice president<br/>PJSC &laquo;TransContainer&raquo;`,
                default: true,
                show: true,
            },
            {
                img: matveeva,
                name: `Anna<br/>Matveeva`,
                position: `Director of DPD&nbsp;Operations in&nbsp;Russia`,
                default: true,
                show: true,
            },
            // {
            //     img: ",
            //     name: `Denis<br/>Rudakov`,
            //     position: `IT&nbsp;Director of&nbsp;DPD in&nbsp;Russia`,
            //     default: true,
            //     show: true,
            // },
            {
                img: stambrovskij,
                name: `Sergey<br/>Stambrovsky`,
                position: `Head of&nbsp;the Information Technology Department of&nbsp;the Novotrans Management Company`,
                default: true,
                show: true,
            },
            {
                img: petrov,
                name: `Ilya<br/>Petrov`,
                position: `Auditor of&nbsp;the technical center of&nbsp;the repair company &laquo;Novotrans&raquo;`,
                default: true,
                show: true,
            },
            {
                img: petrov_pgk,
                name: `Mikhail<br/>Petrov`,
                position: `Director of&nbsp;logistics PJSC &laquo;Freight One&raquo;`,
                default: true,
                show: true,
            },
            {
                img: metelkin,
                name: `Alexander<br/>Metelkin`,
                position: `Deputy Head of&nbsp;Department PJSC &laquo;Freight One&raquo;`,
                default: true,
                show: true,
            },
            {
                img: skovorodnikov,
                name: `Oleg<br/>Skovorodnikov`,
                position: `Director of&nbsp;Digital Transformation Department LLC&nbsp;&laquo;PEK&raquo;`,
                default: true,
                show: true,
            },
            {
                img: golovin,
                name: `Leonid<br/>Golovin`,
                position: `Advisor to&nbsp;the CEO for digital transformation LLC&nbsp;&laquo;Gazpromtrans&raquo;`,
                default: true,
                show: true,
            },
            {
                img: popov,
                name: `Anton<br/>Popov`,
                position: `Head of&nbsp;the Department for the Development of&nbsp;IT&nbsp;Services of&nbsp;the FESCO Port Division`,
                default: true,
                show: true,
            },
            {
                img: kulakov,
                name: `Dmitry <br/> Kulakov`,
                position: `Chief Research Engineer of the Electrical Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: gorin,
                name: `Anton <br/> Gorin`,
                position: `Chief Research Engineer of the Mechanical Systems Department of the central heating center&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: aksenov,
                name: `Maxim <br/> Aksenov`,
                position: `Chief Research Engineer of the Electrical Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: kojevnikov,
                name: `Alexander <br/> Kozhevnikov`,
                position: `Chief Research Engineer of the Energy Systems Department of the Central Heating System&nbsp;TMH`,
                default: true,
                show: true,
            },
            {
                img: pochta,
                name: `Nikita<br/>Juchkov`,
                position: `Head of&nbsp;the open innovation project office of&nbsp;the Innovation Directorate of&nbsp;the Russian Post JSC`,
                default: true,
                show: true,
            },
            {
                img: lazarev,
                name: `Nikita<br/>Lazarev`,
                position: `Manager of&nbsp;the Open Innovation Business System Development Center, Severstal Management JSC`,
                default: true,
                show: true,
            },
        ],
    },
    {
        category: 'Organizers&nbsp;experts',
        experts: [
            {
                img: expert20,
                name: `Roman <br/>Koshelev`,
                position: `Head of the Center<br/> for the Corporate<br/> Accelerator of Start-up Projects,<br/> Russian Railways`,
                default: true,
                show: true,
            },
            // {
            //     img: expert29,
            //     name: `Nastasya<br/>Khlybova`,
            //     position: `Expert,<br/>the Center for the Corporate <br/>Accelerator of&nbsp;Startup Projects, <br/>Russian Railways`,
            //     default: true,
            //     show: true,
            // },
            // {
            //     img: expert33,
            //     name: `Anna<br/>Khvagina`,
            //     position: `Expert,<br/>the Center for the Corporate Accelerator of&nbsp;Start-up Projects of&nbsp;Russian Railways`,
            //     default: true,
            //     show: true,
            // },
            {
                img: polyakov,
                name: `Alexander<br/>Polyakov`,
                position: `Expert,<br/> the Center &laquo;Corporate accelerator of&nbsp;start-up projects of&nbsp;JSC Russian Railways&raquo;`,
                default: true,
                show: true,
            },
            {
                img: petrova,
                name: `Ekaterina <br/>Petrova`,
                position: `CEO, <br/>Corporate Accelerator,<br/>GenerationS`,
                default: true,
                show: true,
            },
            {
                img: martinova,
                name: `Irina <br/>Martynova`,
                position: `Director,<br/>Corporate Department,<br/>GenerationS`,
                default: true,
                show: true,
            },
            {
                img: expert22,
                name: `Galina <br/>Dremova`,
                position: `Integrated <br/>Communications Manager,<br/> GenerationS`,
                default: true,
                show: true,
            },
            {
                img: murzin,
                name: `Michael<br/>Murzin`,
                position: `Acceleration Program Manager, GenerationS`,
                default: true,
                show: true,
            },
            {
                img: smirnova,
                name: `Olga<br/>Smirnova`,
                position: `Specialist of&nbsp;the Center &laquo;Corporate accelerator of&nbsp;start-up projects of&nbsp;JSC Russian Railways&nbsp;&mdash; JSC VNIIZhT&raquo;`,
                default: true,
                show: true,
            },
        ],
    },
];
