import img1 from '../../assets/img/svg/archive/advantages/adv-1.svg';
import img2 from '../../assets/img/svg/archive/advantages/adv-2.svg';
import img3 from '../../assets/img/svg/archive/advantages/adv-3.svg';
import img4 from '../../assets/img/svg/archive/advantages/adv-4.svg';
import img5 from '../../assets/img/svg/archive/advantages/adv-5.svg';

export default {
    ru: {
        title:
            'Сотрудничайте с&nbsp;крупнейшим закупщиком<br/> инноваций и&nbsp;технологий',
        items: [
            {
                title: 'Входит в&nbsp;ТОП-3',
                text: 'мировых лидеров<br/>железнодорожных<br/>компаний',
                img: img1,
            },
            {
                title: '69&nbsp;млрд руб.',
                text:
                    'объем закупок инновационной<br/>и&nbsp;технологичной продукции',
                img: img2,
            },
            {
                title: '337 поставщиков',
                text: 'инновационных<br/>решений',
                img: img3,
            },
            {
                title: '85,6&nbsp;тыс.&nbsp;км',
                text: 'эксплуатационная длина<br/> железных дорог',
                img: img4,
            },
            {
                title: '16&nbsp;площадок',
                text: 'в регионах для внедрения<br/>инноваций',
                img: img5,
            },
        ],
    },
    en: {
        title:
            'Work with the largest procurer of&nbsp;innovative<br/>technologies',
        items: [
            {
                title: 'Among the world&rsquo;s top&nbsp;3',
                text: 'leading railway<br/>companies',
                img: img1,
            },
            {
                title: '69&nbsp;billion rubles',
                text:
                    'The of&nbsp;amount of&nbsp;procurement of&nbsp;innovative and technological solutions',
                img: img2,
            },
            {
                title: '337 contractors',
                text: 'of&nbsp;innovative<br/>solutions',
                img: img3,
            },
            {
                title: '85.6 thousand kilometers\n',
                text: 'the length of&nbsp;used railroad',
                img: img4,
            },
            {
                title: '16&nbsp;regional sites',
                text: 'for implementing innovations',
                img: img5,
            },
        ],
    },
};
