import React, { FC } from 'react';
import './style.sass';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import User from 'models/User';

interface IProps {}

const HeaderUserIcon: FC<IProps> = props => {
    return (
        <div className="header-user-icon">
            <AccountCircleIcon htmlColor="#e21a1a" />
            <div className="header-user-icon__name">
                {User.getUserData()?.name || null}
            </div>
        </div>
    );
};

export default HeaderUserIcon;
