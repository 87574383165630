import img1 from '../../assets/img/svg/archive/partners/rjd.svg';
import img2 from '../../assets/img/svg/archive/partners/vnizjt.jpg';
export default {
    ru: {
        items: [
            {
                logo: img1,
                title: 'ПАРТНЕР АКСЕЛЕРАТОРА',
                text:
                    '<strong>Фонд развития интернет-инициатив (ФРИИ)</strong>&nbsp;&mdash; российский фонд венчурных инвестиций, входит в&nbsp;число самых активных фондов Европы по&nbsp;числу сделок, ТОП-8 мировых акселераторов по&nbsp;версии Forbes. Самая успешная акселерационная программа в&nbsp;России по&nbsp;версии НАБА. В&nbsp;портфеле свыше 470&nbsp;IT-стартапов. Фонд оказывает компаниям образовательную и&nbsp;консалтинговую поддержку, в&nbsp;том числе помогает запустить продажи за&nbsp;рубежом.',
                link: 'https://www.iidf.ru/',
            },
            {
                logo: img2,
                title: 'ОРГАНИЗАТОР АКСЕЛЕРАТОРА',
                text:
                    '<strong>Научно-исследовательский институт железнодорожного транспорта (ВНИИЖТ)</strong>&nbsp;&mdash; крупнейший научный центр железнодорожной отрасли в&nbsp;области научно-исследовательских и&nbsp;проектно-конструкторских разработок, который способен проводить весь спектр сертификационных испытаний. АО&nbsp;&laquo;ВНИИЖТ&raquo;&nbsp;&mdash; дочернее предприятие РЖД.',
                link: 'https://www.vniizht.ru/',
            },
        ],
    },
    en: {
        items: [
            {
                logo: img1,
                title: 'ACCELERATOR&rsquo;S PARTNER',
                text: `<strong>Internet Initiatives Development Fund (IIDF)</strong> is&nbsp;a&nbsp;Russian venture capital fund, one of&nbsp;the most active funds by&nbsp;number of&nbsp;deals, a&nbsp;top 8&nbsp;world&rsquo;s accelerator according to&nbsp;Forbes. The most successful acceleration program in&nbsp;Russia according to&nbsp;RVCA. Over 470 startups in&nbsp;portfolio. The fund provides educational and consultancy support including assistance in&nbsp;launching sales abroad.`,
                link: 'https://www.iidf.ru/',
            },
            {
                logo: img2,
                title: 'ACCELERATOR ORGANISER',
                text:
                    '<strong>All-Russian Scientific Research Institute of&nbsp;Railway Transport (VNIIZHT)</strong>&mdash;the largest research centre of&nbsp;the railway industry in&nbsp;the field of&nbsp;research and design and engineering development that can conduct the whole range certification tests. JSC VNIIZHT&nbsp;&mdash; a&nbsp;branch of&nbsp;Russian Railways.',
                link: 'https://www.vniizht.ru/',
            },
        ],
    },
};
