import * as React from 'react';
import RedButton from '../../../ui/RedButton';
import './style.sass';
import AppointQuestModal from '../AppointQuestModal';
import { useState } from 'react';

const ExpertListToolbar = props => {
    const [isOpen, setToggleModal] = useState(false);
    const modalHandle = active => {
        setToggleModal(active);
    };
    return (
        <>
            <div className="expertListToolbar">
                <div className="expertListToolbar__row">
                    <RedButton
                        size="md"
                        type="button"
                        onClick={() => {
                            modalHandle(true);
                        }}
                    >
                        Назначить заявку
                    </RedButton>
                    {/*<div className="expertListToolbar__link">*/}
                    {/*    Профиль эксперта*/}
                    {/*</div>*/}
                    <div className="expertListToolbar__spacer" />
                </div>
                <AppointQuestModal
                    isOpen={isOpen}
                    IdExpert={props.filterValues.id}
                    modalHandle={modalHandle}
                />
            </div>
        </>
    );
};

export default ExpertListToolbar;
