import React from 'react';
import './style.sass';
import BodyResource from '../../components/resources/BodyResource';
import ResourceTitlePage from '../../components/ui/ResourceTitlePage';
import data from '../../data/personalData.json';
import Divider from '@material-ui/core/Divider';
const PersonalData = () => {
    return (
        <section className="agreement">
            <div className="full-wrapper">
                <ResourceTitlePage text="Согласие на обработку персональных данных пользователя сайта Акселератор РЖД" />
                <BodyResource>
                    <div className="agreement__top">
                        <Divider />
                    </div>
                    <div className="agreement__row">
                        {data.map((item, index) => (
                            <div key={index} className="agreement__item">
                                <div
                                    key={index}
                                    className="agreement__text"
                                    dangerouslySetInnerHTML={{ __html: item }}
                                />
                            </div>
                        ))}
                    </div>
                </BodyResource>
            </div>
        </section>
    );
};

export default PersonalData;
