import React from 'react';
import './style.sass';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const LoginScreenTabList = ({ tabs, activeScreen, setActiveScreen }) => {
    return (
        <div className="login-screen-tab">
            {tabs.map(({ title, code }, key) => {
                const isActive = activeScreen === code;
                return (
                    <div
                        key={key}
                        className={clsx(
                            'login-screen-tab__item',
                            isActive && 'login-screen-tab__item--active'
                        )}
                        onClick={() => setActiveScreen(code)}
                    >
                        {title}
                    </div>
                );
            })}
        </div>
    );
};

LoginScreenTabList.propTypes = {
    setActiveScreen: PropTypes.func.isRequired,
    activeScreen: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired,
};

export default LoginScreenTabList;
