import expert1 from '../../assets/img/archive/experts/exp-1.png';
import expert2 from '../../assets/img/archive/experts/exp-2.JPG';
import expert3 from '../../assets/img/archive/experts/exp-3.png';
import expert4 from '../../assets/img/archive/experts/exp-4.png';
import expert5 from '../../assets/img/archive/experts/exp-5.jpg';
import expert6 from '../../assets/img/archive/experts/exp-6.png';
import expert7 from '../../assets/img/archive/experts/exp-7.jpg';
import expert8 from '../../assets/img/archive/experts/exp-8.png';
import expert9 from '../../assets/img/archive/experts/exp-9.JPG';
import expert10 from '../../assets/img/archive/experts/exp-10.JPG';
import expert11 from '../../assets/img/archive/experts/exp-11.JPG';
import expert12 from '../../assets/img/archive/experts/exp-12.jpg';
import expert13 from '../../assets/img/archive/experts/exp-13.png';
import expert14 from '../../assets/img/archive/experts/exp-14.JPG';
import expert15 from '../../assets/img/archive/experts/exp-15.jpeg';
import expert16 from '../../assets/img/archive/experts/exp-16.jpeg';
import expert17 from '../../assets/img/archive/experts/exp-17.png';
import expert18 from '../../assets/img/archive/experts/exp-18.png';
import expert19 from '../../assets/img/archive/experts/exp-19.png';

export default {
    ru: {
        title: 'ЭКСПЕРТЫ',
        items: [
            {
                img: expert1,
                name: 'АЛЕКСАНДР ЗАЖИГАЛКИН',
                position:
                    'Начальник Центра<br/>инновационного развития&nbsp;&mdash;<br/>филиал ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert2,
                name: 'АЛЕКСАНДР КОСАРЕВ',
                position:
                    'Первый заместитель<br/>Генерального директора<br/>АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert3,
                name: 'ВИКТОР АЛФЕРОВ',
                position:
                    'Заместитель начальника<br/>Департамента технической<br/>политики ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert4,
                name: 'АНДРЕЙ КАЗАКОВ',
                position:
                    'Начальник Центральной дирекции<br/>моторвагонного подвижного<br/>состава&nbsp;&mdash; филиал ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert5,
                name: 'ВЛАДИМИР НИКИТИН',
                position:
                    'Начальник Департамента по<br/>организации, оплате и&nbsp;мотивации<br/>труда ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert6,
                name: 'ДМИТРИЙ КРЮКОВ',
                position:
                    'Заместитель начальника<br/>Департамента пассажирских<br/>перевозок&nbsp;&mdash; начальник отдела<br/>развития цифровых сервисов и<br/>систем ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert7,
                name: 'ДЕНИС ШИКУНОВ',
                position:
                    'Заместитель Генерального<br/>директора по&nbsp;экономике и<br/>финансам АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert8,
                name: 'ГРИГОРИЙ КОЗЫРЬ',
                position:
                    'Начальник Центра<br/>моделирования бизнес-процессов<br/>&mdash; структурное подразделение<br/>ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert9,
                name: 'АЛЕКСЕЙ ПАШИНИН',
                position:
                    'Главный инженер Дирекции<br/>скоростного сообщения&nbsp;&mdash; филиал<br/>ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert10,
                name: 'ЛЮДМИЛА ДЮЖАКОВА',
                position:
                    'Заместитель начальника Центра<br/>инновационного развития&nbsp;&mdash;<br/>филиал ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert11,
                name: 'ДМИТРИЙ ПРОНИН',
                position:
                    'Главный инженер Дирекции по<br/>эксплуатации путевых машин<br/>ОАО &laquo;РЖД&raquo;',
            },
            {
                img: expert12,
                name: 'АЛЕКСАНДР АДАДУРОВ',
                position:
                    'Заместитель Генерального<br/>директора&nbsp;&mdash; Директор НИАЦ<br/>АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert13,
                name: 'АЛЕКСЕЙ ПАРХАЕВ',
                position:
                    'Заместитель Генерального<br/>директора по&nbsp;управлению<br/>персоналом и&nbsp;социальным<br/>вопросам АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert14,
                name: 'АЛЕКСЕЙ ЛОХМАТОВ',
                position:
                    'Начальник Центра<br/>корпоративного акселератора<br/>АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert15,
                name: 'РОМАН КОШЕЛЕВ',
                position:
                    'Заместитель начальника Центра<br/>корпоративного акселератора<br/>АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert16,
                name: 'АЛЕКСАНДР ТАРАРУШКИН',
                position:
                    'Эксперт Центра корпоративного<br/>акселератора АО&nbsp;&laquo;ВНИИЖТ&raquo;',
            },
            {
                img: expert17,
                name: 'ДМИТРИЙ КАЛАЕВ',
                position: 'Директор &laquo;Акселератора ФРИИ&raquo;',
            },
            {
                img: expert18,
                name: 'ИРИНА ТАРАСОВА',
                position:
                    'Директор корпоративных<br/>программ &laquo;Акселератора ФРИИ&raquo;',
            },
            {
                img: expert19,
                name: 'ДМИТРИЙ ГУБКИН',
                position:
                    'Руководитель трекинга<br/>&laquo;Акселератора ФРИИ&raquo;',
            },
        ],
    },
};
