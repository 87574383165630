import React from 'react';
import FastTrackItem from './item';
import FastTrackItemNew from './itemNew';
import { FastTrackItems, FastTrackItemsEn } from '../../data/data';
import parse from 'html-react-parser';
import bg from '../../assets/pic/track-bg.jpg';
import bg2022 from '../../assets/pic/track-bg-2022.jpg';
import './style.sass';
import {
    FastTrackItems2021,
    FastTrackItems2021En,
} from '../../data/2021/fast-track';

const FastTrack = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return langRU ? FastTrackItems2021 : FastTrackItems2021En;
        }
        return langRU ? FastTrackItems : FastTrackItemsEn;
    };
    return (
        <section
            className="fast-track"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
            style={{
                background: `url(${year === '2021' ? bg2022 : bg})`,
            }}
        >
            <div className="full-wrapper">
                <div className="fast-track__title">
                    {langRU
                        ? parse(`Fast track технологий в&nbsp;РЖД`)
                        : parse(
                              `Fast track technologies at&nbsp;Russian Railways`
                          )}
                </div>
                <div className="fast-track__row">
                    {getData().map((item, index) => {
                        if (year === '2021') {
                            return (
                                <FastTrackItemNew
                                    key={item}
                                    index={index}
                                    item={item}
                                    length={getData().length - 1}
                                />
                            );
                        }

                        return (
                            <FastTrackItem
                                key={item}
                                index={index}
                                item={item}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default FastTrack;
