export const TechnoFocus2022 = [
    {
        id: 'complex',
        category: 'Комплексные системы',
        items: [
            {
                id: '1',
                title:
                    'Создание системы автоматизированного контроля и управления вокзалом',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Неоптимальное использование ресурсов систем отопления, вентиляции, климатического и прочего оборудования, а также значительные трудозатраты на настройку необходимых параметров в ручном режиме в помещениях и зданиях вокзалов являются предпосылками для внедрения систем автоматического управления системами вокзала',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'На большинстве вокзалов обеспечение тепловыми и энергоресурсами осуществляется традиционными методами и способами. Требуется внедрение автоматизированных систем управления, характеризующимися небольшими затратами на внедрение и быстрой окупаемостью',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены к внедрению эффективные решения, которые позволят снизить эксплуатационные расходы, связанные с потреблением электро- и теплоэнергии, используемой для обеспечения работы вокзальных комплексов',
                    },
                ],
            },
            {
                id: '2',
                title:
                    'Удаленный мониторинг и контроль устройств систем заземления пассажирских платформ',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных решений, которые позволят удаленно мониторить и контролировать системы заземления пассажирских платформ',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Организация работы по уменьшению рисков задержек поездов',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Внедрение эффективных решений, которые позволят удаленно мониторить и контролировать работу систем заземления',
                    },
                ],
            },
            {
                id: '3',
                title:
                    'Удаленный мониторинг и контроль устройств систем освещения пассажирских платформ',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных решений, которые позволят удаленно мониторить и контролировать системы освещения пассажирских платформ',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Организация работы по уменьшению рисков жалоб пассажиров',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Внедрение эффективных решений, которые позволят удаленно мониторить и контролировать работу систем освещения',
                    },
                ],
            },
            {
                id: '4',
                title:
                    'Системы дистанционного проведения медицинского освидетельствования локомотивных бригад',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Организация проведения предрейсовых медицинских осмотров на станциях при отсутствии медицинских пунктов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Разработка и изменение нормативной документации по прохождению предрейсовых медицинских осмотров. Разработка алгоритма прохождения предрейсовых медицинских осмотров. Подключение комплекса предрейсовых медицинских осмотров к системе передачи данных',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Увеличение полезного времени в движении, сокращение часов сверхурочной работы и организация новых пунктов заступления локомотивных бригад',
                    },
                ],
            },
            {
                id: '5',
                title:
                    'Система онлайн-мониторинга и анализа выполнения цикловых работ ремонтным персоналом «умные очки» с возможностью взаимодействия с базой данных технологических карт, видео и аудиовызова мастера и т.д.',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Выполнение технологии ремонта узлов и деталей МВПС, в том числе специалистами с малым опытом работы',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Выполнение технологии ремонта при ТО-3, ТР-1, ТР-2, ТР-3, КР для электропоездов и дизельного подвижного состава',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Снижение случаев неисправности оборудовании в эксплуатации и изменение планово-предупредительного ремонта',
                    },
                ],
            },
            {
                id: '6',
                title:
                    'Роботизация и автоматизация проведения дефектоскопии узлов и деталей моторвагонного подвижного состава',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Невозможность оперативно использовать оборудование дефектоскопии без предварительной подготовки. Также работник должен быть обучен и иметь опыт работы для принятия решений о выявленных дефектов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Проведение дефектоскопии колесных пар, автосцепки, тележки и других деталей и узлов',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Новые мобильные устройства дефектоскопии (вихретоковая, ультразвуковая, магнитная) для использования неподготовленным персоналом, получение результатов и передача в автоматизированную систему',
                    },
                ],
            },
            {
                id: '7',
                title:
                    'Интегрированная система безопасности, видеофиксации и информирования',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В настоящее время не развита автоматизированная аналитика фиксируемых данных камерами наружного и внутреннего наблюдения',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Видеоряд данных, передающихся камерами электропоезда',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Электронная система распознавания и информирования',
                    },
                ],
            },
            {
                id: '8',
                title:
                    'Комплексная система защиты от зажатия пассажиров автоматическими дверями',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В процессе эксплуатации есть случаи зажатия автоматическими дверьми',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Усовершенствование существующих систем «антизажима» дверных блоков прислонносдвижного типа существующего и выпускаемого моторвагонного подвижного состава',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text: 'Безопасная посадка пассажиров',
                    },
                ],
            },
            {
                id: '9',
                title:
                    'Комплексная система защиты подвижного состава от пассажиров, проезжающих снаружи поезда (зацеперов)',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Ограничить доступ посторонних лиц к крышевому оборудованию подвижного состава',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Составы серии ЭД в/и оборудовать системами защиты',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Исключение возможности проникновения зацеперов на подвижной состав',
                    },
                ],
            },
            {
                id: '10',
                title:
                    'Система активной диагностики с единым центром обработки и хранения данных',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Повышение технической надежности подвижного состава. Предиктивная дианостика электрической, электронной, пневматического оборудования и электрических машин',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Электропоезда производства ОА «ДМЗ» серии ЭД в/и и рельсовых автобусов АО «Метровагонмаш»',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Снижение случаев неисправности оборудования в эксплуатации и изменение планово-предупредительного ремонта',
                    },
                ],
            },
            {
                id: '11',
                title:
                    'Автоматизированный комплекс, позволяющий получение информации о техническом состоянии токоприемников, колесных пар, тягового привода, механического оборудования моторвагонного подвижного состава',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Уход от ручного замера параметров моторвагонного подвижного состава при проведении плановых ремонтов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Замеры параметров моторвагонного подвижного состава (токоприемников, колесных пар, толщина колодки, автосцепного устройства, состояние моторной или прицепной тележки и т.д.)',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Автоматизация процесса сбора информации (замеров по параметрам токоприемников, щеток электрических машин, колесных пар, механического оборудования) с передачей данных в автоматизированные системы и установкой барьеров для невозможности внесения данных свыше предельных параметров',
                    },
                ],
            },
            {
                id: '12',
                title:
                    'Автоматизированный комплекс мониторинга и анализа безопасной эксплуатации опасных производственных объектов (далее – ОПО).',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Государственные и корпоративные отчеты по промышленной безопасности формируются по каждому структурному подразделению и филиалам вручную. Невозможность в режиме «он-лайн» анализа текущей ситуации состояния промышленной безопасности на ОПО, оперативного управления и своевременной реализации мероприятий по промышленной безопасности.',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Государственные и корпоративные отчеты по промышленной безопасности, заключение экспертизы промышленной безопасности, результаты освидетельствования и обследования технических устройств, зданий и сооружений на ОПО.',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Снижение случаев аварий и инцидентов на ОПО. Возможность автоматизированного формирования инвестиционной программы по обновлению технических устройств эксплуатируемых на ОПО. За счет автоматизации снижение расходов на выполнение обязательных мероприятия по промышленной безопасности. Автоматизированный учет и формирование плана проведения аттестации ответственных специалистов в области промышленной безопасности. Анализ и контроль сроков устранения нарушений при эксплуатации технических устройств на ОПО, выявленных по результатам проверок государственных надзорных органов. автоматизированное Автоматическое формирование отчета об обеспечении требований промышленной безопасности в формате Excel и/или XML-формате для направления в Ростехнадзор.',
                    },
                ],
            },
            {
                id: '13',
                title:
                    'Система дистанционного контроля за техническим состоянием и работой систем автоматической противопожарной защиты (СПЗ) на объектах АО «ФПК»',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Позволяет установить объективный дистанционный контроль, автоматизировать выявление неисправностей в работе систем противопожарной защиты. Факт устранения неисправности можно проверить в онлайн режиме',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Реестр недвижимого имущества, перечень систем, данные о системе (оборудование, сертификаты, паспорта, исполнительная документация, инструкция по эксплуатации и т.д.), объектах ее размещения. Информация о состоянии систем должна транслироваться на АРМ главном инженерам филиалов и в центральный аппарат АО «ФПК» с помощью сети internet ',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Организация АРМ главного инженера филиала, структурного подразделения филиала, центрального аппарата АО «ФПК». Повышение эффективности и оперативности контроля за состоянием и обслуживанием  СПЗ, сокращение времени от момента сработки СПЗ до устранения неисправностей. Защита жизни и здоровья работников АО «ФПК».',
                    },
                ],
            },
            {
                id: '14',
                title:
                    'Автоматизированная система управления пожарной безопасностью',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Позволяет установить объективный дистанционный контроль за устранением предписаний органов надзорной деятельности МЧС, прокуратура, Госжелдорнадзор',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Данные о предписаниях, нарушениях, мероприятиях, сроках устранения и исполнения',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Установление контроля за исполнением предписаний, исключение повторных штрафных санкций за их неисполнение, предотвращение административной приостановки объектов по причине неисполнения мероприятий',
                    },
                ],
            },
            {
                id: '15',
                title:
                    'Создание программно-аппаратного комплекса, позволяющего осуществлять контроль геометрических параметров колесных пар и выявлять эксплуатационные дефекты на поверхности катания колес',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В основу функционирования программно-аппаратного комплекса должно быть заложено использование автоматизированных средств контроля, позволяющих осуществлять мониторинг геометрических параметров колесных пар на ходу поезда с целью повышения качества технического обслуживания ходовых частей пассажирских вагонов и сокращения времени на осмотр и контроль геометрических параметров колесных пар осмотрщиком ремонтником вагонов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Параметры диагностики: регистрация даты и времени контроля; номер поезда; определение количества вагонов в составе; номер вагона; регистрация скорости движения поезда; порядковый номер колеса; геометрические параметры и дефекты колес (Обеспечить толщина гребня, толщина обода, равномерный прокат, неравномерный прокат, выщербины, вертикальный подрез гребня, ползуны, навары, местное уширение обода, кольцевые выработки, откол наружной боковой поверхности обода колеса, включая откол кругового наплыва трещины)',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Программно-аппаратный комплекс должен обеспечивать точность измерений геометрических параметров колесных пар в круглосуточном режиме, в том числе при условии воздействия внешних факторов в виде дождя, снега, нефтяных загрязнений без участия оператора. Погрешность измерений элементов колесных пар не должна превышать 0,1 мм',
                    },
                ],
            },
            {
                id: '16',
                title:
                    'Создание автоматизированной системы «Топливно-энергетических ресурсов предприятия»',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В основу функционирования программно-аппаратного комплекса должно быть заложено:\n' +
                        'повышение точности учета;\n' +
                        'непрерывного отслеживания количества отпускаемого топлива;\n' +
                        'повышения точности системы учета топлива при его хранении;\n' +
                        'уменьшения несанкционированного отпуска топлива;\n',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Расход топливно-энергетических ресурсов компании',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Оптимизация топливо-энергетических ресурсов предприятия',
                    },
                ],
            },
        ],
    },
    {
        id: 'it',
        category: 'IT решения',
        items: [
            {
                id: '17',
                title:
                    'Система управления оповещением пассажиров и посетителей вокзальных комплексов',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Создание системы управления оповещением пассажиров и посетителей вокзальных комплексов (визуальное, звуковое)',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Поиск и внедрение новых услуг (сервисов), снижение трудозатрат',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Создание системы, работоспособной без учета ручного ввода информации о расписании движения поездов, получение информации за счет движения поезда по мониторингу диспетчерского контроля и получения данных по нормативному расписанию.',
                    },
                ],
            },
            {
                id: '18',
                title:
                    'Единый ресурс (среда) для обмена информации с изготовителями подвижного состава',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Своевременный обмен конструкторской и технологической документации. Оптимизация процессов обслуживании и ремонта подвижного состава',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Неисправность оборудования, оперативный обмен технологической документацией и изменениями',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Повышение качества ремонта подвижного состава, снижение простоя при проведении рекламационной работы с производителем. Эффективная организация производственных процессов ремонта и обслуживания подвижного состава, уход от аутсорсинга на собственное производство',
                    },
                ],
            },
            {
                id: '19',
                title:
                    'Создание системы автоматизации рабочих процессов, связанных с ведением учета воздействия организации на окружающую среду',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Создание системы автоматизации рабочих процессов специалистов-экологов, связанных с ведением учета воздействия структурных подразделений/филиалов/АО «ФПК» на окружающую среду (отчет ЗО-1, журнал учета отходов), формирования статистической отчетности в области природопользования, отчета по выбросам парниковых газов, формирования сводных отчетов по направлениям с отражением вклада каждого структурного подразделения.',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Государственные и корпоративные отчеты по охране окружающей среды',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Создание системы контроля и мониторинга окружающей среды в он-лайн режиме в регионах присутствия.\n' +
                            ' Автоматизация учета и отчётности по экологии по направлениям: ежеквартальный отчет ЗО-1, учет движения отходов отчетность (2-ТП отходы, 2-ТП воздух, 2-ТП водхоз, 4-ОС, выбросы парниковых газов). \n' +
                            'Формирование файлов в XML-формате, совестимых с государственными информационными системами приема статистической отчетности (кабинет природопользователя)',
                    },
                ],
            },
            {
                id: '20',
                title: 'Планировщик (аналог Google Календаря)',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Импортонезависимая платформа:\n' +
                        '- планирование совещаний;\n' +
                        '- видимость для структурных подразделений;\n' +
                        '- мобильный клиент (web-интерфейс);\n' +
                        '- серверная часть в ДОССе',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Дата, время совещания, длительность, наименование, участники',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Календарь с цветовым разграничением важности совещаний, интуитивно понятный интерфейс, подключение до 2000 рабочих мест и 2000 мобильных устройств',
                    },
                ],
            },
        ],
    },
    {
        id: 'services',
        category: 'Сервисы, услуги',
        items: [
            {
                id: '21',
                title:
                    'Поиск новых инновационных сервисов (услуг) для пассажиров и посетителей вокзалов, и пассажирских платформ',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Дирекция железнодорожных вокзалов и Центральная дирекция пассажирских обустройств на постоянной основе находится в поиске новых сервисов (услуг) для предоставления посетителям вокзала, способствующих повышению комфорта и удобства во время нахождения на железнодорожных вокзалах и пассажирских платформах',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text: 'Поиск и внедрение новых услуг (сервисов)',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложенные к внедрению услуги (сервисы) должны быть востребованными у населения и позволять продуктивно и приятно проводить время на вокзале, а также должны быть экономически целесообразны для внедрения',
                    },
                ],
            },
            {
                id: '22',
                title: 'Сервисы для туристов на борту поезда',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных сервисов для путешественников на борту поезда, которые позволят повысить удовлетворенность путешественников, увеличить конверсию и средний чек',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'В настоящее время сервисы для путешественников на борту поезда ограничиваются продуктами информационно-развлекательной системы, предложениями проводников и услугами вагонов-ресторанов',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены к внедрению эффективные сервисы, которые позволят повысить удовлетворенность путешественников, увеличить конверсию и средний чек, а также снизить эксплуатационные расходы',
                    },
                ],
            },
            {
                id: '23',
                title:
                    'Сервисы для взаимодействия с клиентами на азиатском, европейском и российском рынках',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных сервисов для взаимодействия с клиентами и партнерами, которые позволят упростить процесс выбора и приобретения туров с железнодорожной составляющей, увеличить конверсию и средний чек',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'В настоящее время сервисы по взаимодействию с клиентами ограничиваются сайтами ООО «РЖД Тур», ОАО «РЖД», партнерскими системами продаж, офлайн-точками продаж, в том числе кассами вокзалов',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены эффективные сервисы для взаимодействия с клиентами и партнерами, которые позволят упростить процесс выбора и приобретения туров с железнодорожной составляющей, увеличить конверсию и средний чек',
                    },
                ],
            },
        ],
    },
    {
        id: 'technologies',
        category: 'Технологии и технические средства',
        items: [
            {
                id: '24',
                title:
                    'Технологии по уборке снега на инфраструктуре пассажирского комплекса',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных решений, которые позволят снизить негативный эффект, связанный с разрушением покрытий платформ от использования реагентов и применения ручного инструмента в рамках проводимой снегоборьбы',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'В настоящее время уборка вокзальных комплексов осуществляется с использованием реагентов и пескосоляной смеси, которая оказывает негативное воздействие на покрытие платформ, что приводит к его разрушению. Аналогичная проблема возникает при использовании ручного инструмента, такого как ломы и скребки, от ударов которых разрушается покрытие платформ',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены эффективные решения, которые позволят снизить негативный эффект, связанный с разрушением покрытий платформ от использования реагентов и применения ручного инструмента в рамках проводимой снегоборьбы',
                    },
                ],
            },
            {
                id: '25',
                title: 'Альтернативная асфальтобетонная смесь',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Существующий материал «холодная асфальтобетонная смесь» не обеспечивает необходимое качество ремонта верхнего покрытия пассажирских платформ. Стандартная асфальтобетонная смесь для ряда объектов не может быть доставлена ввиду необходимости применения тяжеловесной техники',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Имеется запрос на новые виды покрытий, имеющих более длительный срок эксплуатации, высокую степень устойчивости к внешним разрушающим факторам, в том числе при уборке в зимний период',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены к внедрению эффективные решения, которые позволят снизить расходы на производство работ по укладке и эксплуатации и будут более устойчивы во внешней среде по сравнению с традиционными покрытиями (асфальт, бетон)',
                    },
                ],
            },
            {
                id: '26',
                title:
                    'Поиск инновационных решений (тех. средств), направленных на снижение трудозатрат на нанесение и обновление полосы безопасности на пассажирских платформах',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В настоящее время нанесение и обновление полосы безопасности на пассажирских платформах производится как вручную, так и с помощью машин для нанесения разметки. При необходимости соблюдения ровной линии, данный процесс становится крайне трудозатратным и несет большое количество производственных временных потерь',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Требуется снизить трудозатраты и эксплуатационные расходы на нанесение полосы безопасности',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены к внедрению эффективные решения, которые позволят снизить эксплуатационные расходы и трудозатраты',
                    },
                ],
            },
            {
                id: '27',
                title: 'Автоматизированная мойка фасадов зданий и окон',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Существует потребность в оказании услуг по мойке фасадов зданий и сооружений с привлечением промышленных альпинистов. Процесс усложняется необходимостью согласовывать «окна» в движении поездов для оказания услуг по мойке частей фасадов, находящихся над путями',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Автоматизированная мойка фасадов без привлечения промышленных альпинистов должна обеспечить очищение фасадов зданий и сооружений без привлечения чел. ресурсов. Принимаемая технология должна соответствовать существующим требованиям по охране труда. Исключение вмешательства в технологический процесс вокзала и станции',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Предложены к внедрению решения, которые позволят справиться с обозначенными проблемами и снизить затраты на проведение работ',
                    },
                ],
            },
            {
                id: '28',
                title:
                    'Поиск инновационных решений для сбора мелкого мусора (окурков и т.п.) с железнодорожных путей',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'В настоящее время процесс уборки железнодорожных путей (в т.ч. подплатформенного пространства) от мелкого мусора и окурков осуществляется неэффективным способом и является трудозатратным процессом, так как уборка производится персоналом вручную. Использование ручного инструмента в ходе такой уборки не приносит значительного улучшения процесса как в части временных затрат, так и в части качества проводимых работ (по причине неровной и неоднородной поверхности (щебень и т.п.). Замусоривание железнодорожных путей и подплатформенного пространства происходит во время нахождения пассажиров на пассажирских платформах.  Данная проблема носит постоянный характер',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Процесс уборки путей может быть улучшен с помощью использования соответствующих инновационных устройств, позволяющих производить сбор мелкого мусора с железнодорожных путей механизированным способом более эффективно по сравнению с ручным способом, а именно в более короткие сроки, привлекая не более одной единицы персонала и обеспечивая удовлетворительное качество такой уборки',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Ожидается результат в виде технического решения, которое позволит:\n' +
                            '- осуществлять уборку железнодорожных (в т.ч. подплатформенного пространства) путей механизированным способом, не оказывающим влияние на движение поездов;\n' +
                            '- значительно сократить время и качество уборки по сравнению с ручным трудом',
                    },
                ],
            },
            {
                id: '29',
                title: 'Альтернативные источники электроэнергии',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Предложение эффективных решений, которые позволят снизить эксплуатационные расходы, связанные с потреблением электро- и теплоэнергии, используемой для обеспечения работы вокзальных комплексов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Обеспечение вокзальных комплексов осуществляется в рамках договоров с ресурсоснабжающими организациями',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Внедрение эффективных решений, которые позволят снизить эксплуатационные расходы, связанные с потреблением электро- и теплоэнергии, используемой для обеспечения работы вокзальных комплексов',
                    },
                ],
            },
            {
                id: '30',
                title:
                    'Импортозамещение составляющих элементов лифтов, эскалаторов и подъемников (марки Shanghai Mitsubishi Elevators CO, Kleemann и Otis)',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'При выходе из строя одного из элементов замена аналогами невозможна, в итоге в нерабочем состоянии оказывается устройство в целом',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Обеспечение качественного ремонта и обслуживания эскалаторов и подъемников',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Обеспечение работоспособного состояния эскалаторов и подъемников',
                    },
                ],
            },
            {
                id: '31',
                title: 'Тормозная система пассажирских вагонов',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Разработка системы дискового тормоза отечественного производства',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Тормозная система должна реализовывать следующие функции: снижение скорости движения, остановка и удержание на месте пассажирского вагона локомотивной тяги с массой тары вагонов до 80 т; обеспечение тормозной эффективности пассажирских вагонов со скоростей движения до 200 км/ч не зависимо от температуры окружающего воздуха и атмосферных осадков',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Замена тормозного оборудования вагонов импортного производства отечественными аналогами',
                    },
                ],
            },
            {
                id: '32',
                title: 'Вентилируемый стальной тормозной диск',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text: 'Разработка вентилируемых стальных тормозных дисков',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Вентилируемый стальной тормозной диск должен работать совместно с металлокерамическими тормозными накладками. Пара трения должна реализовывать следующие функции: снижение скорости движения, остановка и удержание на месте пассажирского вагона локомотивной тяги, а также выдерживание термических нагрузок при торможении вагона с массой тары до 80 тонн;\n' +
                            'обеспечение тормозной эффективности пассажирских вагонов со скоростей движения до 200 км/ч не зависимо от температуры окружающего воздуха и атмосферных осадков; обеспечение тормозной эффективности пассажирских вагонов со скоростей движения до 200 км/ч',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Замена вентилируемых стальных тормозных дисков импортного производства на отечественные',
                    },
                ],
            },
            {
                id: '33',
                title:
                    'Телекоммуникационное оборудование для оказания услуг доступа в Интернет (агрегатор связи)',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        '- Поиск новых технологий;\n' +
                        '- разработка оборудования из отечественных комплектующих;\n' +
                        '- внедрение на поездах ДОСС;\n' +
                        '- сопровождение (ТО и ремонт);\n' +
                        '- поиск технологий по увеличению пропускной способности между базовой станцией и агрегаторами связи',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text: 'Все частоты операторов связи России',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Проведение испытаний; сравнение с существующим оборудованием; принятие решения о проведении модернизации',
                    },
                ],
            },
        ],
    },
    {
        id: 'other',
        category: 'Прочее',
        items: [
            {
                id: '34',
                title:
                    'Экологически чистый хладагент, не содержащий токсичные и легковоспламеняющиеся компоненты для установок кондиционирования воздуха',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Подбор вариантов экологически чистых хладагентов, не содержащих токсичных и легковоспламеняющихся компонентов для их применения в установках кондиционирования воздуха пассажирских вагонов',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'Экологически чистые хладагенты, не содержащие токсичные и легковоспламеняющиеся компоненты',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Применение экологически чистых хладагентов, не содержащих токсичные и легковоспламеняющиеся компоненты и производящихся на территории РФ, в установках кондиционирования воздуха пассажирских вагонов',
                    },
                ],
            },
            {
                id: '35',
                title:
                    'Формирование бизнес-модели, развитие мультимодальных перевозок в АО «ФПК» с применением автотранспорта',
                valueMain: {
                    name: 'Описание задачи/проблемы',
                    text:
                        'Определение географии присутствия в отдельных регионах; определение модели управления парком: аутсорсинг, владение и т.д.; определение списка необходимых партнеров для реализации бизнес-модели, включая партнеров среди транспортных операторов/агрегаторов, партнеров в области продаж, партнеров-поставщиков транспортных средств, поставщиков и операторов систем автоматизации/отдельных ИТ-решений;\n' +
                        'построение финансовой модели до 2025 года для целевой опции бизнес-модели',
                },
                valueOther: [
                    {
                        name: 'Входные данные',
                        text:
                            'В настоящее время мультимодальные перевозки организованы путем подвоза пассажиров автотранспортом на основе заключенного договора фрахтования с одновременным оформлением двух проездных документов: билета на поезд дальнего следования и квитанции разных сборов «Талон, дающий право на получение услуги по организации перевозки автомобильным транспортом»',
                    },
                    {
                        name: 'Ожидаемый результат',
                        text:
                            'Сформированная бизнес-модель развития мультимодальных перевозок, включающая: список первоочередных мероприятий для запуска и масштабирования автобусного бизнеса, перечень крупных автобусных операторов для потенциального поглощения (в случае выбора варианта развития бизнеса с покупкой существующего игрока)',
                    },
                ],
            },
        ],
    },
];

export const TechnoFocus2022En = [
    {
        id: 'complex',
        category: 'Complex systems',
        items: [
            {
                id: '1',
                title:
                    'Creation of a system of automated control and management of the station',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Suboptimal use of resources of heating, ventilation, climate control and other equipment, as well as significant labor costs for setting the necessary parameters in manual mode in the premises and buildings of railway stations are prerequisites for the introduction of automatic control systems of station systems',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'At most stations, the provision of heat and energy resources is carried out by traditional methods and methods. The introduction of automated control systems is required, characterized by low implementation costs and fast payback',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective solutions have been proposed for implementation that will reduce the operating costs associated with the consumption of electricity and heat energy used to ensure the operation of station complexes',
                    },
                ],
            },
            {
                id: '2',
                title:
                    'Remote monitoring and control of devices of grounding systems of passenger platforms',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Offering effective solutions that will allow remote monitoring and monitoring of passenger platform grounding systems',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Organization of work to reduce the risks of train delays',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Implementation of effective solutions that will allow remote monitoring and control of the operation of grounding systems',
                    },
                ],
            },
            {
                id: '3',
                title:
                    'Remote monitoring and control of devices of passenger platform lighting systems',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Offering effective solutions that will allow remote monitoring and control of passenger platform lighting systems',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Organization of work to reduce the risks of passenger complaints',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Implementation of effective solutions that will allow remote monitoring and control of the operation of lighting systems',
                    },
                ],
            },
            {
                id: '4',
                title:
                    'Remote medical examination systems for locomotive crews',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Organization of pre-trip medical examinations at stations in the absence of medical centers',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Development and amendment of regulatory documentation for pre-trip medical examinations. Development of an algorithm for passing pre-trip medical examinations. Connecting the complex of pre-trip medical examinations to the data transmission system',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Increase in useful time in motion, reduction of overtime hours and organization of new entry points for locomotive crews',
                    },
                ],
            },
            {
                id: '5',
                title:
                    'The system of online monitoring and analysis of the performance of cycle work by repair personnel "smart glasses" with the ability to interact with the database of technological maps, video and audio call of the master, etc.',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Implementation of the technology of repair of components and parts of the MVPS, including by specialists with little experience',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Implementation of repair technology at TO-3, TR-1, TR-2, TR-3, KR for electric trains and diesel rolling stock',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Education of cases of equipment malfunction in operation and change of scheduled preventive maintenance',
                    },
                ],
            },
            {
                id: '6',
                title:
                    'Robotization and automation of flaw detection of components and parts of motor car rolling stock',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Inability to use the flaw detection equipment promptly without prior preparation. In addition, the employee must be trained and have work experience to make decisions about the identified defects.',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Carrying out flaw detection of wheelsets, auto couplings, trolleys and other parts and assemblies',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'New mobile devices flaw detection (eddy current, ultrasonic, magnetic) for use by untrained personnel, obtaining results and transmission to an automated system',
                    },
                ],
            },
            {
                id: '7',
                title:
                    'Integrated security, video recording and information system',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Currently, automated analytics of recorded data by outdoor and indoor surveillance cameras is not developed',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Video sequence of data transmitted by electric train cameras',
                    },
                    {
                        name: 'Expected Result',
                        text: 'Electronic recognition and information system',
                    },
                ],
            },
            {
                id: '8',
                title:
                    'Comprehensive protection system against passenger jamming by automatic doors',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'During operation, there are cases of clamping by automatic doors',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Improvement of existing systems of "anti-jamming" of door blocks of the sliding type of existing and manufactured motor-car rolling stock',
                    },
                    {
                        name: 'Expected Result',
                        text: 'Safe boarding of passengers',
                    },
                ],
            },
            {
                id: '9',
                title:
                    'Integrated system of protection of rolling stock from passengers passing outside the train ',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Restrict access of unauthorized persons to the roof equipment of the rolling stock',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'The compositions of the ED v/I series are equipped with protection systems',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Exclusion of the possibility of passengers traveling outside the rolling stock',
                    },
                ],
            },
            {
                id: '10',
                title:
                    'Active diagnostics system with a single data processing and storage center',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Improving the technical reliability of rolling stock. Predictive diagnostics of electrical, electronic, pneumatic equipment and electrical machines',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Electric trains manufactured by OA "DMZ" of the ED v/i series and rail buses of JSC "Metrovagonmash"',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Reduction of cases of equipment malfunction in operation and change of scheduled preventive maintenance',
                    },
                ],
            },
            {
                id: '11',
                title:
                    'An automated complex that allows obtaining information about the technical condition of current collectors, wheel sets, traction drive, mechanical equipment of motor-car rolling stock',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Avoiding manual measurement of parameters of motor car rolling stock during scheduled repairs',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Measurements of parameters of motor-car rolling stock (pantographs, wheel pairs, thickness of the pad, automatic coupling device, condition of the motor or trailer trolley, etc.)',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Automation of the process of collecting information (measurements on the parameters of current collectors, brushes of electric machines, wheel pairs, mechanical equipment) with data transmission to automated systems and installation of barriers to the impossibility of entering data above the limit parameters',
                    },
                ],
            },
            {
                id: '12',
                title:
                    'Automated complex of monitoring and analysis of safe operation of hazardous production facilities (hereinafter – OPO).',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'State and corporate industrial safety reports are generated manually for each structural unit and branches. The inability to analyze the current situation of industrial safety at the OPO, operational management and timely implementation of industrial safety measures in the online mode.',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'State and corporate reports on industrial safety, the conclusion of the industrial safety expertise, the results of the survey and inspection of technical devices, buildings and structures at the OPO.',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Reduction of accidents and incidents at the OPO.\n' +
                            'The possibility of automated formation of an investment program for the renewal of technical devices operated at the OPO.\n' +
                            'Due to automation, the costs of performing mandatory industrial safety measures are reduced. Automated accounting and formation of a plan for certification of responsible specialists in the field of industrial safety.\n' +
                            'Analysis and control of the timing of elimination of violations during the operation of technical devices at the OPO, identified by the results of inspections of state supervisory authorities. automated Automatic generation of a report on ensuring industrial safety requirements in Excel format and/or XML format for referral to Rostechnadzor.',
                    },
                ],
            },
            {
                id: '13',
                title:
                    'The system of remote monitoring of the technical condition and operation of automatic fire protection systems at the facilities of JSC "FPC"',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Allows you to establish objective remote monitoring, automate the detection of malfunctions in the operation of fire protection systems. The fact of troubleshooting can be checked online',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'The register of real estate, the list of systems, data about the system (equipment, certificates, passports, executive documentation, operating instructions, etc.), the objects of its placement. Information about the status of the systems should be transmitted to the chief engineers of the branches and to the central office of JSC "FPC" via the Internet',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Organization of the ARM of the chief engineer of the branch, the structural division of the branch, the central office of JSC "FPC". Improving the efficiency and efficiency of monitoring the condition and maintenance of the SDR, reducing the time from the moment of working out the SDR to troubleshooting. Protection of life and health of employees of JSC "FPC".',
                    },
                ],
            },
            {
                id: '14',
                title: 'Automated fire safety management system',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        "Allows you to establish objective remote control over the elimination of regulations of the supervisory authorities of the Ministry of Emergency Situations, the Prosecutor's office, Goszheldornadzor",
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Data on prescriptions, violations, measures, deadlines for elimination and execution',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Establishment of control over the execution of regulations, exclusion of repeated penalties for non-fulfillment, prevention of administrative suspension of facilities due to non-fulfillment of measures.',
                    },
                ],
            },
            {
                id: '15',
                title:
                    'Creation of a software and hardware complex that allows to control the geometric parameters of wheel pairs and identify operational defects on the surface of the wheels',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The basis for the functioning of the software and hardware complex should be the use of automated controls that allow monitoring the geometric parameters of wheelsets on the train in order to improve the quality of maintenance of the running gear of passenger cars and reduce the time for inspection and control of the geometric parameters of wheelsets by the inspector of the repair of cars',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Diagnostic parameters:\n' +
                            'registration of the date and time of control; train number; determination of the number of cars in the train; car number; registration of the speed of the train; wheel serial number; geometric parameters and wheel defects (Ensure the thickness of the ridge, rim thickness, uniform rolling, uneven rolling, dents, vertical undercut of the ridge, sliders, navars, local rim widening, ring workings, chipping of the outer side surface of the wheel rim, including chipping of the circular crack influx)',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'The software and hardware complex must ensure the accuracy of measurements of the geometric parameters of wheelsets around the clock, including under the condition of external factors in the form of rain, snow, oil pollution without the participation of the operator.\n' +
                            'The measurement error of wheelset elements should not exceed 0.1 mm',
                    },
                ],
            },
            {
                id: '16',
                title:
                    'Creation of an automated system of "Fuel and energy resources of the enterprise"',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The basis for the functioning of the software and hardware complex should be laid:\n' +
                        'improving the accuracy of accounting;\n' +
                        'continuous tracking of the amount of fuel released;\n' +
                        'improving the accuracy of the fuel metering system during its storage;\n' +
                        'reduction of unauthorized fuel release;\n' +
                        'Maintaining an archive of fuel release data.',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Consumption of fuel and energy resources of the company',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Optimization of fuel and energy resources of the enterprise',
                    },
                ],
            },
        ],
    },
    {
        id: 'it',
        category: 'IT solutions',
        items: [
            {
                id: '17',
                title:
                    'Notification management system for passengers and visitors of railway complexes',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Creation of a control system for notifying\n' +
                        'passengers and visitors of railway complexes (visual, audio)',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Search and implementation of new services (services), reduction of labor costs',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Creation of a system that is operable without taking into account manual input of information about the train schedule, obtaining information due to the movement of the train by monitoring dispatching control and obtaining data on the regulatory schedule.',
                    },
                ],
            },
            {
                id: '18',
                title:
                    'A single resource (environment) for the exchange of information with manufacturers of rolling stock',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Timely exchange of design and technological documentation. Optimization of rolling stock maintenance and repair processes',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Equipment malfunction, operational exchange of technological documentation and changes',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Improving the quality of rolling stock repair, reducing downtime during the reclamation work with the manufacturer. Efficient organization of production processes for repair and maintenance of rolling stock, avoiding outsourcing to own production',
                    },
                ],
            },
            {
                id: '19',
                title:
                    'Creation of a system for automating work processes related to accounting for the impact of the organization on the environment',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Creation of a system for automating the work processes of environmental specialists related to accounting for the impact of structural divisions/branches / JSC "FPC" on the environment (ZO-1 report, waste accounting journal), the formation of statistical reporting in the field of environmental management, a report on greenhouse gas emissions, the formation of summary reports on areas reflecting the contribution of each structural divisions.',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'State and corporate environmental protection reports',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Creation of an online environmental control and monitoring system in the regions of presence.\n' +
                            'Automation of accounting and reporting on ecology in the following areas: quarterly report FOR-1, accounting for the movement of waste reporting (2-TP waste, 2-TP air, 2-TP water farm, 4-OS, greenhouse gas emissions).\n' +
                            'Formation of files in XML format compatible with state information systems for receiving statistical reports (cabinet of the nature user)',
                    },
                ],
            },
            {
                id: '20',
                title: 'Scheduler (analogous to Google Calendar)',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Import-independent platform:\n - planning meetings;\n - visibility for structural divisions;\n - mobile client (web interface);\n - server part in DOSSE',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Date, time of the meeting, duration, name, participants',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Calendar with color differentiation of the importance of meetings, intuitive interface, connection of up to 2000 workplaces and 2000 mobile devices',
                    },
                ],
            },
        ],
    },
    {
        id: 'services',
        category: 'Services',
        items: [
            {
                id: '21',
                title:
                    'Search for new innovative services (services) for passengers and visitors of railway stations and passenger platforms',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The Directorate of Railway Stations and the Central Directorate of Passenger Facilities are constantly in search of new services (services) to provide visitors to the station, contributing to increased comfort and convenience while staying at railway stations and passenger platforms',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Search and implementation of new services (services)',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'The services (services) proposed for implementation should be in demand among the population and allow for productive and pleasant time at the train station, and should also be economically feasible for implementation',
                    },
                ],
            },
            {
                id: '22',
                title: 'Services for tourists on board the train',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Offering effective services for travelers on board the train, which will increase the satisfaction of travelers, increase conversion and average check',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Currently, services for travelers on board the train are limited to the products of the infotainment system, the offers of conductors and the services of dining cars',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective services have been proposed for implementation that will increase the satisfaction of travelers, increase conversion and average check, as well as reduce operating costs',
                    },
                ],
            },
            {
                id: '23',
                title:
                    'Services for customer interaction in the Russian, Asian, European markets',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Offering effective services for interaction with customers and partners, which will simplify the process of choosing and purchasing tours with a railway component, increase conversion and average receipt',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Currently, customer interaction services are limited to the websites of Russian Railways Tour LLC, Russian Railways, partner sales systems, offline points of sale, including train station ticket offices',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective services for interaction with customers and partners are proposed, which will simplify the process of choosing and purchasing tours with a railway component, increase conversion and average receipt',
                    },
                ],
            },
        ],
    },
    {
        id: 'technologies',
        category: 'Technologies and technical devices',
        items: [
            {
                id: '24',
                title:
                    'Technologies for snow removal on the infrastructure of the passenger complex',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The offer of effective solutions that will reduce the negative effect associated with the destruction of platform coatings from the use of reagents and the use of hand tools in the framework of the ongoing snowmaking',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Currently, the cleaning of station complexes is carried out using reagents and a sand-salt mixture, which has a negative impact on the coating of platforms, which leads to its destruction. A similar problem arises when using hand tools, such as crowbars and scrapers, from the impacts of which the coating of the platforms is destroyed',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective solutions have been proposed that will reduce the negative effect associated with the destruction of platform coatings from the use of reagents and the use of hand tools in the framework of snow-fighting',
                    },
                ],
            },
            {
                id: '25',
                title: 'Alternative asphalt concrete mix',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The existing material "cold asphalt concrete mix" does not provide the necessary quality of repair of the upper coating of passenger platforms. Standard asphalt concrete mix for a number of objects cannot be delivered due to the need to use heavy machinery\n',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'There is a request for new types of coatings that have a longer service life, a high degree of resistance to external destructive factors, including when cleaning in winter',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective solutions have been proposed for implementation, which will reduce the costs of laying and operation and will be more stable in the external environment compared to traditional coatings (asphalt, concrete)',
                    },
                ],
            },
            {
                id: '26',
                title:
                    'Search for innovative solutions (technical means) aimed at reducing labor costs for applying and updating the safety lane on passenger platforms',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Currently, the application and updating of the safety lane on passenger platforms is carried out both manually and with the help of marking machines. If it is necessary to observe a flat line, this process becomes extremely labor-intensive and carries a large number of production time losses.',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'It is required to reduce labor costs and operating costs for applying a safety strip',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Effective solutions that will reduce operating costs and labor costs are proposed for implementation',
                    },
                ],
            },
            {
                id: '27',
                title: 'Automated washing of building facades and windows',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'There is a need to provide services for washing facades of buildings and structures with the involvement of industrial climbers. The process is complicated by the need to coordinate "windows" in the movement of trains to provide services for washing parts of facades located above the tracks',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Automated washing of facades without the involvement of industrial climbers should ensure the cleaning of facades of buildings and structures without the involvement of human resources. The adopted technology must comply with existing labor protection requirements. Exclusion of interference in the technological process of the railway station and the station',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Solutions have been proposed for implementation that will help to cope with the identified problems and reduce the cost of work',
                    },
                ],
            },
            {
                id: '28',
                title:
                    'Search for innovative solutions for collecting small garbage (cigarette butts, etc.) from railway tracks',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Currently, the process of cleaning railway tracks (including sub-platform space) from small debris and cigarette butts is carried out in an inefficient way and is a labor-intensive process, since staff do cleaning manually. The use of hand tools during such cleaning does not bring a significant improvement in the process both in terms of time costs and in terms of the quality of the work carried out (due to uneven and heterogeneous surfaces (crushed stone, etc.). Littering of railway tracks and sub-platform space occurs while passengers are on passenger platforms. This problem is permanent',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'The process of track cleaning can be improved by using appropriate innovative devices that allow for the collection of small debris from railway tracks in a mechanized way more efficiently compared to the manual method, namely in a shorter time, involving no more than one unit of personnel and ensuring satisfactory quality of such cleaning.',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'The result is expected in the form of a technical solution that will allow:\n' +
                            '- to carry out the cleaning of railway (including sub-platform space) tracks in a mechanized way that does not affect the movement of trains;\n' +
                            '- significantly reduce the time and quality of cleaning compared to manual labor',
                    },
                ],
            },
            {
                id: '29',
                title: 'Alternative sources of electricity',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'The offer of effective solutions that will reduce the operating costs associated with the consumption of electricity and heat used to ensure the operation of station complexes',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Provision of station complexes is carried out within the framework of contracts with resource-supplying organizations',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Implementation of effective solutions that will reduce the operating costs associated with the consumption of electricity and heat used to ensure the operation of station complexes',
                    },
                ],
            },
            {
                id: '30',
                title:
                    'Import substitution of components of elevators, escalators and lifts (Shanghai Mitsubishi Elevator CO, Kleemann and Otis brands)',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'If one of the elements fails, replacement with analogues is impossible, as a result, the device as a whole turns out to be inoperable',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Providing high-quality repair and maintenance of escalators and lifts',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Ensuring the working condition of escalators and lifts',
                    },
                ],
            },
            {
                id: '31',
                title: 'Passenger wagon braking system',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Development of a disc brake system of domestic production',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'The braking system must implement the following functions: reducing the speed of movement, stopping and holding in place a passenger car of locomotive traction with a tare weight of cars up to 80 tons; ensuring the braking efficiency of passenger cars with speeds up to 200 km / h, regardless of ambient temperature and precipitation',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Replacement of brake equipment of cars of imported production with domestic analogues',
                    },
                ],
            },
            {
                id: '32',
                title: 'Ventilated steel brake disc',
                valueMain: {
                    name: 'Description of the task/problem',
                    text: 'Development of ventilated steel brake discs',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'The ventilated steel brake disc must work in conjunction with metal-ceramic brake linings. The friction pair should implement the following functions: reducing the speed of movement, stopping and holding the locomotive traction in place of the passenger car, as well as withstanding thermal loads when braking a car with a container weight of up to 80 tons;\n' +
                            'ensuring the braking efficiency of passenger cars with speeds up to 200 km / h, regardless of ambient temperature and precipitation; ensuring the braking efficiency of passenger cars with speeds up to 200 km / h',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Replacement of ventilated steel brake discs of imported production with domestic ones',
                    },
                ],
            },
            {
                id: '33',
                title:
                    'Telecommunication equipment for the provision of Internet access services (communication aggregator)',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        '- Search for new technologies; \n - development of equipment from domestic components; \n - implementation on DOSS trains; \n - maintenance (maintenance and repair); \n - search for technologies to increase bandwidth between the base station and communication aggregators',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text: 'All frequencies of Russian telecom operators',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Conducting tests; comparison with existing equipment; making a decision on modernization',
                    },
                ],
            },
        ],
    },
    {
        id: 'other',
        category: 'The others',
        items: [
            {
                id: '34',
                title:
                    'Environmentally friendly refrigerant that does not contain toxic and flammable components for air conditioning installations',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Selection of options for environmentally friendly refrigerants that do not contain toxic and flammable components for their use in passenger car air conditioning units',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Environmentally friendly refrigerants that do not contain toxic and flammable components',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'The use of environmentally friendly refrigerants that do not contain toxic and flammable components and are produced in the territory of the Russian Federation in passenger car air conditioning units',
                    },
                ],
            },
            {
                id: '35',
                title:
                    'Formation of a business model, development of multimodal transportation in JSC "FPC" with the use of motor transport',
                valueMain: {
                    name: 'Description of the task/problem',
                    text:
                        'Determining the geography of presence in certain regions; determining the fleet management model: outsourcing, ownership, etc.; determining the list of necessary partners for the implementation of the business model, including partners among transport operators/aggregators, sales partners, partners-suppliers of vehicles, suppliers and operators of automation systems/ individual IT solutions;\n' +
                        'building a financial model until 2025 for the target option of the business model',
                },
                valueOther: [
                    {
                        name: 'Input data',
                        text:
                            'Currently, multimodal transportation is organized by transporting passengers by motor transport on the basis of a chartering contract concluded with the simultaneous issuance of two travel documents: a ticket for a long-distance train and a receipt for various fees "Coupon entitling to receive services for organizing transportation by road"',
                    },
                    {
                        name: 'Expected Result',
                        text:
                            'Formed a business model for the development of multimodal transportation, including: a list of priority measures for launching and scaling up the bus business, a list of large bus operators for potential takeover (in case of choosing a business development option with the purchase of an existing player)',
                    },
                ],
            },
        ],
    },
];
