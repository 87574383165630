import jsonServerProvider from 'ra-data-json-server';
import axios from 'axios';
import LKRepository from '../models/LKRepository';

let defaultUrl = '/bitrix/services/main/ajax.php?action=webpractik:main.forms';
let premoderationRoute = `${defaultUrl}.questionnaireexpertise`;

let questionnaireRoute = `${defaultUrl}.questionnaire`;
const jsonProvider = jsonServerProvider(premoderationRoute);

export const dataProvider = {
    ...jsonProvider,
    getOne: (id, params) => {
        return axios
            .post(
                questionnaireRoute,
                LKRepository.generateFormData({ ID: params.id })
            )
            .then(res => ({
                data: res.data.data,
            }));
    },
    getList: (resource, params) => {
        const objParams = {
            COUNT_ON_PAGE: params.pagination.perPage,
            NUMBER_PAGE: params.pagination.page,
            SORT_FIELD: 'DATE_CREATE',
            SORT_VECTOR: params.sort.order,
            STATE: params.filter.status,
            VECTOR: '',
            VECTOR_OTHER: '',
            COMMENT: '',
        };
        if (params.filter.q) {
            objParams.NAME_PROJECT = params.filter.q;
        }
        return axios
            .post(premoderationRoute, LKRepository.generateFormData(objParams))
            .then(res => ({
                data: res.data.data.QUESTIONNAIRE,
                total: res.data.data.COUNT,
            }));
    },
};
