import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useLogin, useNotify, Notification } from 'react-admin';
import errorMessages from 'data/errorMessages.json';
import './style.sass';
import FlexContainerCenter from 'components/layout/FlexContainerCenter';
import RedButton from 'components/ui/RedButton/index';
import AdminTextField from 'components/form/AdminTextField';
import AdminPasswordTextField from 'components/form/AdminPasswordTextField';

const Authorization = ({ setActiveScreen }) => {
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = values => {
        login(values).catch(err => notify(err));
    };

    const { required, email } = errorMessages;

    const authorizationSchema = Yup.object().shape({
        LOGIN: Yup.string().email(email).required(required),
        PASS: Yup.string().required(required),
    });

    return (
        <>
            <Formik
                initialValues={{ LOGIN: '', PASS: '' }}
                onSubmit={handleSubmit}
                validationSchema={authorizationSchema}
            >
                {props => (
                    <Form noValidate={true}>
                        <FlexContainerCenter>
                            <div className="authorization-form-wrap">
                                <AdminTextField
                                    formikProps={props}
                                    label="E-mail"
                                    name="LOGIN"
                                    type="text"
                                />
                                <AdminPasswordTextField
                                    formikProps={props}
                                    label="Пароль"
                                    name="PASS"
                                />
                                <div
                                    className="forget-password"
                                    onClick={() =>
                                        setActiveScreen('forgetPassword')
                                    }
                                >
                                    Забыли пароль?
                                </div>
                            </div>
                            <RedButton size="sm" type="submit">
                                Войти
                            </RedButton>
                        </FlexContainerCenter>
                    </Form>
                )}
            </Formik>
            <Notification />
        </>
    );
};

export default Authorization;
