import React from 'react';
import clsx from 'clsx';
import cn from './style.module.sass';

const ExpertsTabsItem = ({ item }) => (
    <div
        className={clsx(cn.col, item.show && cn.show, item.default && cn.show)}
    >
        <div className={cn.item}>
            <img src={item.img} alt="" className={cn.img} />
            <div
                className={cn.name}
                dangerouslySetInnerHTML={{
                    __html: item.name,
                }}
            />
            <div
                className={cn.position}
                dangerouslySetInnerHTML={{
                    __html: item.position,
                }}
            />
        </div>
    </div>
);

export default ExpertsTabsItem;
