import direction_2021_1 from 'assets/img/svg/direction/icon-01_2021.svg';
import direction_2021_2 from 'assets/img/svg/direction/icon-02_2021.svg';
import direction_2021_3 from 'assets/img/svg/direction/icon-03_2021.svg';
import direction_2021_4 from 'assets/img/svg/direction/icon-04_2021.svg';

export const DirectionsItems2021 = [
    {
        icon: direction_2021_1,
        question: 'Складская логистика',
        answer: `
        <ol>
<li>Снижение энергоемкости терминально-складской деятельности.</li>
<li>Повышение безопасности складских операций (предотвращение повреждения грузов, обеспечение техники безопасности при погрузочно-разгрузочных работах и&nbsp;др.)</li> 
<li>Автоматизированная система измерения объемов инертных грузов.</li>
<li>Интеллектуальная система охраны труда и&nbsp;промышленной безопасности при выполнении погрузочно-разгрузочных работ.</li> 
<li>Автоматизированные сортировочные устройства тарно-штучных грузов.</li>
<li>Автоматизированные захваты для тяжеловесных грузов.</li>
<li>Системы цифровой и&nbsp;дополненной реальности, применимые к&nbsp;терминально-складской деятельности.</li>
<li>WMS-системы управления складскими пространствами.</li>
<li>Оптимизация работы складов.</li>
<li>Управление материальными запасами на&nbsp;складах и&nbsp;в&nbsp;пути следования.</li>
<li>Роботизация складских операций.</li>
<li>Автоматизация приемки товаров и&nbsp;контроля складских операций.</li>
<li>Экзоскелеты для погрузо-разгрузочных работ.</li>
<li>Машинное зрение для оптимизации складских операций.</li>
<li>AI&nbsp;для складской логистики (нейросетевая аналитика, учет транспортных средств, движения и&nbsp;доступа персонала и&nbsp;др.).</li>
<li>Паллетайзеры.</li>
<li>Инновационные решения по&nbsp;упаковке (новые материалы, способы упаковки хрупких грузов и&nbsp;др.).</li>
<li>Системы сортировки посылок, грузов.</li> 
<li>Системы высокоплотного хранения.</li> 
<li>Решения для оптимизации складского хранения для дарк-сторов.</li>
<li>Беспилотные решения для оптимизации логистики внутри терминально-складских комплексов.</li>
<li>Технология автоматической идентификации (RFID, NB-IoT).</li>
<li>М2М (межмашинные технологии).</li>
<li>Оптимизация складских операций за&nbsp;счет применения Pick-by-Vision.</li>
</ol>
        `,
    },
    {
        icon: direction_2021_2,
        question: 'Транспортная логистика',
        answer: `<ol>
<li>Безлюдные технологии основных повторяющихся и&nbsp;трудоемких процессов.
<ol>
<li>Процесс расцепа вагонов при роспуске на&nbsp;сортировочной горке.</li>
<li>Маневровая работа без участия машинистов и&nbsp;составительской бригады.</li>
<li>Закрепление подвижного состава на&nbsp;приемо-отправочных путях.</li>
<li>Управление стрелками и&nbsp;сигналами.</li> 
</ol>
</li>
<li>Tехнические решения по&nbsp;обеспечению безопасности движения поездов.</li>
<li>Автоматизированная фиксация учетных состояний основных объектов управления (внедрение максимально экономичных решений по&nbsp;достоверному пооперационному учету и&nbsp;агрегации данных без участия человека).</li>
<li>Перевозка (погрузка, разгрузка, сортировка) мусора. Уникальная тара.</li>
<li>Решения для перевозки мелких партий груза.</li> 
<li>Решения для перевозки насыпных грузов (блок&nbsp;&mdash; трейл).</li> 
<li>Внедрение ресурсосберегающих технологий в&nbsp;процессы транспортировки материально-технических ресурсов.</li>
<li>Технические средства и&nbsp;технологии для минимизации остатков груза в&nbsp;вагоне после разгрузки, в&nbsp;т.ч. сокращение смерзаемости груза, новые технологии погрузки и&nbsp;выгрузки.</li>
<li>Технологии повышения уровня сервиса железнодорожных перевозок.</li>
<li>Новые виды вкладышей, креплений, технологий погрузки, крепления и&nbsp;выгрузки грузов.</li>
<li>Решения, позволяющие минимизировать затраты оператора на&nbsp;ремонт вагона.</li>
<li>Решения, позволяющие снизить риск простоев подвижного состава на&nbsp;волатильном рынке и&nbsp;в&nbsp;периоды сезонного спада.</li>
<li>Технологии применения съемных кузовов.</li>
<li>Контрейлерные перевозки.</li>
<li>Беспилотные магистральные автомобильные грузоперевозки.</li>
<li>Новые технологии доставки груза на&nbsp;&laquo;последней миле&raquo;.</li>
<li>Решения, обеспечивающие снижение времени нахождения подвижного состава на&nbsp;пограничных переходах при осуществлении международных перевозок.</li>
<li>Оптимизация портовой логистики за&nbsp;счёт внедрения комплекса технологичных решений (электронный погрузочный лист, мобильные рабочие места и&nbsp;др.).</li>
</ol>`,
    },
    {
        icon: direction_2021_3,
        question: 'Информационная логистика',
        answer: `<ol>
<li>Электронный технологический документооборот.</li>
<li>Решения по&nbsp;автоматическому распознаванию рукописного текста, в&nbsp;том числе на&nbsp;иностранных языках (транспортные накладные и&nbsp;пр.).</li>
<li>Технологии обеспечения регламента переговоров при организации движения.</li>
<li>Определение ключевых показателей эффективности филиалов компании в&nbsp;границах технологического полигона с&nbsp;использованием инструментов динамического моделирования.</li>
<li>Онлайн приём груза. Обмер с&nbsp;электронного устройства и&nbsp;помещение в&nbsp;вагон, внесение в&nbsp;базу всех параметров груза. Использование лидаров и&nbsp;электронных устройств для измерения размеров вагонов и&nbsp;грузов. Варианты использования технологий блокчейн.</li> 
<li>IT-платформа, объединяющая поставщиков услуг, учёт генерации, конвертации перевозок в&nbsp;систему бонусов. Оплата бонусами услуг различных поставщиков. Развитие партнёрских проектов.</li> 
<li>Цифровые двойники в&nbsp;терминально-складском комплексе, логистике и&nbsp;цепочке поставок.</li> 
<li>Машинное чтение (Machine reading) с&nbsp;точностью разбора от&nbsp;95% и&nbsp;интеграцией с&nbsp;базами данных.</li>
<li>Инструменты для расчета транспортно-заготовительных расходов (&laquo;Калькулятор&raquo;) при доставке материально-технических ресурсов не&nbsp;железнодорожным транспортом.</li>
<li>Инструменты для моделирования и&nbsp;оптимизации транспортно- логистических сетей.
<ol>
<li>Инструмент для оптимизации поставок горюче-смазочных материалов.</li>
<li>Инструмент для оптимизации запасов и&nbsp;схем снабжения материально-технических ресурсов.</li>
</ol></li>
<li>Автоматизация сбора big-data и&nbsp;анализа данных по&nbsp;альтернативным видам грузоперевозок, формирование статистической базы данных.</li>
<li>Мониторинг состояния автотранспорта с&nbsp;возможностью предсказания поломок.</li>
<li>Мониторинг состояния водителей в&nbsp;рейсе.</li>
<li>Мониторинг перемещения автотранспортных средств и&nbsp;грузов (температурные логгеры, датчики влажности, движения, нагрузки на&nbsp;ось и&nbsp;др.).</li>
<li>Решения по&nbsp;маршрутизации движения курьеров с&nbsp;возможностью перестроения.</li>
<li>Управление цепями поставок SCV&nbsp;в режиме реального времени.</li>
<li>Цифровизация процесса оформления сборных грузов на&nbsp;интермодальных (море+железная дорога) маршрутах.</li>
</ol>`,
    },
    {
        icon: direction_2021_4,
        question: 'Сервисная логистика',
        answer: `
<ol>
<li>Совмещение коммерческого и&nbsp;технического осмотра вагонов.</li>
<li>Производство совмещенного ремонта инфраструктуры &laquo;под ключ&raquo; с&nbsp;использованием технических и&nbsp;технологических решений, направленных на&nbsp;увеличение межремонтных сроков.
<ol>
<li>Формирование комплексной технологии одновременного (последовательного) выполнения ремонтных работ всеми инфраструктурными хозяйствами (полный цикл).</li>
<li>Формирование оценки эффективности принимаемых решений при планировании работ на&nbsp;инфраструктуре.</li>
<li>Формирование объективной оценки состояния инфраструктуры до&nbsp;начала работ, после окончания работ, в&nbsp;период &laquo;гарантийного срока&raquo; с&nbsp;мониторингом следующих показателей:
<ol>
<li>обеспечение заявленного времени доступа к&nbsp;исправной инфраструктуре;</li>
<li>соответствие установленных скоростей движения проектным значениям;</li>
<li>поездо-часы задержек в&nbsp;связи с&nbsp;отказами всех категорий в&nbsp;работе инфраструктуры;</li>
<li>срывы, отмены и&nbsp;отказы от&nbsp;технологических &laquo;окон&raquo;;</li>
<li>удельная выработка путевых машин;</li>
<li>уровень безопасности движения.</li>
</ol>
</li>
</ol>
<li>Цифровизация технологического процесса пункта технической передачи:
<ol>
<li>Средства (приборы и&nbsp;аппараты) автоматизированного контроля и&nbsp;диагностирования повреждений и&nbsp;разоборудования (хищений) деталей и&nbsp;узлов вагонов и&nbsp;контейнеров.</li>
<li>Автоматизированные системы контроля и&nbsp;диагностирования повреждений и&nbsp;разоборудования (хищений) деталей и&nbsp;узлов вагонов и&nbsp;контейнеров на&nbsp;базе технологии машинного зрения.</li>
</ol>
</li>
<li>Масштабируемая и&nbsp;высокопроизводительная платформа интернета вещей (IOT) для производственных процессов, включающая в&nbsp;себя:
<ol>
<li>Платформу IIoT-уровня (Industrial IoT), аппаратно-независимая и&nbsp;выдерживающая высокую нагрузку.</li>
<li>Полный цикл управления правилами и&nbsp;обработкой миллионов событий в&nbsp;секунду от&nbsp;сотен тысяч устройств.</li>
<li>Встроенный набор готовых компонентов для визуализации, анализа данных и&nbsp;предиктивного анализа.</li>
<li>Инфраструктуру сбора, хранения и&nbsp;потоковой обработки данных от&nbsp;устройств в&nbsp;режиме реального времени.</li>
<li>Поддержку промышленных протоколов для сетей IoT интернета вещей: Modbus, MQTT. Интеграция любых протоколов по&nbsp;запросу.</li>
<li>Набор инструментов для быстрого создания IoT-приложений.</li>
</ol>
</li>
<li>Решения по&nbsp;СКУД и&nbsp;УРВ.</li>
<li>Автоматизация процесса осмотра фитинговых упоров без подъема контейнеров.</li>
</ol>`,
    },
];
export const DirectionsItems2021En = [
    {
        icon: direction_2021_1,
        question: 'Transport logistics',
        answer: `<ol>
<li> Reducing the energy intensity of terminal and warehouse activities. </li>
<li> Improving the safety of warehouse operations (preventing damage to goods, ensuring safety during loading and unloading operations, &nbsp;etc.) </li>
<li> Automated system for measuring the volume of inert cargo. </li>
<li> Intellectual system of labor protection and &nbsp;industrial safety when performing loading and unloading operations. </li>
<li> Automated sorting devices for packaged goods. </li>
<li> Automated grippers for heavy loads. </li>
<li> Digital and &nbsp;augmented reality systems applicable to &nbsp;terminal and warehouse activities. </li>
<li> WMS warehouse management systems. </li>
<li> Optimization of warehouse operations. </li>
<li> Inventory management in &nbsp;warehouses and &nbsp;in &nbsp;routes. </li>
<li> Robotization of warehouse operations. </li>
<li> Automation of goods receipt and &nbsp;warehouse operations control. </li>
<li> Exoskeletons for material handling. </li>
<li> Machine vision to optimize warehouse operations. </li>
<li> AI &nbsp;for warehouse logistics (neural network analytics, accounting of vehicles, traffic and &nbsp;personnel access and &nbsp;etc.). </li>
<li> Palletizers. </li>
<li> Innovative packaging solutions (new materials, packaging methods for fragile goods, &nbsp;others). </li>
<li> Sorting systems for parcels, cargo. </li>
<li> High density storage systems. </li>
<li> Solutions for optimizing warehouse storage for dark stores. </li>
<li> Unmanned solutions for optimizing logistics inside terminal and warehouse complexes. </li>
<li> Automatic identification technology (RFID, NB-IoT). </li>
<li> М2М (machine-to-machine technologies). </li>
<li> Optimization of warehouse operations for &nbsp;through the use of Pick-by-Vision. </li>
</ol>`,
    },
    {
        icon: direction_2021_2,
        question: 'Information logistics',
        answer: `<ol>
<li> Unmanned technologies of the main repetitive and &nbsp;labor-intensive processes.
<ol>
<li> The process of uncoupling wagons when breaking up on a &nbsp;hump. </li>
<li> Shunting work without the participation of machinists and &nbsp;compiling team. </li>
<li> Securing rolling stock on &nbsp;receiving and departure tracks. </li>
<li> Manage arrows and &nbsp;signals. </li>
</ol>
</li>
<li> Technical solutions for &nbsp;ensuring the safety of train traffic. </li>
<li> Automated recording of accounting states of the main objects of management (implementation of the most economical solutions for &nbsp;reliable operational accounting and &nbsp;data aggregation without human intervention). </li>
<li> Transportation (loading, unloading, sorting) of garbage. Unique packaging. </li>
<li> Solutions for transportation of small consignments. </li>
<li> Solutions for the transportation of bulk cargo (block &nbsp;&mdash;trail). </li>
<li> Implementation of resource-saving technologies in &nbsp;processes of transportation of material and technical resources. </li>
<li> Technical means and &nbsp;technologies for minimizing cargo residues in the &nbsp;wagon after unloading, in &nbsp;incl. reduction of freezing of cargo, new technologies of loading and &nbsp;unloading. </li>
<li> Technologies for improving the level of service of railway transportation. </li>
<li> New types of liners, fasteners, loading, fastening and &nbsp;cargo unloading technologies. </li>
<li> Solutions to minimize operator costs for &nbsp;car repairs. </li>
<li> Solutions to reduce the risk of rolling stock downtime in &nbsp;volatile markets and &nbsp;& nbsp; during seasonal downturns. </li>
<li> Swap body technologies. </li>
<li> Piggyback transportation. </li>
<li> Unmanned main road cargo transportation. </li>
<li> New technologies for the delivery of cargo on the &nbsp;"last mile". </li>
<li> Solutions that reduce the time spent by rolling stock at &nbsp;border crossings in the implementation of international traffic. </li>
<li> Optimization of port logistics for &nbsp;through the implementation of a complex of technological solutions (electronic loading list, mobile workstations, &nbsp;others). </li>
</ol>`,
    },
    {
        icon: direction_2021_3,
        question: 'Warehouse Logistics',
        answer: `<ol>
<li> Electronic technological document flow. </li>
<li> Solutions for &nbsp;automatic handwriting recognition, in &nbsp;including in &nbsp;foreign languages ​​(waybills and &nbsp;etc.). </li>
<li> Technologies for ensuring the rules of negotiations when organizing traffic. </li>
<li> Determination of key performance indicators of the company's branches within the &nbsp;boundaries of the technological polygon with &nbsp;using dynamic modeling tools. </li>
<li> Online acceptance of cargo. Measurement from an &nbsp;electronic device and &nbsp;placement in a &nbsp;wagon, entering into the &nbsp;base of all parameters of the cargo. Use of lidars and &nbsp;electronic devices to measure the size of wagons and &nbsp;cargo. Blockchain use cases. </li>
<li> An IT platform that unites service providers, accounting for generation, conversion of shipments into a &nbsp;bonus system. Bonus payments for services from various providers. Development of partner projects. </li>
<li> Digital twins in &nbsp;terminal and warehouse complex, logistics and &nbsp;supply chain. </li>
<li> Machine reading with &nbsp;parsing accuracy from &nbsp;95% and &nbsp;integration with &nbsp;databases. </li>
<li> Tools for calculating transport and procurement costs ("Calculator") when delivering material and technical resources not by rail. </li>
<li> Tools for modeling and &nbsp;optimization of transport and logistics networks.
<ol>
<li> A tool for optimizing the supply of fuels and lubricants. </li>
<li> A tool for optimizing inventory and &nbsp;procurement schemes. </li>
</ol> </li>
<li> Automation of collection of big-data and &nbsp;data analysis on &nbsp;alternative types of cargo transportation, formation of a statistical database. </li>
<li> Vehicle condition monitoring with &nbsp;the ability to predict breakdowns. </li>
<li> Monitoring the status of drivers on a &nbsp;flight. </li>
<li> Monitoring the movement of vehicles and &nbsp;cargo (temperature loggers, moisture sensors, movement, axle loads and &nbsp;others). </li>
<li> Courier routing solutions &nbsp;rebuildable. </li>
<li> Real-time SCV supply chain management. </li>
<li> Digitalization of the process of processing groupage cargo on &nbsp;intermodal (sea + rail) routes. </li>
</ol>`,
    },
    {
        icon: direction_2021_4,
        question: 'Service logistics',
        answer: `
<ol>
<li> Combination of commercial and &nbsp;technical inspection of cars. </li>
<li> Production of combined infrastructure repairs on a turnkey basis with &nbsp;the use of technical and &nbsp;technological solutions aimed at &nbsp;increasing the turnaround time.
<ol>
<li> Formation of an integrated technology for the simultaneous (sequential) execution of repair work by all infrastructure facilities (full cycle). </li>
<li> Formation of an assessment of the effectiveness of decisions made when planning work on the &nbsp;infrastructure. </li>
<li> Formation of an objective assessment of the state of the infrastructure before &nbsp;the start of work, after the end of work, in the &nbsp;period of the "warranty period" with &nbsp;monitoring of the following indicators:
<ol>
<li> ensuring the stated access time to the &nbsp;working infrastructure; </li>
<li> compliance of the set speeds with the design values; </li>
<li> train-hours of delays in &nbsp;connections with &nbsp;failures of all categories in &nbsp;infrastructure operation; </li>
<li> disruptions, cancellations and &nbsp;refusals from &nbsp;technological "windows"; </li>
<li> specific output of track machines; </li>
<li> traffic safety level. </li>
</ol>
</li>
</ol>
<li> Digitalization of the technological process of the technical transfer point:
<ol>
<li> Means (devices and &nbsp;devices) for automated control and &nbsp;diagnostics of damage and &nbsp;disassembly (theft) of parts and &nbsp;units of wagons and &nbsp;containers. </li>
<li> Automated control systems and &nbsp;diagnostics of damage and &nbsp;disassembly (theft) of parts and &nbsp;units of wagons and &nbsp;& nbsp; containers based on machine vision technology. </li>
</ol>
</li>
<li> A scalable and &nbsp;high-performance Internet of Things (IOT) platform for industrial processes, which includes:
<ol>
<li> An IIoT-layer (Industrial IoT) platform, hardware-independent and &nbsp;high load-bearing. </li>
<li> Full cycle of rule management and &nbsp;processing millions of events in &nbsp;second from &nbsp;hundreds of thousands of devices. </li>
<li> Built-in set of ready-made components for visualization, data analysis and predictive analysis. </li>
<li> Infrastructure for collecting, storing and &nbsp;streaming data processing from &nbsp;devices in &nbsp;real time. </li>
<li> Support for industrial protocols for IoT networks of the Internet of Things: Modbus, MQTT. Integration of any protocols by &nbsp;request. </li>
<li> A set of tools for quickly building IoT applications. </li>
</ol>
</li>
<li> Solutions for &nbsp;ACS and &nbsp;URV.</li>
<li> Automation of the process of inspection of fitting stops without lifting containers. </li>
</ol>`,
    },
];
