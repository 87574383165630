import React, { FC, Fragment, useState, useEffect } from 'react';
import './style.sass';
import { dataApplication } from '../../../types/application';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import RedButton from '../../ui/RedButton';
import * as Yup from 'yup';
import Application from 'models/Application';
import LKRepository from 'models/LKRepository';
import FormikEffect from '../FormikUpdate';
import { useHistory } from 'react-router-dom';
import FormikScroll from '../FormikScroll';

type AppFormProps = { data: dataApplication | null };

const ApplicationForm: FC<AppFormProps> = ({ data }) => {
    const [success, setSuccess] = useState<boolean>(false);
    const history = useHistory();
    const {
        getInitialValues,
        getScheme,
        collectionList,
    } = Application.fieldsCollection;

    const initialData =
        Array.isArray(data) || !data ? getInitialValues() : data;

    const handleSubmit = (values: any) => {
        const data = { SENDED: 'Y', ...values, STATUS: 37 };
        LKRepository.postApplication(data)
            .then(res => {
                setSuccess(true);
                setTimeout(() => {
                    history.push('/cabinet');
                }, 1500);
            })
            .catch(err => console.log(err));
    };

    const saveData = (values: any) => {
        const data = { ...values, STATUS: 35 };

        LKRepository.postApplication(data)
            .then(res => LKRepository.saveId(res.data.data))
            .catch(err => console.log(err));
    };

    const onValuesUpdate = ({
        prevValues,
        nextValues,
        formik,
    }: {
        prevValues: any;
        nextValues: any;
        formik: FormikProps<any>;
    }) => {
        if (!formik.values.NAME_PROJECT?.length) return;

        saveData(nextValues);
    };

    return (
        <Formik
            initialValues={initialData}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape(getScheme())}
        >
            {props => {
                return (
                    <Fragment>
                        <FormikEffect
                            onChange={onValuesUpdate}
                            formik={props}
                        />
                        <FormikScroll formik={props} />
                        <Form noValidate={true}>
                            {collectionList.map((item: any) =>
                                item.renderWithFormik(props)
                            )}
                            {(success && (
                                <div className="lk-form-success">
                                    Заявка успешно отправлена!
                                </div>
                            )) || (
                                <>
                                    <RedButton size="md" type="submit">
                                        Отправить заявку
                                    </RedButton>
                                    <button
                                        className="lk-form__save"
                                        type="button"
                                        onClick={() => saveData(props.values)}
                                    >
                                        Сохранить анкету
                                    </button>
                                </>
                            )}
                        </Form>
                    </Fragment>
                );
            }}
        </Formik>
    );
};

export default ApplicationForm;
