import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import BodyResource from '../../resources/BodyResource';
import { metaApplication } from 'types/application';
import Spinner from '../../ui/Spinner';
import ParticipantCabinetContent from '../ParticipantCabinetContent';
import LKRepository from 'models/LKRepository';

const ParticipantCabinet = () => {
    const [appPreview, setAppPreview] = useState<metaApplication | null>(null);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        let isFetching = true;
        LKRepository.getLKData()
            .then(res => {
                if (isFetching) {
                    setAppPreview(res.data.data);
                    setLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
        return () => {
            isFetching = false;
        };
    }, []);

    return (
        <>
            <ResourceTitlePage text="Личный кабинет" />
            <BodyResource contentMaxWidth>
                {loading ? (
                    <Spinner />
                ) : (
                    <ParticipantCabinetContent metaApplication={appPreview} />
                )}
            </BodyResource>
        </>
    );
};

export default ParticipantCabinet;
