import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';
import './style.sass';
const InnerNewsSlider = ({ data }) => {
    const customSlider = useRef();
    const sliderSettings = {
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    dots: true,
                },
            },
        ],
    };
    return (
        <div className="inner-news-container">
            <div className="inner-news-navigation">
                <div
                    id="inner-news-nav-prev"
                    onClick={() => customSlider.current.slickPrev()}
                >
                    <img src={arrowPrev} alt="" />
                </div>
                <div
                    id="inner-news-nav-next"
                    onClick={() => customSlider.current.slickNext()}
                >
                    <img src={arrowNext} alt="" />
                </div>
            </div>
            <div className="inner-news-slider">
                <Slider
                    {...sliderSettings}
                    ref={slider => (customSlider.current = slider)}
                >
                    {data.map((item, index) => {
                        return (
                            <div key={index}>
                                <div>
                                    <img src={item} alt="" />
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};
export default InnerNewsSlider;
