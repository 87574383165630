import React, { FC } from 'react';
import './style.sass';
import DecisionItem from '../DecisionItem';

interface IProps {
    title: string;
    items: { que: string; ans: string[] }[];
}

const Decisions: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-decisions">
            <div className="full-wrapper">
                <div
                    className="archive-decisions__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="archive-decisions__row">
                    {items.map(({ que, ans }, index) => {
                        return <DecisionItem key={index} que={que} ans={ans} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Decisions;
