import * as React from 'react';
import './style.sass';
import ResourceTitlePage from '../../../ui/ResourceTitlePage';
import { useEffect, useState } from 'react';
import axios from 'axios';

const StatisticsManager = props => {
    const [questionnaire, setQuestionnaire] = useState('');

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnaireall`,
                {}
            )
            .then(response => {
                setQuestionnaire(
                    response.data.data.QUESTIONNAIRE
                        ? response.data.data.QUESTIONNAIRE
                        : []
                );
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <>
            <ResourceTitlePage text="Статистика" />
            <div className="statistics-page">
                <div className="statistics-page__box">
                    <div className="statistics-page-item statistics-page-item--main">
                        <div className="statistics-page-item__label">
                            Общее количество заявок
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire ? questionnaire.length : 0}
                        </div>
                    </div>

                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Черновики
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i => i.status === 'Черновик'
                                  ).length
                                : 0}
                        </div>
                    </div>

                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Прошли премодерацию
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i => i.status === 'На экспертизе'
                                  ).length
                                : 0}
                        </div>
                    </div>
                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Не прошли модерацию (отклонены)
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i => i.status === 'Отклонена'
                                  ).length
                                : 0}
                        </div>
                    </div>
                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Прошли экспертизу
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i =>
                                          i.status === 'Отклонена' ||
                                          i.status === 'Одобрена'
                                  ).length
                                : 0}
                        </div>
                    </div>

                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Приглашены на очный отбор
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i => i.status === 'Очный отбор'
                                  ).length
                                : 0}
                        </div>
                    </div>

                    <div className="statistics-page-item">
                        <div className="statistics-page-item__label">
                            Попали в акселератор
                        </div>
                        <div className="statistics-page-item__count">
                            {questionnaire
                                ? questionnaire.filter(
                                      i => i.status === 'Одобрена'
                                  ).length
                                : 0}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticsManager;
