import React from 'react';
import { TextField } from 'react-admin';
import './style.sass';
import FunctionalCustomers from '../../resources/FunctionalСustomers';
import StatusesView from '../../../data/statusesView.json';

const ShowViewItem = ({ label, desc }) => {
    if (desc) {
        return (
            <div className="show-view__row">
                <div className="show-view-item">
                    <div className="show-view-item__label">{label}</div>
                    <div className="show-view-item__desc">
                        {Array.isArray(desc) ? desc.join(', ') : desc}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
const ShowViewPhoto = ({ label, photoLink }) => {
    if (photoLink) {
        return (
            <div className="show-view__row">
                <div className="show-view-item">
                    <div className="show-view-item__label">{label}</div>
                    <div className="show-view-item__desc">
                        <div className="show-view-item__photo">
                            <img
                                src={`${Object.values(photoLink)[0]}`}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
const ShowViewItemLink = ({ label, link }) => {
    if (link) {
        return (
            <div className="show-view__row">
                <div className="show-view-item">
                    <div className="show-view-item__label">{label}</div>
                    <div className="show-view-item__desc">
                        <a
                            href={`${
                                link.includes('@')
                                    ? `mailto:${link}`
                                    : `${link}`
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="show-view-item__link"
                        >
                            {link}
                        </a>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
const ShowViewItemLinkFile = ({ label, link }) => {
    if (link) {
        return (
            <div className="show-view__row">
                <div className="show-view-item">
                    <div className="show-view-item__label">{label}</div>

                    {link.map((item, i) => {
                        return (
                            <div className="show-view-item__desc" key={i}>
                                <a
                                    href={`${item}`}
                                    download="download"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="show-view-item__link"
                                >
                                    {item}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const ApplicationShowView = props => {
    console.log(StatusesView[props.data.STATUS]);
    return (
        <>
            <div className="show-view">
                <ShowViewItem
                    label="Краткое описание проекта"
                    desc={props.data.SHORT_DESCRIBE}
                />
                <ShowViewItem
                    label="Отраслевое направление"
                    desc={props.data.VECTOR && Object.values(props.data.VECTOR)}
                />
                <ShowViewItemLink label="Сайт" link={props.data.SITE} />
                <ShowViewItem
                    label="Текущая стадия проекта"
                    desc={props.data.STATE}
                />
                <ShowViewItem
                    label="ФИО руководителя проекта"
                    desc={props.data.FIO}
                />
                <ShowViewItem label="Страна" desc={props.data.COUNTRY} />
                <ShowViewItem label="Город" desc={props.data.CITY} />
                <ShowViewItemLink label="E-mail" link={props.data.EMAIL} />
                <ShowViewItem label="Телефон" desc={props.data.PHONE} />
                <ShowViewItem
                    label="Откуда узнали об Акселераторе РЖД"
                    desc={props.data.HEAR_ABOUT}
                />
                <ShowViewItem
                    label="Количество членов команды"
                    desc={props.data.NUMBER_PROJECT_TEAM}
                />
                <ShowViewItem
                    label="Описание решения"
                    desc={props.data.DESCRIBE_SOLUTION}
                />
                <ShowViewItem
                    label="Описание функциональности продукта и используемых технологий"
                    desc={props.data.FUNCTIONALITY_PRODUCT}
                />
                <ShowViewPhoto
                    label="Фотографии прототипа"
                    photoLink={props.data.ATTACH_PHOTO}
                />
                <ShowViewItemLink
                    label="Видео прототипа"
                    link={props.data.ATTACH_LINK}
                />
                <ShowViewItem
                    label="Какие проблемы ОАО «РЖД» помогает решить продукт"
                    desc={props.data.WHAT_PROBLEM}
                />
                <ShowViewItem
                    label="Проводились ли уже пилотные внедрения?"
                    desc={props.data.HAVE_PILOT}
                />
                <ShowViewItem
                    label="С какими компаниями? Успешный/неуспешный пилот?"
                    desc={props.data.SUCCES_UNSUCCES_PILOT}
                />
                <ShowViewItem
                    label="Есть ли продажи?"
                    desc={props.data.ANY_SALES}
                />
                <ShowViewItem
                    label="Основные клиенты"
                    desc={props.data.MAIN_CLIENTS}
                />
                <ShowViewItem
                    label="Ключевые конкуренты (компании, технологии)"
                    desc={props.data.KEY_COMPETITION}
                />
                <ShowViewItem
                    label="Уникальность проекта и преимущества перед существующими
            аналогами"
                    desc={props.data.UNIQUENESS_PROJECT}
                />
                <ShowViewItem
                    label="Юридическая структура проекта"
                    desc={props.data.LEGAL_STRUCTURE_FORMALIZED}
                />
                <ShowViewItem
                    label="Защита интеллектуальной собственности"
                    desc={
                        props.data.OWN_INTELLECTUAL &&
                        Object.values(props.data.OWN_INTELLECTUAL)
                    }
                />
                <ShowViewItem
                    label="Ключевые патенты"
                    desc={props.data.KEY_PARTNERS}
                />
                <ShowViewItemLinkFile
                    label="Презентация проекта"
                    link={
                        props.data.PROJECT_PRESENTATION &&
                        Object.values(props.data.PROJECT_PRESENTATION)
                    }
                />
                <ShowViewItemLinkFile
                    label="Дополнительные файлы"
                    link={
                        props.data.ADDITION_FILES &&
                        Object.values(props.data.ADDITION_FILES)
                    }
                />
            </div>
            {StatusesView[props.data.STATUS] && (
                <FunctionalCustomers id={props.data.ID} />
            )}
        </>
    );
};

export default ApplicationShowView;
