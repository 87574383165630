import React, { useEffect } from 'react';
import FirstScreen from '../../components/FirstScreen';
import IntroText from '../../components/IntroText';
import Organizers from '../../components/Organizers';
import Partners from '../../components/Partners';
import FastTrack from '../../components/FastTrack';
import Profit from '../../components/Profit';
import Industries from '../../components/Industries';
import Directions from '../../components/Directions';
import Criteria from '../../components/Criteria';
import Experts from '../../components/Experts';
import Schedule from '../../components/Schedule';
import History from '../../components/History';
import FAQ from '../../components/FAQ';
import News from '../../components/News';
import PartnersAlt from '../../components/PartnersAlt';
import Footer from '../../components/Footer';
import AOS from 'aos';
import 'aos/src/sass/aos.scss';
import { YMInitializer } from 'react-yandex-metrika';
import OnlineMap from '../../components/OnlineMap';
import dataRu from '../../data/data';
import Header from '../../components/2021/Header';
const MainPage = props => {
    useEffect(() => {
        AOS.init();

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document
                    .querySelector(this.getAttribute('href'))
                    .scrollIntoView({
                        behavior: 'smooth',
                    });
            });
        });

        window.addEventListener('scroll', () => {
            AOS.refresh();
        });
    });

    return (
        <>
            <YMInitializer accounts={[65879770]} options={{ webvisor: true }} />
            <Header langRU={true} old />
            <FirstScreen langRU={true} type="video" />
            <IntroText langRU={true} />
            <News langRU={true} />
            <Organizers langRU={true} />
            <Partners langRU={true} />
            <FastTrack langRU={true} />
            <Profit langRU={true} />
            <Industries langRU={true} />
            <Directions langRU={true} />
            <Criteria langRU={true} />
            <Experts langRU={true} />
            <Schedule langRU={true} />
            <OnlineMap langRU={true} />
            <History langRU={true} />
            <FAQ langRU={true} />
            <PartnersAlt langRU={true} />
            <Footer langRU={true} />
        </>
    );
};

export default MainPage;
