import React from 'react';
import ProfitItem from './item';
import ProfitItemNew from './itemNew';
import { ProfitItems, ProfitItemsEn } from '../../data/data';
import { ProfitItems2021, ProfitItems2021En } from '../../data/2021/profit';
import { ProfitItems2022, ProfitItems2022En } from '../../data/2022/profit';
import parse from 'html-react-parser';
import './style.sass';

const Profit = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return langRU ? ProfitItems2021 : ProfitItems2021En;
        }
        if (year === '2022') {
            return langRU ? ProfitItems2022 : ProfitItems2022En;
        }
        return langRU ? ProfitItems : ProfitItemsEn;
    };
    return (
        <section
            id="direction"
            className="profit"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="profit__title">
                    {langRU
                        ? parse(`Участники получат`)
                        : parse(`Participants will receive`)}
                </div>
                <div className="profit__row">
                    {getData().map((item, index) => {
                        if (year === '2021') {
                            return <ProfitItemNew key={index} item={item} />;
                        }
                        return <ProfitItem key={index} item={item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default Profit;
