import MainPage from './pages/MainPage';
import AdminPanel from './pages/AdminPanel';
import ExpertsProfile from './components/resources/ExpertsProfile';
import MainPageEn from './pages/MainPageEn';
import PersonalData from './pages/PersonalData';
import Agreement from './pages/Agreement';
import ConfirmRegistration from './pages/ConfirmRegistration';
import Page2021 from './pages/Page2021';
import Archive2021 from './pages/Archive2021';
import Archive2021En from './pages/Archive2021En';
import PageEn2021 from './pages/PageEn2021';
import LastPrograms from './pages/LastPrograms';
import LastProgramsEn from './pages/LastProgramsEn';
import ExpertsGroup from './pages/ExpertsGroup';
import ExpertsGroupEn from './pages/ExpertsGroupEn';
import ArchivePage from './pages/Archive';
import Cookies from './pages/Cookies';
import PersonalDataVnijt from './pages/PersonalDataVnijt';

export const routes = [
    {
        id: '',
        path: `/last-programs`,
        component: LastPrograms,
    },
    {
        id: '',
        path: `/last-programs/en`,
        component: LastProgramsEn,
    },
    {
        id: '',
        path: `/experts`,
        component: ExpertsGroup,
    },
    {
        id: '',
        path: `/experts/en`,
        component: ExpertsGroupEn,
    },
    {
        id: '',
        path: `/`,
        component: Page2021,
    },
    {
        id: '',
        path: `/en`,
        component: PageEn2021,
    },
    {
        id: '',
        path: `/how-it-was`,
        component: MainPage,
    },
    {
        id: '',
        path: `/how-it-was/en`,
        component: MainPageEn,
    },
    {
        id: '',
        path: `/archive2021`,
        component: Archive2021,
    },
    {
        id: '',
        path: `/archive2021/en`,
        component: Archive2021En,
    },
    {
        id: '',
        path: `/lk`,
        bgBody: 'blue',
        component: AdminPanel,
    },
    {
        id: '',
        path: `/lk/profile`,
        bgBody: 'blue',
        component: ExpertsProfile,
    },
    {
        id: '',
        path: `/agreement`,
        bgBody: 'blue',
        component: Agreement,
    },
    {
        id: '',
        path: `/personal-data`,
        bgBody: 'blue',
        component: PersonalData,
    },
    {
        id: '',
        path: `/personal-data-vnijt`,
        bgBody: 'blue',
        component: PersonalDataVnijt,
    },
    {
        id: '',
        path: `/cookies`,
        bgBody: 'blue',
        component: Cookies,
    },
    {
        id: '',
        path: `/en`,
        bgBody: 'blue',
        component: MainPageEn,
    },
    {
        id: '',
        path: `/confirm_registration`,
        bgBody: 'blue',
        component: ConfirmRegistration,
    },
    {
        id: '',
        path: `/archive`,
        bgBody: 'blue',
        component: ArchivePage,
    },
    {
        id: '',
        path: `/en/archive`,
        bgBody: 'blue',
        component: ArchivePage,
    },
];
