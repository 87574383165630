import * as React from 'react';

const ExpertNameField = ({ record = {}, emptyText }) => {
    if (record['name'] || record['surname']) {
        return (
            <div className={`expert-type-list`}>
                {record['name'] + ' ' + record['surname']}
            </div>
        );
    } else {
        return emptyText;
    }
};

export default ExpertNameField;
