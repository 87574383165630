import React from 'react';
import cn from './style.module.sass';

const ProfitItemNew = ({ item }) => (
    <div className={cn.col}>
        <div className={cn.item}>
            <div className={cn.top}>
                <div className={cn.icon}>
                    <img className={cn.img} src={item.img} alt="" />
                </div>
                <div
                    className={cn.title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
            </div>
            <div
                className={cn.text}
                dangerouslySetInnerHTML={{ __html: item.text }}
            />
        </div>
    </div>
);

export default ProfitItemNew;
