import React from 'react';
import cn from './style.module.sass';
import ScrollerItem from './item';

const Scroller = ({ links }) => {
    return (
        <div className={cn.scroller}>
            {links.map(({ name, label }, index) => (
                <ScrollerItem name={name} label={label} key={index} />
            ))}
        </div>
    );
};

export default Scroller;
