import React, {useEffect, useMemo, useState} from 'react';
import TechnoFocusItem from "./TechnoFocusItem";
import Pagination from "./Pagination";
import { TechnoFocus2022, TechnoFocus2022En } from '../../../data/2022/techno';
import parse from 'html-react-parser';
import './style.sass';
import TechnoItem from "./TechnoItem";

const getPaginationArr = (count) => {
    return [...Array(count).keys()].map(x => ++x);
}

const getCurrentData = (allData, from, to) => {
   return allData.slice(from, to);
}

const TechnoFocus = ({ langRU, year }) => {
    const getData = useMemo(() => langRU ? TechnoFocus2022 : TechnoFocus2022En, [langRU]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentDada, setCurrentDada] = useState([])
    const [paginationCount, setPaginationCount] = useState([])
    const [active, setActive] = useState(null);

    const handleToggle = index => {
        if (index === active) {
            setActive(null);

            return;
        }

        setActive(index);
    };

    useEffect(() => {
        const pageCount =  Math.ceil(getData.length / 10);
        setPaginationCount(getPaginationArr(pageCount))
    }, [])

    useEffect(() => {

        const fromTo = {
            from: 10 * (currentPage - 1),
            to: 10 * currentPage
        }

        const newData = getCurrentData(getData, fromTo.from, fromTo.to)
        setCurrentDada(newData)
    }, [currentPage])

    const btnText = useMemo(() => {
        return langRU
            ? { shown: 'Читать подробнее', hidden: 'Скрыть подробнее'}
            : { shown: 'Read more', hidden: 'Hide details'}
    }, [langRU])

    return (
        <section
            id="techno"
            className="techno"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="techno__title">
                    {langRU
                        ? parse(`Технологический фокус`)
                        : parse(`Technology focus`)}
                </div>

                {/*<Pagination data={paginationCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />*/}

                <div className="techno-table">
                    <div className="techno-table-header">
                        <div className="techno-table-header__row">
                            <div className="techno-table-header__col">
                                {langRU ? 'Название' : 'Name'}
                            </div>
                            <div className="techno-table-header__col">
                                {langRU
                                    ? 'Описание задачи/проблемы. Входные данные. Ожидаемый результат'
                                    : 'Description of the task/problem. Input data. Expected Result'
                                }
                            </div>
                        </div>
                    </div>

                    <div className="techno-table-body">
                        {currentDada.map((item, index) => (
                           <TechnoItem item={item} handleToggle={handleToggle} active={active} btnText={btnText} key={index} />
                         )
                        )}
                    </div>

                    {/*<Pagination data={paginationCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />*/}

                </div>
            </div>
        </section>
    );
};

export default TechnoFocus;
