import React from 'react';
import AbstractField from './AbstractField';
import BasicPasswordField from '../../components/form/basic-components/BasicPasswordField';

class PasswordField extends AbstractField {
    config: any;
    constructor(config: any) {
        super();
        this.config = config;
    }

    public renderWithFormik = (props: any) => {
        const { name } = this.config;
        const { values, handleChange, handleBlur, errors, touched } = props;
        const error = errors[name] && touched[name];

        const newProps: { [p: string]: any } = {
            onChange: handleChange,
            onBlur: handleBlur,
            error,
            value: values[name],
            helperText: error ? errors[name] : '',
        };
        return this.render(newProps);
    };

    public render = (props: any) => {
        const { name, rows, multiline, hintMessage } = this.config;
        /**
         * те свойства из конфига, которые нужно прокинуть в компонент
         */
        const propsFromConfig = {
            name,
            hintMessage,
            label: this.getLabel(),
            ...(() => (multiline ? { multiline, rows } : {}))(),
        };

        return (
            <BasicPasswordField key={name} {...propsFromConfig} {...props} />
        );
    };
}

export default PasswordField;
