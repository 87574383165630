import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ModalWrap from '../../ManagerList/ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const AppointQuestModal = ({ IdExpert, modalHandle, isOpen }) => {
    const classes = useStyles();
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [listQuestionare, setListQuestionare] = React.useState('');
    const [state, setState] = React.useState('');
    const [expertData, setExpertData] = useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.infouser`,
                LKRepository.generateFormData({
                    USERID: IdExpert,
                })
            )
            .then(response => {
                setExpertData(response.data.data);
            })
            .catch(err => console.log(err));
    }, [IdExpert]);

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnaireall`,
                {}
            )
            .then(response => {
                console.log(response.data.data.QUESTIONNAIRE);
                const r = response.data.data.QUESTIONNAIRE.filter(
                    i => !i.experts.includes(IdExpert)
                );
                console.log(r);
                setState(
                    r.reduce((acc, item) => {
                        acc[item.id] = false;
                        return acc;
                    }, {})
                );
                setListQuestionare(r);
            })
            .catch(err => console.log(err));
    }, []);
    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleSearch = event => {
        setSearchValue(event.target.value);
    };

    const handleSave = type => {
        const dataObj =
            type === 'Бизнес'
                ? {
                      EXPERT_BUSSNESS: IdExpert,
                      ID_QUESTIONNARE: Object.keys(state).filter(
                          key => state[key] === true
                      ),
                  }
                : {
                      EXPERT_TECH: IdExpert,
                      ID_QUESTIONNARE: Object.keys(state).filter(
                          key => state[key] === true
                      ),
                  };
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.setarexpert`,
                LKRepository.generateFormData(dataObj)
            )
            .then(response => {
                console.log(response.data.data);
                if (response.data.data.QUESTIONARE.length) {
                    setSuccess('Заявки назначены');
                } else {
                    setError('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <ModalWrap isOpen={isOpen} toggleModal={modalHandle}>
            <div
                className={`expert-modal ${
                    success ? `expert-modal--success` : ``
                }`}
            >
                {!success ? (
                    <>
                        <div className="expert-modal__label">
                            Назначить заявки для
                        </div>
                        <div className="expert-modal__title">
                            {expertData.name}
                        </div>
                        <div className="expert-modal__row">
                            <div className="expert-modal__col-12">
                                <div className="expert-modal__search-box">
                                    <input
                                        type="text"
                                        className="expert-modal__search-input"
                                        value={searchValue}
                                        onChange={handleSearch}
                                        placeholder="Поиск"
                                    />
                                </div>
                            </div>
                            <div className="expert-modal__col-12">
                                <div className="expert-modal__title-m">
                                    Доступные заявки:
                                </div>
                            </div>
                            <div className="expert-modal__col-12">
                                <div className="expert-modal__scrollbar-box">
                                    <Scrollbars style={{ height: 315 }}>
                                        <FormControl
                                            required
                                            error={error}
                                            component="fieldset"
                                        >
                                            <FormGroup>
                                                {listQuestionare &&
                                                    listQuestionare
                                                        .filter(i =>
                                                            i.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    searchValue.toLowerCase()
                                                                )
                                                        )
                                                        .map(item => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                state[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            name={
                                                                                item.id
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        item.name
                                                                    }
                                                                />
                                                            );
                                                        })}
                                            </FormGroup>
                                        </FormControl>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className="expert-modal__col-12">
                                <div className="expert-modal__choosen-title">
                                    Выбрано заявок:{' '}
                                    {state
                                        ? Object.values(state).filter(
                                              i => i === true
                                          ).length
                                        : 0}
                                </div>
                            </div>
                        </div>
                        {error && (
                            <div className="expert-modal__error">
                                <span>{error}</span>
                            </div>
                        )}
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => handleSave(expertData.type)}
                            >
                                Назначить
                            </RedButton>

                            <button
                                className="expert-modal__cancel"
                                onClick={() => {
                                    modalHandle(false);
                                }}
                            >
                                Отмена
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="expert-modal__success">{success}</div>
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => {
                                    modalHandle(false);
                                }}
                            >
                                Закрыть
                            </RedButton>
                        </div>
                    </>
                )}
            </div>
        </ModalWrap>
    );
};

export default AppointQuestModal;
