import React from 'react';
import './style.sass';
import iconCheckedMain from '../../assets/img/svg/map/main-checked.svg';
import iconStatic from '../../assets/img/svg/map/city-static.svg';
import iconChecked from '../../assets/img/svg/map/city-checked.svg';
import { ReactComponent as AllDirection } from 'assets/img/svg/map/direction/all-direction.svg';
import compareDesc from 'date-fns/compareDesc';

const END_DATE = new Date(2021, 7, 7);

class OnlineMap extends React.Component {
    state = {
        activeCity: [],
        events: {
            end: {
                title:
                    'Видеозаписи мероприятий доступны в&nbsp;разделе &laquo;Новости&raquo;',
                date: ``,
                link: '',
            },
            nvsb: {
                title: `#GENSTalks Новосибирск`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
            },
            vldv: {
                title: `#GENSTalks Владивосток`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
            },

            tmsk: {
                title: `#GENSTalks Томск`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
            },
            rst: {
                title: `#GENSTalks Ростов-на-Дону`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
            },
            krsn: {
                title: `#GENSTalks Краснодар`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
            },
            stvp: {
                title: `#GENSTalks Ставрополь`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
            },
            spb: {
                title: `#GENSTalks Санкт-Петербург`,
                date: `03&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217707',
            },
            msk: {
                title: `#GENSTalks Москва`,
                date: `03&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217707',
            },
        },
        city: [
            {
                city: `#GENSTalks: Москва и&nbsp;Санкт-Петербург`,
                date: `03&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217707',
                class: 'msk',
                isPast: new Date(2021, 7, 4),
                iconStatic: iconCheckedMain,
                iconChecked: iconCheckedMain,
            },

            {
                city: `#GENSTalks: Ростов-на-Дону, Краснодар и&nbsp;Ставрополь`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217815',
                class: 'rst',
                isPast: new Date(2021, 7, 6),
            },

            {
                city: `#GENSTalks: Владивосток, Новосибирск и&nbsp;Томск`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
                class: 'nvsb',
                isPast: new Date(2021, 7, 7),
            },
        ],
        markers: [
            {
                city: `Санкт-Петербург`,
                date: `03&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217707',
                class: 'spb',
                isPast: new Date(2021, 7, 4),
            },
            {
                city: `Москва`,
                date: `03&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/events/217707',
                class: 'msk',
                isPast: new Date(2021, 7, 4),
                iconStatic: iconCheckedMain,
                iconChecked: iconCheckedMain,
            },

            {
                city: `Ростов-на-Дону`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
                class: 'rst',
                isPast: new Date(2021, 7, 6),
            },
            {
                city: `Краснодар`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
                class: 'krsn',
                isPast: new Date(2021, 7, 6),
            },
            {
                city: `Ставрополь`,
                date: `05&nbsp;августа в&nbsp;11:00, Online`,
                link: 'https://leader-id.ru/event/54798/',
                class: 'stvp',
                isPast: new Date(2021, 7, 6),
            },
            {
                city: `Новосибирск`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
                class: 'nvsb',
                isPast: new Date(2021, 7, 7),
            },
            {
                city: `Владивосток`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
                class: 'vldv',
                isPast: new Date(2021, 7, 7),
            },

            {
                city: `Томск`,
                date: `06&nbsp;августа в&nbsp;11:00, Оnline`,
                link: 'https://leader-id.ru/events/217869',
                class: 'tmsk',
                isPast: new Date(2021, 7, 7),
            },
        ],
    };

    showMarkers = () => {
        let map = document.querySelector('.map');
        let mapMarkers = document.querySelectorAll('.map__marker');
        let mapDistance = map.getBoundingClientRect().y;
        if (mapDistance < 200) {
            mapMarkers.forEach(item => item.classList.add('anim'));
        }
    };

    componentDidMount() {
        const isFinished = compareDesc(new Date(), END_DATE) === -1;
        window.addEventListener('scroll', this.showMarkers);
        this.showMarkers();
        if (!isFinished) {
            let activeCity = this.state.city.filter(item => {
                const comparor = compareDesc(new Date(), item.isPast);
                return comparor === 1;
            });

            this.setState({ activeCity });
        } else {
            this.setState({ activeCity: [] });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showMarkers);
    }

    render() {
        return (
            <section
                className="map"
                data-aos="slide-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500"
            >
                <div className="full-wrapper">
                    <div className="map__title">
                        Онлайн #GENSTalks в&nbsp;регионах
                    </div>
                    <div className="map__wrapper">
                        {/*<AllDirection className="svg svg--all" />*/}
                        {this.state.markers.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`map__marker active map__marker--${item.class}`}
                                >
                                    <img
                                        src={item.iconStatic || iconStatic}
                                        className={`map__img--static map__img--${item.class}`}
                                        alt=""
                                    />
                                    <img
                                        src={item.iconChecked || iconChecked}
                                        className={`map__img--checked map__img--${item.class}`}
                                        alt=""
                                    />
                                    <div
                                        className={`pulse-container pulse-container--${item.class}`}
                                    >
                                        <div className="pulse-box">
                                            <svg
                                                className="pulse-svg"
                                                width="50px"
                                                height="50px"
                                                viewBox="0 0 50 50"
                                                version="1.1"
                                            >
                                                <circle
                                                    className="circle first-circle"
                                                    fill="transparent"
                                                    cx="25"
                                                    cy="25"
                                                    r="25"
                                                />
                                                <circle
                                                    className="circle second-circle"
                                                    fill="transparent"
                                                    cx="25"
                                                    cy="25"
                                                    r="25"
                                                />
                                                <circle
                                                    className="circle third-circle"
                                                    fill="transparent"
                                                    cx="25"
                                                    cy="25"
                                                    r="25"
                                                />
                                                <circle
                                                    className="circle"
                                                    fill="transparent"
                                                    cx="25"
                                                    cy="25"
                                                    r="25"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div
                                        className="map__city"
                                        dangerouslySetInnerHTML={{
                                            __html: item.city,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="map-info">
                        {this.state.activeCity.map((item, index) => {
                            return (
                                <div className="map-info__col" key={index}>
                                    <div
                                        className="map-info__title"
                                        dangerouslySetInnerHTML={{
                                            __html: item.city,
                                        }}
                                    />
                                    <div
                                        className="map-info__date"
                                        dangerouslySetInnerHTML={{
                                            __html: item.date,
                                        }}
                                    />
                                    <a
                                        href={item.link}
                                        className="map-info__link"
                                        target="_blank"
                                    >
                                        Регистрация
                                    </a>
                                </div>
                            );
                        })}
                    </div>

                    <div className="map-info--mobile">
                        {this.state.city.map((item, index) => {
                            return (
                                <div className="map-info__item" key={index}>
                                    <div
                                        className="map-info__title"
                                        dangerouslySetInnerHTML={{
                                            __html: item.city,
                                        }}
                                    />
                                    <div
                                        className="map-info__date"
                                        dangerouslySetInnerHTML={{
                                            __html: item.date,
                                        }}
                                    />
                                    {compareDesc(new Date(), item.isPast) ===
                                        1 && (
                                        <a
                                            href={item.link}
                                            className="map-info__link"
                                            target="_blank"
                                        >
                                            Регистрация
                                        </a>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default OnlineMap;
