import React, { FC } from 'react';
import './style.sass';
// @ts-ignore
import { UserMenu } from 'react-admin';
import HeaderUserIcon from '../HeaderUserIcon';
import UserLogoutButton from '../UserLogoutButton';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {}

const HeaderUserMenu: FC<IProps> = props => {
    const getHeaderUserIcon = () => <HeaderUserIcon />;
    const styles = makeStyles({
        root: {
            borderRadius: 0,
        },
    });

    return (
        <UserMenu label="Профиль" {...props} icon={getHeaderUserIcon()}>
            <UserLogoutButton />
        </UserMenu>
    );
};

export default HeaderUserMenu;
