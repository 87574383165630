import React, { FC } from 'react';
import './style.sass';
import RedButton from '../../ui/RedButton';
import TextButton from '../../ui/TextButton';

interface IProps {
    withoutNewEntryButton?: boolean;
}

const ParticipantButtons: FC<IProps> = ({ withoutNewEntryButton = false }) => {
    return (
        <div className="participant-buttons">
            {!withoutNewEntryButton && (
                <div className="participant-buttons__entry">
                    {/* todo: год забит статикой ! */}
                    <RedButton href="/lk#/application-2021" size="lg">
                        Подать заявку
                    </RedButton>
                </div>
            )}
            <TextButton
                target="_blank"
                href="http://accelerator.rzd.w6p.ru/"
                size="lg"
            >
                Подробнее об акселераторе
            </TextButton>
        </div>
    );
};

export default ParticipantButtons;
