import React from 'react';
import parse from 'html-react-parser';
import pdfFile from 'assets/pdf/Положение о проведении КАП 2020.pdf';
import file from 'assets/pdf/Положение КАП.pdf';

import './style.sass';

const Footer = ({ langRU, year }) => {
    return (
        <footer id="contacts" className="footer">
            {/*<div className="full-wrapper">*/}
            {/*    <div className="footer__title">*/}
            {/*        {langRU ? parse('Контакты') : parse('Contact details')}*/}
            {/*    </div>*/}
            {/*    <div className="footer__row footer__row--top">*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU ? parse('ВНИИЖТ') : parse('VNIIZhT')}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(`Москва, ул. 3-я Мытищинская, 10`)*/}
            {/*                        : parse(*/}
            {/*                              `10, 3rd Mytishchinskaya Street, Moscow`*/}
            {/*                          )}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__social">*/}
            {/*                    <a*/}
            {/*                        href="https://www.facebook.com/vniizht/"*/}
            {/*                        target="_blank"*/}
            {/*                        className="footer-item__link"*/}
            {/*                    >*/}
            {/*                        Facebook*/}
            {/*                    </a>*/}
            {/*                    <a*/}
            {/*                        href="https://www.instagram.com/vniizht/"*/}
            {/*                        target="_blank"*/}
            {/*                        className="footer-item__link"*/}
            {/*                    >*/}
            {/*                        Instagram*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU ? parse('Телефон') : parse('Phone')}*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    href="tel:+74992604111"*/}
            {/*                    className="footer-item__normal-text"*/}
            {/*                >*/}
            {/*                    +7 (499) 260-41-11*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    E-mail*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    <a*/}
            {/*                        href="mailto:accelerator.rzd@vniizht.ru"*/}
            {/*                        className="footer-item__link"*/}
            {/*                    >*/}
            {/*                        accelerator.rzd@vniizht.ru*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="footer-item__small-text">*/}
            {/*        {langRU ? parse(`Контактное лицо`) : parse(`Main Contact`)}*/}
            {/*    </div>*/}
            {/*    <div className="footer__row footer__row--middle">*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(`Джозеф Тесфайе`)*/}
            {/*                        : parse(`Joseph Tesfaye`)}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(*/}
            {/*                              `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&laquo;ВНИИЖТ&raquo;`*/}
            {/*                          )*/}
            {/*                        : parse(*/}
            {/*                              `Expert, <br> Center for the Corporate <br> Accelerator of&nbsp;Startup Projects,<br> Russian Railways`*/}
            {/*                          )}*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    href="mailto:tesfaye.joseph@vniizht.ru"*/}
            {/*                    className="footer-item__link"*/}
            {/*                >*/}
            {/*                    tesfaye.joseph@vniizht.ru*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(`Иван Карюкин`)*/}
            {/*                        : parse(`Ivan Karyukin`)}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(*/}
            {/*                              `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&laquo;ВНИИЖТ&raquo;`*/}
            {/*                          )*/}
            {/*                        : parse(*/}
            {/*                              `Expert, <br> Center for the Corporate <br> Accelerator of&nbsp;Startup Projects,<br> Russian Railways`*/}
            {/*                          )}*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    href="mailto:Karyukin.Ivan@vniizht.ru"*/}
            {/*                    className="footer-item__link"*/}
            {/*                >*/}
            {/*                    Karyukin.Ivan@vniizht.ru*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(`Сергей Колесников`)*/}
            {/*                        : parse(`Sergei Kolesnikov`)}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(*/}
            {/*                              `Эксперт Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&laquo;ВНИИЖТ&raquo;`*/}
            {/*                          )*/}
            {/*                        : parse(*/}
            {/*                              `Expert, <br> Center for the Corporate <br> Accelerator of&nbsp;Startup Projects,<br> Russian Railways`*/}
            {/*                          )}*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    href="mailto:Kolesnikov.Sergei@vniizht.ru"*/}
            {/*                    className="footer-item__link"*/}
            {/*                >*/}
            {/*                    Kolesnikov.Sergei@vniizht.ru*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="footer__col">*/}
            {/*            <div className="footer__item footer-item">*/}
            {/*                <div className="footer-item__normal-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(`Настасья Хлыбова`)*/}
            {/*                        : parse(`Nastasia Khlybova`)}*/}
            {/*                </div>*/}
            {/*                <div className="footer-item__small-text">*/}
            {/*                    {langRU*/}
            {/*                        ? parse(*/}
            {/*                              `Специалист Центра &laquo;Корпоративный акселератор стартап-проектов ОАО&nbsp;&laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&laquo;ВНИИЖТ&raquo;`*/}
            {/*                          )*/}
            {/*                        : parse(*/}
            {/*                              `Specialist, <br> Center for the Corporate <br> Accelerator of&nbsp;Startup Projects,<br> Russian Railways`*/}
            {/*                          )}*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    href="mailto:Khlybova.Nastasia@vniizht.ru"*/}
            {/*                    className="footer-item__link"*/}
            {/*                >*/}
            {/*                    Khlybova.Nastasia@vniizht.ru*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="footer__program footer-program">
                <div className="full-wrapper">
                    <div className="footer-program__row">
                        <a
                            href={year === '2022' ? file : pdfFile}
                            download
                            className="footer-program__text"
                        >
                            {langRU
                                ? parse(
                                      `Положение проведения акселерационной программы`
                                  )
                                : parse(
                                      `The position of the acceleration program`
                                  )}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
