import React from 'react';

const LineTrack = () => {
    return (
        <svg
            width="191"
            height="6"
            viewBox="0 0 191 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 3H191"
                stroke="white"
                strokeWidth="6"
                strokeLinejoin="round"
                strokeDasharray="20 20"
            />
        </svg>
    );
};

export default LineTrack;
