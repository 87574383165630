import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import { ReactComponent as SkypeIcon } from '../../../assets/img/svg/admin/skype.svg';
import MeetingTable from '../CustomerList/MeetingTable';
import ProjectMaterials from '../ProjectMaterials';
import axios from 'axios';
import LKRepository from '../../../models/LKRepository';

const FunctionalCustomersShow = props => {
    const [fzInfo, setFzInfo] = useState(null);
    useEffect(() => {
        const getParamsPage = props.location.search;
        if (getParamsPage.includes('id')) {
            const appId = new URLSearchParams(getParamsPage).get('id');
            axios
                .post(
                    `/bitrix/services/main/ajax.php?action=webpractik:main.forms.fzdetail`,
                    LKRepository.generateFormData({
                        FZ_ID: appId,
                    })
                )
                .then(res => {
                    setFzInfo(res.data.data);
                })
                .catch(err => console.log(err));
        }
    }, [props.location.search]);
    return (
        <div className="func-customers-show">
            {fzInfo && (
                <>
                    <ResourceTitlePage
                        text={`Функциональный заказчик ${fzInfo[0].fzLinkId}`}
                    />
                    <ResourceTitlePage
                        text={`Проект — "${fzInfo[0].projectInfo}"`}
                    />

                    <div className="func-customers-show__btn">
                        {fzInfo[0].fzSkype && (
                            <a
                                className="skype-button"
                                href={`skype:${fzInfo[0].fzSkype}?call`}
                            >
                                <SkypeIcon /> Звонок через Skype
                            </a>
                        )}
                    </div>
                    <div className="func-customers-show__desc">
                        {fzInfo[0]?.fzDescription}
                    </div>
                    <MeetingTable
                        {...props}
                        record={fzInfo[0]?.meetingMinutes}
                    />
                    <ProjectMaterials
                        {...props}
                        record={fzInfo[0]?.projectMaterials}
                    />
                </>
            )}
        </div>
    );
};

export default FunctionalCustomersShow;
