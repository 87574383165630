import React, { FC, Fragment } from 'react';
import './style.sass';
import { TextField } from '@material-ui/core';

interface IProps {
    [p: string]: any;
}

const BasicTextField: FC<IProps> = ({
    hintMessage,
    hidden,
    dependencyValue,
    dependency,
    maskComponent,
    ...rest
}) => {
    const inValuesArray =
        Array.isArray(dependencyValue) &&
        dependencyValue.includes(dependency?.value);
    const valuesEqual = dependencyValue + '' === dependency?.value + '';

    if (hidden && !valuesEqual && !inValuesArray) {
        return <Fragment />;
    }
    return (
        <div className="text-field">
            <TextField
                variant="filled"
                fullWidth
                size="small"
                InputProps={{
                    inputComponent: maskComponent,
                }}
                {...rest}
            />
            {(hintMessage?.length && (
                <div className="text-field__hint">
                    <div className="text-field__tooltip" />
                    <div className="text-field__message">{hintMessage}</div>
                </div>
            )) ||
                null}
        </div>
    );
};

export default BasicTextField;
