import jsonServerProvider from 'ra-data-json-server';
import axios from 'axios';
import LKRepository from '../models/LKRepository';
import Statuses from '../data/statusesId.json';

let defaultUrl = '/bitrix/services/main/ajax.php?action=webpractik:main.forms';
let premoderationRoute = `${defaultUrl}.questionnaireall`;
let expertsListRoute = `${defaultUrl}.expertlistestimate`;
let questionnaireExpertiseListRoute = `${defaultUrl}.questionnairelistforexpert`;

let questionnaireRoute = `${defaultUrl}.questionnaire`;

const jsonProvider = jsonServerProvider(premoderationRoute);
const filterByValue = (array, string) => {
    return array.filter(o =>
        Object.keys(o).some(k =>
            o[k].toString().toLowerCase().includes(string.toLowerCase())
        )
    );
};
export const dataProviderManager = {
    ...jsonProvider,
    getOne: (id, params) => {
        return axios
            .post(
                questionnaireRoute,
                LKRepository.generateFormData({ ID: params.id })
            )
            .then(res => ({
                data: res.data.data,
            }));
    },
    exportByIds: (resource, params) => {
        return axios.post(
            '/bitrix/services/main/ajax.php?action=webpractik:main.forms.fullzipexchange',
            LKRepository.generateFormData({ SELECTED_ID: params.ids })
        );
    },
    getList: (resource, params) => {
        const statusString = params.filter.status;
        const typeExp = () => {
            if (params.filter.status === 'Бизнес-экспертиза') {
                return '3';
            } else if (params.filter.status === 'Техническая экспертиза') {
                return '4';
            } else {
                return '';
            }
        };

        const objParams = {
            COUNT_ON_PAGE: params.pagination.perPage,
            NUMBER_PAGE: params.pagination.page,
            SORT_FIELD: 'DATE_CREATE',
            SORT_VECTOR: params.sort.order,
            STATE: params.filter.status,
            VECTOR: '',
            VECTOR_OTHER: '',
            COMMENT: '',
            STATUS: Statuses[statusString],
            TYPE_EXPERT: typeExp(),
        };
        if (params.filter.q) {
            objParams.NAME_PROJECT = params.filter.q;
        }
        const objParamsExp = {
            COUNT_ON_PAGE: params.pagination.perPage,
            NUMBER_PAGE: params.pagination.page,
            SORT_FIELD: 'DATE_CREATE',
            SORT_VECTOR: params.sort.order,
            VECTOR: '',
            VECTOR_OTHER: '',
            COMMENT: '',
            STATUS: Statuses[statusString],
            USER_ID: params.filter.id,
        };

        const objParamsAllExp = {
            COUNT_ON_PAGE: params.pagination.perPage,
            NUMBER_PAGE: params.pagination.page,
            SORT_FIELD: 'DATE_CREATE',
            SORT_VECTOR: params.sort.order,
            STATE: params.filter.status,
            VECTOR: '',
            VECTOR_OTHER: '',
            COMMENT: '',
            STATUS: Statuses[statusString],
            ID: params.filter.id,
            UF_TYPE: params.filter.status === 'Все' ? '' : params.filter.status,
        };

        const totalPages = total =>
            Math.ceil(+total / +params.pagination.perPage);

        if (resource === 'manager/experts') {
            return axios
                .post(
                    expertsListRoute,
                    LKRepository.generateFormData(objParamsAllExp)
                )
                .then(res => {
                    if (!params.filter.q) {
                        return {
                            data:
                                Object.values(
                                    res.data.data.ESTIMATE.arResult
                                ) || [],
                            total: res.data.data.COUNT,
                        };
                    } else {
                        return {
                            data:
                                filterByValue(
                                    Object.values(
                                        res.data.data.ESTIMATE.arResult
                                    ),
                                    params.filter.q
                                ) || [],
                            total: res.data.data.COUNT,
                        };
                    }
                });
        } else if (resource === 'manager/experts/questionare') {
            return axios
                .post(
                    questionnaireExpertiseListRoute,
                    LKRepository.generateFormData(objParamsExp)
                )
                .then(res => ({
                    data: res.data.data.QUESTIONNAIRE || [],
                    total: res.data.data.COUNT,
                }));
        } else if (resource === 'fc/request') {
            return axios
                .post(
                    `${defaultUrl}.questionnairlisttofz`,
                    LKRepository.generateFormData(objParamsExp)
                )
                .then(res => ({
                    data: res.data.data.QUESTIONNAIRE || [],
                    total: res.data.data.COUNT,
                }));
        } else {
            return axios
                .post(
                    premoderationRoute,
                    LKRepository.generateFormData(objParams)
                )
                .then(res => {
                    return {
                        data: res.data.data.QUESTIONNAIRE || [],
                        total: res.data.data.COUNT,
                    };
                });
        }
    },
};
