import TextField from './fields/TextField';
import SelectField from './fields/SelectField';
import RadioField from './fields/RadioField';
import CheckboxField from './fields/CheckboxField';
import FileField from './fields/File';
import TitleField from './fields/Title';
import SingleCheckboxField from './fields/SingleCheckbox';
import PasswordField from './fields/PasswordField';
import MultiTextField from './fields/MultiTextField';
import TextRatingField from './fields/TextRatingField';

class FieldsCollection {
    private collectionEntities: {
        [index: string]: any;
    } = {
        file: FileField,
        title: TitleField,
        text: TextField,
        select: SelectField,
        radioGroup: RadioField,
        checkboxGroup: CheckboxField,
        password: PasswordField,
        multiField: MultiTextField,
        rating: TextRatingField,
        // checkbox: SingleCheckboxField,
    };

    private createCollection = (config: Array<any>): Array<any> => {
        return config
            .map(configItem => {
                const field = this.collectionEntities[configItem.type] || null;
                if (field) return new field(configItem);
            })
            .filter(item => item);
    };

    constructor(config: Array<any>) {
        this._collectionList = this.createCollection(config);
    }

    private _collectionList: Array<any> = [];

    public get collectionList() {
        return this._collectionList;
    }

    public getInitialValues = () => {
        return this.collectionList.reduce((acc, { getInitValue }) => {
            return { ...acc, ...getInitValue() };
        }, {});
    };

    public getScheme = () => {
        return this.collectionList.reduce((acc, { getScheme }) => {
            return { ...acc, ...getScheme() };
        }, {});
    };
}

export default FieldsCollection;

// private getSelect = (item: any, formikProps: any) => {
//     const { name, label, options } = item;
//     return (
//       <LkSelect
//         formikProps={formikProps}
//         label={label}
//         name={name}
//         options={options}
//       />
//     );
// };
//
// private getCheckbox = (item: any, formikProps: any) => {
//     return <ControlCheckbox formikProps={formikProps} {...item} />;
// };
//
// private getGroupCheckbox = (item: any, formikProps: any) => {
//     return <ControlGroupCheckbox formikProps={formikProps} {...item} />;
// };
//
// private getRadioGroup = (item: any, formikProps: any) => {
//     return <LkRadioGroup {...item} formikProps={formikProps} />;
// };
//
// private getFileField = (item: any, formikProps: any) => {
//     return <div>FILE!! -- {item.label}</div>;
// };
