import React, { useEffect } from 'react';
import './style.sass';
import Header from '../../components/2021/Header';
import FirstScreen from '../../components/FirstScreen';
import IntroText from '../../components/IntroText';
import Organizers from '../../components/Organizers';
import Partners from '../../components/Partners';
import FastTrack from '../../components/FastTrack';
import Profit from '../../components/Profit';
import Industries from '../../components/Industries';
import Directions from '../../components/Directions';
import Criteria from '../../components/Criteria';
import Experts from '../../components/Experts';
import Schedule from '../../components/Schedule';
import History from '../../components/History';
import FAQ from '../../components/FAQ';
import News from '../../components/News';
import PartnersAlt from '../../components/PartnersAlt';
import Footer from '../../components/Footer';
import AOS from 'aos';
import 'aos/src/sass/aos.scss';
import { YMInitializer } from 'react-yandex-metrika';
import OnlineMap from '../../components/OnlineMap';
import ExpertsTabs from '../../components/ExpertsTabs';
import HistoryTabs from '../../components/HistoryTabs';
import NewsSlider from '../../components/NewsSlider';
import Scroller from '../../components/Scroller';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import promoBg from '../../assets/pic/promo.jpg';

const Archive2021En = props => {
    const links = [
        { name: 'intro', label: 'About&nbsp;Accelerator' },
        {
            name: 'organizers',
            label: 'Organizers&nbsp;and&nbsp;partners',
        },
        { name: 'track', label: 'Process&nbsp;work' },
        { name: 'industries', label: 'Industry&nbsp;directions' },
        { name: 'criteria', label: 'Selection&nbsp;criteria' },
        { name: 'experts', label: 'Experts' },
        { name: 'schedule', label: 'Schedule' },
        { name: 'history', label: 'Success&nbsp;stories' },
        { name: 'faq', label: 'FAQ' },
        { name: 'news', label: 'News' },
        { name: 'partners-alt', label: 'Infopartners' },
        { name: 'contacts', label: 'Contacts' },
    ];

    const width = useWindowWidth();
    const isShow = width > 1199;
    useEffect(() => {
        AOS.init();
        window.addEventListener('scroll', () => {
            AOS.refresh();
        });
    });
    return (
        <>
            <div className="ym-counter">
                <YMInitializer
                    accounts={[82711978]}
                    options={{ webvisor: true }}
                />
            </div>
            {isShow && <Scroller links={links} />}
            <Header langRU={false} year="2021" />
            <FirstScreen langRU={false} type="full" bg={promoBg} year="2021" />
            <div name="intro">
                <IntroText langRU={false} year="2021" />
            </div>
            <div name="organizers">
                <Organizers langRU={false} year="2021" />
            </div>
            <div name="partners">
                <Partners langRU={false} year="2021" />
            </div>
            <div name="track">
                <FastTrack langRU={false} year="2021" />
            </div>
            <div name="profit">
                <Profit langRU={false} year="2021" />
            </div>
            <div name="industries">
                <Industries langRU={false} year="2021" />
            </div>
            <div name="directions">
                <Directions langRU={false} year="2021" />
            </div>
            <div name="criteria">
                <Criteria langRU={false} year="2021" />
            </div>
            <div name="experts">
                <ExpertsTabs langRU={false} year="2021"/>
            </div>
            <div name="schedule">
                <Schedule langRU={false} year="2021" />
            </div>
            <div name="history">
                <HistoryTabs langRU={false} />
            </div>
            <div name="faq">
                <FAQ langRU={false} year="2021" />
            </div>
            <div name="partners-alt">
                <PartnersAlt langRU={false} year="2021" />
            </div>
            <div name="contacts">
                <Footer langRU={false} year="2021" />
            </div>
        </>
    );
};

export default Archive2021En;
