//@ts-nocheck
import React, { FC, ReactNode, useEffect, useState } from 'react';
import './style.sass';
import 'aos/src/sass/aos.scss';
import Header from '../../components/Archive/Header';
import Footer from '../../components/Archive/Footer';
import MainScreen from '../../components/Archive/MainScreen';
import Promo from '../../components/Archive/Promo';
import Members from '../../components/Archive/Members';
import Advantages from '../../components/Archive/Advantages';
import FastTrack from '../../components/Archive/FastTrack';
import Decisions from '../../components/Archive/Decisions';
import Experts from '../../components/Archive/Experts';
import Stages from '../../components/Archive/Stages';
import Events from '../../components/Archive/Events';
import Partners from '../../components/Archive/Partners';
import Contacts from '../../components/Archive/Contacts';
import mainScreenData from '../../data/archive/main-screen.json';
import promoData from '../../data/archive/promo.json';
import membersData from '../../data/archive/members.json';
import advantagesData from '../../data/archive/advantages.js';
import fastTrackData from '../../data/archive/fasttrack.json';
import decisionData from '../../data/archive/decisions.json';
import expertsData from '../../data/archive/experts.js';
import stagesData from '../../data/archive/stages.json';
import eventsData from '../../data/archive/events.json';
import partnersData from '../../data/archive/partners.js';
import contactsData from '../../data/archive/contacts.json';
import { withRouter } from 'react-router';
import ArchiveModal from '../../components/Archive/ArchiveModal';

interface IProps {}
interface IData {
    ru: any;
    en?: any;
}

const ArchivePage: FC<IProps> = props => {
    const path = props.location.pathname;
    const lang = path === '/en/archive' ? 'en' : 'ru';

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document
                    .querySelector(this.getAttribute('href'))
                    .scrollIntoView({
                        behavior: 'smooth',
                    });
            });
        });
        setTimeout(() => setIsOpen(true), 60000);
    });

    const getLocaledData = (Component: ReactNode, data: IData) => {
        if (path === '/en/archive') {
            return data.en ? <Component {...data.en} /> : null;
        }
        return <Component {...data.ru} />;
    };

    return (
        <>
            <Header lang={lang} />
            {getLocaledData(MainScreen, mainScreenData)}
            {getLocaledData(Promo, promoData)}
            {getLocaledData(Members, membersData)}
            {getLocaledData(Advantages, advantagesData)}
            {getLocaledData(FastTrack, fastTrackData)}
            {getLocaledData(Decisions, decisionData)}
            {getLocaledData(Experts, expertsData)}
            {getLocaledData(Stages, stagesData)}
            {getLocaledData(Events, eventsData)}
            {getLocaledData(Partners, partnersData)}
            {/*{getLocaledData(Contacts, contactsData)}*/}
            <Footer lang={lang} />
            <ArchiveModal
                lang={lang}
                isOpen={isOpen}
                toggleModal={() => setIsOpen(false)}
            />
        </>
    );
};

export default withRouter(ArchivePage);
