import React, { FC } from 'react';
import './style.sass';

interface IProps {
    children: [JSX.Element, JSX.Element] | JSX.Element;
}

const WrapperPairFields: FC<IProps> = ({ children }) => {
    const isSingleChild = !Array.isArray(children);

    return (
        <div className="wrapper-pair-fields">
            <div className="wrapper-pair-fields__item">
                {/* @ts-ignore TS7053 */}
                {isSingleChild ? children : children[0]}
            </div>
            {!isSingleChild && (
                <div className="wrapper-pair-fields__item">
                    {/* @ts-ignore TS7053 */}
                    {children[1]}
                </div>
            )}
        </div>
    );
};

export default WrapperPairFields;
