import React, { FC } from 'react';
import './style.sass';
// @ts-ignore
import { ReactComponent as DraftIcon } from '../../../assets/img/svg/status-entry/new-entry.svg';
// @ts-ignore
import { ReactComponent as ImprovementIcon } from '../../../assets/img/svg/status-entry/improvement-entry.svg';
// @ts-ignore
import { ReactComponent as ModeratedIcon } from '../../../assets/img/svg/status-entry/moder-entry.svg';
// @ts-ignore
import { ReactComponent as RejectedIcon } from '../../../assets/img/svg/status-entry/rejected-entry.svg';
// @ts-ignore
import { ReactComponent as SuccessIcon } from '../../../assets/img/svg/status-entry/success-entry.svg';

interface IProps {
    status: string | number;
}

const StatusIcon: FC<IProps> = ({ status }) => {
    switch (status) {
        case 'Черновик':
            return <DraftIcon />;
        case 'На доработке':
            return <ImprovementIcon />;
        case 'На премодерации':
            return <ModeratedIcon />;
        case 'На экспертизе':
            return <ModeratedIcon className="blue-theme" />;
        case 'Отклонена':
            return <RejectedIcon />;
        case 'Одобрена':
            return <SuccessIcon />;
        default:
            return <DraftIcon />;
    }
};

export default StatusIcon;
