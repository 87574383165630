export abstract class Repository {
    protected defaultUrl: string =
        '/bitrix/services/main/ajax.php?action=webpractik';

    public generateFormData = (obj: any) => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key]) && obj[key][0] instanceof File) {
                obj[key].forEach((file: File) =>
                    formData.append(key + '[]', file)
                );
                return;
            }
            formData.append(key, obj[key]);
        });
        return formData;
    };
}
