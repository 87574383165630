import orgIcon1 from 'assets/img/svg/organizers/org-01.svg';
import orgIcon2 from 'assets/img/svg/organizers/org-02.svg';
import orgIcon1en from 'assets/img/svg/organizers/org-01-en.png';

export const OrganizatorsItems2021 = [
    {
        logo: orgIcon1,
        text: `Научно-исследовательский институт железнодорожного транспорта (ВНИИЖТ)&nbsp;&mdash; крупнейший научный центр железнодорожной отрасли в&nbsp;области научно-исследовательских и&nbsp;проектно-конструкторских разработок, который способен проводить весь спектр сертификационных испытаний. АО&nbsp;&laquo;ВНИИЖТ&raquo;&nbsp;&mdash; дочернее предприятие ОАО&nbsp;&laquo;РЖД&raquo;.`,
    },
    {
        logo: orgIcon2,
        text: `<div>GenerationS&nbsp;&mdash; платформа по&nbsp;развитию корпоративных инноваций; крупнейший корпоративный акселератор России и&nbsp;СНГ. Входит в&nbsp;состав Группы АО&nbsp;&laquo;УК&nbsp;РФПИ&raquo;.</div><div>GenerationS реализует комплексные программы по&nbsp;развитию корпораций: внутрикорпоративные&nbsp;&mdash; по&nbsp;развитию внутреннего предпринимательства; акселерационные&nbsp;&mdash; по&nbsp;скаутингу и&nbsp;акселерации внешних проектов; международные&nbsp;&mdash; по&nbsp;обучению сотрудников методам построения и&nbsp;ведения инноваций на&nbsp;базе корпораций&nbsp;&mdash; международных лидеров.</div>`,
    },
];
export const OrganizatorsItems2021En = [
    {
        logo: orgIcon1en,
        text: `The Railway Research Institute VNIIZhT is&nbsp;the largest R&amp;D and design center in&nbsp;the railway industry, which is&nbsp;capable of&nbsp;performing all the certification tests. VNIIZhT JSC is&nbsp;a&nbsp;subsidiary of&nbsp;Russian Railways OJSC.`,
    },
    {
        logo: orgIcon2,
        text: `<div>GenerationS&nbsp;&mdash; a&nbsp;platform for the development of&nbsp;corporate innovations; the largest corporate accelerator in&nbsp;Russia and the CIS. It&nbsp;is&nbsp;a&nbsp;part of&nbsp;the Group of&nbsp;JSC &laquo;MC&nbsp;RDIF&raquo;.</div><div>GenerationS implements complex programs for the development of&nbsp;corporations: intracorporate&nbsp;&mdash; for the development of&nbsp;internal entrepreneurship; acceleration&nbsp;&mdash; on&nbsp;scouting and acceleration of&nbsp;external projects; international&nbsp;&mdash; to&nbsp;train employees in&nbsp;methods of&nbsp;building and conducting innovations based on&nbsp;corporations&nbsp;&mdash; international leaders.</div>`,
    },
];
