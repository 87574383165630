import React from 'react';
import './style.sass';
import PropTypes from 'prop-types';

const FlexContainerCenter = ({ children }) => {
    return <div className="flex-container-center">{children}</div>;
};

FlexContainerCenter.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

export default FlexContainerCenter;
