import React, { useState, useEffect } from 'react';
import './style.sass';
import Header from '../../admin-header/Header';
import Registration from '../Registration/index.tsx';
import Authorization from '../Authorization';
import LoginScreenTabList from '../LoginScreenTabList';
import ForgetPassword from '../ForgetPassword';
import RestorePassword from '../RestorePassword';

const LoginScreen = props => {
    const [activeScreen, setActiveScreen] = useState('registration');
    const [paramsUserId, setParamsUserId] = useState('');

    useEffect(() => {
        const getParamsPage = props.location.search;
        if (getParamsPage.includes('change_password=yes')) {
            const userId = new URLSearchParams(getParamsPage).get('USER_ID');
            setParamsUserId(userId);
            setActiveScreen('restorePassword');
        }
    }, [props.location.search]);

    const screens = [
        {
            code: 'registration',
            title: 'Регистрация',
            component: Registration,
            tabs: true,
        },
        {
            code: 'auth',
            title: 'Авторизация',
            component: Authorization,
            tabs: true,
        },
        {
            code: 'forgetPassword',
            title: 'Восстановление доступа',
            component: ForgetPassword,
        },
        {
            code: 'restorePassword',
            title: 'Восстановление доступа',
            component: RestorePassword,
        },
    ];

    const screen = screens.find(screen => screen.code === activeScreen);

    return (
        <>
            <Header notAuthorized />
            <div className="login-screen">
                <div className="login-screen__wrap">
                    {(screen.tabs && (
                        <LoginScreenTabList
                            tabs={screens.filter(screen => screen.tabs)}
                            setActiveScreen={setActiveScreen}
                            activeScreen={activeScreen}
                        />
                    )) || (
                        <div className="login-screen__title">
                            {screen.title}
                        </div>
                    )}

                    <div className="login-screen__window">
                        <screen.component
                            {...props}
                            setActiveScreen={setActiveScreen}
                            paramsUserId={paramsUserId}
                        />
                    </div>

                    {!screen.tabs && (
                        <div
                            className="login-screen__backlink"
                            onClick={() => setActiveScreen('auth')}
                        >
                            Войти в кабинет
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LoginScreen;
