import React, { useEffect, useState } from 'react';
import {
    HeaderMenuLinks2021,
    HeaderMenuLinks2021En,
    HeaderMenuLinks2021Old,
    HeaderMenuLinks2021EnOld,
} from '../../../data/2021/header';
import rjdLogo from '../../../assets/img/svg/header/rjd-logo.svg';
import vnijtLogo from '../../../assets/img/svg/header/vnijt-logo.svg';
import vnijtLogoEn from '../../../assets/pic/vnijt-logo-en.png';
import vnijtLogoWhite from '../../../assets/pic/vnijt-logo-en-wh.png';
import rjdLogoM from '../../../assets/img/svg/header/rjd-logo-m.svg';
import generationLogoM from '../../../assets/img/svg/header/generation-logo-m.svg';
import vnijtLogoM from '../../../assets/img/svg/header/vnijt-logo-m.svg';
import genLogo from '../../../assets/img/svg/header/gen-logo.svg';
import './style.sass';
import parse from 'html-react-parser';
import { useLocation, useHistory } from 'react-router';

const Header = ({ langRU, old = false }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { pathname } = useLocation();
    const { push } = useHistory();
    const regLink = langRU
        // ? '/lk#/login'
        // : 'https://www.f6s.com/russian-railways-accelerator-logistics/apply';
         ? 'https://account.generation-startup.ru/'
         : 'https://account.generation-startup.ru/';
    const getLinks = () => {
        if (old) {
            return langRU ? HeaderMenuLinks2021Old : HeaderMenuLinks2021EnOld;
        }
        return langRU ? HeaderMenuLinks2021 : HeaderMenuLinks2021En;
    };

    const changeLang = () => {
        const regex = /\/en/;
        const path = regex.test(pathname)
            ? pathname.replace('/en', '')
            : `${pathname}/en`;
        if (pathname === '/') {
            push('/en');
            return;
        }
        push(path);
    };
    useEffect(() => {
        const body = document.querySelector('body');
        if (menuIsOpen) {
            body.classList.add('overflow');
            return;
        }
        body.classList.remove('overflow');
    }, [menuIsOpen]);

    return (
        <header className="header2021">
            <div className="full-wrapper">
                <div className="header2021__row">
                    <div className="header2021__logos">
                        <a
                            href="https://www.rzd.ru/"
                            target="_blank"
                            className="header2021__logo"
                        >
                            <img src={rjdLogo} alt="" />
                        </a>
                        <a
                            href="https://www.vniizht.ru/"
                            target="_blank"
                            className="header2021__logo"
                        >
                            <img
                                src={langRU ? vnijtLogo : vnijtLogoEn}
                                alt=""
                            />
                        </a>
                        <a
                            href="https://generation-startup.ru/"
                            target="_blank"
                            className="header2021__logo"
                        >
                            <img src={genLogo} alt="" />
                        </a>
                    </div>
                    <div className="header2021__menu header2021-menu-main">
                        <nav className="header2021-menu">
                            {getLinks().map((item, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={item.id}
                                        className="header2021-menu__link"
                                    >
                                        <span
                                            className={`${
                                                pathname === item.id && 'active'
                                            }`}
                                        >
                                            {item.link}
                                        </span>
                                    </a>
                                );
                            })}
                        </nav>
                    </div>
                    <div
                        className={`header2021__menu header2021-menu-mobile ${
                            menuIsOpen ? 'active' : null
                        }`}
                    >
                        <div className="header2021-menu-mobile__top">
                            <a
                                href="https://www.rzd.ru/"
                                target="_blank"
                                className="header2021-menu-mobile__logo"
                            >
                                <img src={rjdLogoM} alt="" />
                            </a>
                            <div
                                className="header2021__menu-close"
                                onClick={() => setMenuIsOpen(!menuIsOpen)}
                            >
                                <i className="fas fa-times" />
                            </div>
                        </div>
                        <nav className="header2021-menu">
                            {getLinks().map((item, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={item.id}
                                        className="header2021-menu__link"
                                        onClick={() =>
                                            setMenuIsOpen(!menuIsOpen)
                                        }
                                    >
                                        {item.link}
                                    </a>
                                );
                            })}
                        </nav>
                        <div className="header2021-menu-mobile__logos">
                            <a
                                href="https://www.vniizht.ru/"
                                target="_blank"
                                className="header2021-menu-mobile__logo"
                            >
                                <img
                                    src={langRU ? vnijtLogoM : vnijtLogoWhite}
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://generation-startup.ru/"
                                target="_blank"
                                className="header2021-menu-mobile__logo"
                            >
                                <img src={generationLogoM} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="header2021__toggles">
                        <div className="header2021__apply">
                            <a
                                className="header2021__apply-btn"
                                href={regLink}
                                target="_blank"
                            >
                                {langRU ? parse('Авторизация') : 'Auth'}
                            </a>
                        </div>
                        <div
                            className="header2021__menu-open"
                            onClick={() => setMenuIsOpen(!menuIsOpen)}
                        >
                            <div className="header2021__bars">
                                <span className="header2021__bar" />
                                <span className="header2021__bar" />
                                <span className="header2021__bar" />
                            </div>
                        </div>
                        <div className="header2021__menu-lang">
                            <div
                                className="header2021-menu__link"
                                onClick={changeLang}
                            >
                                {langRU ? 'EN' : 'RU'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
