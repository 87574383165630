import React from 'react';
import RedButton from '../../ui/RedButton';
import ModalWrap from '../../resources/CustomerList/ExpertModal';
import './style.sass';

const ModalSuccessRegistration = ({ isOpen, modalHandle }) => {
    return (
        <ModalWrap isOpen={isOpen} toggleModal={modalHandle}>
            <div className={`expert-modal expert-modal--success`}>
                <div className="registration-modal__title">
                    Спасибо за регистрацию!
                </div>
                <div className="registration-modal__text">
                    Пожалуйста, <b>подтвердите ваш email</b>, перейдя по ссылке
                    в письме, которое мы отправили на вашу почту
                </div>
                <div className="expert-modal__bottom">
                    <RedButton
                        size="sm"
                        type="button"
                        onClick={() => {
                            modalHandle(false);
                        }}
                    >
                        Закрыть
                    </RedButton>
                </div>
            </div>
        </ModalWrap>
    );
};

export default ModalSuccessRegistration;
