import React from 'react';
import cn from './style.module.sass';
import clsx from 'clsx';
import parse from 'html-react-parser';

const Tab = ({ label, active, handleTab, length }) => {
    return (
        <div className={cn.col} data-length={length}>
            <div className={cn.tab} onClick={handleTab}>
                <span className={clsx(cn.label, active && cn.active)}>
                    {parse(label)}
                </span>
            </div>
        </div>
    );
};

export default Tab;
