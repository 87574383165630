import React, { useState, useRef, useEffect } from 'react';
import HistoryItem from './item';
import Tabs from '../Tabs';
import {
    HistoryItems2021En,
    HistoryItems2021,
    HistoryItems2022,
    HistoryItems2022En
} from '../../data/2021/histories';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import parse from 'html-react-parser';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';
import clsx from 'clsx';

import cn from './style.module.sass';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const NextArrow = ({ className, style, onClick }) => (
    <div
        className={clsx(cn.next, className)}
        style={{ ...style }}
        onClick={onClick}
    >
        <img src={arrowNext} alt="" />
    </div>
);

const PrevArrow = ({ className, style, onClick }) => (
    <div
        className={clsx(cn.prev, className)}
        style={{ ...style }}
        onClick={onClick}
    >
        <img src={arrowPrev} alt="" />
    </div>
);

const sliderSettings = {
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
            },
        },
    ],
};

const HistoryTabs = ({ langRU, year = '2021' }) => {
    let data = null
    if(year === '2021') data = langRU ? HistoryItems2021 : HistoryItems2021En
    if(year === '2022') data = langRU ? HistoryItems2022 : HistoryItems2022En

    const [activeTab, setActiveTab] = useState(data[0].category);
    const [animation, setAnimation] = useState(false);
    const tabs = data.map(({ category }) => category);
    const customSlider = useRef();
    const width = useWindowWidth();

    const getContent = () => {
        return data.find(({ category }) => category === activeTab).content;
    };

    const initAnimation = () => {
        setAnimation(true);
        setTimeout(() => setAnimation(false), 500);
    };

    const handleTab = tab => {
        initAnimation();
        setActiveTab(tab);
    };
    return (
        <section
            className={cn.history}
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className={clsx(cn.fullWrapper, 'full-wrapper')}>
                <div className={cn.title}>
                    {langRU
                        ? parse('Истории успеха')
                        : parse('Success stories')}
                </div>
                <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    handleTab={handleTab}
                    fullTabs
                >
                    <div
                        className={clsx(
                            cn.row,
                            cn.slider,
                            animation && cn.anim
                        )}
                    >
                        <Slider
                            key={width}
                            {...sliderSettings}
                            ref={slider => (customSlider.current = slider)}
                        >
                            {getContent().map((item, index) => {
                                return (
                                    <HistoryItem langRU={langRU} item={item} key={index}/>
                                );
                            })}
                        </Slider>
                    </div>
                </Tabs>
            </div>
        </section>
    );
};

export default HistoryTabs;
