import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NewsItems2022 } from '../../data/2022/news';
import { NewsItems2021 } from '../../data/2021/news';
import cn from './style.module.sass';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';
import clsx from 'clsx';
import NewsSliderItem from './item';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const NextArrow = ({ className, style, onClick }) => (
    <div
        className={clsx(cn.next, className)}
        style={{ ...style }}
        onClick={onClick}
    >
        <img src={arrowNext} alt="" />
    </div>
);
const PrevArrow = ({ className, style, onClick }) => (
    <div
        className={clsx(cn.prev, className)}
        style={{ ...style }}
        onClick={onClick}
    >
        <img src={arrowPrev} alt="" />
    </div>
);

const NewsSlider = ({ year }) => {
    const getData = () => {
        if (year === '2021') {
            return NewsItems2021;
        }
        if (year === '2022') {
            return NewsItems2022;
        }
    };

    const [stateKey, setStateKey] = useState(0);
    const customSlider = useRef();
    const width = useWindowWidth();
    const sliderSettings = {
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        swipe: false,
        swipeToSlide: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                },
            },
        ],
    };
    useEffect(() => {
        setTimeout(() => setStateKey(1), 500);
    });
    return (
        <section
            className={cn.news}
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
            id="video-news"
        >
            <div className={clsx(cn.fullWrapper, 'full-wrapper')}>
                <div className={cn.title}>Новости</div>
                <div className="news-navigation">
                    <a href="" id="news-nav-prev">
                        <img src={arrowPrev} alt="" />
                    </a>
                    <a href="" id="news-nav-next">
                        <img src={arrowNext} alt="" />
                    </a>
                </div>
                <div className={clsx(cn.row, cn.slider, 'news-slider')}>
                    <Slider
                        key={`${width}-${stateKey}`}
                        {...sliderSettings}
                        ref={slider => (customSlider.current = slider)}
                    >
                        {getData().map((item, index) => {
                            return <NewsSliderItem key={index} item={item} />;
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default NewsSlider;
