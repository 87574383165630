export const ScheduleItems2021 = [
    { date: `15&nbsp;июля`, text: `Открытие акселератора` },
    { date: `до&nbsp;08&nbsp;сентября`, text: `Сбор заявок` },
    { date: `до&nbsp;22&nbsp;сентября`, text: `Экспертиза проектов` },
    {
        date: `29-30&nbsp;сентября`,
        text: `Онлайн отбор проектов`,
    },
    {
        date: `01&nbsp;октября&nbsp;&mdash; 30&nbsp;ноября`,
        text: `Акселерационная программа`,
    },
    { date: `Декабрь`, text: `DemoDay` },
];
export const ScheduleItems2021En = [
    { date: `July&nbsp;15`, text: `Opening of&nbsp;accelerator` },
    {
        date: `until September&nbsp;08`,
        text: `Submission of&nbsp;applications`,
    },
    { date: `until September&nbsp;22`, text: `Expert assessment` },
    {
        date: `29-30&nbsp;September`,
        text: `Online selection of&nbsp;projects`,
    },
    {
        date: `01&nbsp;October&nbsp;&mdash; 30&nbsp;November`,
        text: `Acceleration program`,
    },
    { date: `December`, text: `DemoDay` },
];
