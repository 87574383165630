import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';

const NumericMask: FC<any> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
            ]}
            placeholderChar={'\u2000'}
        />
    );
};

export default NumericMask;
