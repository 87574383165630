import React, { FC } from 'react';
import './style.sass';
//@ts-ignore
import promoImg from '../../../assets/img/archive/promo/promo.jpg';

interface IProps {
    title: string;
    text: string[];
}

const Promo: FC<IProps> = ({ title, text }) => {
    return (
        <div className="archive-promo">
            <div className="full-wrapper">
                <div className="archive-promo__row">
                    <div className="archive-promo__col">
                        <div className="archive-promo__item">
                            <div
                                className="archive-promo__title"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />

                            {text?.length > 0 &&
                                text.map((item, index) => (
                                    <div
                                        key={index}
                                        className="archive-promo__text"
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="archive-promo__col archive-promo__col--img">
                        <div className="archive-promo__item ">
                            <img
                                src={promoImg}
                                alt=""
                                className="archive-promo__img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Promo;
