import React, { FC } from 'react';
import './style.sass';
import Button from '@material-ui/core/Button';

interface IProps {
    children: string;
    size?: 'lg';
    href?: string;
    [key: string]: any;
}

const TextButton: FC<IProps> = props => {
    const { signModification, size, ...restProps } = props;
    const classList = ['admin-text-btn'];

    if (size) {
        classList.push(`admin-text-btn--${size}`);
    }

    return <Button {...restProps} className={classList.join(' ')} />;
};

export default TextButton;
