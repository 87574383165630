import React from 'react';
import {
    AutocompleteInput,
    Filter,
    TextInput,
    ReferenceInput,
    SearchInput,
} from 'react-admin';
import './style.sass';
import ExportButton from '../../../ui/ExportButton';
export const RequestFilter = props => {
    return (
        <>
            <Filter {...props} className="request-toolbar">
                <SearchInput className="request-search" source="q" alwaysOn />
                <ReferenceInput
                    source="name"
                    reference="request"
                    label="Название"
                >
                    <TextInput />
                </ReferenceInput>
            </Filter>
            <ExportButton />
        </>
    );
};
