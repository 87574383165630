import React, { useEffect } from 'react';
import './style.sass';
import Header from '../../components/2021/Header';
import FirstScreen from '../../components/FirstScreen';
import IntroText from '../../components/IntroText';
import Organizers from '../../components/Organizers';
import Partners from '../../components/Partners';
import FastTrack from '../../components/FastTrack';
import Profit from '../../components/Profit';
import Industries from '../../components/Industries';
import Directions from '../../components/Directions';
import Criteria from '../../components/Criteria';
import Schedule from '../../components/Schedule';
import FAQ from '../../components/FAQ';
import PartnersAlt from '../../components/PartnersAlt';
import AOS from 'aos';
import 'aos/src/sass/aos.scss';
import { YMInitializer } from 'react-yandex-metrika';
import OnlineMap from '../../components/OnlineMap';
import ExpertsTabs from '../../components/ExpertsTabs';
import HistoryTabs from '../../components/HistoryTabs';
import NewsSlider from '../../components/NewsSlider';
import Footer from '../../components/2021/Footer';
import Scroller from '../../components/Scroller';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import promoBg from '../../assets/pic/promo.jpg';

const Archive2021 = props => {
    const links = [
        { name: 'intro', label: 'Об&nbsp;Акселераторе' },
        { name: 'organizers', label: 'Организаторы&nbsp;и&nbsp;партнеры' },
        { name: 'track', label: 'Процесс&nbsp;работы' },
        { name: 'industries', label: 'Отраслевые&nbsp;направления' },
        { name: 'criteria', label: 'Критерии&nbsp;отбора' },
        { name: 'experts', label: 'Эксперты' },
        { name: 'schedule', label: 'График' },
        { name: 'history', label: 'Истории&nbsp;успеха' },
        { name: 'faq', label: 'FAQ' },
        { name: 'news', label: 'Новости' },
        { name: 'partners-alt', label: 'Инфопартнеры' },
        { name: 'contacts', label: 'Контакты' },
    ];

    const width = useWindowWidth();
    const isShow = width > 1199;
    useEffect(() => {
        AOS.init();
        window.addEventListener('scroll', () => {
            AOS.refresh();
        });
    });
    return (
        <>
            <div className="ym-counter">
                <YMInitializer
                    accounts={[82711978]}
                    options={{ webvisor: true }}
                />
            </div>
            {isShow && <Scroller links={links} />}
            <Header langRU={true} year="2021" />
            <FirstScreen langRU={true} type="full" bg={promoBg} year="2021" />
            <div name="intro">
                <IntroText langRU={true} year="2021" />
            </div>
            <div name="organizers">
                <Organizers langRU={true} year="2021" />
            </div>
            <div name="news">
                <NewsSlider langRU={true} year="2021" />
            </div>
            <div name="partners">
                <Partners langRU={true} year="2021" />
            </div>
            <div name="track">
                <FastTrack langRU={true} year="2021" />
            </div>
            <div name="profit">
                <Profit langRU={true} year="2021" />
            </div>
            <div name="industries">
                <Industries langRU={true} year="2021" />
            </div>
            <div name="directions">
                <Directions langRU={true} year="2021" />
            </div>
            <div name="criteria">
                <Criteria langRU={true} year="2021" />
            </div>
            <div name="experts">
                <ExpertsTabs langRU={true} year="2021" />
            </div>
            <div name="schedule">
                <Schedule langRU={true} year="2021" />
            </div>
            {/*<div name="map">*/}
            {/*    <OnlineMap langRU={true} />*/}
            {/*</div>*/}
            <div name="history">
                <HistoryTabs langRU={true} />
            </div>
            <div name="faq">
                <FAQ langRU={true} year="2021" />
            </div>
            <div name="partners-alt">
                <PartnersAlt langRU={true} year="2021" />
            </div>
            <div name="contacts">
                <Footer langRU={true} year="2021" />
            </div>
        </>
    );
};

export default Archive2021;
