import React, { FC } from 'react';
import './style.sass';
//@ts-ignore
import logoRu from '../../../assets/img/svg/archive/header-logos.svg';
//@ts-ignore
import logoEn from '../../../assets/img/archive/header-logos-en.png';

interface IProps {
    lang: string;
}

const Header: FC<IProps> = ({ lang }) => {
    return (
        <header className="archive-header">
            <div className="full-wrapper">
                <div className="archive-header__row">
                    <div className="archive-header__col archive-header__col--logos">
                        <div className="archive-header__item">
                            <a
                                href="https://www.vniizht.ru/"
                                target="_blank"
                                className="archive-header__logo"
                            >
                                <img
                                    src={lang === 'ru' ? logoRu : logoEn}
                                    className="archive-header__logos"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="archive-header__col archive-header__col--menu">
                        <div className="archive-header__menu archive-header-menu">
                            <a
                                href="#archive-advantages"
                                className="archive-header-menu__link"
                            >
                                {lang === 'ru' ? 'ВОЗМОЖНОСТИ' : ''}
                            </a>
                            <a
                                href="#archive-fast-track"
                                className="archive-header-menu__link"
                            >
                                {lang === 'ru' ? 'ПРОГРАММА' : ''}
                            </a>
                            <a
                                href="#archive-stages"
                                className="archive-header-menu__link"
                            >
                                {lang === 'ru' ? 'УСЛОВИЯ УЧАСТИЯ' : ''}
                            </a>
                            <a
                                href="#archive-events"
                                className="archive-header-menu__link"
                            >
                                {lang === 'ru' ? 'МЕРОПРИЯТИЯ' : ''}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
