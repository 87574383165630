import React from 'react';
import axios from 'axios';
import LKRepository from '../../../../models/LKRepository';
import Statuses from '../../../../data/statusesId.json';
import ModalWrap from '../ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ModalCancel = ({
    name,
    isOpen,
    handleCancel,
    refresh,
    record,
    notify,
}) => {
    const classes = useStyles();
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [comment, setComment] = React.useState('');

    const handleChange = event => {
        setComment(event.target.value);
    };

    const handleSaveComment = () => {
        if (comment) {
            setError('');
            axios
                .post(
                    `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                    LKRepository.generateFormData({
                        ID: record.id,
                        COMMENT: comment,
                        STATUS: Statuses['Отклонена'],
                    })
                )
                .then(response => {
                    if (response.data.status === 'success') {
                        setSuccess('Заявка отклонена');
                        refresh();
                    } else {
                        notify('Что-то пошло не так');
                    }
                })
                .catch(err => console.log(err));
        } else {
            setError('Заполните комментарий');
        }
    };

    return (
        <ModalWrap isOpen={isOpen} toggleModal={handleCancel}>
            <div
                className={`expert-modal ${
                    success ? `expert-modal--success` : ``
                }`}
            >
                {!success ? (
                    <>
                        <div className="expert-modal__label">
                            Оставьте комментарий
                        </div>
                        <div className="expert-modal__title">{name}</div>
                        <div className="expert-modal__row">
                            <div className="expert-modal__col-12">
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="commentRevision"
                                        label="Комментарий"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        {error && (
                            <div className="expert-modal__error">
                                <span>{error}</span>
                            </div>
                        )}
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="lg-m"
                                type="button"
                                onClick={() => handleSaveComment()}
                            >
                                Отклонить
                            </RedButton>

                            <button
                                className="expert-modal__cancel"
                                onClick={() => {
                                    handleCancel(false);
                                }}
                            >
                                Отменить
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="expert-modal__success">{success}</div>
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => {
                                    handleCancel(false);
                                }}
                            >
                                Закрыть
                            </RedButton>
                        </div>
                    </>
                )}
            </div>
        </ModalWrap>
    );
};

export default ModalCancel;
