import ParticipantCabinet from '../../components/lk/ParticipantCabinet';
import ApplicationPage from '../../components/application-form/ApplicationPage';
import ExpertsProfile from '../../components/resources/ExpertsProfile';
import ProfileEdit from '../../components/resources/ExpertsProfileEdit';
import RequestList from '../../components/resources/Request/RequestList';
import RequestEdit from '../../components/resources/Request/RequestEdit';
import ExpertsRules from '../../components/resources/ExpertsRules';
import ManagerList from '../../components/resources/ManagerList';
import ManagerShow from '../../components/resources/ManagerList/ManagerShow';
import ExpertsQuestionareList from '../../components/resources/ManagerExperts/ExpertsQuestionareList';
import ExpertsList from '../../components/resources/ManagerExperts/ExpertsList';
import StatisticsManager from '../../components/resources/ManagerExperts/StatisticsManager';
import CustomerList from '../../components/resources/CustomerList';

export const resources = {
    startup: [
        {
            name: 'cabinet',
            list: ParticipantCabinet,
            edit: false,
            options: { label: 'Личный кабинет' },
        },
        {
            name: 'application-2021',
            list: ApplicationPage,
            edit: false,
            options: { label: 'Акселератор 2021' },
        },
        {
            name: 'profile',
            list: ExpertsProfile,
            edit: ProfileEdit,
            options: { label: 'Профиль' },
        },
    ],
    expert: [
        {
            name: 'request',
            list: RequestList,
            edit: RequestEdit,
            options: { label: 'Заявки' },
        },
        {
            name: 'profile',
            list: ExpertsProfile,
            edit: ProfileEdit,
            options: { label: 'Профиль' },
        },
        {
            name: 'rules',
            list: ExpertsRules,
            edit: false,
            options: { label: 'Правила пользования' },
        },
    ],
    manager: [
        {
            name: 'manager/request',
            list: ManagerList,
            edit: ManagerShow,
            options: { label: 'Заявки' },
        },
        {
            name: 'manager/experts/questionare',
            list: ExpertsQuestionareList,
            edit: false,
            options: { label: 'Заявки экспертов', shouldHideInMenu: true },
        },
        {
            name: 'manager/experts',
            list: ExpertsList,
            edit: ManagerShow,
            options: { label: 'Эксперты' },
        },
        {
            name: 'profile',
            list: ExpertsProfile,
            edit: ProfileEdit,
            options: { label: 'Профиль' },
        },
        {
            name: 'manager/statistic',
            list: StatisticsManager,
            edit: false,
            options: { label: 'Статистика' },
        },
    ],
    functional: [
        {
            name: 'fc/request',
            list: CustomerList,
            edit: ManagerShow,
            options: { label: 'Заявки' },
        },
        {
            name: 'profile',
            list: ExpertsProfile,
            edit: ProfileEdit,
            options: { label: 'Профиль' },
        },
        // {
        //     name: 'fc/statistic',
        //     list: StatisticsManager,
        //     edit: false,
        //     options: { label: 'Статистика' },
        // },
    ],
    spectator: [
        {
            name: 'spectator/request',
            list: ManagerList,
            edit: ManagerShow,
            options: { label: 'Заявки' },
        },
        {
            name: 'profile',
            list: ExpertsProfile,
            edit: ProfileEdit,
            options: { label: 'Профиль' },
        },
        {
            name: 'spectator/statistic',
            list: StatisticsManager,
            edit: false,
            options: { label: 'Статистика' },
        },
    ],
};
