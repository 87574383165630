import React, { FC } from 'react';
import './style.sass';

interface IProps {
    title: string;
    items: string[];
}

const FastTrack: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-fasttrack" id="archive-fast-track">
            <div className="full-wrapper">
                <div
                    className="archive-fasttrack__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="archive-fasttrack__row">
                    {items?.length > 0 &&
                        items.map((item, index) => (
                            <div className="archive-fasttrack__col" key={index}>
                                <div className="archive-fasttrack__item">
                                    <div className="archive-fasttrack__num">
                                        {index + 1}
                                    </div>
                                    <div
                                        className="archive-fasttrack__text"
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default FastTrack;
