import React, { useEffect, useState } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    useDataProvider,
    useTranslate,
} from 'react-admin';
import SlideToggle from 'react-slide-toggle';
import { CardTitle } from '../../CustomTitles/CardTitle';
import './style.sass';
import BodyResource from '../../BodyResource';
import Spinner from '../../../ui/Spinner';
import config from '../../../../data/project-config.json';

const RequestCard = props => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const { iconClose, iconOpen, title } = props;

    return (
        <>
            <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement }) => (
                    <BodyResource requestEdit>
                        <div
                            className="card__header"
                            onClick={() => {
                                toggle();
                                setIsOpen(!isOpen);
                            }}
                        >
                            <CardTitle title={translate(title)} />
                            <div className="card__toggle">
                                {isOpen
                                    ? translate('ru.editGroup.hide')
                                    : translate('ru.editGroup.show')}
                                <img
                                    src={isOpen ? iconClose : iconOpen}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div
                            className="card__content"
                            ref={setCollapsibleElement}
                        >
                            {props.data.map((item, index) => {
                                if (!item.data) {
                                    return null;
                                }
                                return (
                                    <div className="card__text" key={index}>
                                        <div className="card__text--label">
                                            {item.label}
                                        </div>
                                        <div className="card__text--data">
                                            {typeof item.data === 'object'
                                                ? Object.keys(item.data).map(
                                                      key => (
                                                          <div className="card__text--obj">
                                                              {item.data[
                                                                  key
                                                              ].includes(
                                                                  '/upload'
                                                              ) ? (
                                                                  <a
                                                                      href={
                                                                          item
                                                                              .data[
                                                                              key
                                                                          ]
                                                                      }
                                                                      download
                                                                  >
                                                                      {
                                                                          item
                                                                              .data[
                                                                              key
                                                                          ]
                                                                      }
                                                                  </a>
                                                              ) : (
                                                                  item.data[key]
                                                              )}
                                                          </div>
                                                      )
                                                  )
                                                : item.data}
                                        </div>
                                    </div>
                                    // <TextField
                                    //     key={index}
                                    //     className="card__text"
                                    //     source={item.data}
                                    //     label={item.label}
                                    // />
                                );
                            })}
                        </div>
                    </BodyResource>
                )}
            />
        </>
    );
};

export default RequestCard;
