import AbstractField from './AbstractField';
import FileGroup from 'components/form/basic-components/FileGroup';
import React from 'react';

export default class FileField extends AbstractField {
    config: any;

    constructor(config: any) {
        super();
        this.config = config;
    }

    public renderWithFormik = (props: any) => {
        const { name } = this.config;
        const { values, setFieldValue, handleBlur, errors, touched } = props;
        const error = errors[name] && touched[name];

        const newProps: { [p: string]: any } = {
            onChange: setFieldValue,
            onBlur: handleBlur,
            error,
            value: values[name],
            helperText: error ? errors[name] : '',
        };
        return this.render(newProps);
    };

    public render = (props: any) => {
        const { name, accept, multiple } = this.config;
        const propsFromConfig = {
            name,
            accept,
            multiple,
            label: this.getLabel(),
        };

        return <FileGroup key={name} {...propsFromConfig} {...props} />;
    };
}
