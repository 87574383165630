import React from 'react';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import BodyResource from '../BodyResource';
import Spinner from '../../ui/Spinner';
import ExpertsProfileGrid from '../ExpertsProfileGrid';
import axios from 'axios';
import User from '../../../models/User';

class ExpertsProfile extends React.Component {
    state = {
        userData: {},
        isLoading: true,
    };
    defaultUrl = '/bitrix/services/main/ajax.php?action=webpractik';

    generateFormData = obj => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            formData.append(key, obj[key]);
        });
        return formData;
    };
    async componentDidMount() {
        axios
            .post(
                `${this.defaultUrl}:main.forms.infouser`,
                this.generateFormData({ USERID: User.getId() })
            )
            .then(res => {
                this.setState({
                    userData: res.data.data,
                    isLoading: false,
                });
            })
            .catch(err => console.log(err));
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                <ResourceTitlePage text="Профиль" />
                <BodyResource contentMaxWidthProfile>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <ExpertsProfileGrid {...this.state.userData} />
                    )}
                </BodyResource>
            </>
        );
    }
}

export default ExpertsProfile;
