import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import { ReactComponent as SkypeIcon } from '../../../assets/img/svg/admin/skype.svg';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LKRepository from '../../../models/LKRepository';

const ShowViewItem = ({ label, desc }) => {
    if (desc) {
        return (
            <div className="show-view__row">
                <div className="show-view-item">
                    <div className="show-view-item__label">{label}</div>
                    <div className="show-view-item__desc">
                        {Array.isArray(desc) ? desc.join(', ') : desc}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const FunctionalCustomers = ({ id, permissions }) => {
    const [arrFZ, setArrFZ] = useState([]);
    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnairfzinfo`,
                LKRepository.generateFormData({
                    APP_ID: id,
                })
            )
            .then(res => {
                console.log(res.data.data);
                setArrFZ(res.data.data);
            })
            .catch(err => console.log(err));
    }, []);
    return (
        <div className="func-customers">
            {arrFZ ? (
                <ResourceTitlePage text="Функциональные заказчики" />
            ) : (
                <ResourceTitlePage text="ФЗ для заявки не назначены!" />
            )}

            {arrFZ?.map(item => {
                return (
                    <div className="func-customers__row">
                        <div className="func-customers-item">
                            <div className="func-customers-item__header">
                                <div className="func-customers-item__title">
                                    Функциональный заказчик № {item.fzLinkId}
                                </div>
                                <div className="func-customers-item__btn">
                                    {item.fzSkype && (
                                        <a
                                            className="skype-button"
                                            href={`skype:${item.fzSkype}?call`}
                                        >
                                            <SkypeIcon /> Звонок через Skype
                                        </a>
                                    )}
                                </div>
                            </div>

                            <div className="func-customers-item__row">
                                <div className="func-customers-project-item">
                                    <div className="func-customers-project-item__title">
                                        Протокол встречи
                                    </div>
                                    {item.meetingMinutes ? (
                                        <div className="func-customers-project-item-log">
                                            <div className="func-customers-project-item-log__row func-customers-project-item-log__row--header">
                                                <div className="func-customers-project-item-log__col">
                                                    Дата встречи
                                                </div>
                                                <div className="func-customers-project-item-log__col">
                                                    Файл
                                                </div>
                                            </div>
                                            {item.meetingMinutes?.map(i => {
                                                return (
                                                    <div className="func-customers-project-item-log__row">
                                                        <div className="func-customers-project-item-log__col">
                                                            {i.uploadDate}
                                                        </div>
                                                        <div className="func-customers-project-item-log__col">
                                                            <a
                                                                href={
                                                                    i.files[0]
                                                                        .path
                                                                }
                                                                download
                                                            >
                                                                {
                                                                    i.files[0]
                                                                        .name
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="func-customers-project-item-log">
                                            <span className="func-customers-project-item-log__error">
                                                Протоколы не загружены
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="func-customers-item__row">
                                <div className="func-customers-project-item">
                                    <div className="func-customers-project-item__title">
                                        Материалы проекта
                                    </div>

                                    {item.projectMaterials ? (
                                        <div className="func-customers-project-item-log">
                                            <div className="func-customers-project-item-log__row func-customers-project-item-log__row--header">
                                                <div className="func-customers-project-item-log__col">
                                                    Дата загрузки
                                                </div>
                                                <div className="func-customers-project-item-log__col">
                                                    Файл
                                                </div>
                                                <div className="func-customers-project-item-log__col">
                                                    Комментарий
                                                </div>
                                                <div className="func-customers-project-item-log__col">
                                                    Автор
                                                </div>
                                            </div>
                                            {item.projectMaterials?.map(i => {
                                                return (
                                                    <div className="func-customers-project-item-log__row">
                                                        <div className="func-customers-project-item-log__col">
                                                            {i.uploadDate}
                                                        </div>
                                                        <div className="func-customers-project-item-log__col">
                                                            <a
                                                                href={
                                                                    i.files[0]
                                                                        .path
                                                                }
                                                                download
                                                            >
                                                                {
                                                                    i.files[0]
                                                                        .name
                                                                }
                                                            </a>
                                                        </div>
                                                        <div className="func-customers-project-item-log__col">
                                                            {i.comment}
                                                        </div>
                                                        <div className="func-customers-project-item-log__col">
                                                            {i.author}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="func-customers-project-item-log">
                                            <span className="func-customers-project-item-log__error">
                                                Материалы не загружены
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <br />
                        <Link
                            to={{
                                pathname: '/fc-show',
                                search: stringify({
                                    id: item.fzLinkId,
                                }),
                            }}
                            className="func-customers__link-more"
                        >
                            Подробнее
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default FunctionalCustomers;
