import React from 'react';
import { ExpertsItems } from '../../data/data';
import { ExpertsItemsEn } from '../../data/data';

import parse from 'html-react-parser';

import './style.sass';
import ExpertsSlider from '../ExpertsSlider';

const Experts = ({ langRU }) => {
    const data = langRU ? ExpertsItems : ExpertsItemsEn;
    return (
        <section
            id="graph"
            className="experts"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="experts__title">
                    {langRU ? parse(`Эксперты`) : parse(`Experts`)}
                </div>
                <ExpertsSlider data={data} />
                <div className="experts__link-wrapper">
                    <a href="" className="experts__link">
                        {langRU ? parse(`Все эксперты`) : parse(`All experts`)}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Experts;
