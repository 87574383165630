import React from 'react';
import './style.sass';

const FastTrackItem = ({ index, item }) => {
    return (
        <div
            className="fast-track__col"
            data-aos="fade-right"
            data-aos-delay={(index + 1) * 500}
        >
            <div className="fast-track__item">
                <svg
                    width="290px"
                    height="160px"
                    viewBox="0 0 290 160"
                    version="1.1"
                >
                    <g
                        id="Landing3"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                    >
                        <g
                            id="rzd-2"
                            transform="translate(-304.000000, -3089.000000)"
                            fill="#91A0B6"
                            className="fast-track__decor"
                        >
                            <g
                                id="Group-105"
                                transform="translate(0.000000, 2909.000000)"
                            >
                                <polygon
                                    id="Rectangle-Copy"
                                    points="304 180 564 180 594 260 564 340 304 340 334 260"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
                <div
                    className="fast-track__text"
                    dangerouslySetInnerHTML={{
                        __html: item,
                    }}
                />
            </div>
        </div>
    );
};

export default FastTrackItem;
