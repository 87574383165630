import React, { useState } from 'react';
import Modal from 'react-modal';
import './style.sass';
import parse from 'html-react-parser';

const ArchiveModal = ({ isOpen, toggleModal, children, lang }) => {
    Modal.setAppElement('#root');
    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            className={'archive-modal'}
        >
            <i
                className="archive-modal__close"
                onClick={() => toggleModal(false)}
            />
            <div className="archive-modal__title">
                {lang === 'ru'
                    ? parse(
                          'Примите участие в&nbsp;новом наборе Акселератора РЖД'
                      )
                    : parse(
                          'Take part in&nbsp;the new set of&nbsp;the Russian Railways Accelerator'
                      )}
            </div>
            <div className="archive-modal__text">
                {lang === 'ru'
                    ? parse(
                          'Станьте участником акселератора и&nbsp;получите заказчика в&nbsp;лице одного из&nbsp;мировых лидеров железнодорожной отрасли. Заявки принимаются до&nbsp;30&nbsp;августа 2020&nbsp;года.'
                      )
                    : parse(
                          'Become a&nbsp;member of&nbsp;the accelerator and get a&nbsp;customer in&nbsp;the person of&nbsp;one of&nbsp;the world leaders in&nbsp;the railway industry. Applications are accepted until August&nbsp;30, 2020.'
                      )}
            </div>
            <a
                href="https://accelerator.rzd.ru/"
                target="_blank"
                className="archive-modal__button"
            >
                {lang === 'ru' ? 'Узнать подробности' : 'Learn more'}
            </a>
        </Modal>
    );
};

export default ArchiveModal;
