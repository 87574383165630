import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ModalWrap from '../../ManagerList/ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import Statuses from '../../../../data/statusesId.json';
import { Notification, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import AppointQuestModal from '../AppointQuestModal';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ActionField = ({ IdExpert, source, basePath, record = {} }) => {
    const notify = useNotify();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isOpen, setToggleModal] = useState(false);
    const expertType =
        (record.estimate_expert_type &&
            record.estimate_expert_type.some(
                item => item.TYPE_EXPERT === '3'
            )) ||
        false;
    const expertTechCheck =
        (record.estimate_expert_type &&
            record.estimate_expert_type.some(
                item => item.TYPE_EXPERT === '4'
            )) ||
        false;
    const menuOpen = () => {
        setToggleMenu(true);
    };
    const modalHandle = active => {
        setToggleMenu(false);
        setToggleModal(active);
    };

    const changeStatusRequest = status => {
        const message = `Заявка ${status.toLowerCase()}`;
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                LKRepository.generateFormData({
                    ID: record.id,
                    STATUS: Statuses[status],
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify(message);
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <div className="action-field">
            <div className="action-field__title" onClick={menuOpen}>
                Действия
            </div>
            {toggleMenu && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setToggleMenu(false);
                    }}
                >
                    <div
                        className={`action-field-menu ${
                            toggleMenu && `active`
                        }`}
                    >
                        {basePath !== '/manager/experts/questionare' ? (
                            <div className="action-field-menu__item">
                                <Link
                                    to={{
                                        pathname:
                                            '/manager/experts/questionare',
                                        search: stringify({
                                            page: 1,
                                            perPage: 25,
                                            filter: JSON.stringify({
                                                id: record.id,
                                                status: 'Все',
                                            }),
                                        }),
                                    }}
                                >
                                    Заявки эксперта
                                </Link>
                            </div>
                        ) : (
                            <>
                                <div className="action-field-menu__item">
                                    <Link
                                        to={{
                                            pathname: `/manager/request/${record.id}`,
                                        }}
                                    >
                                        Открыть
                                    </Link>
                                </div>
                                {expertTechCheck ? (
                                    <div
                                        className="action-field-menu__item"
                                        onClick={() => {
                                            changeStatusRequest('Одобрена');
                                        }}
                                    >
                                        В акселератор
                                    </div>
                                ) : (
                                    <div className="action-field-menu__item disabled">
                                        В акселератор
                                    </div>
                                )}

                                <div
                                    className="action-field-menu__item"
                                    onClick={() => {
                                        changeStatusRequest('Отклонена');
                                    }}
                                >
                                    Отклонить
                                </div>
                                {record.status !== 'На экспертизе' && (
                                    <>
                                        <div
                                            className="action-field-menu__item"
                                            onClick={() => {
                                                changeStatusRequest(
                                                    'На доработке'
                                                );
                                            }}
                                        >
                                            Вернуть на доработку
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <div
                            className="action-field-menu__item"
                            onClick={() => {
                                modalHandle(true);
                            }}
                        >
                            Назначить заявку
                        </div>
                    </div>
                </OutsideClickHandler>
            )}
            {isOpen && (
                <AppointQuestModal
                    {...record}
                    isOpen={isOpen}
                    IdExpert={IdExpert ? IdExpert : record.id}
                    modalHandle={modalHandle}
                />
            )}
            <Notification />
        </div>
    );
};

export default ActionField;
