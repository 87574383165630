import React, { FC } from 'react';
import './style.sass';

interface IProps {
    isActive: boolean;
    isHovering?: boolean;
}

const CrossIcon: FC<IProps> = ({ isActive, isHovering = false }) => {
    return (
        <svg
            className={`cross-icon ${isActive && 'active'} ${
                isHovering && 'hover'
            }`}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
        >
            <g
                stroke="none"
                strokeWidth="2px"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
            >
                <g
                    className="cross-icon__filler"
                    transform="translate(1.000000, 1.000000)"
                    stroke="#000000"
                >
                    <path d="M0,11 L22,11" />
                    <path d="M11,0 L11,22" />
                </g>
            </g>
        </svg>
    );
};

export default CrossIcon;
