import React from 'react';
import parse from 'html-react-parser';
import { PartnersItems } from '../../data/data';
import { PartnersItems2021 } from '../../data/2021/partners';
import { PartnersItems2022 } from '../../data/2022/partners';
import './style.sass';

const Partners = ({ langRU, year }) => {
    const getData = () => {
        if (year === '2021') {
            return PartnersItems2021;
        }
        if (year === '2022') {
            return PartnersItems2022;
        }
        return PartnersItems;
    };

    return (
        <section
            className="partners"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="partners__title">
                    {langRU
                        ? parse('Партнёры акселератора')
                        : parse('Accelerator partners')}
                </div>
                <div className="partners__row">
                    {getData().map((item, index) => {
                        return (
                            <div className="partners__col" key={index}>
                                <div className="partners__item">
                                    <img
                                        src={item.img}
                                        alt=""
                                        className="partners__img"
                                    />
                                    <div className="partners__overlay">
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            className="partners__link"
                                        >
                                            {item.link}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Partners;
