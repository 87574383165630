import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import BodyResource from '../../resources/BodyResource';
import Spinner from '../../ui/Spinner';
import ApplicationForm from '../ApplicationForm';
import { dataApplication } from '../../../types/application';
import LKRepository from 'models/LKRepository';
import applicationConfig from 'data/application-config.json';
import ApplicationShowView from '../ApplicationView';

const ApplicationPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [initialData, setinitialData] = useState<dataApplication | null>(
        null
    );
    const [
        initialDataShow,
        setInitialDataShow,
    ] = useState<dataApplication | null>(null);

    const getFieldsByType = (types: string[]) => {
        return applicationConfig.filter(field => types.includes(field.type));
    };

    const processFields = (data: any) => {
        const singleValueFields = getFieldsByType([
            'radioGroup',
            'select',
            'checkbox',
        ]);
        const multiValueFields = getFieldsByType(['checkboxGroup']);

        if (data) {
            singleValueFields.forEach((field: any) => {
                const label = data[field.name];
                data[field.name] =
                    field?.options?.find(
                        (option: any) => option.label === label
                    )?.value || '';
            });
            multiValueFields.forEach((field: any) => {
                if (data[field.name]) {
                    const labelArray = Object.values(data[field.name]);

                    data[field.name] =
                        field?.options
                            ?.filter((option: any) =>
                                labelArray.includes(option.label)
                            )
                            .map((option: any) => option.value) || [];
                }
            });
        }
        return data;
    };

    useEffect(() => {
        let isFetching = true;
        LKRepository.getApplicationId()
            .then(() => LKRepository.getApplicationData())
            .then(res => {
                if (isFetching) {
                    setInitialDataShow(res.data.data);
                    const data = processFields(
                        Object.assign({}, res.data.data)
                    );
                    setinitialData(data);
                    setLoading(false);
                }
            })
            .catch(err => console.log(err));

        return () => {
            isFetching = false;
        };
    }, []);

    return (
        <>
            {!loading &&
                (!initialData?.STATUS ||
                initialData?.STATUS === 'Черновик' ||
                initialData?.STATUS === 'На доработке' ? (
                    <ResourceTitlePage text="Анкета участника" />
                ) : (
                    <ResourceTitlePage
                        text={
                            initialData?.NAME_PROJECT
                                ? initialData?.NAME_PROJECT
                                : ''
                        }
                    />
                ))}
            <BodyResource>
                {loading ? (
                    <Spinner />
                ) : !initialData?.STATUS ||
                  initialData?.STATUS === 'Черновик' ||
                  initialData?.STATUS === 'На доработке' ? (
                    <ApplicationForm data={initialData} />
                ) : (
                    <ApplicationShowView data={initialDataShow} />
                )}
            </BodyResource>
        </>
    );
};

export default ApplicationPage;
