import React from 'react';
import DirectionsItem from './item';
import { DirectionsItems, DirectionsItemsEn } from '../../data/data';
import {
    DirectionsItems2021,
    DirectionsItems2021En,
} from '../../data/2021/directions';
import './style.sass';

const Directions = ({ langRU, year }) => {
    const regLink = langRU
        ? '/lk#/login'
        : 'https://www.f6s.com/russian-railways-accelerator-logistics/apply';

    const getData = () => {
        if (year === '2021') {
            return langRU ? DirectionsItems2021 : DirectionsItems2021En;
        }
        return langRU ? DirectionsItems : DirectionsItemsEn;
    };
    return (
        <section
            id="criteria"
            className="directions"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="directions__row">
                    {getData().map((item, index) => {
                        return <DirectionsItem key={index} item={item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default Directions;
