import russianMessages from 'ra-language-russian';

export default {
    ...russianMessages,
    ru: {
        title: {
            about: 'О проекте',
            product: 'Продукт проекта',
            structure: 'Юридическая структура проекта',
            files: 'Файлы проекта',
            leader: 'Руководитель',
            expertReview: 'Экспертная оценка',
        },
        editGroup: {
            hide: 'Свернуть',
            show: 'Подробнее',
        },
        button: {
            save: 'ОЦЕНИТЬ',
        },
        reviewTable: {
            title: 'Итоговая оценка:',
            criteria: 'КРИТЕРИЙ',
            review: 'ОЦЕНКА',
        },
    },
};
