import React, { useState } from 'react';
import './style.sass';
// @ts-ignore
import { Notification, useLogin, useNotify } from 'react-admin';
import { Form, Formik } from 'formik';
import errorMessages from '../../../data/errorMessages.json';
import * as Yup from 'yup';
import RegistrationFormContent from '../RegistrationFormContent/index';
import { IRegistrationValues } from '../../../types/registration';
import User from '../../../models/User';
import AuthRepository from '../../../models/AuthRepository';
import ModalSuccessRegistration from '../ModalRegistrationSuccess';

const Registration = () => {
    const login = useLogin();
    const notify = useNotify();
    const [isOpen, setToggleModal] = useState(false);

    const initialValues = {
        NAME: '',
        PHONE: '',
        EMAIL: '',
        PASS: '',
        COMFIRM_PASS: '',
        RECAPTCHA: '',
        AGREEMENT: false,
        POLICY_PERSONAL: false,
    };

    const getSchema = () => {
        const {
            required,
            email,
            minTel,
            maxTel,
            correctFormatTel,
            minPassword,
            minLowercase,
            minUppercase,
            passwordsMatch,
            agreement,
            recaptcha,
        } = errorMessages;

        return Yup.object().shape({
            EMAIL: Yup.string().email(email).required(required),
            NAME: Yup.string().required(required),
            PHONE: Yup.string()
                .min(6, minTel)
                .max(18, maxTel)
                .matches(/^.+[^_]$/, correctFormatTel)
                .required(required),
            PASS: Yup.string()
                .min(8, minPassword)
                .matches(/[a-z]/, minLowercase)
                .matches(/[A-Z]/, minUppercase)
                .required(required),
            COMFIRM_PASS: Yup.string()
                .oneOf([Yup.ref('PASS'), undefined], passwordsMatch)
                .required(passwordsMatch),
            AGREEMENT: Yup.bool().oneOf([true], agreement),
            POLICY_PERSONAL: Yup.bool().oneOf([true], agreement),
            RECAPTCHA: Yup.string().required(recaptcha),
        });
    };

    const handleSubmit = (values: IRegistrationValues) => {
        const data = {
            ...values,
            PHONE: values.PHONE.replace(/[^\w\s]/gi, '')
                .replace(/\s/gi, '')
                .replace(/^7/, '8'),
        };
        AuthRepository.register(data)
            .then(res => {
                // todo: c сервера ошибка летит со статусом success
                if (typeof res.data.data === 'number') {
                    login({ LOGIN: values.EMAIL, PASS: values.PASS });
                    User.setLocalData({ id: res.data.data });
                    setToggleModal(true);
                } else {
                    res.data.data && notify(res.data.data, 'warning');
                }
            })
            .then(() => AuthRepository.saveUserData());
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={getSchema}
            >
                {props => (
                    <Form noValidate={true}>
                        <RegistrationFormContent {...props} />
                    </Form>
                )}
            </Formik>
            <Notification />
            <ModalSuccessRegistration
                isOpen={isOpen}
                modalHandle={setToggleModal}
            />
        </>
    );
};

export default Registration;
