import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import iconOpen from '../../../assets/img/svg/icon-edit-open.svg';
import iconClose from '../../../assets/img/svg/icon-edit-close.svg';
import ExpertiseReview from './ExpertiseReview';
import { RecordTitle } from '../CustomTitles/RecordTitle';
import { EditTitle } from '../CustomTitles/EditTitle';
import RequestCard from './RequestCard';
import projectConfig from '../../../data/project-config.json';
import Spinner from '../../ui/Spinner';
import config from '../../../data/project-config.json';
import axios from 'axios';
import AuthRepository from '../../../models/AuthRepository';
import ExpertiseShowView from './ExpertiseShowView';

const icons = {
    iconOpen,
    iconClose,
};
const generateData = (config, data) => {
    const formatedData = [];

    config.sourceArray.forEach(item => {
        formatedData.push({
            label: item.label,
            data: data[item.source],
        });
    });

    return formatedData;
};
const generateFormData = obj => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
    });
    return formData;
};
const RequestEdit = props => {
    const [title, setTitle] = useState('');
    const [singleRequest, setSingleRequest] = useState({});
    const [idExpert, setIdExpert] = useState('');
    const [isRated, setIsRated] = useState(false);
    const [expertType, setExpertType] = useState('');

    const { getOne } = useDataProvider();
    useEffect(() => {
        getOne('request', { id: props.id }).then(res => {
            setTitle(res.data.NAME_PROJECT);
            setSingleRequest(res.data);
        });
    }, [props.id]);

    useEffect(() => {
        AuthRepository.checkAuth().then(res => {
            setIdExpert(res.data.data);
        });
    }, [props.id]);

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.estimategetquestionnaite`,
                generateFormData({ UF_ID_REQUEST: props.id })
            )
            .then(response => {
                const r = Object.keys(response.data.data);
                const isRated = r.some(item => item === idExpert);
                setIsRated(isRated);
                const expType = response.data.data[idExpert];
                setExpertType(expType);
                setLoaded(true);
            })
            .catch(err => console.log(err));
    }, [idExpert]);

    const [isLoaded, setLoaded] = useState(false);
    return (
        <React.Fragment>
            {!isLoaded ? (
                <Spinner />
            ) : (
                <>
                    <RecordTitle record={title} />
                    {projectConfig.map((item, index) => {
                        return (
                            <RequestCard
                                data={generateData(
                                    config[index],
                                    singleRequest
                                )}
                                key={index}
                                {...props}
                                {...icons}
                                {...item}
                            />
                        );
                    })}
                </>
            )}
            <EditTitle title="ru.title.expertReview" />
            {!isRated ? (
                <>
                    <ExpertiseReview {...props} />
                </>
            ) : (
                <ExpertiseShowView
                    generateFormData={generateFormData}
                    id={props.id}
                    type={expertType}
                />
            )}
        </React.Fragment>
    );
};

export default RequestEdit;
