import React from 'react';
import AbstractField from './AbstractField';
import FormGroupTitle from 'components/form/basic-components/FormGroupTitle';

export default class TitleField extends AbstractField {
    constructor(public config: any) {
        super();
    }

    public renderWithFormik = () => {
        return (
            <FormGroupTitle
                id={this.config.id ? this.config.id : ''}
                key={this.config.label}
                label={this.config.label}
            />
        );
    };

    public render = () => {};
}
