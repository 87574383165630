import React, { FC } from 'react';
import './style.sass';
import FBIcon from '../../ui/FBIcon';
import VKIcon from '../../ui/VKIcon';
import OKIcon from '../../ui/OKIcon';
import TWIcon from '../../ui/TWIcon';

interface IProps {
    lang: string;
}

const Footer: FC<IProps> = ({ lang }) => {
    return (
        <footer className="archive-footer">
            <div className="full-wrapper">
                <div className="archive-footer__row">
                    <div className="archive-footer__col archive-footer__col--info">
                        <div className="archive-footer__info archive-footer-info">
                            {lang === 'ru' && (
                                <a
                                    href="https://drive.google.com/file/d/1p6nMWDpYnzrl10NtADc_3MIydmidVQFY/view?usp=sharing"
                                    target="_blank"
                                    className="archive-footer-info__agreement"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            'Положение о&nbsp;проведении Акселератора РЖД',
                                    }}
                                />
                            )}
                            <div
                                className="archive-footer-info__text"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === 'ru'
                                            ? '&copy;&nbsp;ОАО &laquo;РЖД&raquo;, ФРИИ. 2019&nbsp;| Все права защищены'
                                            : '&copy;&nbsp;JSC &laquo;Russian Railways&raquo;, IIDF. 2019&nbsp;| All rights reserved',
                                }}
                            />
                        </div>
                    </div>
                    <div className="archive-footer__col archive-footer__col--social">
                        <div className="archive-footer-social">
                            <div className="archive-footer-social__list">
                                <div className="archive-footer-social__item">
                                    <a
                                        href="https://www.facebook.com/sharer.php?src=sp&u=file%3A%2F%2F%2Fhome%2Fanton%2F%25D0%25A0%25D0%25B0%25D0%25B1%25D0%25BE%25D1%2587%25D0%25B8%25D0%25B9%2520%25D1%2581%25D1%2582%25D0%25BE%25D0%25BB%2Fproject362200%2Fpage7926511.html&title=RZD%20ACCELERATOR&utm_source=share2"
                                        target="_blank"
                                    >
                                        <FBIcon />
                                    </a>
                                </div>
                                <div className="archive-footer-social__item">
                                    <a
                                        href="https://vk.com/share.php?url=file%3A%2F%2F%2Fhome%2Fanton%2F%25D0%25A0%25D0%25B0%25D0%25B1%25D0%25BE%25D1%2587%25D0%25B8%25D0%25B9%2520%25D1%2581%25D1%2582%25D0%25BE%25D0%25BB%2Fproject362200%2Fpage7926511.html&title=RZD%20ACCELERATOR&utm_source=share2"
                                        target="_blank"
                                    >
                                        <VKIcon />
                                    </a>
                                </div>
                                <div className="archive-footer-social__item">
                                    <a
                                        href="https://connect.ok.ru/offer?url=file%3A%2F%2F%2Fhome%2Fanton%2F%25D0%25A0%25D0%25B0%25D0%25B1%25D0%25BE%25D1%2587%25D0%25B8%25D0%25B9%2520%25D1%2581%25D1%2582%25D0%25BE%25D0%25BB%2Fproject362200%2Fpage7926511.html&title=RZD%20ACCELERATOR&utm_source=share2"
                                        target="_blank"
                                    >
                                        <OKIcon />
                                    </a>
                                </div>
                                <div className="archive-footer-social__item">
                                    <a
                                        href="https://twitter.com/intent/tweet?text=RZD%20ACCELERATOR&url=file%3A%2F%2F%2Fhome%2Fanton%2F%25D0%25A0%25D0%25B0%25D0%25B1%25D0%25BE%25D1%2587%25D0%25B8%25D0%25B9%2520%25D1%2581%25D1%2582%25D0%25BE%25D0%25BB%2Fproject362200%2Fpage7926511.html&utm_source=share2"
                                        target="_blank"
                                    >
                                        <TWIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
