import React from 'react';
import {
    LastProgramsItems2021,
    LastProgramsItems2021En,
} from '../../data/2021/last-programs';
import { useHistory } from 'react-router';
import cn from './style.module.sass';
const LastProgramBlock = ({ langRU }) => {
    const { push } = useHistory();

    const getData = () => {
        return langRU ? LastProgramsItems2021 : LastProgramsItems2021En;
    };

    return (
        <div className={cn.wrapper}>
            <div className="full-wrapper">
                <div className={cn.row}>
                    {getData().map((item, index) => (
                        <div
                            className={cn.col}
                            key={item.title}
                            onClick={() => {
                                if (index === 0) {
                                    push(item.link);
                                    return;
                                }
                                window.location.href = item.link;
                            }}
                        >
                            <div className={cn.item}>
                                <div className={cn.imgWrap}>
                                    <img
                                        src={item.img}
                                        alt=""
                                        className={cn.img}
                                    />
                                </div>
                                <div className={cn.content}>
                                    <div
                                        className={cn.text}
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                    <a href={item.link} className={cn.link}>
                                        <span>
                                            {langRU
                                                ? 'Смотреть как это было'
                                                : 'See how it was'}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LastProgramBlock;
