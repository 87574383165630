import React, { useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import './style.sass';
import { Projects2022 } from '../../data/2022/projects2022';
import ProjectsItem from '../2022/ProjectsItem/ProjectsItem';

const getCurrentData = (allData, from, to) => {
    return allData.slice(from, to);
};

const TechnoFocus = ({ langRU, year }) => {
    const getData = useMemo(() => (langRU ? Projects2022 : Projects2022), [
        langRU,
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDada, setCurrentDada] = useState([]);
    const [active, setActive] = useState(null);

    const handleToggle = index => {
        if (index === active) {
            setActive(null);

            return;
        }

        setActive(index);
    };

    useEffect(() => {
        const fromTo = {
            from: 10 * (currentPage - 1),
            to: 10 * currentPage,
        };

        const newData = getCurrentData(getData, fromTo.from, fromTo.to);
        setCurrentDada(newData);
    }, [currentPage]);

    const btnText = useMemo(() => {
        return langRU
            ? { shown: 'Читать подробнее', hidden: 'Скрыть подробнее' }
            : { shown: 'Read more', hidden: 'Hide details' };
    }, [langRU]);

    return (
        <section
            id="techno"
            className="techno"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="techno__title">
                    {langRU
                        ? parse(`Перечень проектов прошедших очный отбор`)
                        : parse(`Projects`)}
                </div>
                <div className="techno-table">
                    <div className="techno-table-header">
                        <div className="techno-table-header__row">
                            <div className="techno-table-header__col">
                                {langRU ? '' : ''}
                            </div>
                            <div className="techno-table-header__col">
                                {langRU ? '' : ''}
                            </div>
                        </div>
                    </div>

                    {/*<div className="techno-item__wrapper-text">*/}
                    {/*    <div className="techno-item__name">*/}
                    {/*        Пассажирский комплекс*/}
                    {/*    </div>*/}
                    {/*    <div className="techno-item__name">*/}
                    {/*        Технологическая независимость*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="techno-table-body">
                        {currentDada.map((item, index) => (
                            <ProjectsItem
                                item={item}
                                handleToggle={handleToggle}
                                active={active}
                                btnText={btnText}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TechnoFocus;
