import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ModalWrap from '../ExpertModal';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import Statuses from '../../../../data/statusesId.json';
import StatusesView from '../../../../data/statusesView.json';
import { Notification, useNotify, useRefresh } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import FCModal from '../FCModal/FCModal';
import ModalCancel from '../CancelModal';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ActionField = ({ source, basePath, record = {} }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isOpen, setToggleModal] = useState(false);
    const [isOpenRevision, setToggleModalRevision] = useState(false);
    const [isOpenFCModal, setToggleFCModal] = useState(false);
    const [isOpenCancelModal, setToggleCancelModal] = useState(false);

    const expertType =
        (record.estimate_expert_type &&
            record.estimate_expert_type.some(
                item => item.TYPE_EXPERT === '3'
            )) ||
        false;
    const expertTechCheck =
        (record.estimate_expert_type &&
            record.estimate_expert_type.some(
                item => item.TYPE_EXPERT === '4'
            )) ||
        false;

    const expertTechCheckStatus =
        (record.st && record.st.some(item => item === '4')) || false;

    const menuOpen = () => {
        setToggleMenu(true);
    };
    const modalHandle = active => {
        setToggleMenu(false);
        setToggleModal(active);
    };
    const modalHandleRevision = active => {
        setToggleMenu(false);
        setToggleModalRevision(active);
    };

    const modalHandleCancel = active => {
        setToggleMenu(false);
        setToggleCancelModal(active);
    };
    const ModalExpert = props => {
        const classes = useStyles();
        const [success, setSuccess] = React.useState('');
        const [error, setError] = React.useState('');
        const [expertBiz, setExpertBiz] = React.useState('');
        const [expertTech, setExpertTech] = React.useState('');
        const [experts, setExperts] = React.useState('');
        const [expertsBiz, setExpertsBiz] = React.useState('');
        const [expertsTech, setExpertsTech] = React.useState('');

        const handleChange = (event, type) => {
            if (type === 'biz') {
                setExpertBiz(event.target.value);
                // const filtered = Object.keys(experts)
                //     .filter(key => experts[key].id !== event.target.value)
                //     .reduce((obj, key) => {
                //         obj[key] = experts[key];
                //         return obj;
                //     }, {});
                // setExpertsTech(filtered);
            } else if (type === 'tech') {
                setExpertTech(event.target.value);
                // const filtered = Object.keys(experts)
                //     .filter(key => experts[key].id !== event.target.value)
                //     .reduce((obj, key) => {
                //         obj[key] = experts[key];
                //         return obj;
                //     }, {});
                // setExpertsBiz(filtered);
            }
        };

        const handleSave = type => {
            const dataObj =
                type === 'biz'
                    ? {
                          EXPERT_BUSSNESS: expertBiz,
                          ID_QUESTIONNARE: props.id,
                      }
                    : {
                          EXPERT_TECH: expertTech,
                          ID_QUESTIONNARE: props.id,
                      };
            if (
                (type === 'biz' && expertBiz === '') ||
                (type === 'tech' && expertTech === '')
            ) {
                setError('Выберите эксперта');
            } else {
                setError('');
                axios
                    .post(
                        `/bitrix/services/main/ajax.php?action=webpractik:main.forms.setexpert`,
                        LKRepository.generateFormData(dataObj)
                    )
                    .then(response => {
                        console.log(response.data.data);
                        if (response.data.data.length) {
                            setSuccess('Эксперт назначен');
                            refresh();
                        } else {
                            setError('Что-то пошло не так');
                        }
                    })
                    .catch(err => console.log(err));
            }
        };

        useEffect(() => {
            axios
                .post(
                    `/bitrix/services/main/ajax.php?action=webpractik:main.forms.expertlist`,
                    {}
                )
                .then(response => {
                    setExperts(response.data.data);
                    setExpertsBiz(
                        Object.values(response.data.data).filter(
                            i => i.type === 'Бизнесс'
                        )
                    );
                    setExpertsTech(
                        Object.values(response.data.data).filter(
                            i => i.type === 'Технологии'
                        )
                    );
                })
                .catch(err => console.log(err));
        }, []);
        return (
            <ModalWrap isOpen={isOpen} toggleModal={modalHandle}>
                <div
                    className={`expert-modal ${
                        success ? `expert-modal--success` : ``
                    }`}
                >
                    {!success ? (
                        <>
                            <div className="expert-modal__label">
                                {expertType
                                    ? 'Назначить технического-эксперта для'
                                    : 'Назначить бизнес-эксперта для'}
                            </div>
                            <div className="expert-modal__title">
                                {props.name}
                            </div>
                            <div className="expert-modal__row">
                                {!expertType ? (
                                    <div className="expert-modal__col-12">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                id="business"
                                                className={classes.formControl}
                                            >
                                                Бизнес-эксперт
                                            </InputLabel>
                                            <Select
                                                labelId="business"
                                                id="business-select"
                                                defaultValue=""
                                                className={classes.formControl}
                                                value={expertBiz}
                                                onChange={e => {
                                                    handleChange(e, 'biz');
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Не выбрано
                                                </MenuItem>
                                                {Object.keys(expertsBiz)
                                                    .sort((a, b) => {
                                                        return expertsBiz[
                                                            a
                                                        ].surname.localeCompare(
                                                            expertsBiz[b]
                                                                .surname
                                                        );
                                                    })
                                                    .map(key => (
                                                        <MenuItem
                                                            value={
                                                                expertsBiz[key]
                                                                    .id
                                                            }
                                                            key={key}
                                                        >
                                                            {expertsBiz[key]
                                                                .name
                                                                ? `${expertsBiz[key].name} ${expertsBiz[key].surname}`
                                                                : expertsBiz[
                                                                      key
                                                                  ].email}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : (
                                    <div className="expert-modal__col-12">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                id="tech"
                                                className={classes.formControl}
                                            >
                                                Технический-эксперт
                                            </InputLabel>
                                            <Select
                                                labelId="tech"
                                                id="tech-select"
                                                defaultValue=""
                                                value={expertTech}
                                                className={classes.formControl}
                                                onChange={e => {
                                                    handleChange(e, 'tech');
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Не выбрано
                                                </MenuItem>
                                                {Object.keys(expertsTech)
                                                    .sort((a, b) => {
                                                        return expertsTech[
                                                            a
                                                        ].surname.localeCompare(
                                                            expertsTech[b]
                                                                .surname
                                                        );
                                                    })
                                                    .map(key => (
                                                        <MenuItem
                                                            value={
                                                                expertsTech[key]
                                                                    .id
                                                            }
                                                            key={key}
                                                        >
                                                            {expertsTech[key]
                                                                .name
                                                                ? `${expertsTech[key].name} ${expertsTech[key].surname}`
                                                                : expertsTech[
                                                                      key
                                                                  ].email}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </div>
                            {error && (
                                <div className="expert-modal__error">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="expert-modal__bottom">
                                {!expertType ? (
                                    <RedButton
                                        size="sm"
                                        type="button"
                                        onClick={() => handleSave('biz')}
                                    >
                                        Сохранить
                                    </RedButton>
                                ) : (
                                    <RedButton
                                        size="sm"
                                        type="button"
                                        onClick={() => handleSave('tech')}
                                    >
                                        Сохранить
                                    </RedButton>
                                )}

                                <button
                                    className="expert-modal__cancel"
                                    onClick={() => {
                                        modalHandle(false);
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="expert-modal__success">
                                {success}
                            </div>
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        modalHandle(false);
                                    }}
                                >
                                    Закрыть
                                </RedButton>
                            </div>
                        </>
                    )}
                </div>
            </ModalWrap>
        );
    };
    /*******/

    const ModalRevision = props => {
        const classes = useStyles();
        const [success, setSuccess] = React.useState('');
        const [userData, setUserData] = React.useState({
            EMAIL: '',
            USER_ID: '',
        });
        const [error, setError] = React.useState('');
        const [comment, setComment] = React.useState('');

        const handleChange = event => {
            setComment(event.target.value);
        };

        const handleSaveComment = async () => {
            if (comment) {
                setError('');

                axios
                    .post(
                        `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                        LKRepository.generateFormData({
                            ID: record.id,
                            COMMENT: comment,
                            STATUS: Statuses['На доработке'],
                            ...userData,
                        })
                    )
                    .then(response => {
                        if (response.data.status === 'success') {
                            setSuccess('Заявка отправлена на доработку');
                            refresh();
                        } else {
                            notify('Что-то пошло не так');
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                setError('Заполните комментарий');
            }
        };

        const generateFormData = obj => {
            const formData = new FormData();
            Object.keys(obj).forEach(key => {
                if (Array.isArray(obj[key]) && obj[key][0] instanceof File) {
                    obj[key].forEach(file => formData.append(key + '[]', file));
                    return;
                }
                formData.append(key, obj[key]);
            });
            return formData;
        };

        const fetchApplication = async () => {
            const formData = generateFormData({
                ID: record.id,
            });
            return await axios
                .post(
                    `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnaire`,
                    formData
                )
                .then(async data => {
                    const USERID = data.data.data['CREATED_BY'];
                    const response = await axios.post(
                        `/bitrix/services/main/ajax.php?action=webpractik:main.forms.infouser`,
                        generateFormData({
                            USERID,
                        })
                    );

                    const { email, id } = response.data.data;
                    setUserData({ USER_ID: id, EMAIL: email });
                });
        };

        useEffect(() => {
            fetchApplication();
        }, []);

        return (
            <ModalWrap
                isOpen={isOpenRevision}
                toggleModal={modalHandleRevision}
            >
                <div
                    className={`expert-modal ${
                        success ? `expert-modal--success` : ``
                    }`}
                >
                    {!success ? (
                        <>
                            <div className="expert-modal__label">
                                Оставьте комментарий
                            </div>
                            <div className="expert-modal__title">
                                {props.name}
                            </div>
                            <div className="expert-modal__row">
                                <div className="expert-modal__col-12">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="commentRevision"
                                            label="Комментарий"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {error && (
                                <div className="expert-modal__error">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="lg-m"
                                    type="button"
                                    onClick={() => handleSaveComment()}
                                >
                                    Отправить на доработку
                                </RedButton>

                                <button
                                    className="expert-modal__cancel"
                                    onClick={() => {
                                        modalHandleRevision(false);
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="expert-modal__success">
                                {success}
                            </div>
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        modalHandleRevision(false);
                                    }}
                                >
                                    Закрыть
                                </RedButton>
                            </div>
                        </>
                    )}
                </div>
            </ModalWrap>
        );
    };

    const changeStatusRequest = status => {
        const message = `Заявка ${status.toLowerCase()}`;
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                LKRepository.generateFormData({
                    ID: record.id,
                    STATUS: Statuses[status],
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify(message);
                    refresh();
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };
    const changeCallingRequest = call => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                LKRepository.generateFormData({
                    ID: record.id,
                    CALLING: call,
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify('Заявка сохранена');
                    refresh();
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <div className="action-field">
            <div className="action-field__title" onClick={menuOpen}>
                Действия
            </div>
            {toggleMenu && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setToggleMenu(false);
                    }}
                >
                    <div
                        className={`action-field-menu ${
                            toggleMenu && `active`
                        }`}
                    >
                        <div className="action-field-menu__item">
                            <a href={`/lk#${basePath}/${record.id}`}>Открыть</a>
                        </div>
                        {record.status === 'Очный отбор' ? (
                            <div
                                className="action-field-menu__item"
                                onClick={() => {
                                    changeStatusRequest('Одобрена');
                                }}
                            >
                                В акселератор
                            </div>
                        ) : (
                            <div className="action-field-menu__item disabled">
                                В акселератор
                            </div>
                        )}
                        {record.status === 'Одобрена' && (
                            <>
                                <div
                                    className="action-field-menu__item"
                                    onClick={() => {
                                        changeStatusRequest('Demoday');
                                    }}
                                >
                                    Перевести в Demoday
                                </div>
                            </>
                        )}
                        <div
                            className="action-field-menu__item"
                            onClick={() => {
                                changeStatusRequest('Архив');
                            }}
                        >
                            Архив
                        </div>
                        {record.status === 'DemoDay' && (
                            <>
                                <div
                                    className="action-field-menu__item"
                                    onClick={() => {
                                        changeStatusRequest('Сопровождение');
                                    }}
                                >
                                    Сопровождение
                                </div>
                            </>
                        )}
                        {expertTechCheck &&
                        record.status !== 'Одобрена' &&
                        record.status !== 'DemoDay' &&
                        record.status !== 'Очный отбор' &&
                        record.status !== 'Сопровождение' &&
                        record.status !== 'Архив' ? (
                            <div
                                className="action-field-menu__item"
                                onClick={() => {
                                    changeStatusRequest('Очный отбор');
                                }}
                            >
                                Очный отбор
                            </div>
                        ) : (
                            <div className="action-field-menu__item disabled">
                                Очный отбор
                            </div>
                        )}

                        {!expertTechCheck &&
                            !expertTechCheckStatus &&
                            record.status !== 'Черновик' &&
                            record.status !== 'На доработке' &&
                            record.status !== 'Отклонена' && (
                                <div
                                    className="action-field-menu__item"
                                    onClick={() => {
                                        modalHandle(true);
                                    }}
                                >
                                    {expertType
                                        ? 'Назначить технического-эксперта'
                                        : 'Назначить бизнес-эксперта'}
                                </div>
                            )}

                        {StatusesView[record.status] && (
                            <div
                                className="action-field-menu__item"
                                onClick={() => {
                                    setToggleFCModal(true);
                                }}
                            >
                                Назначить ФЗ
                            </div>
                        )}

                        <div
                            className="action-field-menu__item"
                            onClick={() => {
                                modalHandleCancel(true);
                                // changeStatusRequest('Отклонена');
                            }}
                        >
                            Отклонить
                        </div>
                        {record.status !== 'На доработке' &&
                            record.status !== 'Одобрена' &&
                            record.status !== 'DemoDay' &&
                            record.status !== 'Очный отбор' &&
                            record.status !== 'Сопровождение' &&
                            record.status !== 'Архив' && (
                                <>
                                    <div
                                        className="action-field-menu__item"
                                        onClick={() => {
                                            modalHandleRevision(true);
                                        }}
                                    >
                                        Вернуть на доработку
                                    </div>
                                    <div
                                        className="action-field-menu__item"
                                        onClick={() => {
                                            changeCallingRequest('Y');
                                        }}
                                    >
                                        Состоялся звонок
                                    </div>
                                </>
                            )}
                    </div>
                </OutsideClickHandler>
            )}
            {isOpen && <ModalExpert {...record} />}
            {isOpenRevision && <ModalRevision {...record} />}
            {isOpenFCModal && (
                <FCModal
                    isOpen={isOpenFCModal}
                    toggle={setToggleFCModal}
                    {...record}
                />
            )}
            {isOpenCancelModal && (
                <ModalCancel
                    isOpen={isOpenCancelModal}
                    toggle={setToggleCancelModal}
                    name={record.name}
                    handleCancel={modalHandleCancel}
                    refresh={refresh}
                    record={record}
                    notify={notify}
                />
            )}
            <Notification />
        </div>
    );
};

export default ActionField;
