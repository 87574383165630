import { connect } from 'formik';
import { useEffect } from 'react';

const FormikScroll = ({ formik }) => {
    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            document
                .getElementsByName(Object.keys(formik.errors)[0])[0]
                .focus();
        }
    }, [formik]);

    return null;
};

export default connect(FormikScroll);
