import React, { FC } from 'react';
import './style.sass';
import { statusApplication } from '../../../types/application';
import StatusIcon from '../StatusIcon';
import { Link } from 'react-router-dom';

interface IProps {
    status: string | number;
}

const MetaEntryIcon: FC<IProps> = ({ status }) => {
    if (status === 'Черновик') {
        return (
            <Link
                to="application-2021"
                className="meta-entry-icon meta-entry-icon--link"
            >
                <StatusIcon status={status} />
            </Link>
        );
    }

    return (
        <div className="meta-entry-icon">
            <StatusIcon status={status} />
        </div>
    );
};

export default MetaEntryIcon;
