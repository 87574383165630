export const Projects2022 = [
    {
        id: 'projects',
        category: 'Проекты',
        items: [
            {
                id: '-1',
                title: '',
                additionalClass: 'Пассажирский комплекс',
            },
            {
                id: '0',
                title: '',
                additionalClass: 'Технологическая независимость',
            },
            {
                id: '1',
                title:
                    'Разработка нового сценария покупки туристических поездов в В2В-платформе компании «Инновационная мобильность»',
            },
            {
                id: '2',
                title:
                    'Техноплазма (Новые плазмотроны для прецизионной резки металлов в заготовительных производствах РЖД)',
            },
            {
                id: '3',
                title:
                    'Специальные Технологии Контроля (Медицинский предрейсовый комплекс)',
            },
            {
                id: '4',
                title:
                    'ServiceVizor (Система онлайн-мониторинга и анализа выполнения работ ремонтным персоналом с применением дополненной реальности, возможностью взаимодействия с базой данных технологических карт, видео и аудиовызова мастера)',
            },
            {
                id: '5',
                title:
                    'Удаленный мониторинг и контроль устройств систем освещения пассажирских платформ (SmartUnity 4.0)',
            },
            {
                id: '6',
                title: 'Пятиосевой полимерный 3D принтер',
            },
            {
                id: '7',
                title: 'Teal HR',
            },
            {
                id: '8',
                title: 'Индустриальная акустика',
            },
            {
                id: '9',
                title: 'Пельмени в СТАКАНЕ – Пельмешки-РЖДешки',
            },
            {
                id: '10',
                title: 'Gyrokite',
            },
            {
                id: '11',
                title: 'Износостойкие минеральные покрытия MICOTECH',
            },
            {
                id: '12',
                title: 'Быстровозводимые пассажирские платформы',
            },
            {
                id: '13',
                title: 'MedPoint24',
            },
            {
                id: '14',
                title: 'ПРО100 УБОРКА',
            },
            {
                id: '15',
                title:
                    'Управление ремонтом (Комплексная система оценки технического состояния подвижного состава при использовании по назначению, техническом обслуживании и ремонте с единым центром обработки и хранения диагностической информации)',
            },
            {
                id: '16',
                title:
                    'Волоконно-оптическая система контроля деформаций земляного полотна железной дороги',
            },
            {
                id: '17',
                title:
                    'Электронная система согласования, хранения и управления конструкторско-технологическими данными о подвижном составе\n',
            },
            {
                id: '18',
                title: 'KOENIG ELEVATOR (Технологии и технические средства)',
            },
            {
                id: '19',
                title:
                    'Интеллектуальная система учета топливно-энергетических ресурсов предприятия (ИСУ ТЭР СИМ-Энерго)',
            },
            {
                id: '20',
                title: '',
            },
            {
                id: '21',
                title:
                    'Система освещения с удаленным управлением',
            },
            {
                id: '22',
                title: '',
            },
            {
                id: '23',
                title: 'ScanDerm',
            },
            {
                id: '24',
                title: '',
            },
            {
                id: '25',
                title: 'Автоматизированная система управления освещением пассажирских платформ Synergy',
            },
            {
                id: '26',
                title: '',
            },
            {
                id: '27',
                title:
                    'Предрейсовые медицинские осмотры с применением телемедицинских технологий',
            },
            {
                id: '28',
                title: '',
            },
        ],
    },
];
