import React, { FC } from 'react';
import './style.sass';

interface IProps {
    text: string;
}

const ResourceTitlePage: FC<IProps> = ({ text }) => {
    return <h2 className="resource-title-page">{text}</h2>;
};

export default ResourceTitlePage;
