import * as React from 'react';

const EstimateExpField = ({ source, record = {} }) => {
    const est = +record[source];
    if (isNaN(est)) {
        return <div className={`estimate-field-itog`}>—</div>;
    } else {
        return <div className={`estimate-field-itog`}>{est.toFixed(1)}</div>;
    }
};

export default EstimateExpField;
