import React from 'react';

import './style.sass';

const CriteriaItem = ({ item }) => (
    <div className="criteria__col" data-aos="zoom-in" data-aos-delay="800">
        <div className="criteria__item">
            <img src={item.img} className="criteria__icon" alt="" />
            <div
                className="criteria__text"
                dangerouslySetInnerHTML={{
                    __html: item.text,
                }}
            />
        </div>
    </div>
);

export default CriteriaItem;
