import React from 'react';
import SlideToggle from 'react-slide-toggle';
import iconOpen from '../../assets/img/svg/direction/open.svg';
import iconClose from '../../assets/img/svg/direction/close.svg';

const FAQItem = ({ item }) => {
    return (
        <div className="faq__col">
            <SlideToggle
                duration={250}
                collapsed
                render={({ toggle, setCollapsibleElement, progress }) => (
                    <div className="faq__item">
                        <div
                            className={`faq__question ${
                                progress !== 0 ? 'active' : null
                            }`}
                            onClick={toggle}
                        >
                            <div
                                className="faq__text"
                                dangerouslySetInnerHTML={{
                                    __html: item.question,
                                }}
                            />
                            <img
                                src={iconOpen}
                                alt=""
                                className="faq__arrow faq__arrow--open"
                            />
                            <img
                                src={iconClose}
                                alt=""
                                className="faq__arrow faq__arrow--close"
                            />
                        </div>
                        <div
                            className="faq__answer"
                            dangerouslySetInnerHTML={{
                                __html: item.answer,
                            }}
                            ref={setCollapsibleElement}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default FAQItem;
