import img1 from '../../assets/pic/schedule/schedule-01.svg';
import img2 from '../../assets/pic/schedule/schedule-02.svg';
import img3 from '../../assets/pic/schedule/schedule-03.svg';
import img4 from '../../assets/pic/schedule/schedule-04.svg';
import img5 from '../../assets/pic/schedule/schedule-05.svg';
import img6 from '../../assets/pic/schedule/schedule-06.svg';

import activeImg1 from '../../assets/pic/schedule/active-schedule-01.svg';
import activeImg2 from '../../assets/pic/schedule/active-schedule-02.svg';
import activeImg3 from '../../assets/pic/schedule/active-schedule-03.svg';
import activeImg4 from '../../assets/pic/schedule/active-schedule-04.svg';
import activeImg5 from '../../assets/pic/schedule/active-schedule-05.svg';
import activeImg6 from '../../assets/pic/schedule/active-schedule-06.svg';

export const ScheduleItems2022 = [
    {
        date: `17 августа 2022`,
        text: `Открытие акселератора`,
        img: img1,
        activeImg: activeImg1,
    },
    {
        date: `до 18 сентября 2022`,
        text: `Сбор заявок`,
        img: img2,
        activeImg: activeImg2,
    },
    {
        date: `до 28 сентября 2022`,
        text: `Экспертиза`,
        img: img3,
        activeImg: activeImg3,
    },
    {
        date: `14 октября 2022`,
        text: `Очный отбор проектов`,
        img: img4,
        activeImg: activeImg4,
    },
    {
        date: `25&nbsp;октября&nbsp;&mdash; 19&nbsp;декабря`,
        text: `Акселерационная программа`,
        img: img5,
        activeImg: activeImg5,
    },
    {
        date: `23 декабря 2022`,
        text: `DemoDay Санкт-Петербург`,
        img: img6,
        activeImg: activeImg6,
    },
];
export const ScheduleItems2022En = [
    {
        date: `August 17, 2022`,
        text: `Opening the accelerator`,
        img: img1,
        activeImg: activeImg1,
    },
    {
        date: `until September 18, 2022`,
        text: `Collection of applications`,
        img: img2,
        activeImg: activeImg2,
    },
    {
        date: `until September 28, 2022`,
        text: `Expertise`,
        img: img3,
        activeImg: activeImg3,
    },
    {
        date: `until October 14, 2022`,
        text: `Face-to-face selection of projects`,
        img: img4,
        activeImg: activeImg4,
    },
    {
        date: `October 25 &mdash; December 19`,
        text: `Acceleration program`,
        img: img5,
        activeImg: activeImg5,
    },
    {
        date: `December 23, 2022`,
        text: `DemoDay St. Petersburg`,
        img: img6,
        activeImg: activeImg6,
    },
];
