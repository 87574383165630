import React from 'react';
import ExpertsEstimate from '../../ExpertsEstimate';
import BodyResource from '../../BodyResource';
import './style.sass';

const ExpertiseReview = props => {
    return (
        <BodyResource requestEdit>
            <ExpertsEstimate {...props} />
        </BodyResource>
    );
};

export default ExpertiseReview;
