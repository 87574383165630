import * as React from 'react';
import { ReactComponent as DoneIcon } from '../../../../assets/img/svg/admin/done.svg';
import { ReactComponent as UnDoneIcon } from '../../../../assets/img/svg/admin/undone.svg';

const StatusExpertIcon = ({ source, record = {}, label }) => {
    const bizIcon = () => {
        return record.estimate_expert_type &&
            record.estimate_expert_type.find(
                item => item.TYPE_EXPERT === '3'
            ) &&
            record.estimate_expert_type.find(item => item.TYPE_EXPERT === '3')
                .UF_RATING.length ? (
            <DoneIcon />
        ) : (
            <UnDoneIcon />
        );
    };
    const techIcon = () => {
        return record.estimate_expert_type &&
            record.estimate_expert_type.find(
                item => item.TYPE_EXPERT === '4'
            ) &&
            record.estimate_expert_type.find(item => item.TYPE_EXPERT === '4')
                .UF_RATING.length ? (
            <DoneIcon />
        ) : (
            <UnDoneIcon />
        );
    };
    return (
        <div className={`estimate-field`}>
            {label === 'БЭ' ? bizIcon() : techIcon()}
        </div>
    );
};

export default StatusExpertIcon;
