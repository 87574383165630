import React from 'react';
import cn from './style.module.sass';
import { ExpertsItems2021, ExpertsItems2021En } from '../../data/2021/experts';

const CategoriesBlock = ({ langRU }) => {
    const data = langRU ? ExpertsItems2021 : ExpertsItems2021En;
    const sanitize = str => {
        return str.replace(/(<([^>]+)>)/gi, ' ');
    };
    return (
        <div className={cn.wrapper}>
            <div className="full-wrapper">
                {data.map(({ category, experts }) => (
                    <div key={category} className={cn.row}>
                        <div
                            className={cn.title}
                            dangerouslySetInnerHTML={{ __html: category }}
                        />
                        <div className={cn.innerRow}>
                            {experts.map(({ name, position }, index) => (
                                <div className={cn.col} key={name}>
                                    <div className={cn.item}>
                                        <div
                                            className={cn.name}
                                            dangerouslySetInnerHTML={{
                                                __html: sanitize(name),
                                            }}
                                        />

                                        <div
                                            className={cn.position}
                                            dangerouslySetInnerHTML={{
                                                __html: sanitize(position),
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoriesBlock;
