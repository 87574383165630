import React, { Component } from 'react';
import './style.sass';
import ControlCheckbox from '../../ui/ControlCheckbox';
import parse from 'html-react-parser';

interface IState {}

interface IProps {
    values: any;
    handleChange: (e: any) => void;
    handleBlur: any;
    errors: any;
    touched: any;
}

class RegistrationListCheckbox extends Component<IProps, IState> {
    textLabels = {
        agreement: (
            <>
                Я&nbsp;согласен с&nbsp;условиями
                <a target="_blank" rel="noopener noreferrer" href="/agreement">
                    {' '}
                    пользовательского соглашения
                </a>{' '}
                и{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/personal-data"
                >
                    {' '}
                    обработки персональных данных
                </a>
            </>
        ),
        policyPersonal: (
            <>
                Я согласен с
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/personal-data-vnijt"
                >
                    {' '}
                    политикой обработки персональных данных
                </a>{' '}
                и{' '}
                <a target="_blank" rel="noopener noreferrer" href="/cookies">
                    {' '}
                    использования cookies-файлов
                </a>
            </>
        ),
        mailing: parse(
            'Хочу получать рассылку с&nbsp;полезными советами от&nbsp;GenerationS'
        ),
    };

    render() {
        return (
            <div className="registration-form-content__checkbox-list">
                <ControlCheckbox
                    formikProps={this.props}
                    name="AGREEMENT"
                    label={this.textLabels.agreement}
                />
                <ControlCheckbox
                    formikProps={this.props}
                    name="POLICY_PERSONAL"
                    label={this.textLabels.policyPersonal}
                />
            </div>
        );
    }
}

export default RegistrationListCheckbox;
