import React, { useState, useEffect } from 'react';
import ModalWrap from '../ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import Statuses from '../../../../data/statusesId.json';
import { Notification, useNotify, useRefresh } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const FCModal = ({ isOpen, toggle, id }) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [funcCustomer, setFuncCustomer] = React.useState(null);

    const handleChange = event => {
        const result = funcCustomer.map(item => {
            if (+item.ID === +event.target.name) {
                return { ...item, checked: event.target.checked };
            } else {
                return item;
            }
        });
        setFuncCustomer(result);
    };

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.fzlisttoapp`,

                LKRepository.generateFormData({
                    APP_ID: id,
                })
            )
            .then(response => {
                setFuncCustomer(response.data.data);
            })
            .catch(err => console.log(err));
    }, []);

    const handleSaveFC = () => {
        // if (funcCustomer.some(i => i.checked)) {
        //     const arrFzIds = funcCustomer
        //         .map(item => {
        //             if (item.checked) {
        //                 return item.ID;
        //             }
        //         })
        //         .filter(i => i);
        //
        //     setError('');
        //
        // } else {
        //     setError('Выберите ФЗ');
        // }
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.fztoappglue
`,
                LKRepository.generateFormData({
                    APP_ID: id,
                    FZ_SELECT_STATE: JSON.stringify(funcCustomer),
                })
            )
            .then(response => {
                if (response.data.data === true) {
                    setSuccess('ФЗ назначены');
                    refresh();
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <ModalWrap isOpen={isOpen} toggleModal={toggle}>
            <div
                className={`expert-modal ${
                    success ? `expert-modal--success` : ``
                }`}
            >
                {!success ? (
                    <>
                        <div className="expert-modal__title">
                            Назначить функционального заказчика:
                        </div>
                        <div className="expert-modal__row">
                            <div className="expert-modal__col-12">
                                <FormControl
                                    required
                                    error={error}
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <FormGroup>
                                        {funcCustomer?.map(item => {
                                            return (
                                                <FormControlLabel
                                                    key={item.ID}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                item.checked
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            name={item.ID}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                        {error && (
                            <div className="expert-modal__error">
                                <span>{error}</span>
                            </div>
                        )}
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => handleSaveFC()}
                            >
                                Сохранить
                            </RedButton>

                            <button
                                className="expert-modal__cancel"
                                onClick={() => {
                                    toggle(false);
                                }}
                            >
                                Отменить
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="expert-modal__success">{success}</div>
                        <div className="expert-modal__bottom">
                            <RedButton
                                size="sm"
                                type="button"
                                onClick={() => {
                                    toggle(false);
                                }}
                            >
                                Закрыть
                            </RedButton>
                        </div>
                    </>
                )}
            </div>
        </ModalWrap>
    );
};

export default FCModal;
