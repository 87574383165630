import partners1 from 'assets/pic/partners/partner-01.svg';
import partners5 from 'assets/pic/partners/partner-05.svg';
import partners7 from 'assets/pic/partners/partner-07.jpg';
import partners9 from 'assets/pic/partners/partner-09.jpg';
import partners10 from 'assets/pic/partners/partner-10.png';
import partnerPochta from 'assets/pic/partners/pochta.png';
import partners_pek from 'assets/pic/partners/pek.PNG';
import partners_dpd from 'assets/pic/partners/dpd.png';
import partners_zavod from 'assets/pic/partners/kirovsky_zavod.png';
import partners_novotrans from 'assets/pic/partners/novotrans.png';
import leader from 'assets/pic/partners/leader.svg';
import partners_new_industry from 'assets/pic/partners/newindustry.png';
import partners_severstal from 'assets/pic/partners/severstal.png';
import partners_kirov from 'assets/pic/partners/kirov.png';
import fesco from 'assets/pic/partners/fesco.png';
import kamaflow from 'assets/pic/partners/kamaflow.jpg';
import bring from 'assets/pic/partners/bring.png';
import spb from 'assets/pic/partners/spb.png';

export const PartnersItems2021 = [
    { img: partners1, link: 'www.pgkweb.ru', href: 'https://pgkweb.ru/' },
    { img: fesco, link: 'www.fesco.ru/ru', href: 'https://www.fesco.ru/ru/' },
    { img: partners5, link: 'www.trcont.com', href: 'https://trcont.com/' },
    {
        img: partners7,
        link: 'www.tmholding.ru',
        href: 'https://www.tmholding.ru/',
    },
    {
        img: partners9,
        link: 'trans.gazprom.ru',
        href: 'https://trans.gazprom.ru/',
    },
    {
        img: partners_novotrans,
        link: 'www.novotrans.com/',
        href: 'https://novotrans.com/',
    },
    { img: partners_pek, link: 'www.pecom.ru', href: 'https://pecom.ru/' },
    {
        img: partnerPochta,
        link: 'www.pochta.ru',
        href: 'https://www.pochta.ru/',
    },
    { img: partners_dpd, link: 'www.dpd.ru', href: 'https://www.dpd.ru/' },
    {
        img: partners_severstal,
        link: 'www.severstal.com',
        href: 'https://www.severstal.com/',
    },
    {
        img: partners_kirov,
        link: 'www.kirovgroup.vc',
        href: 'http://kirovgroup.vc/',
    },
    {
        img: partners10,
        link: 'www.mosinnov.ru',
        href: 'https://www.mosinnov.ru/',
    },
    {
        img: partners_zavod,
        link: 'www.kzgroup.ru',
        href: 'https://kzgroup.ru/',
    },
    {
        img: partners_new_industry,
        link: 'www.newindustry.vc',
        href: 'https://newindustry.vc/',
    },
    {
        img: leader,
        link: 'leader-invest.ru',
        href: 'https://leader-invest.ru/',
    },
    {
        img: kamaflow,
        link: 'kamaflow.com',
        href: 'https://kamaflow.com',
    },
    {
        img: bring,
        link: 'bringingtogether.ru',
        href: 'https://bringingtogether.ru',
    },
    {
        img: spb,
        link: 'spb.tpprf.ru',
        href: 'https://spb.tpprf.ru/ru/',
    },
];
