import React, { Component } from 'react';
import './style.sass';
import HeaderUserMenu from '../HeaderUserMenu';
import HeaderAdminLeft from '../HeaderAdminLeft';

class Header extends Component {
    render() {
        const { notAuthorized } = this.props;

        return (
            <div className="header-admin">
                <HeaderAdminLeft />
                {!notAuthorized && <HeaderUserMenu />}
            </div>
        );
    }
}

export default Header;
