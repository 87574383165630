import React, { FC } from 'react';
// @ts-ignore
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles({
    drawerPaper: {
        marginTop: 10,
    },
});

const CustomSidebar: FC<any> = props => {
    const classes = useSidebarStyles();
    return <Sidebar classes={classes} {...props} />;
};

export default CustomSidebar;
