import { nanoid } from 'nanoid';

import slider_2_0 from 'assets/pic/news/sliders/23-12/37.jpg';
import slider_2_1 from 'assets/pic/news/sliders/23-12/1.jpg';
import slider_2_2 from 'assets/pic/news/sliders/23-12/2.jpg';
import slider_2_3 from 'assets/pic/news/sliders/23-12/3.jpg';
import slider_2_4 from 'assets/pic/news/sliders/23-12/4.jpg';
import slider_2_5 from 'assets/pic/news/sliders/23-12/5.jpg';
import slider_2_6 from 'assets/pic/news/sliders/23-12/6.jpg';
import slider_2_7 from 'assets/pic/news/sliders/23-12/7.jpg';
import slider_2_8 from 'assets/pic/news/sliders/23-12/8.jpg';
import slider_2_9 from 'assets/pic/news/sliders/23-12/9.jpg';
import slider_2_10 from 'assets/pic/news/sliders/23-12/10.jpg';
import slider_2_11 from 'assets/pic/news/sliders/23-12/11.jpg';
import slider_2_12 from 'assets/pic/news/sliders/23-12/12.jpg';
import slider_2_13 from 'assets/pic/news/sliders/23-12/13.jpg';
import slider_2_14 from 'assets/pic/news/sliders/23-12/14.jpg';
import slider_2_15 from 'assets/pic/news/sliders/23-12/15.jpg';
import slider_2_16 from 'assets/pic/news/sliders/23-12/16.jpg';
import slider_2_17 from 'assets/pic/news/sliders/23-12/17.jpg';
import slider_2_18 from 'assets/pic/news/sliders/23-12/18.jpg';
import slider_2_19 from 'assets/pic/news/sliders/23-12/19.jpg';
import slider_2_20 from 'assets/pic/news/sliders/23-12/20.jpg';
import slider_2_21 from 'assets/pic/news/sliders/23-12/21.jpg';
import slider_2_22 from 'assets/pic/news/sliders/23-12/22.jpg';
import slider_2_23 from 'assets/pic/news/sliders/23-12/23.jpg';
import slider_2_24 from 'assets/pic/news/sliders/23-12/24.jpg';
import slider_2_25 from 'assets/pic/news/sliders/23-12/25.jpg';
import slider_2_26 from 'assets/pic/news/sliders/23-12/26.jpg';
import slider_2_27 from 'assets/pic/news/sliders/23-12/27.jpg';
import slider_2_28 from 'assets/pic/news/sliders/23-12/28.jpg';
import slider_2_29 from 'assets/pic/news/sliders/23-12/29.jpg';
import slider_2_30 from 'assets/pic/news/sliders/23-12/30.jpg';
import slider_2_31 from 'assets/pic/news/sliders/23-12/31.jpg';
import slider_2_32 from 'assets/pic/news/sliders/23-12/32.jpg';
import slider_2_33 from 'assets/pic/news/sliders/23-12/33.jpg';
import slider_2_34 from 'assets/pic/news/sliders/23-12/34.jpg';
import slider_2_35 from 'assets/pic/news/sliders/23-12/35.jpg';
import slider_2_36 from 'assets/pic/news/sliders/23-12/36.jpg';
import vinogradov from 'assets/pic/news/vinogradov.jpg';
import news_2022_17_08 from 'assets/pic/news/news_2022_17_08.jpg';
import news_2022_18_08 from 'assets/pic/news/news_2022_18_08.jpeg';
import rzd2022videoimage from 'assets/pic/news/rzd2022videoimage.png';
import news_2022_24_08 from 'assets/pic/news/news_2022_24_08.jpg';
import news_2022_01_11 from 'assets/pic/news/news_2022_01_11.jpeg';
import news_2022_16_11 from 'assets/pic/news/news_2022_16_11.jpg';
import news_2022_11_18_13_30 from 'assets/pic/news/2022-11-18_13-30.png';
import item_16_11_2022 from 'assets/pic/news/item-16-11-2022.jpg';
import item_16_11_2022_1 from 'assets/pic/news/item_16_11_2022_1.jpeg';
import item_16_11_2022_2 from 'assets/pic/news/item_16_11_2022_2.jpeg';
import item_16_11_2022_3 from 'assets/pic/news/item_16_11_2022_3.jpeg';
import item_16_11_2022_4 from 'assets/pic/news/item_16_11_2022_4.jpeg';
import generations_image from 'assets/pic/news/generations_image.jpg';
import item_vkontakte from 'assets/pic/news/item_vkontakte.png';
import prodvizhen from 'assets/pic/news/prodvizhen.png';
import news22_12_2022 from 'assets/pic/news/sliders/news22-12-2022.jpg';
import news26_12_2022 from 'assets/pic/news/sliders/news25-12-2022.jpeg';
import news25_12_2022 from 'assets/pic/news/sliders/news26-12-2022.jpg';
//Картинки Новости - для модалки
import modalImg from 'assets/pic/news/news-bg.jpg';

import news_2021_23_12_3 from 'assets/pic/news/2021/23-13.jpg';
import news_2021_27_12 from 'assets/pic/news/2021/27-12.jpg';

//Видео Новости статика

//Картинки Новости - для модалки иконки
import twIcon from 'assets/pic/news/tw-icon.svg';
import fbIcon from 'assets/pic/news/fb-icon.svg';

export const NewsItems2022 = [
    {
        id: 'news26_12_2022',
        img: news26_12_2022,
        subtitle: `26 декабря 2022`,
        text: 'Дизайн-мышление, мини-акселераторы и гемба-тур. На «PRO//Движение.Инновации» участники освоили новые форматы работы',
        textBody: [
            {
                type: 'title',
                content: 'Все самое интересное с площадки конференции в репортаже «Гудка»',
            },
            {
                type: 'text',
                content: 'Конференция «PRO//Движение.Инновации», как уже сообщал «Гудок», прошла в Санкт-Петербурге 22–23 декабря. На коммуникационной площадке, организованной Издательским домом «Гудок», главные инженеры дирекций ОАО «РЖД» и железных дорог сети, начальники центров инновационного развития, а также представители бизнеса и научных организаций обсудили вопросы поддержки и развития инновационной деятельности компании.',
            },
            {
                type: 'text',
                content: 'Программа двухдневной конференции была насыщена мероприятиями, нацеленными на эффективное взаимодействие участников: прошли мини-акселераторы проектов по направлениям «Экология», «Культура и уровень жизни» и «Университетское предпринимательство», панельные дискуссии, стратегические сессии, круглые столы.',
            },
            {
                type: 'text',
                content: 'Например, в ходе мини-акселератора «Экология» были выбраны три лучших проекта из десяти представленных: установки для промышленного пылеподавления; очистка специальным биопрепаратом щебёночного балласта пути от нефтепродуктов; фотоэлектрические фасадные системы как альтернативные источники электроэнергии для промышленных и гражданских сооружений.',
            },
            {
                type: 'text',
                content: 'Впервые в рамках конференции был проведён гемба-тур «Интегратор инноваций» (так обозначается подход, характерный для японской управленческой практики кайдзен, согласно которому для полноценного понимания ситуации необходимо прийти на гемба – место выполнения рабочего процесса, собрать факты и непосредственно на месте принять решение).',
            },
            {
                type: 'text',
                content: 'Посетив универсальный городской терминал Санкт-Петербург-Финляндский, участники конференции представили заместителю генерального директора – главному инженеру ОАО «РЖД» Анатолию Храмцову план реинжиниринга процессов подразделения. ',
            },
            {
                type: 'text',
                content: 'Как отметили представители бизнеса, сейчас более актуально и целесообразно создание цифровой модели для последующей всесторонней аналитики, а не оснащение техники и объектов терминала всевозможными дополнительными датчиками. Это предложение было решено реализовать, чтобы, определив полученный эффект, понять, стоит ли тиражировать его на сеть.',
            },
            {
                type: 'text',
                content: 'Одними из основных мероприятий заключительного дня конференции стали демодень акселератора ОАО «РЖД» по направлениям «Пассажирский комплекс» и «Технологическая независимость», защита перед экспертами планов развития железных дорог (дизайн-мышление «Новые подходы и задачи для повышения эффективности инновационных процессов») и пленарное заседание «Синергия инноваций для государства, бизнеса и людей: как приблизить будущее?». ',
            },
            {
                type: 'text',
                content: 'Так, в рамках дизайн-мышления главные инженеры железных дорог и дирекций разделились на четыре команды и сформировали несколько предложений для повышения эффективности взаимодействия с внешним контуром, функциональными заказчиками и внутри компании, например между региональными центрами инновационного развития и региональными центрами корпоративного управления. ',
            },
            {
                type: 'text',
                content: 'Участники предложили разработать механизм возврата доли экономического эффекта от реализации проектов для развития будущих проектов, актуализировать механизм принятия решений по финансированию кроссфункциональных проектов, сформировать систему обучения и актуализации знаний для технических новаторов. Эти предложения направят в Центр инновационного развития на рассмотрение, уточнение и в перспективе реализации для дальнейшего улучшения инновационной деятельности в компании.',
            },
            {
                type: 'text',
                content: 'На пленарном заседании «Синергия инноваций для государства, бизнеса и людей: как приблизить будущее?» Анатолий Храмцов, начальник Октябрьской дороги Виктор Голомолзин, президент Союза промышленников и предпринимателей Ленинградской области Александр Габитов, общественный представитель Агентства стратегических инициатив в технологическом секторе Кирилл Еруков, руководитель консалтингового направления компании «Иннопрактика» Виталий Сараев и министр промышленности и торговли Новгородской области Иван Чекмарёв обсудили, как развивается инновационная деятельность в транспортной отрасли и каковы её векторы в связи с санкционным давлением. ',
            },
            {
                type: 'text',
                content: 'Виктор Голомолзин отметил, что из-за санкций образовалась уникальная возможность для создания инновационных продуктов и выхода с ними на рынок, в том числе на рынок логистических услуг. Так, железнодорожный транспорт уже сыграл ключевую роль в плане переориентации грузопотоков. ',
            },
            {
                type: 'text',
                content: '«В коридоре Запад – Восток мы обкатали два казахстанско-китайских пункта пропуска (Достык – Алашанькоу и Алтынколь – Хоргос), массово перераспределяя туда грузопотоки. Начиная с прошлого года мы стали «раскатывать» коридор Север – Юг. Грузопоток по его западной ветви, через черноморские порты в сторону Турции и далее, вырос в 20 раз. Перспективным, на наш взгляд, является восточный коридор – через Россию, Туркменистан, Иран и далее по морю в сторону Индии и Юго-Восточной Азии, – и мы усиленно по нему работаем, – сказал начальник ОЖД. – Благодаря инновационным решениям в сфере грузовой логистики, которые мы оперативно реализовали на Северо-Западе, грузооборот в целом по Октябрьской магистрали остался на прежнем уровне к прошлому году». ',
            },
            {
                type: 'text',
                content: 'По словам Анатолия Храмцова, холдинг – это социально-экономическое связующее звено общества: ежегодно ОАО «РЖД» перевозит 1 млрд пассажиров и огромное количество грузов. «Без инноваций мы не дадим обществу то, что ему нужно, – бесшовный транспортный продукт. Мы можем обойтись без инноваций и лишь строить новые пути, а можем внедрять новшества. Например, виртуальная автосцепка позволяет увеличить пропускную способность на 10%. Такой способ управления движением поездов уникален», – отметил заместитель генерального директора – главный инженер ОАО «РЖД». ',
            },
            {
                type: 'text',
                content: 'Анатолий Храмцов подчеркнул, что сегодня любая отрасль, не только транспортная, нуждается в новшествах. И главное тут не столько сами разработки и проекты, сколько способность иначе мыслить, иначе взглянуть на процессы: нужны новаторские прорывные идеи. «Нашу реальность, наше будущее, наше сознание определяет инновационное мышление», – добавил он.',
            },
            {
                type: 'text',
                content: '<a href="https://gudok.ru/content/science_education/1623196/">Ссылка на источник</a>',
            }
        ]
    },
    {
        id: 'news25_12_2022',
        img: news25_12_2022,
        subtitle: `25 декабря 2022`,
        text: 'Семь стартапов стали победителями корпоративной акселерационной программы ОАО «РЖД»',
        textBody: [
            {
                type: 'title',
                content: 'Командам предстоит провести испытания, по результатам которых предстоит тиражирование продуктов',
            },
            {
                type: 'text',
                content: 'На конференции «PRO//Движение.Инновации» прошёл финал четвёртой корпоративной акселерационной программы ОАО «РЖД». Победителями стали семь перспективных стартапов. ',
            },
            {
                type: 'text',
                content: 'В этом году акселерационная программа ОАО «РЖД», организованная АО «ВНИИЖТ», проходила по двум направлениям: «Пассажирские перевозки» и «Технологическая независимость». В ходе экспертного отбора из более чем 450 поступивших заявок в финал прошли 12 проектов. ',
            },
            {
                type: 'text',
                content: '«В рамках акселерационной программы мы подбираем проекты в соответствии с технологическим фокусом, который нам обозначили функциональные заказчики в лице дирекций компании. В этом году рассматривались очень разнообразные по своей направленности стартапы. От инноваций в сфере медицины до 3D-принтера для производства запчастей, – рассказал начальник центра «Корпоративный акселератор стартап-проектов ОАО «РЖД» Роман Кошелев. – При этом мы не ограничиваем стартапы только заявленными направлениями. Если команда уверена в своём проекте, она подаёт заявку и мы её рассматриваем. Помимо ОАО «РЖД», у корпоративного акселератора есть широкий пул партнёров, которые также заинтересованы в качественных и эффективных проектах». ',
            },
            {
                type: 'text',
                content: 'Победителями четвёртой акселерационной программы по направлению «Пассажирский комплекс» стали проекты, посвящённые автоматизации систем освещения, новому сценарию покупки билетов на туристические поезда, а также геймифицированный сервис мотивации персонала и медицинский предрейсовый комплекс для проведения дистанционных медосмотров сотрудников. ',
            },
            {
                type: 'text',
                content: 'В части «Технологической независимости» лучшими были признаны пятиосевой полимерный 3D-принтер и быстровозводимые пассажирские платформы.\n',
            },
            {
                type: 'text',
                content: 'Награды финалистам вручил заместитель генерального директора – главный инженер ОАО «РЖД» Анатолий Храмцов.',
            },
            {
                type: 'text',
                content: '«С командами-победителями подпишут дорожные карты. В них определят дальнейшие шаги по проведению пилотных испытаний, на основе которых будет приниматься решение о тиражировании проекта», – подытожил Роман Кошелев. ',
            },
            {
                type: 'text',
                content: 'Четвёртая акселерационная программа стала заключительной для корпоративного акселератора. В следующем году он трансформируется в Транспортный акселератор РЖД, который объединит лидеров отрасли для совместного развития инновационных проектов.',
            },
            {
                type: 'text',
                content: '<a href="https://gudok.ru/content/science_education/1623166/">Ссылка на источник</a>',
            }
        ]
    },
    {
        id: 'news22_12_2022',
        img: news22_12_2022,
        subtitle: `22 декабря 2022`,
        text: 'Инновации – в жизнь. Внедрение новых решений – не дань моде, а вопрос выживания',
        textBody: [
            {
                type: 'title',
                content: 'Все самое важное с площадки конференции «PRO//Движение. Инновации»',
            },
            {
                type: 'text',
                content: 'Конференция «PRO//Движение. Инновации», организованная Издательским домом «Гудок», начала работу в Санкт-Петербурге. В течение двух дней более 200 участников – работники ОАО «РЖД», главные инженеры и руководители предприятий и дирекций, эксперты, представители промышленных и научных организаций и бизнеса – обсудят вопросы поддержки и развития инновационной деятельности компании.',
            },
            {
                type: 'text',
                content: 'По словам начальника Центра инновационного развития ОАО «РЖД» Дмитрия Вербова, конференция «PRO//Движение. Инновации» фактически стала и первым слётом начальников инновационных центров дорог сети.',
            },
            {
                type: 'text',
                content: 'В ходе круглых столов, мини-акселераторов, панельных дискуссий участники конференции обсуждают актуальные вопросы развития инновационной деятельности, разрабатывают предложения по внедрению новшеств в производственные и технологические процессы транспортной отрасли, а также решения, которые сократят время от появления идей до их реализации.',
            },
            {
                type: 'text',
                content: 'Основные векторы развития инновационной деятельности – это мотивация работников холдинга участвовать в техническом творчестве и меры их поддержки, улучшение взаимодействия с функциональными заказчиками, которые не всегда готовы идти на риск и внедрять новшества в производство, повышение привлекательности новаторской деятельности для бизнеса.',
            },
            {
                type: 'text',
                content: 'Приоритетными для внедрения инноваций являются направления устойчивого развития ESG: экология, качество и уровень жизни, система управления, отметил на панельной дискуссии «Экосистема инноваций РЖД. Перезагрузка» Дмитрий Вербов. Так, из-за изменения климата зимы на Восточном полигоне становятся более снежными, и использование на железных дорогах новой более высокопроизводительной техники снизит в перспективе затраты на снегоборьбу и повысит эффективность работы. Кроме того, внедряемые новшества автоматизируют процессы и облегчают тяжёлый физический труд, позволяют вывести людей из опасных зон – а безопасность является главным приоритетом ОАО «РЖД». ',
            },
            {
                type: 'text',
                content: 'Если сейчас оптимальная выработка в суточное «окно» составляет 1,5 км пути, то к 2030 году этот показатель нужно увеличить до 5 км в сутки, подчеркнул в своём выступлении главный инженер Центральной дирекции по ремонту пути Виктор Шамраев. Это необходимо, чтобы обеспечить растущую провозную способность при меньшей занятости инфраструктуры во время ремонта. Повысить производительность более чем в три раза возможно благодаря высокотехнологичной путевой технике: новым укладочным кранам, щебнеочистительным и выправочным комплексам.',
            },
            {
                type: 'text',
                content: 'Инновации – это не дань моде, а вопрос выживания в быстро меняющихся условиях, уверен начальник Департамента по организации, оплате и мотивации труда ОАО «РЖД» Владимир Никитин: только с помощью применения новшеств компания и отрасль смогут соответствовать запросам рынка. Адаптация железных дорог к изменениям и внедрение лучших практик в ежедневные процессы улучшат качество услуг и обеспечат рост экономики страны. ',
            },
            {
                type: 'text',
                content: 'Инновации играют ключевую роль в обеспечении роста производительности труда, отметил Владимир Никитин. Компания мотивирует работников заниматься техническим творчеством: к примеру, поощрение за внедрение инновации составляет 5–10% от суммы экономического эффекта от реализации предложения; за внедрение проектов бережливого производства 30% от суммы экономии идёт на премирование участников, а 20% аккумулируется в фонде предприятия на дальнейшее развитие. Эффект от внедрения бережливых проектов в 2022 году составил 1 млрд руб., соответственно 300 млн направлено на премирование сотрудников. За 11 месяцев 2022 года рацпредложения внесли 289 тыс. работников компании, в общей сложности им выплачено 200 млн руб.',
            },
            {
                type: 'text',
                content: 'Сегодня участники конференции представят свои проекты заместителю генерального директора – главному инженеру ОАО «РЖД» Анатолию Храмцову. На конференции планируется подписание соглашений о взаимодействии ОАО «РЖД» с научными и промышленными организациями, среди которых Всероссийское общество изобретателей и рационализаторов, АО «ВНИИЖТ», ООО «Управляющая компания РВК», ООО «Газпромтранс», ООО «ЛОГОПЕР».',
            },
            {
                type: 'text',
                content: '<a href="https://gudok.ru/content/science_education/1623060/">Ссылка на источник</a>',
            },
        ]
    },
    {
      id: 'item-22-12-2022',
      img: prodvizhen,
      subtitle: `22-23 декабря 2022`,
        text: 'Конференция «PRO//Движение.Инновации» <br/>Центр Деловых Связей Октябрьской железной дороги',
        textBody: [
            {
                type: 'title',
                content: 'Конференция «PRO//Движение.Инновации» Центр Деловых Связей Октябрьской железной дороги',
            },
            {
                type: 'text',
                content: 'Как сегодня экосистема инноваций холдинга «РЖД» позволяет реагировать на внешние и внутренние вызовы и обеспечивать достижение стратегических приоритетов развития Компании? Каков вектор развития экосистемы с учетом этих приоритетов?',
            },
            {
                type: 'text',
                content: 'Приоритеты развития Компании в области инновационного развития. Стратегия цифровой трансформации ОАО «РЖД»: новые задачи ускоренного развития.',
            },
            {
                type: 'text',
                content: 'Среднесрочные и долгосрочные экономические эффекты от экосистемы инноваций холдинга «РЖД» и региональных инновационных площадок. Ключевые показатели эффективности ОАО «РЖД», в том числе от экосистемы инноваций.',
            },
            {
                type: 'text',
                content: 'Перспективы и направления развития экосистем, образ - модель будущего региональных инновационных площадок, их цели и задачи на среднесрочную перспективу.',
            },
            {
                type: 'text',
                content: 'Взаимодействие функциональных заказчиков, инновационной площадки, власти и бизнеса: что нужно изменить?',
            },
            {
                type: 'text',
                content: 'Существующие сдерживающие факторы при внедрении инновационных проектов и технологий: технологические, организационные, мотивационные.',
            },
            {
                type: 'text',
                content: 'Инвестиционные ниши и нужные стартапы: как выстраивать диалог между властью и отраслевыми заказчиками?',
            },
            {
                type: 'text',
                content: 'Механизмы ответственного финансировании проектов.',
            },
            {
                type: 'text',
                content: 'Кластеры и технопарки как драйверы развития инновационного предпринимательства.',
            },
            {
                type: 'text',
                content: 'Государственная и частная поддержка проектов. Необходимые меры государственной поддержки для отечественных компаний: дополнительное стимулирование и ликвидация барьеров.',
            },
            {
                type: 'text',
                content: 'Модели взаимодействия с властью при реализации программ развития регионов. <a href="https://railwayforum.ru/events/program.php?ELEMENT_ID=12287">Ссылка на источник</a>',
            }
        ]
    },
    {
      id: 'item_16_11_2022_4',
      img: item_16_11_2022_4,
      subtitle: `17 ноября 2022`,
      text: 'GenerationS, РЖД и ВНИИЖТ будут развивать транспортные технологии вместе с лидерами транспортной отрасли',
      textBody: [
          {
              type: 'text',
              content: `<img src=${generations_image} alt="generationS"/>`,
          },
          {
              type: 'text',
              content: '17 ноября 2022 года',
          },
          {
            type: 'text',
            content: 'ПРЕСС-РЕЛИЗ'
          },
          {
              type: 'title',
              content: 'GenerationS, РЖД и ВНИИЖТ будут развивать транспортные технологии вместе с лидерами транспортной отрасли',
          },
          {
              type: 'text',
              content: '16 ноября 2022 года в рамках XVI Международного Форума и Выставки «Транспорт России» РВК (корпоративный акселератор GenerationS), РЖД и ВНИИЖТ подписали четырехсторонние соглашения с «АВТОВАЗ», «НИИЦ ЖДВ» Минобороны РФ, «НефтеТрансСервис», «ТрансКонтейнер», «ФЕСКО Интегрированный транспорт» и Фондом «Транспортные инновации Москвы». Стороны планируют развивать взаимовыгодные отношения в области сквозных инновационных проектов.',
          },
          {
              type: 'text',
              content: 'Крупнейшие компании отрасли объединят опыт и экспертизу для создания Транспортного акселератора — многоотраслевой программы развития инноваций в транспортной отрасли. Цель акселератора — поддержка и внедрение технологических решений в транспортной отрасли через синергию корпоративных и технических компетенций компаний-лидеров на постоянной основе.',
          },
          {
              type: 'text',
              content: 'Сотрудничество планируется сразу по нескольким направлениям:',
          },
          {
              type: 'text',
              content: '• Обмен информацией о перспективных инновационных проектах в интересах развития Сторон;',
          },
          {
              type: 'text',
              content: '• Оценка потенциала и возможных областей развития инновационных проектов;',
          },
          {
              type: 'text',
              content: '• Совместное формирование трендов инновационного развития и гарантированного спроса на инновационную продукцию и технологии для решения задач развития транспортной отрасли;',
          },
          {
              type: 'text',
              content: '• Объединение и расширение направлений поиска инновационных проектов и повышение уровня их технологической и инвестиционной привлекательности, и формирование совместного технологического фокуса (том числе в области технологического суверенитета);',
          },
          {
              type: 'text',
              content: '• Организация совместного пилотирования и внедрения сквозных инновационных технологий, в том числе для обеспечения синергии мультимодальной логистики транспорта;',
          },
          {
              type: 'text',
              content: '• Проведение совместных акселерационных программ;',
          },
          {
              type: 'text',
              content: '• Выстраивания межотраслевой профильной инновационной экосистемы транспортной отрасли.',
          },
          {
              type: 'text',
              content: 'GenerationS в рамках соглашения планирует оказывать полную консультационную поддержку при реализации акселератора на следующих этапах:',
          },
          {
              type: 'text',
              content: '• Формирование технологического фокуса (направления поиска технологических проектов);',
          },
          {
              type: 'text',
              content: '• Поиск технологических проектов (скаутинг воронки);',
          },
          {
              type: 'text',
              content: '• Экспертные сессии по отбору проектов;',
          },
          {
              type: 'text',
              content: '• Сопровождение пилотных проектов;',
          },
          {
              type: 'text',
              content: '• Поддержка формирования технологических команд внутри основных компаний-партнёров;',
          },
          {
              type: 'text',
              content: '• Возможность приоритетного инвестиционного участия партнёров при рассмотрении воронки.',
          },
          {
              type: 'text',
              content: '<strong>Екатерина Петрова</strong>, директор GenerationS: «Новые вызовы, единые цели и задачи, движение вперёд – вот, что объединяет партнёров акселератора в желании развивать инновации. Совместное развитие, технологическая поддержка, коммерциализация и масштабирование – ключевые ценности, которые были заложены в концепцию проекта. Благодаря синергии партнёров, транспортный акселератор сможет стать полноценным инновационным Хабом, который объединит технологии, государство и бизнес для формирования транспортной индустрии будущего в России.»',
          },
          {
              type: 'text',
              content: '<strong>Анатолий Храмцов</strong>, заместитель генерального директора – главный инженер ОАО «РЖД»: «Важным условием развития компании является наращивание спроса на инновационную продукцию и услуги. Финансирование текущего портфеля из более чем 160 проектов в рамках Комплексной программы инновационного развития холдинга «РЖД» в 2022 году составляет порядка 140 млрд рублей. В их числе такие знаковые, известные всем проекты как московские центральные диаметры, квантовые коммуникации, беспилотные системы мониторинга инфраструктуры».',
          },
          {
              type: 'text',
              content: '<strong>Максим Соколов</strong>, Президент АО «АВТОВАЗ»: «В текущее время вызовов, стоящих перед отраслями экономики, нам жизненно важно развивать инновационные технологии, которые обеспечат устойчивый тренд роста в будущем.»',
          },
          {
              type: 'text',
              content: 'Дмитрий Игнатюк, директор по эксплуатации АО «НефтеТрансСервис»: «Развитие инновационных проектов, ориентированных на поиск технологических оптимумов операторской среды всегда было одним из приоритетных направлений группы компаний «НефтеТрансСервис». Наличие у нас собственных производственных площадок во взаимной увязке с операторской деятельностью уже позволило апробировать ряд технологических разработок, реализовав проект «Умное депо». Мы с пристальным интересом следим за развитием транспортного отраслевого акселератора ОАО «РЖД», функционирующего на площадке АО «ВНИИЖТ». Войдя в данный проект, мы планируем укрепить свои позиции в реализации стратегии информационного развития, как за счет создания дополнительной возможности представить внутренние разработки ГК «НТС», так и за счет возможности внедрения на наших предприятиях принципиально новых технологических решений».',
          },
          {
              type: 'text',
              content: '<strong>Иван Юнин</strong>, генеральный директор Фонда «Транспортные инновации Москвы»: «Поиск и внедрение инноваций в такую сложную и многоуровневую структуру, как «Московский транспорт», — непростая задача. Поэтому в конце 2021 года в экосистеме Департамента транспорта г. Москвы заработала новая организация - Фонд «Транспортные инновации Москвы». Мы занимаемся поиском, оценкой, тестированием и финансированием перспективных технологий в сфере транспорта, мобильности и умного города. Мы плотно работаем с корпоративным сектором и выстраиваем трехстороннее сотрудничество в формате город-корпорация и стартапы. Мы открыты к сотрудничеству с потенциальными партнерами. И я надеюсь, что выпускники нового акселератора РЖД смогут стать портфельными компаниями нашего Фонда.»',
          },
          {
              type: 'text',
              content: 'Итогом Транспортного акселератора станет единая воронка технологических проектов, которая пройдет корпоративную техническую экспертизу и даст возможность запустить пилотные проекты со стратегическими партнерами в отрасли. С учётом широкой сети партнёрств, ожидается высокая конверсия пилотных проектов и коммерческое сотрудничество, включая возможность рассматривать полученную воронку и в качестве возможных инвестиций со стороны фондов РВК.',
          },
          {
              type: 'text',
              content: 'Транспортная неделя — главное деловое событие отрасли в России, которое ежегодно проводится Министерством транспорта Российской Федерации. Миссия мероприятия – побуждать к дискуссии и осмыслению Российского транспорта; укреплять сопричастность транспортного сообщества к формированию устойчивого роста и благополучия нашей страны; демонстрировать всё лучшее, что есть в транспортном комплексе.',
          },
          {
              type: 'title',
              content: 'O GenerationS',
          },
          {
              type: 'text',
              content: 'GenerationS – платформа по развитию корпоративных инноваций; крупнейший корпоративный акселератор России и СНГ. GenerationS реализует комплексные программы по развитию корпораций: внутрикорпоративные – по развитию внутреннего предпринимательства; акселерационные – по скаутингу и акселерации внешних проектов; международные – по обучению сотрудников методам построения и ведения инноваций на базе корпораций – международных лидеров. На сегодняшний день инфраструктура акселератора включает свыше 20 тыс. стартапов из более чем 60 стран, более 2000 корпоративных и экосистемных партнеров в России и за рубежом.'
          }
      ]
    },
    {
        id: 'item_16_11_2022_3',
        img: item_16_11_2022_3,
        subtitle: `17 ноября 2022`,
        text: 'Такие моменты неспроста называют milestone. Спешим рассказать вам, какой большой день был недавно.',
        textBody: [
            {
                type: 'title',
                content: 'Такие моменты неспроста называют milestone. Спешим рассказать вам, какой большой день был недавно.',
            },
            {
                type: 'text',
                content: `🤝GenerationS <a href="https://t.me/TheInnovations">Ссылка на источник</a>, РЖД  <a href="https://t.me/telerzd">Ссылка на источник</a> и ВНИИЖТ <a href="https://t.me/vniizht_rzd">Ссылка на источник</a> подписали четырехсторонние соглашения с лидерами транспортной отрасли. Мы объединяемся для создания Транспортного акселератора — масштабной программы развития инноваций.`,
            },
            {
                type: 'text',
                content: 'Соглашения были подписаны в рамках «Транспортной недели» — главного делового события отрасли в России. В число партнеров вошли:',
            },
            {
                type: 'text',
                content: `🔹«АВТОВАЗ» <a href="http://info.avtovaz.ru/">Ссылка на источник</a>(задача — импортозамещение и новые технологии для производства автомобилей).`,
            },
            {
                type: 'text',
                content: `🔹«НИИЦ ЖДВ» <a href="https://ens.mil.ru/science/SRI/information.htm?id=12430@morfOrgScience">Ссылка на источник</a> Минобороны (новые технологии для оборонной отрасли).
`,
            },
            {
                type: 'text',
                content: `🔹«НефтеТрансСервис» <a href="https://ntstrans.ru/">Ссылка на источник</a> (задача — технологии в области нефтегазовой логистики).`,
            },
            {
                type: 'text',
                content: `🔹«ТрансКонтейнер» <a href="https://trcont.com/">Ссылка на источник</a> (задача — проекты в области вагоностроения и логистики).`,
            },
            {
                type: 'text',
                content: `🔹«ФЕСКО Интегрированный транспорт» <a href="https://www.fesco.ru/ru/">Ссылка на источник</a>, Дальневосточное Морское Пароходство (задача — импортозамещение и новые технологии в области морского транспорта).`,
            },
            {
                type: 'text',
                content: `🔹Фонд «Транспортные инновации Москвы» <a href="https://ftim.ru/">Ссылка на источник</a> (проекты для пилотирования на территории Москвы и инвестирования).`,
            },
            {
                type: 'text',
                content: 'Мы окажем полную консультационную поддержку акселератора: формирование технологического фокуса, скаутинг, экспертные сессии, сопровождение пилотов и т.д.',
            },
            {
                type: 'text',
                content: 'Итогом станет единая воронка технологических проектов, которая пройдет корпоративную техническую экспертизу и позволит запустить пилотные проекты с лидерами отрасли.',
            },
            {
                type: 'text',
                content: `<iframe width="100%" height="400" src="https://www.youtube.com/embed/zIUyPe9Bkmc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ],
    },
    {
        id: 'item-16-11-2022-2',
        img: item_16_11_2022_2,
        subtitle: `17 ноября 2022`,
        text: `GenerationS, РЖД и ВНИИЖТ будут развивать транспортные технологии вместе с лидерами транспортной отрасли`,
        textBody: [
            {
                type: 'title',
                content: 'GenerationS, РЖД и ВНИИЖТ будут развивать транспортные технологии вместе с лидерами транспортной отрасли',
            },
            {
                type: 'text',
                content: 'РВК (корпоративный акселератор GenerationS), РЖД и ВНИИЖТ подписали четырехсторонние соглашения с «АВТОВАЗ», «НИИЦ ЖДВ» Минобороны РФ, «НефтеТрансСервис», «ТрансКонтейнер», «ФЕСКО Интегрированный транспорт» и Фондом «Транспортные инновации Москвы».',
            },
            {
                type: 'text',
                content: 'Стороны планируют развивать взаимовыгодные отношения в области сквозных инновационных проектов.',
            },
            {
                type: 'text',
                content: 'Крупнейшие компании отрасли объединят опыт и экспертизу для создания Транспортного акселератора — многоотраслевой программы развития инноваций в транспортной отрасли. Цель акселератора — поддержка и внедрение технологических решений в транспортной отрасли через синергию корпоративных и технических компетенций компаний-лидеров на постоянной основе.',
            },
            {
                type: 'text',
                content: 'Сотрудничество планируется сразу по нескольким направлениям:',
            },
            {
                type: 'text',
                content: '• Обмен информацией о перспективных инновационных проектах в интересах развития Сторон;',
            },
            {
                type: 'text',
                content: '• Оценка потенциала и возможных областей развития инновационных проектов;',
            },
            {
                type: 'text',
                content: '• Совместное формирование трендов инновационного развития и гарантированного спроса на инновационную продукцию и технологии для решения задач развития транспортной отрасли;',
            },
            {
                type: 'text',
                content: '• Объединение и расширение направлений поиска инновационных проектов и повышение уровня их технологической и инвестиционной привлекательности, и формирование совместного технологического фокуса (том числе в области технологического суверенитета);',
            },
            {
                type: 'text',
                content: '• Организация совместного пилотирования и внедрения сквозных инновационных технологий, в том числе для обеспечения синергии мультимодальной логистики транспорта;',
            },
            {
                type: 'text',
                content: '• Проведение совместных акселерационных программ;',
            },
            {
                type: 'text',
                content: '• Выстраивания межотраслевой профильной инновационной экосистемы транспортной отрасли.',
            },
            {
                type: 'text',
                content: 'GenerationS в рамках соглашения планирует оказывать полную консультационную поддержку при реализации акселератора на следующих этапах:',
            },
            {
                type: 'text',
                content: '• Формирование технологического фокуса (направления поиска технологических проектов);',
            },
            {
                type: 'text',
                content: '• Поиск технологических проектов (скаутинг воронки);',
            },
            {
                type: 'text',
                content: '• Экспертные сессии по отбору проектов;',
            },
            {
                type: 'text',
                content: '• Сопровождение пилотных проектов;',
            },
            {
                type: 'text',
                content: '• Поддержка формирования технологических команд внутри основных компаний-партнёров;',
            },
            {
                type: 'text',
                content: '• Возможность приоритетного инвестиционного участия партнёров при рассмотрении воронки.',
            },
            {
                type: 'text',
                content: `<strong>Екатерина Петрова</strong>, директор GenerationS: «Новые вызовы, единые цели и задачи, движение вперёд — вот, что объединяет партнёров акселератора в желании развивать инновации. Совместное развитие, технологическая поддержка, коммерциализация и масштабирование — ключевые ценности, которые были заложены в концепцию проекта. Благодаря синергии партнёров, транспортный акселератор сможет стать полноценным инновационным Хабом, который объединит технологии, государство и бизнес для формирования транспортной индустрии будущего в России.»`,
            },
            {
                type: 'text',
                content: `<strong>Анатолий Храмцов</strong>, заместитель генерального директора — главный инженер ОАО «РЖД»: «Важным условием развития компании является наращивание спроса на инновационную продукцию и услуги. Финансирование текущего портфеля из более чем 160 проектов в рамках Комплексной программы инновационного развития холдинга „РЖД“ в 2022 году составляет порядка 140 млрд рублей. В их числе такие знаковые, известные всем проекты как московские центральные диаметры, квантовые коммуникации, беспилотные системы мониторинга инфраструктуры».`,
            },
            {
                type: 'text',
                content: `<strong>Максим Соколов</strong>, Президент АО «АВТОВАЗ»: «В текущее время вызовов, стоящих перед отраслями экономики, нам жизненно важно развивать инновационные технологии, которые обеспечат устойчивый тренд роста в будущем.»`,
            },
            {
                type: 'text',
                content: `«Развитие инновационных проектов, ориентированных на поиск технологических оптимумов операторской среды, всегда было одним из приоритетных фокус-вниманием группы компаний „НефтеТрансСервис“. Наличие у нас собственных производственных площадок во взаимной увязке с основным направлением операторской деятельности уже позволило апробировать ряд технологических разработок, реализовав проект „Умного депо“. Мы с пристальным интересом следим за развитием транспортного отраслевого акселератора ОАО „РЖД“, реализуемого на площадке АО „ВНИИЖТ“. Войдя в данный проект, мы планируем укрепить свои позиции в реализации стратегии информационного развития, как за счет создания дополнительной возможности представить внутренние разработки ГК „НТС“, так и за счет возможности реализации на наших предприятиях принципиально новых технологических решений» — прокомментировал <strong>Дмитрий Игнатюк</strong>, подписавший Соглашение о сотрудничестве со стороны АО «НефтеТрансСервис».`,
            },
            {
                type: 'text',
                content: `<strong>Иван Юнин</strong>, генеральный директор Фонда «Транспортные инновации Москвы»: «Поиск и внедрение инноваций в такую сложную и многоуровневую структуру, как „Московский транспорт“, — непростая задача. Поэтому в конце 2021 года в экосистеме Департамента транспорта г. Москвы заработала новая организация — Фонд „Транспортные инновации Москвы“. Мы занимаемся поиском, оценкой, тестированием и финансированием перспективных технологий в сфере транспорта, мобильности и умного города. Мы плотно работаем с корпоративным сектором и выстраиваем трехстороннее сотрудничество в формате город-корпорация и стартапы. Мы открыты к сотрудничеству с потенциальными партнерами. И я надеюсь, что выпускники нового акселератора РЖД смогут стать портфельными компаниями нашего Фонда.»`,
            },
            {
                type: 'text',
                content: 'Итогом Транспортного акселератора станет единая воронка технологических проектов, которая пройдет корпоративную техническую экспертизу и даст возможность запустить пилотные проекты со стратегическими партнерами в отрасли. С учётом широкой сети партнёрств, ожидается высокая конверсия пилотных проектов и коммерческое сотрудничество, включая возможность рассматривать полученную воронку и в качестве возможных инвестиций со стороны фондов РВК.',
            },
        ]
    },
    {
        id: 'item-16-11-2022-1',
        img: item_16_11_2022_1,
        subtitle: `16 ноября 2022`,
        text: `Стартапам — зелёный свет`,
        textBody: [
            {
                type: 'title',
                content: 'Стартапам — зелёный свет',
            },
            {
                type: 'text',
                content: '#ТрансКонтейнер, Российские железные дороги, РВК (GenerationS) и ВНИИЖТ подписали соглашение о развитии инновационных проектов на базе акселерационной программы РЖД. ⤵️',
            },
            {
                type: 'text',
                content: 'Будем участвовать в отборе и развитии инициатив, способных повысить эффективность ж/д транспорта, оптимизировать логистические процессы и создать новые продукты для отрасли.',
            },
            {
                type: 'text',
                content: 'Готовы предоставлять свою инфраструктуру разработчикам наиболее интересных для нас стартапов для пилотирования проектов.',
            },
            {
                type: 'text',
                content: 'Намерены активно следить за появлением в акселераторе новых проектов в сфере импортозамещения и искать варианты их использования для улучшения качества и надёжности нашего сервиса.',
            }
        ]
    },
    {
        id: 'item-16-11-2022',
        img: item_16_11_2022,
        subtitle: `16 ноября 2022`,
        text: `РЖД, FESCO, ВНИИЖТ и РВК будут развивать инновационный транспортный акселератор`,
        textBody: [
            {
                type: 'title',
                content: 'РЖД, FESCO, ВНИИЖТ и РВК будут развивать инновационный транспортный акселератор',
            },
            {
                type: 'text',
                content: 'ОАО «Российские железные дороги» («РЖД»), Транспортная группа FESCO («FESCO»), Научно-исследовательский институт железнодорожного транспорта («ВНИИЖТ», дочернее предприятие Российских железных дорог) и Российская венчурная компания («РВК») договорились о развитии инновационного транспортного акселератора.',
            },
            {
                type: 'text',
                content: 'РЖД, FESCO, ВНИИЖТ и РВК будут развивать инновационный транспортный акселератор\n' +
                    'Четырехстороннее соглашение подписали заместитель генерального директора – главный инженер ОАО «РЖД» Анатолий Храмцов, исполнительный директор «ФЕСКО Интегрированный Транспорт» (входит в FESCO) Леонид Шляхтуров, генеральный директор ВНИИЖТ Сергей Виноградов и генеральный директор РВК Анатолий Браверман в рамках XVI Международного форума и выставки «Транспорт России».',
            },
            {
                type: 'text',
                content: 'Помимо проведения совместных акселерационных программ, стороны намерены объединить усилия для внедрения сквозных цифровых технологий в транспортной отрасли и поиска инновационных логистических проектов с целью повышения уровня их технологической и инвестиционной привлекательности.',
            }
        ]
    },
    {
        img: news_2022_11_18_13_30,
        subtitle: `16 ноября 2022`,
        text: `РЖД презентовали сервис «Транспортный акселератор». Эта площадка работает на базе ВНИИЖТ. Она стала новым этапом развития корпоративного акселератора.`,
        id: nanoid(),
        isLinks: false,
        isVideo: true,
        videoBody: `
            <video className="modal-video" autoplay controls="controls">
                <source src="https://video.rzdtv.ru/video/news/2022-11/20221116_news_14_1.mp4" type="video/mp4"/>
            </video>
        `,
    },
    {
        id: 'news-modal-16-11-2022',
        img: news_2022_16_11,
        subtitle: '16 ноября 2022',
        text: 'Инновационный хаб. Транспортный акселератор РЖД объединит лидеров отрасли для поиска и внедрения прорывных идей',
        textBody: [
            {
                type: 'title',
                content: 'Новый инструмент инновационной экосистемы компании подразумевает создание уникального сообщества, направленного на достижение стратегических задач',
            },
            {
                type: 'text',
                content: '16 ноября на XVI Международном форуме и выставке «Транспортная неделя» представлена концепция Транспортного акселератора РЖД.',
            },
            {
                type: 'text',
                content: 'ОАО «РЖД» выступило с инициативой создания на своей площадке Транспортного акселератора РЖД. Его цель – объединить лидеров транспортной отрасли для совместного развития проектов и сквозных технологий, направленных на решение ключевых задач через привлечение технологических стартап-компаний.',
            },
            {
                type: 'text',
                content: '«Важным условием развития компании является наращивание спроса на инновационную продукцию и услуги, – отметил заместитель генерального директора – главный инженер ОАО «РЖД» Анатолий Храмцов. – Финансирование текущего портфеля из более чем 160 проектов в рамках Комплексной программы инновационного развития холдинга «РЖД» в 2022 году составляет порядка 140 млрд руб. В их числе такие знаковые, известные всем проекты, как Московские центральные диаметры (МЦД), квантовые коммуникации, беспилотные системы управления и удалённого мониторинга инфраструктуры».',
            },
            {
                type: 'text',
                content: 'Совместный поиск, доработка и внедрение перспективных технологических решений позволят повысить восприимчивость предприятий транспортной отрасли к инновациям. Кроме того, это увеличит конкуренцию и обеспечит стабильный спрос на суверенные новаторские технологии в транспорте. ',
            },
            {
                type: 'text',
                content: 'По словам генерального директора АО «ВНИИЖТ» Сергея Виноградова, «проект подразумевает создание уникального сообщества в отрасли, направленного на достижение стратегических задач с помощью коммерчески привлекательных инновационных решений».',
            },
            {
                type: 'text',
                content: '«Новые вызовы, единые цели и задачи, движение вперёд – вот что объединяет партнёров в желании развивать инновации. Совместное развитие, технологическая поддержка, коммерциализация и масштабирование – ключевые ценности, которые были заложены в концепцию проекта. Благодаря синергии партнёров Транспортный акселератор РЖД сможет стать полноценным инновационным хабом, который объединит технологии, государство и бизнес для формирования транспортной индустрии будущего в России», – отметила директор GenerationS Екатерина Петрова.',
            },
            {
                type: 'text',
                content: 'В подписании соглашения приняли участие АО «ВНИИЖТ», ООО «УК РВК», ФГБУ «Научно-исследовательский центр Железнодорожных войск» Минобороны РФ, Фонд развития инноваций в области общественного транспорта «Транспортные инновации Москвы», АО «АВТОВАЗ», АО «НефтеТрансСервис», ООО «ФЕСКО Интегрированный Транспорт» и ПАО «ТрансКонтейнер».',
            },
            {
                type: 'text',
                content: 'Напомним, в 2019 году в АО «ВНИИЖТ» был создан центр «Корпоративный акселератор стартап-проектов ОАО «РЖД». На его основе успешно проводятся ежегодные акселерационные программы, на участие в которых подают заявки сотни команд, предлагающих свои инновационные решения для компании. К участию в акселерационных программах ежегодно присоединяются десятки партнёров (производственные и логистические компании, институты развития и фонды).',
            },
            {
                type: 'text',
                content: 'Транспортный акселератор РЖД будет учитывать уже накопленный опыт проведения таких программ и позволит повысить количество и качество проектов без увеличения финансовой нагрузки на холдинг, а также расширить возможности компаний-­участников по поиску, пилотированию и совместному внедрению сквозных инноваций.',
            }
        ]
    },
    {
        id: 'item_vkontakte',
        img: item_vkontakte,
        subtitle: `16 ноября 2022`,
        text: 'Присоединяйтесь к GenerationS в Вконтакте',
        textBody: [
            {
                type: 'text',
                content: `<a href="https://vk.com/techstartrussia">Ссылка на источник</a>`,
            }
        ]
    },
    {
        id: 'news-modal-24-08-2022',
        img: news_2022_01_11,
        subtitle: `01 ноября 2022`,
        text: `Двадцать три стартап-проекта прошли в акселерационную программу 2022 года Акселератора ОАО «РЖД» и GenerationS`,
        textBody: [
            {
                type: 'title',
                content: 'Двадцать три стартап-проекта прошли в акселерационную программу 2022 года Акселератора ОАО «РЖД» и GenerationS',
            },
            {
                type: 'text',
                content: 'ОАО «РЖД» и АО «ВНИИЖТ» в партнерстве с GenerationS подвели промежуточные итоги акселерационной программы, запущенной в августе этого года, и определили самые перспективные стартапы в сфере пассажирских перевозок и технологической независимости для дальнейшего участия в Акселераторе РЖД. Всего в акселератор прошли 23 команды.',
            },
            {
                type: 'text',
                content: 'Для участия в Корпоративной акселерационной программе РЖД 2022 года было отобрано 23 проекта. Среди которых: ',
            },
            {
                type: 'text',
                content: '• Быстровозводимые пассажирские платформы — проект, который реализует быстровозводимые модульные пассажирские платформы двух типов: 1. На основе конструкции из блоков пенополистирола 2. Из оцинкованных металлических элементов на регулируемых винтовых опорах;',
            },
            {
                type: 'text',
                content: '• Удаленный мониторинг и контроль устройств систем освещения пассажирских платформ — автоматизированная система управления наружным освещением пассажирских платформ с индивидуальным контролем и управлением каждым осветительным прибором;',
            },
            {
                type: 'text',
                content: '• Teal HR — геймифицированный сервис по работе с персоналом.',
            },
            {
                type: 'text',
                content: 'Дополнительно в корпоративную акселерационную программу вошли проекты в области телемедицины, энергоэффективности, связи, повышения клиенториентированности и др.',
            },
            {
                type: 'text',
                content: 'Полный список проектов можно посмотреть на официальном сайте акселератора: <a href="https://accelerator.rzd.ru/">Ссылка на источник</a>',
            },
            {
                type: 'text',
                content: 'Питч-сессии с представителями компании и официальными партнерами Акселератора прошли в середине октября. Свои решения представляли 50 проектов, из которых были выбраны стартапы для дальнейшего пилотирования.',
            },
            {
                type: 'text',
                content: 'Заявки на участие в акселерационной программе подали свыше 450 стартапов из России и СНГ, из которых более 150 проектов прошли детальную экспертизу.',
            },
            {
                type: 'text',
                content: 'Среди российских городов лидерами по числу заявок стали Москва, Санкт-Петербург, Пермь, Новосибирск и Екатеринбург.',
            },
            {
                type: 'text',
                content: 'Большинство команд продемонстрировали высокую степень зрелости решений: свыше 75% проектов, прошедших экспертизу, уже провели пилотные испытания или наладили производство и имеют первые продажи, порядка 30% участников зарегистрировали зарубежные и российские патенты.',
            },
            {
                type: 'text',
                content: 'В течение следующих двух месяцев, финалисты будут дорабатывать свои решения вместе с функциональными заказчиками со стороны ОАО «РЖД», а также официальными партнерами Акселератора РЖД, изучать требования к продукции и экономике проектов, методику испытаний и другие аспекты взаимодействия с Компанией и партнёрами.',
            },
            {
                type: 'text',
                content: 'Поддержку финалистам акселератора окажет Moscow Seed Fund, фонд развития венчурного инвестирования города Москвы.',
            },
            {
                type: 'text',
                content: 'В текущей акселерационной программе партнерами Акселератора стали такие компании как АО «Синара-Транспортные Машины», АО «Трансмашхолдинг», ООО «Газпромтранс» СПбТПП, ПАО «Трансконтейнер», Агентство стратегических инициатив, ГК «Новотранс», Фонд «Новая индустрия», ФАУ «РОСДОРНИИ» и фонд Kama Flow.',
            },
            {
                type: 'text',
                content: 'По итогам акселерационной программы проекты представят свои решения руководству компании и партнером на Демо Дне в декабре 2022 года.',
            },
            {
                type: 'text',
                content: 'Следите за новостями на официальном сайте акселератора: <a href="https://accelerator.rzd.ru/">Ссылка на источник</a>',
            },
            {
                type: 'title',
                content: 'O GenerationS',
            },
            {
                type: 'text',
                content: 'GenerationS – платформа по развитию корпоративных инноваций; крупнейший корпоративный акселератор России и СНГ. GenerationS реализует комплексные программы по развитию корпораций: внутрикорпоративные – по развитию внутреннего предпринимательства; акселерационные – по скаутингу и акселерации внешних проектов; международные – по обучению сотрудников методам построения и ведения инноваций на базе корпораций – международных лидеров. На сегодняшний день инфраструктура акселератора включает свыше 20 тыс. стартапов из более чем 60 стран, более 2000 корпоративных и экосистемных партнеров в России и за рубежом.',
            },
            {
                type: 'text',
                content: '<a href="www.generation-startup.ru">www.generation-startup.ru</a>',
            },
            {
                type: 'title',
                content: 'О корпоративном акселераторе ОАО «РЖД» ',
            },
            {
                type: 'text',
                content: 'Корпоративный акселератор ОАО «РЖД» создан на базе АО «ВНИИЖТ» в 2019 году как один из элементов комплексной системы развития инноваций и работы со стартап-проектами, сформированной в компании по решению генерального директора — председателя правления ОАО «РЖД» Олега Белозёрова. В июле 2020 года АО «ВНИИЖТ» открыл специальную площадку для работы Корпоративного акселератора, проведения публичных мероприятий и коворкинг-пространства. В 2019, 2020 и 2021 годах проведены три Корпоративные акселерационные программы ОАО «РЖД». По данным агентства РБК и исследовательского проекта Barometer, в 2021 году ОАО «РЖД» входит в 5 крупнейших промышленных и логистических российских компаний, наиболее эффективно работающих с инновациями.',
            },
            {
                type: 'title',
                content: 'Пресс-служба GenerationS:',
            },
            {
                type: 'text',
                content: '<a href="mailto:Dremova.GV@rvc.ru">Dremova.GV@rvc.ru </a>',
            },
        ]
    },
    {
        id: 'news-modal-24-08-2022',
        img: news_2022_24_08,
        subtitle: `24 августа 2022`,
        text: `Открыт прием заявок в акселератор РЖД`,
        textBody: [
            {
                type: 'title',
                content:
                    'ОАО «РЖД» и платформа по развитию корпоративных инноваций GenerationS открыли прием заявок в акселератор с фокусом на решения в области совершенствования пассажирского комплекса и технологической независимости. В конце 2022 года РЖД проведет демодень, на котором объявят лучшие проекты.',
            },
            {
                type: 'text',
                content:
                    'Цель акселерационной программы — найти инновационные решения, которые помогут повысить уровень обслуживания клиентов пассажирского комплекса РЖД, эффективность подразделений компании, обеспечивающих пассажирские перевозки, найти решения, позволяющие повысить технологическую независимость холдинга от воздействия различных внешних и макроэкономических факторов.  ',
            },
            {
                type: 'text',
                content:
                    '« <...> Для повышения интереса к программе мы планируем расширить перечень партнеров и инвестиционных фондов, сохранив ранее выстроенные связи и усилив их новыми интересантами программы на взаимовыгодных условиях. В рамках акселерационной программы планируется увеличение срока работы с проектами, попавшими в итоговый пул, для проведения необходимого тестирования технологий и ускорения принятия решений по их внедрению», — Роман Кошелев, начальник «Центра Корпоративный акселератор ОАО “РЖД” АО “ВНИИЖТ”». ',
            },
            {
                type: 'text',
                content:
                    '«В этом году мы ожидаем, что интерес к участию в акселерационной программе будет не меньше, чем в предыдущие годы. Для акселератора выбраны наиболее актуальные направления: пассажирские перевозки и импортозамещение. В период активных изменений, с которыми сегодня сталкивается наша экономика , очень важно, чтобы стартапы получали поддержку в лице надежных партнеров, которым также необходимы качественные отечественные решения», — Екатерина Петрова, директор GenerationS.',
            },
            {
                type: 'text',
                content:
                    'Принять участие в отборе могут стартапы, разрабатывающие инновационные решения по следующим поднаправлениям: ',
            },
            {
                type: 'text',
                content: '• комплексные системы;',
            },
            {
                type: 'text',
                content: '• IT-решения;',
            },
            {
                type: 'text',
                content: '• сервисы, услуги;',
            },
            {
                type: 'text',
                content: '• технологии и технические средства и др.',
            },
            {
                type: 'text',
                content:
                    'Стартапы должны соответствовать таким ключевым параметрам, как: ',
            },
            {
                type: 'text',
                content: '• готовый и испытанный экспериментальный образец;',
            },
            {
                type: 'text',
                content:
                    '• наличие юридического лица (или готовность его создать);',
            },
            {
                type: 'text',
                content: '• соответствие направлениям отбора;',
            },
            {
                type: 'text',
                content: '• готовность провести пилот с РЖД.',
            },
            {
                type: 'text',
                content:
                    'В ходе экспертизы будут отобраны проекты для участия в питч-сессиях, по итогам которых экспертное жюри отберет стартапы для акселерационного этапа.',
            },
            {
                type: 'text',
                content:
                    'Участие в акселераторе позволит стартапам получить развернутую обратную связь от экспертов, быстрый доступ к заказчикам, работающим в структуре железных дорог, а также ускоренный доступ к испытаниям на полигонах компании. Самые перспективные стартапы смогут стать поставщиками решений для ОАО «РЖД».',
            },
            {
                type: 'text',
                content:
                    'Прием заявок продлится до 15 сентября 2022 года.',
            },
            {
                type: 'text',
                content: '<a href="https://rb.ru/partners/akselerator-rzhd/">Ссылка на источник</a>'
            }
        ],
    },
    {
        id: 'news-modal-18-08-2022',
        img: news_2022_18_08,
        subtitle: `18 августа 2022`,
        text: `РЖД и GenerationS приступили к отбору инновационных проектов`,
        textBody: [
            {
                type: 'title',
                content:
                    'РЖД и GenerationS приступили к отбору инновационных проектов',
            },
            {
                type: 'text',
                content:
                    'Акселератор ОАО «РЖД» и GenerationS начинают прием заявок для участия в акселераторе с фокусом на решения в области совершенствования пассажирского комплекса и технологической независимости.',
            },
            {
                type: 'text',
                content:
                    'Промежуточные итоги будут объявлены осенью этого года. В конце года пройдет Демо день, где будут объявлены победители.',
            },
            {
                type: 'text',
                content:
                    'Главная цель акселерационной программы – найти инновационные решения, которые помогут повысить уровень обслуживания клиентов пассажирского комплекса РЖД, эффективность подразделений РЖД, обеспечивающих пассажирские перевозки, найти решения, позволяющие повысить технологическую независимость Холдинга от действия различных внешних и макроэкономических факторов.',
            },
            {
                type: 'text',
                content:
                    'Принять участие в отборе могут стартапы, разрабатывающие инновационные решения по следующим поднаправлениям:',
            },
            {
                type: 'text',
                content: '• Комплексные системы',
            },
            {
                type: 'text',
                content: '• IT-решения',
            },
            {
                type: 'text',
                content: '• Сервисы, услуги',
            },
            {
                type: 'text',
                content: '• Технологии и технические средства и др. ',
            },
            {
                type: 'text',
                content:
                    'Полный список поднаправлений доступен на официальном сайте акселератора.',
            },
            {
                type: 'text',
                content:
                    'В рамках акселерационной программы планируется увеличение срока работы с проектами, попавшими в итоговый пул, для проведения необходимого тестирования технологий и ускорения принятия решений по их внедрению',
            },
            {
                type: 'text',
                content:
                    'Отбор стартапов будет проводиться по следующим ключевым параметрам:',
            },
            {
                type: 'text',
                content: '• Изготовлен и испытан экспериментальный образец',
            },
            {
                type: 'text',
                content:
                    '• Наличие юридического лица (или готовность его создать)',
            },
            {
                type: 'text',
                content: '• Соответствие направлениям отбора',
            },
            {
                type: 'text',
                content: '• Готовность провести пилот с РЖД',
            },
            {
                type: 'text',
                content:
                    'В ходе экспертизы будут отобраны проекты для участия в питч-сессиях, по итогам которых экспертное жюри отберет стартапы для акселерационного этапа.',
            },
            {
                type: 'text',
                content:
                    'Участие в акселераторе ОАО «РЖД» позволяет стартапам получить развернутую обратную связь от экспертов, быстрый доступ к заказчикам, работающим в структуре железных дорог, а также ускоренный доступ к испытаниям на полигонах Компании. Самые перспективные стартапы смогут стать поставщиками решений для ОАО «РЖД».',
            },
            {
                type: 'text',
                content:
                    'GenerationS и ОАО «РЖД» проводят совместный акселератор уже в третий раз. В 2020 году партнеры отбирали решения по направлению «Подвижной состав», в 2021 году шел отбор решений по направлению «Логистика», где было подано более 1000 заявок из 17 стран.',
            },
            {
                type: 'text',
                content: '<a href="https://vc.ru/generations/483015-rzhd-i-generations-pristupili-k-otboru-innovacionnyh-proekto">Ссылка на источник</a>'
            }
        ],
    },
    {
        id: 'news-modal-video-17-08-2022',
        subtitle: `17 августа 2022`,
        text: `Открытие Акселератора РЖД`,
        img: rzd2022videoimage,
        isLinks: false,
        isVideo: true,
        videoBody: `
              <iframe width="640" height="360" src="https://www.youtube.com/embed/rVXmPwIjKcA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `,
    },
    {
        img: news_2022_17_08,
        subtitle: `17 августа 2022`,
        text: `Акселератор ОАО «РЖД» открывает отбор лучших технологий для пассажиров`,
        id: 'news-modal-17-08-2022',
        textBody: [
            {
                type: 'title',
                content:
                    'Партнёром холдинга выступает платформа по развитию корпоративных инноваций GenerationS',
            },
            {
                type: 'text',
                content:
                    'В 2022 году в корпоративную акселерационную программу холдинга «РЖД» приглашаются стартапы с самыми перспективными идеями для пассажирских перевозок. Отбор инновационных проектов стартует 17 августа и продлится до 15 сентября. Промежуточные итоги будут объявлены осенью.',
            },
            {
                type: 'text',
                content:
                    'А в конце года ОАО «РЖД» проведёт демодень, где будут объявлены победители. В ходе экспертизы будут отобраны проекты для участия в питч-сессиях, по итогам которых экспертное жюри отберет стартапы для акселерационного этапа.',
            },
            {
                type: 'text',
                content:
                    'Партнёром акселератора ОАО «РЖД» выступает платформа по развитию корпоративных инноваций GenerationS. Зарегистрироваться на участие в церемонии открытия акселератора можно на сайте платформы',
            },
            {
                type: 'text',
                content:
                    'На церемонии открытия акселерационной программы представители Центра инновационного развития РЖД и ВНИИЖТа расскажут, с какой целью запускается акселератор-2022, по каким ключевым направлениям будет проходить отбор инновационных проектов, что получат стартапы от участия и, самое главное, какие результаты ожидаются по завершении. Также перед потенциальными участниками выступит заместитель генерального директора – главный инженер ОАО «РЖД» Анатолий Храмцов.',
            },
            {
                type: 'text',
                content:
                    'Представители стартапов – участников прошлых программ поделятся своими историями успеха и дадут ценные практические советы будущим участникам отбора.',
            },
            {
                type: 'text',
                content:
                    'Главная цель акселерационной программы, как отмечается в сообщении GenerationS, – найти инновационные решения, которые помогут повысить уровень обслуживания пассажиров на железной дороге, увеличить эффективность подразделений РЖД, обеспечивающих пассажирские перевозки, найти решения, позволяющие повысить технологическую независимость холдинга от различных внешних и макроэкономических факторов.',
            },
            {
                type: 'text',
                content:
                    'Принять участие в отборе могут стартапы, разрабатывающие инновационные решения по следующим направлениям:',
            },
            {
                type: 'text',
                content: '• Комплексные системы',
            },
            {
                type: 'text',
                content: '• IT-решения',
            },
            {
                type: 'text',
                content: '• Сервисы, услуги',
            },
            {
                type: 'text',
                content: '• Технологии и технические средства и др. ',
            },
            {
                type: 'text',
                content:
                    'Участие в акселераторе ОАО «РЖД» позволяет стартапам получить развёрнутую обратную связь от экспертов, быстрый доступ к заказчикам, работающим в структуре железных дорог, а также ускоренный доступ к испытаниям на полигонах компании. Самые перспективные стартапы смогут стать поставщиками решений для ОАО «РЖД».',
            },
            {
                type: 'text',
                content:
                    'Начальник Центра «Корпоративный акселератор ОАО «РЖД» АО «ВНИИЖТ» Роман Кошелев напомнил, что новая акселерационная программа стала уже четвёртой для холдинга.  «В 2022 году акселератор РЖД открывает четвёртую акселерационную программу, которая в этом году направлена не только на поиск оптимальных и прорывных решений для отдельного направления деятельности компании «РЖД» – пассажирского комплекса, но и ставит задачей расширить границы взаимодействия со стартапами тематикой импортозамещения в целом, актуальность которой заметно выросла в современных условиях. Для повышения интереса к программе мы планируем расширить перечень партнёров и инвестиционных фондов, сохранив ранее выстроенные связи и усилив их новыми интересантами программы на взаимовыгодных условиях. В рамках акселерационной программы планируется увеличение срока работы с проектами, попавшими в итоговый пул, для проведения необходимого тестирования технологий и ускорения принятия решений по их внедрению», – подчеркнул Роман Кошелев.',
            },
            {
                type: 'text',
                content:
                    'По мнению директора GenerationS Екатерины Петровой, организаторы отбора ожидают, что интерес к участию в акселерационной программе будет не меньше, чем в предыдущие годы. «Для акселератора выбраны наиболее актуальные направления – пассажирские перевозки и импортозамещение. В период активных изменений, с которыми сегодня сталкивается наша экономика, очень важно, чтобы стартапы получали поддержку в лице надёжных партнеров, которым также необходимы качественные отечественные решения», – отметила Екатерина Петрова.',
            },
            {
                type: 'text',
                content:
                    'Как и ранее, главными критериями для участия в акселерационной программе являются наличие экспериментального образца, готовность к пилотированию на российских железных дорогах.',
            },
        ],
    },
    {
        img: vinogradov,
        subtitle: `16 августа 2022`,
        text: `Пассажирский комплекс и технологическая независимость: акселератор ОАО «РЖД» и GenerationS приступили к отбору инновационных проектов в рамках корпоративной акселерационной программы`,
        id: 'news-modal-16-08-2022',
        textBody: [
            {
                type: 'title',
                content:
                    'Акселератор ОАО «РЖД» при участии платформы по развитию корпоративных инноваций GenerationS начинают прием заявок для участия в акселераторе с фокусом на решения в области совершенствования пассажирского комплекса и технологической независимости.',
            },
            {
                type: 'title',
                content:
                    'Промежуточные итоги будут объявлены осенью этого года. В конце года ОАО «РЖД» проведет Демо день, где будут объявлены победители.',
            },
            {
                type: 'text',
                content:
                    'Главная цель акселерационной программы – найти инновационные решения, которые помогут повысить уровень обслуживания клиентов пассажирского комплекса РЖД, эффективность подразделений РЖД, обеспечивающих пассажирские перевозки, найти решения, позволяющие повысить технологическую независимость Холдинга от действия различных внешних и макроэкономических факторов.',
            },
            {
                type: 'text',
                content:
                    'Роман Кошелев, начальник «Центра Корпоративный акселератор ОАО «РЖД» АО «ВНИИЖТ»: «В 2022 году акселератор РЖД открывает четвертую акселерационную программу, которая в этом году направлена не только на поиск оптимальных и прорывных решений для отдельного направления деятельности компании РЖД – пассажирского комплекса, но и ставит задачей расширить границы взаимодействия со стартапами тематикой импортозамещения в целом, актуальность которой заметно выросла в современных условиях.',
            },
            {
                type: 'text',
                content:
                    'Для повышения интереса к программе мы планируем расширить перечень партнеров и инвестиционных фондов, сохранив ранее выстроенные связи и усилив их новыми интересантами программы на взаимовыгодных условиях. В рамках акселерационной программы планируется увеличение срока работы с проектами, попавшими в итоговый пул, для проведения необходимого тестирования технологий и ускорения принятия решений по их внедрению».',
            },
            {
                type: 'text',
                content:
                    'Екатерина Петрова, директор GenerationS: «В этом году мы ожидаем, что интерес к участию в акселерационной программе будет не меньше, чем в предыдущие годы. Для акселератора выбраны наиболее актуальные направления – пассажирские перевозки и импортозамещение. В период активных изменений, с которыми сегодня сталкивается наша экономика, очень важно, чтобы стартапы получали поддержку в лице надежных партнеров, которым также необходимы качественные отечественные решения».',
            },
            {
                type: 'text',
                content:
                    'Принять участие в отборе могут стартапы, разрабатывающие инновационные решения по следующим поднаправлениям:',
            },
            {
                type: 'text',
                content: '• Комплексные системы',
            },
            {
                type: 'text',
                content: '• IT решения',
            },
            {
                type: 'text',
                content: '• Сервисы, услуги',
            },
            {
                type: 'text',
                content: '• Технологии и технические средства и др.',
            },
            {
                type: 'text',
                content:
                    'Полный список поднаправлений доступен на официальном сайте акселератора.',
            },
            {
                type: 'text',
                content: 'Прием заявок продлится до 15 сентября.',
            },
            {
                type: 'text',
                content:
                    'Отбор стартапов будет проводиться по следующим ключевым параметрам:',
            },
            {
                type: 'text',
                content: '• Изготовлен и испытан экспериментальный образец',
            },
            {
                type: 'text',
                content:
                    '• Наличие юридического лица (или готовность его создать)',
            },
            {
                type: 'text',
                content: '• Соответствие направлениям отбора',
            },
            {
                type: 'text',
                content: '• Готовность провести пилот с РЖД',
            },
            {
                type: 'text',
                content:
                    'В ходе экспертизы будут отобраны проекты для участия в питч-сессиях, по итогам которых экспертное жюри отберет стартапы для акселерационного этапа.',
            },
            {
                type: 'text',
                content:
                    'Участие в акселераторе ОАО «РЖД» позволяет стартапам получить развернутую обратную связь от экспертов, быстрый доступ к заказчикам, работающим в структуре железных дорог, а также ускоренный доступ к испытаниям на полигонах Компании. Самые перспективные стартапы смогут стать поставщиками решений для ОАО «РЖД».',
            },
            {
                type: 'text',
                content:
                    'GenerationS и ОАО «РЖД» проводят совместный акселератор уже в третий раз. В 2020 году партнеры отбирали решения по направлению «Подвижной состав», в 2021 году шел отбор решений по направлению «Логистика», где было подано более 1000 заявок из 17 стран.',
            },
            {
                type: 'text',
                content:
                    'О Корпоративном акселераторе ОАО «РЖД»\n' +
                    '\n' +
                    'Корпоративный акселератор ОАО «РЖД» создан на базе АО «ВНИИЖТ» в 2019 году как один из элементов комплексной системы развития инноваций и работы со стартап-проектами, сформированной в компании по решению генерального директора — председателя правления ОАО «РЖД» Олега Белозёрова. В 2020 году АО «ВНИИЖТ» открыл специальную площадку для работы Корпоративного акселератора, проведения публичных мероприятий и коворкинг-пространства. По данным агентства РБК и исследовательского проекта Barometer, за период с июня 2020 года по июнь 2021 года ОАО «РЖД» входит в 5 крупнейших российских компаний, наиболее эффективно работающих с инновациями в области «Промышленность и логистика».\n',
            },
            {
                type: 'text',
                content:
                    'O GenerationS\n' +
                    '\n' +
                    'GenerationS – платформа по развитию корпоративных инноваций; крупнейший корпоративный акселератор России и СНГ.\n' +
                    '\n' +
                    'GenerationS реализует комплексные программы по развитию корпораций: внутрикорпоративные – по развитию внутреннего предпринимательства; акселерационные – по скаутингу и акселерации внешних проектов; международные – по обучению сотрудников методам построения и ведения инноваций на базе корпораций – международных лидеров.',
            },
            {
                type: 'text',
                content:
                    'На сегодняшний день инфраструктура акселератора включает свыше 20 тыс. стартапов из более чем 60 стран, более 2000 корпоративных и экосистемных партнеров в России и за рубежом. В 2018 году стал и лучшим корпоративным акселератором Европы по версии Corporate Startup Summit. GenerationS также входит в Топ-5 лучших в мире государственных акселераторов по версии UBI Global. В 2020 году GenerationS стал членом Ассоциации INSME – международной сети для малого и среднего бизнеса под эгидой ОЭСР. В сентябре 2020 года GenerationS стал победителем в категории «Ecosystem best practice» конкурса, организованного ITU – Международным союзом электросвязи, являющимся специализированным учреждением ООН. В декабре 2020 года GenerationS стал лауреатом российской премии «Digital Leaders 2020» в номинации «Проект года в госсекторе» в категории «Акселерационные программы». В апреле 2021 года GenerationS одержал победу в Global Outlook Awards в категории «Outstanding Contribution to Innovation Ecosystem Development – Russia 2021», а в июне был отмечен премией Cosmopolitan The Daily Business Awards 2021 в категории «Best Practices in Ecosystem Acceleration - Russia 2021».',
            },
        ],
    },
    {
        img:
            'https://rzdtv.ru/wp-content/uploads/2022/01/20220110_tema_chasa_koshelev.png',
        subtitle: `21 января 2022`,
        text: `Акселератор РЖД — 2021. После финала`,
        id: nanoid(),
        isLinks: false,
        isVideo: true,
        videoBody: `
            <video className="modal-video" autoplay controls="controls">
                <source src="https://video.rzdtv.ru/video/Tema_chasa/20220110_Tema_chasa_Koshelev.mp4" type="video/mp4"/>
            </video>
        `,
    },
    {
        img: news_2021_27_12,
        id: 'news-modal-28-12-2021',
        subtitle: `28 декабря 2021`,
        text: `Подведены итоги программы Корпоративного Акселератора ОАО «РЖД» 2021 по направлению «Логистика»`,
        textBody: [
            {
                type: 'title',
                content: `Подведены итоги программы Корпоративного Акселератора ОАО «РЖД» 2021 по направлению «Логистика»`,
            },
            {
                type: 'text',
                content: `21 декабря в АО «ВНИИЖТ» состоялся Финал (Демо день) Корпоративной акселерационной программы 2021 г. по направлению «Логистика», который прошел в комбинированном он- и оффлайн формате.`,
            },
            {
                type: 'text',
                content: `Мероприятие открыл генеральный директор АО «ВНИИЖТ» Сергей Виноградов. Он поздравил участников с выходом в финал и пожелал им победы. Подводя итог трёх акселерационных программ, он отметил, что в Акселератор с момента начала его работы поступило около 2000 заявок, из которых около 200 проектов прошли очный экспертный отбор и 61 попали в финальные стадии, то есть вышли на прямой тесный контакт с функциональными заказчиками в РЖД и с партнерами программы. «Это достижение, которое доказывает интерес и необходимость продолжения работы Корпоративного Акселератора ОАО «РЖД» в интересах транспортной отрасли и развития инноваций,» - подчеркнул генеральный директор ВНИИЖТ. Он также отметил, что Демо День впервые проводится в очном формате на площадке института, для чего были созданы особые условия – зал для проведения питч-сессий и публичных мероприятий Акселератора. `,
            },
            {
                type: 'text',
                content: `На участие в программе «Логистика» 2021 года было подано более 500 заявок из 12 стран, из которых 150 прошли дополнительную экспертизу и около 70 проектов отбор в рамках питч-сессий с функциональными заказчиками ОАО «РЖД» и партнерами. В результате 21 проект прошел в акселерационную программу, 15 проектов стали ее финалистами и выступили на Демо дне.`,
            },
            {
                type: 'text',
                content: `С приветственной речью к участникам выступил начальник Центра инновационного развития ОАО «РЖД» Дмитрий Вербов: «Мы создали экосистему, в которой есть возможность рассматривать различные виды проектов, возможность реализации которых есть как у ОАО «РЖД», так и у партнеров Акселератора, от чего в конечном итоге выигрывает вся отрасль. Несмотря на то, что иногда заказчики в РЖД уже конкурируют с партнерами за проекты программ Акселератора, мы все делаем одно общее дело, связанное с улучшением перевозочного процесса».`,
            },
            {
                type: 'text',
                content: `В состав жюри вошли представители ОАО «РЖД», бизнес-партнеры, а также, впервые за время работы Акселератора РЖД, представители венчурных и инвестиционных фондов - Фонд «Новая индустрия», «Kama Flow», «Венчурный фонд МТС».`,
            },
            {
                type: 'text',
                content: `В 2021 значительно расширился круг партнёров акселератора за счёт ведущих транспортно-логистических компаний, таких как ПАО «ТрансКонтейнер», Fesco, ООО «Газпромтранс». К партнерской программе присоединилась Почта России, крупные предприятия-производители ПАО «Кировский завод», АО «Северсталь», подразделения группы ТМХ. Со стороны институтов развития и поддержки предпринимательства в состав партнеров вошла Санкт-Петербургская торгово-промышленная палата.`,
            },
            {
                type: 'text',
                content: `Команды-участники Демо-дня презентовали свои проекты и ответили на вопросы жюри и других участников мероприятия.`,
            },
            {
                type: 'text',
                content: `По итогам Демо-дня были определены 7 стартап команд победителей, ими стали: Jolt (с проектом очистки вагона от замёрзших остатков груза с помощью магнитоимпульса), Cаllpy (оптимизация работы с клиентом на сайте с помощью приложения), RevGames (привлечение клиентов на маркетплейс с помощью игр), «Mpfit» (автоматизации складов малой площади), ARIPIX (разработка и производство комплексов для замены ручного труда на базе роботов), Digital Wave (оценка объёма сыпучих грузов с помощью аппаратных средств) и Axelot WMS X5 (автоматическое управление процессами на складе). С данными проектами будут подписаны Дорожные карты на проведение испытаний и адаптации их продукции в интересах ОАО «РЖД».`,
            },
            {
                type: 'text',
                content: `После определения победителей состоялась церемония награждения. Заместитель генерального директора – главный инженер ОАО «РЖД» Анатолий Храмцов вручил дипломы победителям. `,
            },
            {
                type: 'text',
                content: `Ряд проектов был отмечен партнерами программы, а Фонд развития венчурного инвестирования г. Москвы (Moscow Seed Fund) вручил сертификат на 500 тыс.руб. на развитие проекту ARIPIX – одному из победителей.`,
            },
            {
                type: 'text',
                content: `«Проекты, которые мы выбрали сегодня, решают задачи не только улучшения внутренних процессов компании, но и совершенствования взаимодействия с внешним контуром, повышения качества работы с клиентами, – отметил Анатолий Храмцов. – РЖД – это клиентоориентированная компания, потребитель наших услуг для нас превыше всего».`,
            },
            {
                type: 'text',
                content: `Анатолий Храмцов выразил благодарность институту и всем участникам мероприятия за организацию, отметив, что отношение к работе Акселератора в компании меняется, информированность перерастает в активную заинтересованность, что необходимо только развивать.`,
            },
            {
                type: 'text',
                content: `Для АО «ВНИИЖТ» программа 2021 года стала третьим успешным опытом проведения акселерационных программ специалистами Акселератора ОАО «РЖД» и второй программой, партнером которой стала платформа по развитию корпоративных инноваций GenerationS. `,
            },
            {
                type: 'text',
                content: `По результатам успешных тестирований команды-победители программы получат возможность дальнейшего масштабирования своих решений на сети железных дорог и долгосрочного сотрудничества с ОАО «РЖД» и партнерами.`,
            },
        ],
        social1: twIcon,
        social2: fbIcon,
    },
    {
        img: news_2021_23_12_3,
        subtitle: `23 декабря 2021`,
        text: `Демо День Корпоративного акселератора ОАО «РЖД» 2021.`,
        id: 'news-modal-23-12-2021-3-57',
        imgInner: modalImg,
        isLinks: false,
        social1: twIcon,
        social2: fbIcon,
        isVideo: false,
        textBody: [
            {
                type: 'slider',
                content: [
                    slider_2_0,
                    slider_2_1,
                    slider_2_2,
                    slider_2_3,
                    slider_2_4,
                    slider_2_5,
                    slider_2_6,
                    slider_2_7,
                    slider_2_8,
                    slider_2_9,
                    slider_2_10,
                    slider_2_11,
                    slider_2_12,
                    slider_2_13,
                    slider_2_14,
                    slider_2_15,
                    slider_2_16,
                    slider_2_17,
                    slider_2_18,
                    slider_2_19,
                    slider_2_20,
                    slider_2_21,
                    slider_2_22,
                    slider_2_23,
                    slider_2_24,
                    slider_2_25,
                    slider_2_26,
                    slider_2_27,
                    slider_2_28,
                    slider_2_29,
                    slider_2_30,
                    slider_2_31,
                    slider_2_32,
                    slider_2_33,
                    slider_2_34,
                    slider_2_35,
                    slider_2_36,
                ],
            },
        ],
    },
];
