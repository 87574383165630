import React, { Component } from 'react';
import './style.sass';
import AdminTextField from '../../form/AdminTextField';
import WrapperPairFields from '../WrapperPairFields';
import AdminPasswordTextField from '../../form/AdminPasswordTextField';
import RedButton from '../../ui/RedButton/index';
import FlexContainerCenter from '../../layout/FlexContainerCenter';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHelperText } from '@material-ui/core';
import RegistrationListCheckbox from '../RegistrationListCheckbox';
import { FormikProps } from 'formik';
import { IRegistrationValues } from '../../../types/registration';
import EmailTextMask from '../../form/basic-components/MaskTextField/email';
import NumericMask from '../../form/basic-components/MaskTextField/numeric';

interface IState {}

interface IProps {}

class RegistrationFormContent extends Component<
    IProps & FormikProps<IRegistrationValues>,
    IState
> {
    render() {
        const { errors, touched, setFieldValue } = this.props;
        const recaptchaError = touched.RECAPTCHA && errors.RECAPTCHA;
        return (
            <FlexContainerCenter>
                <AdminTextField
                    formikProps={this.props}
                    label="ФИО"
                    name="NAME"
                    type="text"
                />
                <WrapperPairFields>
                    <AdminTextField
                        formikProps={this.props}
                        label="Телефон"
                        name="PHONE"
                        type="text"
                        mask={NumericMask}
                    />
                    <AdminTextField
                        formikProps={this.props}
                        label="E-mail"
                        name="EMAIL"
                        type="text"
                        mask={EmailTextMask}
                    />
                </WrapperPairFields>
                <WrapperPairFields>
                    <AdminPasswordTextField
                        formikProps={this.props}
                        label="Пароль"
                        name="PASS"
                        type="password"
                    />
                    <AdminPasswordTextField
                        formikProps={this.props}
                        label="Подтверждение пароля"
                        name="COMFIRM_PASS"
                        type="password"
                    />
                </WrapperPairFields>
                <WrapperPairFields>
                    <div className="recapcha-wrap">
                        <ReCAPTCHA
                            sitekey="6LfpobUZAAAAAKIGjFiqKpLFdYL3_ToGrWjkghFe"
                            onChange={value =>
                                setFieldValue('RECAPTCHA', value)
                            }
                        />
                        {recaptchaError && (
                            <FormHelperText error={Boolean(recaptchaError)}>
                                {errors.RECAPTCHA}
                            </FormHelperText>
                        )}
                    </div>
                </WrapperPairFields>
                <RegistrationListCheckbox {...this.props} />
                <RedButton size="md" type="submit">
                    Зарегистрироваться
                </RedButton>
            </FlexContainerCenter>
        );
    }
}

export default RegistrationFormContent;
