import React from 'react';
import './style.sass';
import { MenuItemLink } from 'react-admin';

const ExpertsRulesList = ({ data, links }) => {
    return (
        <>
            <div className="rules__intro">
                Добро пожаловать на&nbsp;сайт корпоративного акселератора
                GenerationS (далее&nbsp;&mdash; Сайт). Администрация Сайта
                предоставляет Вам доступ к&nbsp;использованию Сайта и&nbsp;его
                сервиса &laquo;Личный кабинет&raquo; (далее&nbsp;&mdash; Личный
                кабинет) на&nbsp;условиях, являющихся предметом настоящих
                Правил.
            </div>
            <div className="rules__links-list">
                {links.map(({ link, label }, index) => {
                    return (
                        <MenuItemLink
                            className="rules__link"
                            key={link}
                            primaryText={`${index + 1}. ${label}`}
                            to={link}
                        />
                    );
                })}
            </div>

            <ol className="rules__list">
                {data.map(({ title, text }, index) => {
                    return (
                        <li className="rules__item">
                            {title}
                            <ol className="rules__inner-list">
                                {text.map(text => {
                                    if (typeof text === 'string') {
                                        return (
                                            <li
                                                key={text}
                                                className="rules__item rules__item--inner"
                                                dangerouslySetInnerHTML={{
                                                    __html: text,
                                                }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <li className="rules__item rules__item--inner rules__item--no-before">
                                                <ol className="rules__deep-list">
                                                    {text.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="rules__item rules__item--deep"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item,
                                                            }}
                                                        />
                                                    ))}
                                                </ol>
                                            </li>
                                        );
                                    }
                                })}
                            </ol>
                        </li>
                    );
                })}
            </ol>
        </>
    );
};

export default ExpertsRulesList;
