import React, { useEffect, useState } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    useDataProvider,
    useTranslate,
} from 'react-admin';
import SlideToggle from 'react-slide-toggle';
import './style.sass';
import config from '../../../../data/project-config.json';
import GetAppIcon from '@material-ui/icons/GetApp';
import BodyResource from '../../BodyResource';
import { CardTitle } from '../../CustomTitles/CardTitle';
import projectConfig from '../../../../data/project-config.json';
import RequestCard from '../../Request/RequestCard';
import axios from 'axios';
import LKRepository from '../../../../models/LKRepository';
import { ReactComponent as SkypeIcon } from '../../../../assets/img/svg/admin/skype.svg';

const generateData = (config, data) => {
    const formatedData = [];

    config.sourceArray.forEach(item => {
        formatedData.push({
            label: item.label,
            data: data[item.source],
        });
    });

    return formatedData;
};

const ShowViewItem = ({ label, desc }) => {
    if (desc) {
        return (
            <div className="card__text">
                <div className="card__text--label">{label}</div>
                <div className="card__text--data">
                    {Array.isArray(desc) ? desc.join(', ') : desc}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const ManagerRequestCard = props => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [linkFile, setLinkFile] = useState('');

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.getarchive`,
                LKRepository.generateFormData({ ID: props.id })
            )
            .then(response => {
                setLinkFile(response.data.data);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <>
            <div className="manager-req-card">
                <div className="manager-req-card__excel">
                    <div className="manager-req-card__item">
                        <a
                            className="export-button"
                            href={linkFile ? linkFile : null}
                            download
                        >
                            <GetAppIcon /> Скачать анкету и файлы
                        </a>
                    </div>
                    <div className="manager-req-card__item">
                        {/*<a*/}
                        {/*    className="skype-button"*/}
                        {/*    href={linkFile ? linkFile : null}*/}
                        {/*>*/}
                        {/*    <SkypeIcon /> Звонок через Skype*/}
                        {/*</a>*/}
                    </div>
                </div>
                <div className="manager-req-card__desc">
                    {props.data.VECTOR && (
                        <ShowViewItem
                            label="Отраслевое направление"
                            desc={
                                props.data.VECTOR &&
                                Object.values(props.data.VECTOR)
                            }
                        />
                    )}
                    {props.data.SHORT_DESCRIBE && (
                        <div className="card__text">
                            <div className="card__text--label">
                                Краткое описание проекта
                            </div>
                            <div className="card__text--data">
                                {props.data.SHORT_DESCRIBE}
                            </div>
                        </div>
                    )}
                    {props.data.FIO && (
                        <div className="card__text">
                            <div className="card__text--label">
                                ФИО руководителя проекта
                            </div>
                            <div className="card__text--data">
                                {props.data.FIO}
                            </div>
                        </div>
                    )}
                    {props.data.PHONE && (
                        <div className="card__text">
                            <div className="card__text--label">Телефон</div>
                            <div className="card__text--data">
                                {props.data.PHONE}
                            </div>
                        </div>
                    )}
                </div>

                <SlideToggle
                    collapsed
                    render={({ toggle, setCollapsibleElement }) => (
                        <div className="manager-req-card__body">
                            <div
                                className="card__header"
                                onClick={() => {
                                    toggle();
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <div>
                                    <div className="manager-req-card__toggle">
                                        {isOpen
                                            ? 'Скрыть анкету'
                                            : 'Показать анкету'}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="card__content"
                                ref={setCollapsibleElement}
                            >
                                {projectConfig.map((item, index) => {
                                    return (
                                        <div className="manager-req-card__category">
                                            <div className="manager-req-card__category-title">
                                                {translate(item.title)}
                                            </div>
                                            {generateData(
                                                config[index],
                                                props.data
                                            ).map((item, index) => {
                                                return (
                                                    <div
                                                        className="card__text"
                                                        key={index}
                                                    >
                                                        {item.data && (
                                                            <>
                                                                <div className="card__text--label">
                                                                    {item.label}
                                                                </div>
                                                                <div className="card__text--data">
                                                                    {typeof item.data ===
                                                                    'object'
                                                                        ? Object.keys(
                                                                              item.data
                                                                          ).map(
                                                                              key => (
                                                                                  <div className="card__text--obj">
                                                                                      {item.data[
                                                                                          key
                                                                                      ].includes(
                                                                                          '/upload'
                                                                                      ) ? (
                                                                                          <a
                                                                                              href={
                                                                                                  item
                                                                                                      .data[
                                                                                                      key
                                                                                                  ]
                                                                                              }
                                                                                              download
                                                                                          >
                                                                                              {
                                                                                                  item
                                                                                                      .data[
                                                                                                      key
                                                                                                  ]
                                                                                              }
                                                                                          </a>
                                                                                      ) : (
                                                                                          item
                                                                                              .data[
                                                                                              key
                                                                                          ]
                                                                                      )}
                                                                                  </div>
                                                                              )
                                                                          )
                                                                        : item.data}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                />
            </div>
        </>
    );
};

export default ManagerRequestCard;
