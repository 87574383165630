import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class IntroSlider extends Component {
    render() {
        const sliderSettings = {
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
        };

        return (
            <div className="introtext-slider">
                <Slider {...sliderSettings}>
                    {this.props.data.map((item, index) => {
                        return (
                            <div className="introtext-slider__col" key={index}>
                                <div className="introtext-slider__item">
                                    <img
                                        src={item.img}
                                        alt=""
                                        className="introtext-slider__img"
                                    />
                                    <div
                                        className="introtext-slider__title"
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                    <div
                                        className="introtext-slider__text"
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        );
    }
}
