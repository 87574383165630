import news36 from 'assets/pic/news/programm-1.jpg';
import news37 from 'assets/pic/news/programm-2.jpg';
import news38 from 'assets/pic/news/programm-3.jpg';

export const LastProgramsItems2021 = [
    {
        img: news38,
        title: 'Акселерационная программа 2021',
        link: '/archive2021',
    },
    {
        img: news36,
        title: 'Акселерационная программа 2020',
        link: '/how-it-was',
    },
    {
        img: news37,
        title: 'Акселерационная программа 2019',
        link: '/archive',
    }
];
export const LastProgramsItems2021En = [
    {
        img: news38,
        title: 'Acceleration program 2021',
        link: '/archive2021/en',
    },
    {
        img: news36,
        title: 'Acceleration program 2020',
        link: '/how-it-was/en',
    },
    {
        img: news37,
        title: 'Acceleration program 2019',
        link: '/archive',
    }
];
