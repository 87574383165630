import React, { FC, useState } from 'react';
import './style.sass';
//@ts-ignore
import SlideToggle from 'react-slide-toggle';
import CrossIcon from '../../ui/CrossIcon';

export interface IRender {
    toggle: any;
    setCollapsibleElement: any;
    progress: any;
}

interface IProps {
    que: string;
    ans: string[];
}

const DecisionItem: FC<IProps> = ({ que, ans }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <div className="archive-decisions__col">
            <SlideToggle
                duration={500}
                collapsed
                render={({
                    toggle,
                    setCollapsibleElement,
                    progress,
                }: IRender) => (
                    <div
                        className="archive-decisions__item"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        <div
                            className={`archive-decisions__question ${
                                progress !== 0 ? 'active' : null
                            }`}
                            onClick={toggle}
                        >
                            <div
                                className="archive-decisions__text"
                                dangerouslySetInnerHTML={{
                                    __html: que,
                                }}
                            />
                            <CrossIcon
                                isActive={progress !== 0}
                                isHovering={isHovering}
                            />
                        </div>

                        <div
                            className="archive-decisions__hidden"
                            ref={setCollapsibleElement}
                        >
                            {ans?.length > 0 &&
                                ans.map((item, index) => (
                                    <div
                                        className="archive-decisions__answer"
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

export default DecisionItem;
