import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import iconOpen from '../../../assets/img/svg/icon-edit-open.svg';
import iconClose from '../../../assets/img/svg/icon-edit-close.svg';
import ExpertiseReview from '../Request/ExpertiseReview';
import { RecordTitle } from '../CustomTitles/RecordTitle';
import { EditTitle } from '../CustomTitles/EditTitle';
import ManagerRequestCard from './ManagerRequestCard';
import projectConfig from '../../../data/project-config.json';
import Spinner from '../../ui/Spinner';
import config from '../../../data/project-config.json';
import axios from 'axios';
import AuthRepository from '../../../models/AuthRepository';
import ExpertiseCardView from './ExpertiseCardView';
import Statuses from '../../../data/statusesId.json';
import RedButton from '../../ui/RedButton';
import LKRepository from '../../../models/LKRepository';
import User from '../../../models/User';
import MeetingTable from '../CustomerList/MeetingTable';
import ProjectMaterials from '../ProjectMaterials';
import FunctionalCustomers from '../FunctionalСustomers';
import FunctionalCustomersShowFZ from '../FunctionalСustomersFZ';

const icons = {
    iconOpen,
    iconClose,
};

const generateFormData = obj => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
    });
    return formData;
};
const ManagerShow = ({ permissions, ...props }) => {
    const [title, setTitle] = useState('');
    const [singleRequest, setSingleRequest] = useState({});
    const [idExpert, setIdExpert] = useState('');
    const [isRated, setIsRated] = useState(false);
    const [record, setRecord] = useState({});

    const { getOne } = useDataProvider();
    useEffect(() => {
        getOne('request', { id: props.id }).then(res => {
            setTitle(res.data.NAME_PROJECT);
            setSingleRequest(res.data);
        });
    }, [props.id]);

    useEffect(() => {
        AuthRepository.checkAuth().then(res => {
            setIdExpert(res.data.data);
        });
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnaireall`,
                {}
            )
            .then(response => {
                const r = response.data.data.QUESTIONNAIRE.find(
                    item => +item.id === +props.id
                );
                setRecord(r);
            })
            .catch(err => console.log(err));
    }, [props.id]);

    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.estimategetquestionnaite`,
                generateFormData({ UF_ID_REQUEST: props.id })
            )
            .then(response => {
                const r = Object.keys(response.data.data);
                // const isRated = r.some(item => item === idExpert);
                r.length && setIsRated(true);

                setLoaded(true);
            })
            .catch(err => console.log(err));
    }, [idExpert]);

    const [isLoaded, setLoaded] = useState(false);
    const notify = useNotify();
    const handleStatus = status => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updatequestionarymanager
`,
                LKRepository.generateFormData({
                    ID: props.id,
                    STATUS: status,
                })
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify('Заявка сохранена!');
                    setTimeout(() => {
                        props.history.push('/manager/request');
                    }, 1500);
                } else {
                    notify('Что-то пошло не так');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            {!isLoaded ? (
                <Spinner />
            ) : (
                <>
                    <RecordTitle record={title} />
                    <ManagerRequestCard
                        data={singleRequest}
                        {...props}
                        {...icons}
                    />
                </>
            )}

            {record &&
            record.status === 'На премодерации' &&
            User.getRole(permissions) !== 'functional' ? (
                <div className="expertise-manager-itog__actions expertise-manager__btn-back">
                    <RedButton
                        size="lg-m"
                        type="button"
                        onClick={() => {
                            handleStatus(36);
                        }}
                    >
                        Вернуть на доработку
                    </RedButton>
                </div>
            ) : null}

            <EditTitle title="ru.title.expertReview" />
            <ExpertiseCardView
                {...props}
                permissions={permissions}
                record={record}
                isRated={isRated}
                generateFormData={generateFormData}
            />
            {record &&
                (record.status === 'Одобрена' ||
                    record.status === 'Очный отбор') &&
                User.getRole(permissions) !== 'functional' && (
                    <>
                        <FunctionalCustomers {...props} />
                        {/*<MeetingTable*/}
                        {/*    {...props}*/}
                        {/*    record={record.estimate_expert_type}*/}
                        {/*/>*/}
                        {/*<ProjectMaterials {...props} />*/}
                    </>
                )}

            {record &&
                record.status === 'Одобрена' &&
                User.getRole(permissions) === 'functional' && (
                    <>
                        <FunctionalCustomersShowFZ {...props} />
                    </>
                )}
        </React.Fragment>
    );
};

export default ManagerShow;
