import React, { FC } from 'react';
import './style.sass';

interface IProps {
    items: {
        logo: string;
        title: string;
        text: string;
        link: string;
    }[];
}

const Partners: FC<IProps> = ({ items }) => {
    return (
        <div className="archive-partners">
            <div className="full-wrapper">
                <div className="archive-partners__row">
                    {items?.length > 0 &&
                        items.map(({ logo, title, text, link }, index) => (
                            <div className="archive-partners__col" key={index}>
                                <div className="archive-partners__item archive-partners-item">
                                    <div className="archive-partners-item__left">
                                        <a
                                            href={link}
                                            target="_blank"
                                            className="archive-partners-item__link"
                                        >
                                            <img src={logo} alt={title} />
                                        </a>
                                    </div>
                                    <div className="archive-partners-item__right">
                                        <div
                                            className="archive-partners-item__title"
                                            dangerouslySetInnerHTML={{
                                                __html: title,
                                            }}
                                        />
                                        <div
                                            className="archive-partners-item__text"
                                            dangerouslySetInnerHTML={{
                                                __html: text,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Partners;
