import React, {
    Fragment,
    cloneElement,
    useMemo,
    useEffect,
    useState,
} from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    Responsive,
    TextField,
    TextInput,
    SearchInput,
    EmailField,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
    useListContext,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StatusTextField from '../../ManagerList/Fields/StatusTextField';
import StatusExpertIcon from '../../ManagerList/Fields/StatusExpertIcon';
import ExpertTypeField from '../Fields/ExpertTypeField';
import ActionField from '../Fields/ActionField';
import './style.sass';
import { makeStyles } from '@material-ui/core/styles';
import ExpertListToolbar from '../ExpertListToolbar';
import ExpertsQuestionareTitle from '../ExpertsQuestionareTitle';
import ObjTextField from '../../ManagerList/Fields/ObjTextField';
import EstimateExpField from '../../Request/Fields/EstimateField';

const filterStyles = {
    status: { width: 150 },
};
export const styles = {
    widthHalf: { width: '329px' },
    width: { width: '674px' },
    widthFormGroup: { display: 'inline-block', marginRight: 16 },
    marginBox: { marginBottom: '20px' },
    boxShadow: { boxShadow: 'none' },
    root: { boxShadow: 'none' },
    indicator: { backgroundColor: 'red' },
};

const useStyles = makeStyles(styles);

const ListActions = props => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <ExpertListToolbar {...props} />
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};
const datagridStyles = {
    total: { fontWeight: 'bold' },
};

class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'Все', name: 'Все' },
        { id: 'Ожидают оценки', name: 'Ожидают оценки' },
        { id: 'Отправлены на доработку', name: 'Отправлены на доработку' },
        { id: 'Оценены', name: 'Оценены' },
    ];

    state = {
        Все: [],
        'Ожидают оценки': [],
        'Отправлены на доработку': [],
        Оценены: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <Responsive
                    medium={
                        <div>
                            {filterValues.status === 'Все' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Все']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <EstimateExpField
                                        source="estimate"
                                        label="Оценка"
                                        cellClassName="request-estimate"
                                    />
                                    <TextField source="state" label="Stage" />
                                    <ActionField
                                        source="id"
                                        label="Действия"
                                        IdExpert={filterValues.id}
                                    />
                                </Datagrid>
                            )}
                            {filterValues.status === 'Ожидают оценки' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Ожидают оценки']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <EstimateExpField
                                        source="estimate"
                                        label="Оценка"
                                        cellClassName="request-estimate"
                                    />
                                    <TextField source="state" label="Stage" />
                                    <ActionField
                                        source="id"
                                        label="Действия"
                                        IdExpert={filterValues.id}
                                    />
                                </Datagrid>
                            )}
                            {filterValues.status ===
                                'Отправлены на доработку' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Отправлены на доработку']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <EstimateExpField
                                        source="estimate"
                                        label="Оценка"
                                        cellClassName="request-estimate"
                                    />
                                    <TextField source="state" label="Stage" />
                                    <ActionField
                                        source="id"
                                        label="Действия"
                                        IdExpert={filterValues.id}
                                    />
                                </Datagrid>
                            )}
                            {filterValues.status === 'Оценены' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Оценены']}
                                    className={classes.root}
                                >
                                    <TextField source="date" label="Дата" />
                                    <StatusTextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField source="status" label="Статус" />
                                    <ObjTextField
                                        source="vector"
                                        label="Отраслевое направление"
                                        sortable={false}
                                    />
                                    <EstimateExpField
                                        source="estimate"
                                        label="Оценка"
                                        cellClassName="request-estimate"
                                    />
                                    <TextField source="state" label="Stage" />
                                    <ActionField
                                        source="id"
                                        label="Действия"
                                        IdExpert={filterValues.id}
                                    />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const ExpertsQuestionareList = ({ classes, ...props }) => {
    return (
        <Fragment>
            <ExpertsQuestionareTitle {...props} />
            <List
                {...props}
                filterDefaultValues={{ status: 'Все' }}
                sort={{ order: 'DESC' }}
                perPage={25}
                actions={
                    <ListActions {...props} className="expertListToolbarBox" />
                }
            >
                <StyledTabbedDatagrid />
            </List>
        </Fragment>
    );
};

export default ExpertsQuestionareList;
