import React from 'react';
import { Datagrid, RichTextField, TextField } from 'react-admin';
import './style.sass';
import EstimateExpField from '../Fields/EstimateField';
import ActionField from '../Fields/ActionField';

const DatagridTab = props => {
    return (
        <Datagrid {...props} ids={props.ids} optimized>
            <TextField
                source="date"
                label="Дата"
                cellClassName="request-date"
            />
            <TextField
                source="name"
                label="Название"
                cellClassName="request-name"
            />
            <EstimateExpField
                source="estimate"
                label="Оценка"
                cellClassName="request-estimate"
            />
            <RichTextField
                source="comment"
                label="Комментарий"
                multiLine
                cellClassName="request-comment"
            />
            <ActionField source="id" label="Действия" />
        </Datagrid>
    );
};

export default DatagridTab;
