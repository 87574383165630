import React, { FC } from 'react';
import './style.sass';
import { metaApplication } from '../../../types/application';
import MetaEntryPdf from '../MetaEntryPdf';
import MetaEntryStatus from '../MetaEntryStatus';
import MetaEntryIcon from '../MetaEntryIcon';
import MetaEntryComment from '../MetaEntryComment';
import { Link } from 'react-router-dom';

interface IProps {
    metaApplication: metaApplication | null;
}

const ParticipantEntry: FC<IProps> = ({ metaApplication }) => {
    if (!metaApplication)
        return <div className="participant-entry__title">Заявки</div>;

    const { status, comment, name, pdfLink } = metaApplication!;

    return (
        <div className="participant-entry">
            {metaApplication && (
                <>
                    <div className="participant-entry__title">Заявки</div>
                    <div className="meta-entry">
                        <MetaEntryIcon status={status} />
                        <div className="meta-entry__body">
                            <Link
                                className="meta-entry__title"
                                to="application-2021"
                            >
                                {name}
                            </Link>
                            <MetaEntryStatus status={status} />
                            {comment &&
                                (status === 'Черновик' ||
                                    status === 'На доработке') && (
                                    <MetaEntryComment text={comment} />
                                )}
                        </div>
                        <MetaEntryPdf pdfLink={pdfLink} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ParticipantEntry;
