import React, { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import './style.sass';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import StatusesExcel from '../../../data/statusesExcel.json';

const ExportButton = props => {
    const [file, setFile] = useState();

    const context = useListContext();
    const exportParams = {
        // SORT_FIELD: 'DATE_CREATE',
        // SORT_VECTOR: context.currentSort.order,
        // STATE: context.filterValues.status,
        // VECTOR: '',
        // VECTOR_OTHER: '',
        // COMMENT: '',
        STATUS: StatusesExcel[context.filterValues.status],
    };
    const generateFormData = obj => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            formData.append(key, obj[key]);
        });
        return formData;
    };
    useEffect(() => {
        async function fetchData() {
            let response = await axios.post(
                '/bitrix/services/main/ajax.php?action=webpractik:main.forms.generateexcel',
                generateFormData(exportParams)
            );
            let data = response.data.data;
            setFile(data);
        }

        fetchData().then(res => console.log(res));
    }, [context.filterValues.status]);

    return (
        <a className="export-button" href={file ? file : null} download>
            <GetAppIcon /> Экспорт
        </a>
    );
};

export default ExportButton;
