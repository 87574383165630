import React, { FC } from 'react';
import './style.sass';

interface IProps {
    text: string;
}

const MetaEntryComment: FC<IProps> = ({ text }) => {
    return (
        <div className="meta-entry-comment">
            <div className="meta-entry-comment__title">
                Комментарий модератора
            </div>
            <div className="meta-entry-comment__text">{text}</div>
        </div>
    );
};

export default MetaEntryComment;
