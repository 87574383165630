import React, { useState } from 'react';
import Tabs from '../Tabs';
import ExpertsTabsItem from './item';
import parse from 'html-react-parser';
import cn from './style.module.sass';
import clsx from 'clsx';
import { ExpertsItems2021, ExpertsItems2021En } from '../../data/2021/experts';
import { ExpertsItems2022, ExpertsItems2022En } from '../../data/2022/experts';

const ExpertsTabs = ({ langRU, year }) => {

    const getData = () => {
        if (year === '2022')  return langRU ? ExpertsItems2022 : ExpertsItems2022En;
        if (year === '2021')  return langRU ? ExpertsItems2021 : ExpertsItems2021En;
    };


    const initialTab = getData()[0].category;
    const initialTabLabel = langRU ? 'Все' : 'All';
    const [activeTab, setActiveTab] = useState(initialTab);
    const [experts, setExperts] = useState(getData());
    const [animation, setAnimation] = useState(false);
    const [show, setShow] = useState(false);
    const tabs = getData().map(({ category }) => category);

    const initAnimation = () => {
        setAnimation(true);
        setTimeout(() => setAnimation(false), 500);
    };

    const handleTab = tab => {
        setActiveTab(tab);
        initAnimation();
    };

    const getContent = () => {
        if (['All', 'Все'].includes(activeTab)) {
            return experts.flatMap(({ experts }) => experts);
        }
        return experts.find(({ category }) => category === activeTab).experts;
    };

    const toggleVisibility = () => {
        const allShowedExperts = experts.map(item => {
            const state = !show;
            const newExperts = item.experts.map(experts => ({
                ...experts,
                show: state,
            }));
            return { ...item, experts: newExperts };
        });
        setExperts(allShowedExperts);
        setShow(prev => !prev);
    };

    const getBtnLabel = () => {
        if (show) {
            return langRU ? parse(`Скрыть`) : parse(`Hide`);
        }
        return langRU ? parse(`Все эксперты`) : parse(`All experts`);
    };

    const hasHiddenExperts = () => {
        return [
            'Все',
            'All',
            'Эксперты&nbsp;&laquo;РЖД&raquo;',
            'Russian&nbsp;Railways&nbsp;experts',
        ].includes(activeTab);
    };

    return (
        <section
            id="graph"
            className="experts"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="experts__title">
                    {langRU ? parse(`Эксперты`) : parse(`Experts`)}
                </div>
                <Tabs
                    tabs={[...tabs, initialTabLabel]}
                    activeTab={activeTab}
                    handleTab={handleTab}
                >
                    <div className={clsx(cn.row, animation && cn.anim)}>
                        {getContent().map((item, index) => {
                            return <ExpertsTabsItem key={index} item={item} />;
                        })}
                    </div>
                    {hasHiddenExperts() && (
                        <div
                            className={cn.linkWrapper}
                            onClick={toggleVisibility}
                        >
                            <div className={cn.link}>{getBtnLabel()}</div>
                        </div>
                    )}
                </Tabs>
            </div>
        </section>
    );
};

export default ExpertsTabs;
