import React, { FC } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './style.sass';
// @ts-ignore
import { ReactComponent as CheckedIcon } from '../../../../assets/img/svg/form/checked.svg';
import { FormHelperText } from '@material-ui/core';

interface IProps {}

const BasicRadioGroup: FC<any> = props => {
    const {
        currentValue,
        label,
        options,
        error = false,
        helperText = '',
        onChange,
        onBlur,
        name,
    } = props;

    return (
        <div className="lk-radio-group">
            <div className="lk-radio-group__title">{label}</div>
            <RadioGroup name={name} className="lk-radio-group__list">
                {options.map(({ value, label }: any, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={value}
                        label={label}
                        classes={{ label: 'lk-radio-group__label' }}
                        control={
                            <Radio
                                checked={+currentValue === value}
                                classes={{ root: 'lk-radio-group__radio' }}
                                checkedIcon={<CheckedIcon />}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        }
                    />
                ))}
            </RadioGroup>
            <FormHelperText error={error}>
                {error ? helperText : ''}
            </FormHelperText>
        </div>
    );
};

export default BasicRadioGroup;
