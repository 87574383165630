import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import MaterialModal from './MaterialModal';

const ProjectMaterials = props => {
    const [isOpen, setIsOpen] = React.useState(false);
    const modalToggle = active => {
        setIsOpen(active);
    };
    const [appId, setAppId] = useState(null);
    useEffect(() => {
        const getParamsPage = props.location.search;
        if (getParamsPage.includes('id')) {
            const appId = new URLSearchParams(getParamsPage).get('id');
            setAppId(appId);
        }
    }, [props.location.search]);
    return (
        <div className="func-customers">
            <ResourceTitlePage text="Материалы проекта" />

            {appId && !props.fzLinkId ? (
                <>
                    <div className="customers-modal-btn__wr">
                        <span
                            className="customers-modal-btn"
                            onClick={() => {
                                modalToggle(true);
                            }}
                        >
                            Загрузить материалы
                        </span>
                    </div>
                    <MaterialModal
                        isOpen={isOpen}
                        toggle={modalToggle}
                        id={appId}
                    />
                </>
            ) : (
                <>
                    <div className="customers-modal-btn__wr">
                        <span
                            className="customers-modal-btn"
                            onClick={() => {
                                modalToggle(true);
                            }}
                        >
                            Загрузить материалы
                        </span>
                    </div>
                    <MaterialModal
                        isOpen={isOpen}
                        toggle={modalToggle}
                        id={props.fzLinkId}
                    />
                </>
            )}

            <div className="func-customers__row">
                <div className="func-customers-item">
                    <div className="func-customers-item__row">
                        <div className="func-customers-project-item">
                            <div className="func-customers-project-item-log">
                                {props.record && (
                                    <div className="func-customers-project-item-log__row func-customers-project-item-log__row--header">
                                        <div className="func-customers-project-item-log__col">
                                            Дата загрузки
                                        </div>
                                        <div className="func-customers-project-item-log__col">
                                            Файл
                                        </div>
                                        <div className="func-customers-project-item-log__col">
                                            Комментарий
                                        </div>
                                        <div className="func-customers-project-item-log__col">
                                            Автор
                                        </div>
                                    </div>
                                )}

                                {props.record?.map(item => {
                                    return (
                                        <div className="func-customers-project-item-log__row">
                                            <div className="func-customers-project-item-log__col">
                                                {item.uploadDate}
                                            </div>
                                            <div className="func-customers-project-item-log__col">
                                                <a
                                                    href={item.files[0].path}
                                                    download
                                                >
                                                    {item.files[0].name}
                                                </a>
                                            </div>
                                            <div className="func-customers-project-item-log__col">
                                                {item.comment}
                                            </div>
                                            <div className="func-customers-project-item-log__col">
                                                {item.author}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectMaterials;
