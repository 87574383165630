import React, { FC } from 'react';
import './style.sass';

interface IProps {
    title: string;
    items: { date: string; text: string; link: string; label: string }[];
}

const Events: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-events" id="archive-events">
            <div className="full-wrapper">
                <div
                    className="archive-events__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="archive-events__row">
                    {items?.length > 0 &&
                        items.map(({ date, text, link, label }, index) => (
                            <div className="archive-events__col" key={index}>
                                <div className="archive-events__item archive-events-item">
                                    <div
                                        className="archive-events-item__date"
                                        dangerouslySetInnerHTML={{
                                            __html: date,
                                        }}
                                    />
                                    <div
                                        className="archive-events-item__text"
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    />
                                    <a
                                        href={link}
                                        target="_blank"
                                        className="archive-events-item__link"
                                    >
                                        {label}
                                    </a>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Events;
