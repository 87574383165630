import * as React from 'react';

const ExpertTypeField = ({ source, record = {}, emptyText }) => {
    if (record[source]) {
        return (
            <div className={`expert-type-list`}>
                {record[source] === '3' ? 'Бизнес' : 'Технология'}
            </div>
        );
    } else {
        return emptyText;
    }
};

export default ExpertTypeField;
