import React, { FC, useState } from 'react';
import './style.sass';
import { IRender } from '../DecisionItem';
//@ts-ignore
import SlideToggle from 'react-slide-toggle';
import ExpertsRow from '../ExpertsRow';

interface IProps {
    title: string;
    items: { img: string; name: string; position: string }[];
}

const Experts: FC<IProps> = ({ title, items }) => {
    const DEFAULT_COUNT = 8;

    const [expertsToShow, setExpertsToShow] = useState<number>(DEFAULT_COUNT);

    const toggleExperts = () => {
        if (expertsToShow === DEFAULT_COUNT) {
            return setExpertsToShow(items.length);
        }
        setExpertsToShow(DEFAULT_COUNT);
    };

    return (
        <div className="archive-experts">
            <div className="full-wrapper">
                <div
                    className="archive-experts__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <ExpertsRow items={items} defaultCount={DEFAULT_COUNT} />

                <SlideToggle
                    duration={500}
                    collapsed={true}
                    render={({
                        toggle,
                        setCollapsibleElement,
                        progress,
                    }: IRender) => (
                        <div className="archive-experts__collapsed">
                            <div className="archive-experts__show-more">
                                <div
                                    className="archive-experts__btn"
                                    onClick={() => {
                                        toggleExperts();
                                        toggle();
                                    }}
                                >
                                    {expertsToShow === DEFAULT_COUNT
                                        ? 'Показать еще'
                                        : 'Свернуть'}
                                </div>
                            </div>

                            <div
                                className="archive-experts__row"
                                ref={setCollapsibleElement}
                            >
                                {items?.length > 0 &&
                                    items.map(
                                        ({ img, name, position }, index) => {
                                            if (index + 1 > DEFAULT_COUNT) {
                                                return (
                                                    <div
                                                        className="archive-experts__col"
                                                        key={index}
                                                    >
                                                        <div className="archive-experts__item">
                                                            <img
                                                                src={img}
                                                                alt={name}
                                                                className="archive-experts__img"
                                                            />
                                                            <div
                                                                className="archive-experts__name"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: name,
                                                                }}
                                                            />
                                                            <div
                                                                className="archive-experts__position"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: position,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    )}
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default Experts;
