import partnersAlt1 from 'assets/pic/partnersAlt/partner-01.jpg';
import partnersAlt2 from 'assets/pic/partnersAlt/partner-02.jpg';
import partnersAlt3 from 'assets/pic/partnersAlt/kuzbass.jpg';
import partnersAlt4 from 'assets/pic/partnersAlt/partner-03.jpg';
import ictmoscow from 'assets/pic/partnersAlt/ictmoscow.jpg';
import moscowSeedFund from 'assets/pic/partnersAlt/moscow-seed-fund.jpg';
import starhub from 'assets/pic/partnersAlt/starhub.jpg';
import berza from 'assets/pic/partnersAlt/berza.png';
import rbru from 'assets/pic/partnersAlt/rbru.png';
import innovation from 'assets/pic/partnersAlt/innovation.png';
import sgu from 'assets/pic/partnersAlt/sgu.png';
import hack from 'assets/pic/partnersAlt/hack.png';
import ingria from 'assets/pic/partnersAlt/ingria.png';
import technorussian from 'assets/pic/partnersAlt/technorussian.png';
import magazine from 'assets/pic/partnersAlt/magazine.png';
import startup from 'assets/pic/partnersAlt/startup.png';
import itmo from 'assets/pic/partnersAlt/itmo.png';
import madi from 'assets/pic/partnersAlt/madi.png';
import nti from 'assets/pic/partnersAlt/nti.png';
import startechvc from 'assets/pic/partnersAlt/startechvc.png';
import technomoscow from 'assets/pic/partnersAlt/technomoscow.png';
import mfti from 'assets/pic/partnersAlt/mfti.png';
import cdo2day from 'assets/pic/partnersAlt/cdo2day.png';
import rosbis from 'assets/pic/partnersAlt/rosbis.png';
import roscarier from 'assets/pic/partnersAlt/roscarier.png';
import msubis from 'assets/pic/partnersAlt/msubis.png';
import yellowrockets from 'assets/pic/partnersAlt/yellowrockets.png';

export const PartnersAltItems2022 = [
    { img: partnersAlt1, link: 'www.rzdtv.ru', href: 'http://www.rzdtv.ru/' },
    { img: partnersAlt2, link: 'www.gudok.ru', href: 'https://gudok.ru/' },
    {
        img: partnersAlt3,
        link: 'www.technopark42.ru',
        href: 'https://technopark42.ru/',
    },
    {
        img: partnersAlt4,
        link: 'www.technovery.com',
        href: 'https://technovery.com/',
    },
    {
        img: ictmoscow,
        link: 'www.ict.moscow',
        href: 'https://ict.moscow/',
    },
    {
        img: moscowSeedFund,
        link: 'www.mosinnov.ru',
        href: 'https://www.mosinnov.ru/',
    },
    {
        img: starhub,
        link: 'starthub.moscow',
        href: 'https://starthub.moscow/',
    },
    {
        img: berza,
        link: 'www.berza.ru',
        href: 'https://berza.ru/',
    },
    {
        img: rbru,
        link: 'www.rb.ru',
        href: 'https://rb.ru/',
    },
    {
        img: innovation,
        link: 'www.fasie.ru',
        href: 'https://fasie.ru/',
    },
    {
        img: sgu,
        link: 'www.sgu.ru',
        href: 'https://www.sgu.ru/structure/uid/biznes-inkubator',
    },
    {
        img: hack,
        link: 'www.hackagencyrussia.ru',
        href: 'https://hackagencyrussia.ru/',
    },
    {
        img: startup,
        link: 'www.t.me/startupchances',
        href: 'https://t.me/startupchances',
    },
    {
        img: ingria,
        link: 'https://ingria-park.ru/',
        href: 'https://ingria-park.ru/',
    },
    {
        img: technorussian,
        link: 'https://rutechpark.ru/',
        href: 'https://rutechpark.ru/',
    },
    {
        img: magazine,
        link: 'https://avtprom.ru/',
        href: 'https://avtprom.ru/',
    },
    {
        img: itmo,
        link: 'www.accel.itmo.ru/',
        href: 'https://accel.itmo.ru/',
    },
    {
        img: madi,
        link: 'www.madi.ru/',
        href: 'https://www.madi.ru/',
    },
    {
        img: nti,
        link: 'www.nti2035.ru',
        href: 'https://nti2035.ru/',
    },
    {
        img: startechvc,
        link: 'www.startech.vc/ru',
        href: 'https://startech.vc/ru',
    },
    {
        img: technomoscow,
        link: 'www.technomoscow.ru',
        href: 'https://technomoscow.ru/',
    },
    {
        img: mfti,
        link: 'www.mipt.ru/',
        href: 'https://mipt.ru/',
    },
    {
        img: cdo2day,
        link: 'www.cdo2day.ru/',
        href: 'https://cdo2day.ru/',
    },
    {
        img: rosbis,
        link: 'www.vk.com/rosmolodez.business',
        href: 'https://vk.com/rosmolodez.business',
    },
    {
        img: roscarier,
        link: 'www.vk.com/rosmolodez.career',
        href: 'https://vk.com/rosmolodez.career',
    },
    {
        img: msubis,
        link: 'www.inmsu.ru/ru/',
        href: 'http://www.inmsu.ru/ru/',
    },
    {
        img: yellowrockets,
        link: 'www.yellowrockets.com/',
        href: 'https://yellowrockets.com/',
    },
];
