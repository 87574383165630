import React from 'react';

import './style.sass';

const IndustriesItem = ({ item }) => (
    <div className="industries__col" data-aos="zoom-in" data-aos-delay="800">
        <div className="industries__item">
            <div className="industries__img">
                <img src={item.icon} alt="" />
            </div>

            <div
                className="industries__text"
                dangerouslySetInnerHTML={{
                    __html: item.text,
                }}
            />
        </div>
    </div>
);

export default IndustriesItem;
