import React, { FC, Fragment } from 'react';
import './style.sass';
import { Divider } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
// @ts-ignore
import { ReactComponent as NotEstimate } from '../../../../assets/img/svg/notEstimate.svg';

interface IProps {
    [p: string]: any;
}

const BasicTextRatingField: FC<IProps> = ({
    name,
    hintMessage,
    hidden,
    dependencyValue,
    dependency,
    maskComponent,
    onChange,
    setFieldValue,
    calcEstimate,
    error,
    helperText,
    ...rest
}) => {
    const [value, setValue] = React.useState(null);

    const inValuesArray =
        Array.isArray(dependencyValue) &&
        dependencyValue.includes(dependency?.value);
    const valuesEqual = dependencyValue === dependency?.value;

    if (hidden && !valuesEqual && !inValuesArray) {
        return <Fragment />;
    }

    return (
        <>
            <div className="estimate-field">
                <NotEstimate
                    className={`estimate__not-estimate ${
                        value === null ? 'active' : 'null'
                    }`}
                    onClick={() => setValue(null)}
                />
                <Box component="fieldset">
                    <Rating
                        name={name}
                        value={value}
                        onChange={(event, newValue) => {
                            // @ts-ignore
                            setValue(newValue);
                            onChange(event, name, newValue);
                            calcEstimate(name, newValue);
                        }}
                    />
                </Box>
                <div className="estimate-field__grade">
                    <span className="estimate-field__grade--big">
                        {value ? value : 0}
                    </span>
                    /5
                </div>
                {error && (
                    <span className="helper-text__error">{helperText}</span>
                )}
            </div>

            <Divider />
        </>
    );
};

export default BasicTextRatingField;
