import React, { useState } from 'react';
import './style.sass';
import AdminTextField from '../../form/AdminTextField';
import { Form, Formik } from 'formik';
import RedButton from '../../ui/RedButton';
import FlexContainerCenter from '../../layout/FlexContainerCenter';
import * as Yup from 'yup';
import errorMessages from 'data/errorMessages.json';
import LKRepository from '../../../models/LKRepository';
import AdminPasswordTextField from '../../form/AdminPasswordTextField';
import WrapperPairFields from '../WrapperPairFields';
import axios from 'axios';
import User from '../../../models/User';

const RestorePassword = props => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getSchema = () => {
        const {
            required,
            minPassword,
            minLowercase,
            minUppercase,
            passwordsMatch,
        } = errorMessages;

        return Yup.object().shape({
            pass: Yup.string()
                .min(8, minPassword)
                .matches(/[a-z]/, minLowercase)
                .matches(/[A-Z]/, minUppercase)
                .required(required),
            confirm_pass: Yup.string()
                .oneOf([Yup.ref('pass'), undefined], passwordsMatch)
                .required(passwordsMatch),
        });
    };

    const handleSubmit = values => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.updateuser`,
                LKRepository.generateFormData({
                    ...values,
                    id: props.paramsUserId,
                })
            )
            .then(res => {
                if (res.data.data === true) {
                    setSuccessMessage('Пароль успешно сменен');
                } else {
                    res.data.errors.map(item => {
                        setErrorMessage(item.message);
                    });
                }
                console.log(res.data.data);
            })
            .catch(err => console.log(err));
    };

    if (successMessage.length)
        return <div className="forget-success">{successMessage}</div>;

    return (
        <>
            <div className="forget-title">
                Установите новый пароль для доступа
            </div>
            <Formik
                initialValues={{ pass: '', confirm_pass: '' }}
                onSubmit={handleSubmit}
                validationSchema={getSchema}
            >
                {props => (
                    <Form>
                        <FlexContainerCenter>
                            <div className="authorization-form-wrap">
                                <AdminPasswordTextField
                                    formikProps={props}
                                    label="Новый пароль"
                                    name="pass"
                                    type="password"
                                />
                                <AdminPasswordTextField
                                    formikProps={props}
                                    label="Подтвердите пароль"
                                    name="confirm_pass"
                                    type="password"
                                />
                            </div>
                            <RedButton size="sm" type="submit">
                                Отправить
                            </RedButton>
                        </FlexContainerCenter>
                    </Form>
                )}
            </Formik>
            {(errorMessage.length && (
                <div className="forget-error">{errorMessage}</div>
            )) ||
                null}
        </>
    );
};

export default RestorePassword;
