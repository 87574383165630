import * as React from 'react';
import { ReactComponent as CallingIcon } from '../../../../assets/img/svg/admin/phone.svg';

const statusRequest = status => {
    switch (status) {
        case 'Черновик':
            return '';
        case 'На доработке':
            return '';
        case 'На премодерации':
            return 'red';
        case 'На экспертизе':
            return 'red';
        case 'Отклонена':
            return '';
        case 'Одобрена':
            return '';
        default:
            return '';
    }
};

const StatusTextField = ({ source, record = {} }) => {
    return (
        <div className="status-field-icon">
            {record.calling && (
                <CallingIcon className="status-field-icon__call" />
            )}
            <div className={`status-field ${statusRequest(record.status)}`}>
                {record[source]}
            </div>
        </div>
    );
};

export default StatusTextField;
