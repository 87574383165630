import React, { Fragment } from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import DatagridTab from '../DatagridTab';
import './style.sass';

export class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'На премодерации', name: 'Премодерация' },
        { id: 'На экспертизе', name: 'Экспертиза' },
        // { id: 'Акселератор', name: 'Акселератор' },
        // { id: 'Demoday', name: 'Demoday' },
        // { id: 'Сопровождение', name: 'Сопровождение' },
    ];
    style = {
        caption: {
            font: 'bold 14px/171% Helvetica',
            color: '#212121',
        },
    };
    state = {
        'На премодерации': [],
        'На экспертизе': [],
        // Акселератор: [],
        // Demoday: [],
        // Сопровождение: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {
        const { isXSmall, classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                {/*<Tabs*/}
                {/*    variant="fullWidth"*/}
                {/*    centered*/}
                {/*    value={filterValues.status}*/}
                {/*    indicatorColor="primary"*/}
                {/*    onChange={this.handleChange}*/}
                {/*    className="request-list"*/}
                {/*>*/}
                {/*    {this.tabs.map(choice => (*/}
                {/*        <Tab*/}
                {/*            className="request-list__tab"*/}
                {/*            key={choice.id}*/}
                {/*            label={choice.name}*/}
                {/*            value={choice.id}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</Tabs>*/}
                {/*<Divider />*/}
                <DatagridTab {...props} ids={this.state['На экспертизе']} />
                {/*<div className="request-table">*/}
                {/*    {filterValues.status === 'На премодерации' && (*/}
                {/*        <DatagridTab*/}
                {/*            {...props}*/}
                {/*            ids={this.state['На премодерации']}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    {filterValues.status === 'На экспертизе' && (*/}
                {/*        <DatagridTab*/}
                {/*            {...props}*/}
                {/*            ids={this.state['На экспертизе']}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    {filterValues.status === 'acselerator' && (*/}
                {/*        <DatagridTab {...props} ids={this.state.acselerator} />*/}
                {/*    )}*/}
                {/*    {filterValues.status === 'demoday' && (*/}
                {/*        <DatagridTab {...props} ids={this.state.demoday} />*/}
                {/*    )}*/}
                {/*    {filterValues.status === 'escort' && (*/}
                {/*        <DatagridTab {...props} ids={this.state.escort} />*/}
                {/*    )}*/}
                {/*</div>*/}
            </Fragment>
        );
    }
}
