import React from 'react';
import './style.sass';

const OrganizersItem = ({ langRU, item, alt }) => (
    <div className="organizers__col" data-aos="zoom-in" data-aos-delay="800">
        <div className={`organizers__item organizers-item ${alt && 'alt'}`}>
            <div className="organizers-item__img">
                <img
                    src={item.logo}
                    className={`${langRU && 'organizers-item__img--en'}`}
                    alt=""
                />
            </div>
            <div
                className="organizers-item__text"
                dangerouslySetInnerHTML={{
                    __html: item.text,
                }}
            />
            {item?.photo && (
                <div className="organizers-item__img">
                    <img src={item?.photo} alt="" />
                </div>
            )}
        </div>
    </div>
);

export default OrganizersItem;
