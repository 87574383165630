import React, { FC } from 'react';
import './style.sass';

interface IProps {
    pdfLink: string;
}

const MetaEntryPdf: FC<IProps> = ({ pdfLink }) => {
    return (
        <a className="meta-entry-pdf" href={pdfLink} download>
            Скачать PDF
        </a>
    );
};

export default MetaEntryPdf;
