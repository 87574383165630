import React, { Fragment } from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    Responsive,
    TextField,
    TextInput,
    SearchInput,
    EmailField,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StatusTextField from '../../ManagerList/Fields/StatusTextField';
import StatusExpertIcon from '../../ManagerList/Fields/StatusExpertIcon';
import ExpertTypeField from '../Fields/ExpertTypeField';
import ActionField from '../Fields/ActionField';
import './style.sass';
import { makeStyles } from '@material-ui/core/styles';
import ResourceTitlePage from '../../../ui/ResourceTitlePage';
import ExpertNameField from '../Fields/ExpertName';

const filterStyles = {
    status: { width: 150 },
};
export const styles = {
    widthHalf: { width: '329px' },
    width: { width: '674px' },
    widthFormGroup: { display: 'inline-block', marginRight: 16 },
    marginBox: { marginBottom: '20px' },
    boxShadow: { boxShadow: 'none' },
    root: { boxShadow: 'none' },
    indicator: { backgroundColor: 'red' },
};

const useStyles = makeStyles(styles);

export const ExpertsListFilter = props => {
    const classes = useStyles();
    return (
        <Filter {...props} className={classes.marginBox}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};
const datagridStyles = {
    total: { fontWeight: 'bold' },
};

class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'Все', name: 'Все' },
        { id: '3', name: 'Бизнес' },
        { id: '4', name: 'Технология' },
    ];

    state = {
        Все: [],
        Бизнес: [],
        Технология: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }

        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <Responsive
                    medium={
                        <div>
                            {filterValues.status === 'Все' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Все']}
                                    className={classes.root}
                                >
                                    <ExpertNameField
                                        label="Имя"
                                        emptyText="нет данных"
                                    />
                                    <TextField
                                        source="position"
                                        label="Должность"
                                        emptyText="нет данных"
                                    />
                                    <EmailField source="email" label="E-mail" />
                                    <ExpertTypeField
                                        source="TYPE"
                                        label="Экспертиза"
                                        emptyText="не назначен"
                                    />

                                    <TextField
                                        source="COUNT_ALL"
                                        label="Назначено"
                                        textAlign="center"
                                        emptyText="-"
                                    />
                                    <TextField
                                        source="COUNT_ESTIMATE"
                                        label="Оценено"
                                        textAlign="center"
                                        emptyText="-"
                                    />

                                    <ActionField source="id" label="Действия" />
                                </Datagrid>
                            )}
                            {filterValues.status === '3' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Бизнес']}
                                    className={classes.root}
                                >
                                    <ExpertNameField
                                        label="Имя"
                                        emptyText="нет данных"
                                    />
                                    <TextField
                                        source="position"
                                        label="Должность"
                                        emptyText="нет данных"
                                    />
                                    <EmailField source="email" label="E-mail" />
                                    <ExpertTypeField
                                        source="TYPE"
                                        label="Экспертиза"
                                        emptyText="не назначен"
                                    />

                                    <TextField
                                        source="COUNT_ALL"
                                        label="Назначено"
                                        textAlign="center"
                                        emptyText="-"
                                    />
                                    <TextField
                                        source="COUNT_ESTIMATE"
                                        label="Оценено"
                                        textAlign="center"
                                        emptyText="-"
                                    />

                                    <ActionField source="id" label="Действия" />
                                </Datagrid>
                            )}
                            {filterValues.status === '4' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['Технология']}
                                    className={classes.root}
                                >
                                    <ExpertNameField
                                        label="Имя"
                                        emptyText="нет данных"
                                    />
                                    <TextField
                                        source="position"
                                        label="Должность"
                                        emptyText="нет данных"
                                    />
                                    <EmailField source="email" label="E-mail" />
                                    <ExpertTypeField
                                        source="TYPE"
                                        label="Экспертиза"
                                        emptyText="не назначен"
                                    />

                                    <TextField
                                        source="COUNT_ALL"
                                        label="Назначено"
                                        textAlign="center"
                                        emptyText="-"
                                    />
                                    <TextField
                                        source="COUNT_ESTIMATE"
                                        label="Оценено"
                                        textAlign="center"
                                        emptyText="-"
                                    />

                                    <ActionField source="id" label="Действия" />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const ExpertsList = ({ classes, ...props }) => (
    <Fragment>
        <ResourceTitlePage text="Эксперты" />
        <List
            {...props}
            filterDefaultValues={{ status: 'Все' }}
            sort={{ order: 'DESC' }}
            perPage={25}
            filters={<ExpertsListFilter />}
        >
            <StyledTabbedDatagrid />
        </List>
    </Fragment>
);

export default ExpertsList;
