import React, { FC } from 'react';
import './style.sass';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, FormHelperText } from '@material-ui/core';

interface IProps {
    label: string | JSX.Element;
    name: string;
    formikProps: any;
    labelSm?: boolean;
    [p: string]: any;
}

const ControlCheckbox: FC<IProps> = props => {
    const { formikProps, name, labelSm, ...restProps } = props;

    const { values, handleChange, handleBlur, errors, touched } = formikProps;

    const error = errors[name] && touched[name];

    const classNameCheckbox = ['admin-control-checkbox'];

    if (error) {
        classNameCheckbox.push('admin-control-checkbox--error');
    }

    return (
        <div className="control-checkbox-wrap">
            <FormControlLabel
                className="admin-control-root"
                control={
                    <Checkbox
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={name}
                        color="primary"
                        className={classNameCheckbox.join(' ')}
                        value={values[name]}
                        classes={{
                            root: labelSm
                                ? 'admin-control-checkbox__checkbox'
                                : '',
                        }}
                    />
                }
                {...restProps}
                classes={{
                    label: labelSm ? 'admin-control-checkbox__label' : '',
                }}
            />
            <FormHelperText error={error}>
                {error ? errors[name] : ''}
            </FormHelperText>
        </div>
    );
};

export default ControlCheckbox;
