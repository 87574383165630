import React, { FC } from 'react';
import './style.sass';

interface IProps {
    img: string;
    name: string;
    position: string;
}

const ExpertsItem: FC<IProps> = ({ name, position, img }) => {
    return (
        <div className="archive-experts__col">
            <div className="archive-experts__item">
                <img src={img} alt={name} className="archive-experts__img" />
                <div
                    className="archive-experts__name"
                    dangerouslySetInnerHTML={{
                        __html: name,
                    }}
                />
                <div
                    className="archive-experts__position"
                    dangerouslySetInnerHTML={{
                        __html: position,
                    }}
                />
            </div>
        </div>
    );
};

export default ExpertsItem;
