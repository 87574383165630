import React from 'react';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import BodyResource from '../BodyResource';
import Spinner from '../../ui/Spinner';
import ExpertsProfileEditForm from '../ExpertsProfileEditForm';

class ExpertsProfileEdit extends React.Component {
    state = {
        isLoading: true,
    };

    componentDidMount() {
        //    запросить данные о заявке
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 500);
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                <ResourceTitlePage text="Профиль" />
                <BodyResource contentMaxWidthProfile>
                    {isLoading ? <Spinner /> : <ExpertsProfileEditForm />}
                </BodyResource>
            </>
        );
    }
}

export default ExpertsProfileEdit;
