import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import logoEn from '../../../assets/img/svg/first-screen-logo-en.svg';
import logo from '../../../assets/img/svg/first-screen-logo.svg';
import videoAlt from '../../../assets/video/bg-movie-alt.mov';
import './style.sass';
import TextButton from '../../ui/TextButton';
import ExportButton from '../../ui/ExportButton';

const FirstScreen = ({
    full,
    langRU,
    bg = '',
    outbox = '',
    type,
    year = '2021',
}) => {
    const regLink = langRU
        ? // ? '/lk#/login'
          // : 'https://www.f6s.com/russian-railways-accelerator-logistics/apply';
          'https://account.generation-startup.ru/'
        : 'https://account.generation-startup.ru/';

    useEffect(() => {
        if (type === 'video') {
            document.addEventListener('DOMContentLoaded', function (event) {
                Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
                    get: function () {
                        return (
                            this.currentTime > 0 &&
                            !this.paused &&
                            !this.ended &&
                            this.readyState > 2
                        );
                    },
                });
                document.getElementById('firstScreen-video').play();
            });
        }
    }, []);
    return (
        <section
            className={`first-screen ${
                type && type !== 'video' && `bg ${type}`
            } ${full && 'full'} ${year === '2022' && 'fullNew'}`}
            style={{ backgroundImage: `url(${bg}` }}
        >
            {type === 'outbox' && (
                <div className="first-screen__decor">
                    <img src={outbox} alt="" />
                </div>
            )}
            {type === 'full' ||
                (full && <div className="first-screen__bg-decor" />)}
            {type === 'video' && (
                <video playsInline autoPlay muted id="firstScreen-video">
                    <source src={videoAlt} type="video/mp4" />
                </video>
            )}

            <div className="full-wrapper">
                <div className="first-screen__row">
                    <div className="first-screen__logo">
                        <img src={langRU ? logo : logoEn} alt="" />
                    </div>

                    <div className="first-screen__headline">
                        {langRU
                            ? <div dangerouslySetInnerHTML={{__html: `Предварительный набор проектов в&nbsp;Транспортный акселератор РЖД 2023`}}/>
                            : <div dangerouslySetInnerHTML={{__html: `Apply now to join the waitlist of the Russian Railways Transport Accelerator 2023`}}/>}
                    </div>

                    {/*<div className="first-screen__desc">*/}
                    {/*    {langRU*/}
                    {/*        ? 'Стань участником акселератора и получи заказчика в лице одного из мировых лидеров железнодорожной отрасли'*/}
                    {/*        : 'Become a member of the accelerator and get a customer represented by one of the world leaders in the railway industry'}*/}
                    {/*</div>*/}

                    {/*<div className="first-screen__notify">*/}
                    {/*    {langRU*/}
                    {/*        ? 'Сбор заявок завершён'*/}
                    {/*        : 'Collection of applications completed'}*/}
                    {/*</div>*/}
                    <a
                        className="first-screen__btn"
                        href={regLink}
                        target="_blank"
                    >
                        {langRU ? 'ПОДАТЬ ЗАЯВКУ' : 'APPLY'}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default FirstScreen;
