import React, { FC } from 'react';
import './style.sass';

interface IProps {
    title: string;
    items: { title: string; text: string }[];
}

const Members: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-members">
            <div className="full-wrapper">
                <div
                    className="archive-members__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />

                <div className="archive-members__row">
                    {items?.length > 0 &&
                        items.map(({ title, text }, index) => (
                            <div className="archive-members__col" key={index}>
                                <div className="archive-members__item archive-members-item">
                                    <div
                                        className="archive-members-item__title"
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    />
                                    <div
                                        className="archive-members-item__text"
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Members;
