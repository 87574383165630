import React, { useEffect } from 'react';
import './style.sass';
import ResourceTitlePage from '../../components/ui/ResourceTitlePage';
import BodyResource from '../../components/resources/BodyResource';
import data from '../../data/cookies.json';
import Divider from '@material-ui/core/Divider';

const Cookies = () => {
    return (
        <section className="agreement">
            <div className="full-wrapper">
                <ResourceTitlePage text="Политика использования cookies-файлов" />
                <BodyResource>
                    <div className="agreement__top">
                        <Divider />
                    </div>
                    <div className="agreement__row">
                        {data.map((item, index) => (
                            <div key={index} className="agreement__item">
                                <div
                                    key={index}
                                    className="agreement__text"
                                    dangerouslySetInnerHTML={{ __html: item }}
                                />
                            </div>
                        ))}
                    </div>
                    <div>
                        <table className="agreement__table">
                            <tr className="agreement__row">
                                <td className="agreement__th">
                                    наименование cookie
                                </td>
                                <td className="agreement__th">описание</td>
                                <td className="agreement__th">вид cookie</td>
                                <td className="agreement__th">срок хранения</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">zm</td>
                                <td className="agreement__td" rowSpan="21">
                                    cookie
                                    <span lang="en-us">s</span>
                                    -файлы используются яндекс.метрикой для
                                    сбора информации о характеристиках
                                    посетителя, его поведении на страницах
                                    сайта, целевых действиях и др.
                                </td>
                                <td className="agreement__td" rowSpan="27">
                                    аналитические
                                </td>
                                <td className="agreement__td" rowSpan="10">
                                    1 год
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">mda</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ym_uid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yandexuid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    sync_cookie_csrf
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">amcuid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yp</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">l</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">ymex</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yuidss</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">cycada</td>
                                <td className="agreement__td">1 месяц</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ym_isad</td>
                                <td className="agreement__td">2 дня</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ym_isad</td>
                                <td className="agreement__td" rowSpan="4">
                                    30 минут
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">ys</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">gdpr</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ym_visorc</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">sesson_id</td>
                                <td className="agreement__td" rowSpan="5">
                                    до завершения сеанса браузера
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yandex_login</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yabs-sid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">yandex_gid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">sessionid2</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ga</td>
                                <td className="agreement__td" rowSpan="6">
                                    cookie
                                    <span lang="en-us">s</span>
                                    -файлы используются google analytics для
                                    сбора информации о характеристиках
                                    посетителя, его поведении на страницах
                                    сайта, целевых действиях и др.
                                </td>
                                <td className="agreement__td">2 года</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">__gads</td>
                                <td className="agreement__td" rowSpan="5">
                                    1 год
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_ga</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">tmr_reqnum</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">tmr_lvidts</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">_gcl_au</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    bitrix_sm_uidl
                                </td>
                                <td className="agreement__td" rowSpan="4">
                                    идентификация пользователя
                                </td>
                                <td className="agreement__td" rowSpan="6">
                                    обязательные
                                </td>
                                <td className="agreement__td" rowSpan="6">
                                    до завершения сеанса браузера
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">phpsessid</td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    bitrix_sm_uidh
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    bitrix_sm_login
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    bitrix_sm_sound_login_played
                                </td>
                                <td className="agreement__td" rowSpan="2">
                                    настройки пользователя
                                </td>
                            </tr>
                            <tr className="agreement__row">
                                <td className="agreement__td">
                                    bitrix_sm_last_settings
                                </td>
                            </tr>
                        </table>
                    </div>
                </BodyResource>
            </div>
        </section>
    );
};

export default Cookies;
