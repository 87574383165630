import React from 'react';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import BodyResource from '../BodyResource';
import './style.sass';
import Spinner from '../../ui/Spinner';
import ExpertsRulesList from '../ExpertsRulesList';
import data from '../../../data/rules.json';
import links from '../../../data/rules-links.json';

class ExpertsRules extends React.Component {
    state = {
        isLoading: true,
        data,
        links,
    };

    componentDidMount() {
        //    запросить данные о заявке
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 1000);
    }

    render() {
        const { isLoading, data, links } = this.state;
        return (
            <>
                <ResourceTitlePage text="Правила пользования" />
                <BodyResource contentMaxWidthProfile>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <ExpertsRulesList data={data} links={links} />
                    )}
                </BodyResource>
            </>
        );
    }
}

export default ExpertsRules;
