import React, { FC, Fragment } from 'react';
import './style.sass';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const BasicPasswordField = props => {
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    // const handleChange = prop => event => {
    //     setValues({ ...values, [prop]: event.target.value });
    //     props.onChange(props.name, event.target.value);
    // };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    return (
        <div className="text-field">
            <FormControl variant="filled">
                <InputLabel htmlFor="filled-adornment-password">
                    {props.label}
                </InputLabel>
                <FilledInput
                    id="filled-adornment-password"
                    {...props}
                    type={values.showPassword ? 'text' : 'password'}
                    // value={values.password}
                    // onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    );
};

export default BasicPasswordField;
