import React, { useRef, useState } from 'react';
import parse from 'html-react-parser';
import cn from './style.module.sass';
import clsx from 'clsx';
import ModalWrap from '../resources/ManagerList/ExpertModal';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const HistoryItem = ({ item, langRU }) => {
    const [open, setOpen] = useState(false);
    const modalRef = useRef(null);
    useOnClickOutside(modalRef, () => setOpen(false));
    return (
        <div className={cn.col}>
            <div onClick={() => setOpen(true)} className={cn.item}>
                <div className={cn.img}>
                    <img src={item.img ? item.img : null} alt="" />
                </div>
                <div className={cn.subtitle}>{item.subtitle}</div>
                <div className={cn.text}>{parse(item.text)}</div>
                <a href="javascript:;" className={cn.link}>
                    {langRU ? parse('Подробнее') : parse('')}
                </a>
            </div>
            <ModalWrap isOpen={open} toggleModal={setOpen} redBorder>
                <div className={cn.historyModal} ref={modalRef}>
                    <div className={cn.modalRow}>
                        <div className={clsx(cn.modalCol, cn.full)}>
                            <div className={clsx(cn.modalItem, cn.img)}>
                                <img
                                    src={item.modalImg}
                                    alt=""
                                    className={cn.modalImg}
                                />
                            </div>
                        </div>

                        {item.modalLogo ? (
                            <div className={cn.modalCol}>
                                <div className={clsx(cn.modalItem, cn.logo)}>
                                    <img
                                        src={item.modalLogo}
                                        alt=""
                                        className={cn.modalLogo}
                                    />
                                </div>
                            </div>
                        ) : null}

                        <div
                            className={clsx(
                                cn.modalCol,
                                !item.modalLogo && cn.single
                            )}
                        >
                            <div
                                className={clsx(
                                    cn.modalItem,
                                    !item.modalLogo && cn.single
                                )}
                            >
                                <div className={cn.modalTitle}>
                                    {item.subtitle}
                                </div>
                                <div className={cn.modalText}>
                                    {parse(item.modalDescription)}
                                </div>
                                <div className={cn.modalSubtitle}>
                                    {langRU
                                        ? parse('Функциональный заказчик:')
                                        : parse('End customer:')}
                                </div>
                                <div className={cn.modalText}>
                                    {parse(item.modalClient)}
                                </div>
                                <div className={cn.modalResults}>
                                    <div className={cn.modalSubtitle}>
                                        {langRU
                                            ? parse('Результаты:')
                                            : parse('Results')}
                                    </div>
                                    <div className={cn.modalText}>
                                        {parse(item.modalResults)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrap>
        </div>
    );
};

export default HistoryItem;
