import React, { useEffect, useState } from 'react';
import './style.sass';
import SlideToggle from 'react-slide-toggle';
import uniqBy from 'lodash.uniqby';

const ExpertiseCollapseBox = ({ items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const uniqItems = uniqBy(items, 'UF_CRIT');
    return (
        <>
            <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement }) => (
                    <>
                        <div className="expertise-collapse-box">
                            <div
                                className="expertise-collapse-box__body"
                                ref={setCollapsibleElement}
                            >
                                {uniqItems.map(item => {
                                    return (
                                        <div className="expertise-collapse-box-item">
                                            <div className="expertise-collapse-box-item__t-r">
                                                <div className="expertise-collapse-box-item__title">
                                                    {item.UF_CRIT}
                                                </div>
                                                <div className="expertise-collapse-box-item__rating">
                                                    {item.UF_RATING}
                                                </div>
                                            </div>

                                            <div className="expertise-collapse-box-item__comment">
                                                {item.UF_COMMENT}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div
                                className="expertise-collapse-box__toggle"
                                onClick={() => {
                                    toggle();
                                    setIsOpen(!isOpen);
                                }}
                            >
                                {!isOpen
                                    ? 'Показать оценку по критериям'
                                    : 'Свернуть оценку по критериям'}
                            </div>
                        </div>
                    </>
                )}
            />
        </>
    );
};

export default ExpertiseCollapseBox;
