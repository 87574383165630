import React from 'react';
import parse from 'html-react-parser';
import file from 'assets/pdf/Положение КАП.pdf';

import './style.sass';

const Footer = ({ langRU, year }) => {
    return (
        <footer id="contacts" className="footer2021">
            <div className="full-wrapper">
                <div className="footer2021__title">
                    {langRU ? parse('Контакты') : parse('Contact details')}
                </div>
                <div className="footer2021__row footer2021__row--top">
                    <div className="footer2021__col" data-width="2">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                GenerationS
                            </div>
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(
                                          `Москва, Пресненская наб., д. 8, стр. 1 МФК &laquo;Город Столиц&raquo, Южный блок, 12 этаж`
                                      )
                                    : parse(
                                          `Moscow, Presnenskaya emb., 8, building 1 MFC "City of Capitals", South Tower, 12th floor`
                                      )}
                            </div>
                            <div className="footer2021-item__social">
                                <a
                                    href="https://t.me/innovationsquad_by_generationS"
                                    target="_blank"
                                    className="footer2021-item__link"
                                >
                                    Telegram
                                </a>
                                <a
                                    href="https://vk.com/techstartrussia"
                                    target="_blank"
                                    className="footer2021-item__link"
                                >
                                    Vkontakte
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                {langRU ? parse('Телефон') : parse('Phone')}
                            </div>
                            <a
                                href="tel:+74957770104"
                                className="footer2021-item__normal-text"
                            >
                                +7 (499) 750-09-11
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                {langRU ? parse(`Факс`) : parse(`Fax`)}
                            </div>
                            <div className="footer2021-item__normal-text">
                                <a
                                    href="tel:+74957770106"
                                    className="footer2021-item__normal-text"
                                >
                                    +7 (499) 750-09-11
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer2021__row footer2021__row--top">
                    <div className="footer2021__col" data-width="2">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                {langRU ? parse('ВНИИЖТ') : parse('VNIIZhT')}
                            </div>
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(
                                          `г. Москва, ул. 3-я Мытищинская, 10`
                                      )
                                    : parse(
                                          `10, 3rd Mytishchinskaya Street, Moscow`
                                      )}
                            </div>
                            {/*<div className="footer2021-item__social">*/}
                            {/*    <a*/}
                            {/*        href="https://www.facebook.com/vniizht/"*/}
                            {/*        target="_blank"*/}
                            {/*        className="footer2021-item__link"*/}
                            {/*    >*/}
                            {/*        Facebook*/}
                            {/*    </a>*/}
                            {/*    <a*/}
                            {/*        href="https://www.instagram.com/vniizht/"*/}
                            {/*        target="_blank"*/}
                            {/*        className="footer2021-item__link"*/}
                            {/*    >*/}
                            {/*        Instagram*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                {langRU ? parse('Телефон') : parse('Phone')}
                            </div>
                            <a
                                href="tel:+74992604111"
                                className="footer2021-item__normal-text"
                            >
                                +7 (499) 260-41-11
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__small-text">
                                Email
                            </div>
                            <a
                                href="mailto:Accelerator.rzd@vniizht.ru"
                                className="footer2021-item__normal-text"
                            >
                                Accelerator.rzd@vniizht.ru
                            </a>
                        </div>
                    </div>
                </div>

                <div className="footer2021-item__small-text footer2021-item__small-textcustom">
                    {parse('E-mail')}
                </div>
                <div className="footer2021__row footer2021__row--middle">
                    {/*                    <div className="footer2021__col" data-width="1">*/}
                    {/*                        <div className="footer2021__item footer2021-item">*/}
                    {/*                            <div className="footer2021-item__normal-text">*/}
                    {/*                                {langRU*/}
                    {/*                                    ? parse(`Екатерина Петрова`)*/}
                    {/*                                    : parse(`Ekaterina Petrova*/}
                    {/*`)}*/}
                    {/*                            </div>*/}
                    {/*                            <div className="footer2021-item__small-text">*/}
                    {/*                                {langRU*/}
                    {/*                                    ? parse(*/}
                    {/*                                          `Директор корпоративного акселератора`*/}
                    {/*                                      )*/}
                    {/*                                    : parse(`Corporate Accelerator Director`)}*/}
                    {/*                            </div>*/}
                    {/*                            <a*/}
                    {/*                                href="mailto:Petrova.ES@rvc.ru"*/}
                    {/*                                className="footer2021-item__link"*/}
                    {/*                            >*/}
                    {/*                                Petrova.ES@rvc.ru*/}
                    {/*                            </a>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Роман Кошелев`)
                                    : parse(`Roman Koshelev`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `Начальник Центра &laquo;Корпоративный акселератор стартап проектов ОАО &laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&laquo;ВНИИЖТ&raquo;`
                                      )
                                    : parse(
                                          `Head of&nbsp;the Center &laquo;Corporate accelerator for start-up projects of&nbsp;JSC&raquo; Russian Railways &laquo;- JSC&raquo; VNIIZhT &quot;`
                                      )}
                            </div>
                            <a
                                href="mailto:Koshelev.Roman@vniizht.ru"
                                className="footer2021-item__link"
                            >
                                Koshelev.Roman@vniizht.ru
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Александр Поляков`)
                                    : parse(`Alexander Polyakov`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `Эксперт Центра &laquo;Корпоративный акселератор стартап проектов ОАО &laquo;РЖД&raquo;&nbsp;&mdash; АО&nbsp;&quot;ВНИИЖТ`
                                      )
                                    : parse(
                                          `Expert of&nbsp;the Center &laquo;Corporate accelerator of&nbsp;start-up projects of&nbsp;JSC&raquo; Russian Railways &laquo;- JSC&raquo; VNIIZhT`
                                      )}
                            </div>
                            <a
                                href="mailto:Polyakov.Aleksandr@vniizht.ru"
                                className="footer2021-item__link"
                            >
                                Polyakov.Aleksandr@vniizht.ru
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Ольга Смирнова`)
                                    : parse(`Olga Smirnova`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `Эксперт Центра «Корпоративный акселератор стартап-проектов ОАО «РЖД» — АО «ВНИИЖТ»`
                                      )
                                    : parse(
                                          `Expert of the Center "Corporate accelerator of startup projects of JSC "Russian Railways— - JSC "VNIIZHT"`
                                      )}
                            </div>
                            <a
                                href="mailto:Smirnova.Olga@vniizht.ru"
                                className="footer2021-item__link"
                            >
                                Smirnova.Olga@vniizht.ru
                            </a>
                        </div>
                    </div>
                    {/*<div*/}
                    {/*    className="footer2021__col footer2021__col--empty"*/}
                    {/*    data-width="1"*/}
                    {/*></div>*/}
                </div>
                <div className="footer2021__row footer2021__row--middle">
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Екатерина Петрова`)
                                    : parse(`Ekaterina Petrova`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `
                                            <div> Директор корпоративного акселератора GenerationS </div>
<!--                                            <div> Департамент по акселерационным программам </div>-->
<!--                                            <div> Акселератор GenerationS</div>-->
<!--                                            <div> ООО «УК РВК» (входит в группу АО «УК РФПИ») </div>-->
                                            `
                                      )
                                    : parse(
                                          `
                                            <div> Director of Innovation and Technological Development </div>
                                            <div> Department for Acceleration Programs </div>
                                            <div> Accelerator GenerationS </div>
                                            <div> RVC Management Company LLC (part of the RDIF Management Company JSC group) </div>
                                            `
                                      )}
                            </div>
                            <a
                                href="mailto:Petrova.ES@rvc.ru"
                                className="footer2021-item__link"
                            >
                                Petrova.ES@rvc.ru
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Михаил Мурзин`)
                                    : parse(`Mikhail Murzin`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `
                                        <div>Менеджер программы</div>
<!--                                        <div>Департамент по акселерационным программам</div>-->
<!--                                        <div>Акселератор GenerationS</div>-->
<!--                                        <div>ООО «УК РВК» (входит в группу АО «УК РФПИ») </div>-->
                                        `
                                      )
                                    : parse(
                                          `
                                        <div>Senior analyst</div>
                                        <div>Department for Acceleration Programs</div>
                                        <div>Accelerator GenerationS</div>
                                        <div>RVC Management Company LLC (part of the RDIF Management Company JSC group)</div>
                                        `
                                      )}
                            </div>
                            <a
                                href="mailto:Murzin.MK@rvc.ru"
                                className="footer2021-item__link"
                            >
                                Murzin.MK@rvc.ru
                            </a>
                        </div>
                    </div>
                    <div className="footer2021__col" data-width="1">
                        <div className="footer2021__item footer2021-item">
                            <div className="footer2021-item__normal-text">
                                {langRU
                                    ? parse(`Ирина Мартынова`)
                                    : parse(`Irina Martynova`)}
                            </div>
                            <div className="footer2021-item__small-text">
                                {langRU
                                    ? parse(
                                          `Руководитель корпоративного направления GenerationS `
                                      )
                                    : parse(
                                          `Head of GenerationS Corporate Direction`
                                      )}
                            </div>
                            <a
                                href="Martynova.iv@rvc.ru"
                                className="footer2021-item__link"
                            >
                                Martynova.iv@rvc.ru
                            </a>
                        </div>
                    </div>
                    {/*<div className="footer2021__col" data-width="1">*/}
                    {/*    <div className="footer2021__item footer2021-item">*/}
                    {/*        <div className="footer2021-item__normal-text">*/}
                    {/*            {langRU*/}
                    {/*                ? parse(`Галина Дремова`)*/}
                    {/*                : parse(`Galina Dremova`)}*/}
                    {/*        </div>*/}
                    {/*        <div className="footer2021-item__small-text">*/}
                    {/*            {langRU*/}
                    {/*                ? parse(`Старший менеджер по коммуникациям`)*/}
                    {/*                : parse(`Senior Communications Manager`)}*/}
                    {/*        </div>*/}
                    {/*        <a*/}
                    {/*            href="mailto:Dremova.GV@rvc.ru"*/}
                    {/*            className="footer2021-item__link"*/}
                    {/*        >*/}
                    {/*            Dremova.GV@rvc.ru*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="footer2021__program footer2021-program">
                <div className="full-wrapper">
                    <div className="footer2021-program__row">
                        <a
                            href={file}
                            download
                            className="footer2021-program__text"
                        >
                            {langRU
                                ? parse(
                                      `Положение проведения акселерационной программы`
                                  )
                                : parse(
                                      `The position of the acceleration program`
                                  )}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
