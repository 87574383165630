import expItem1 from 'assets/img/svg/experience/exp-item-06.svg';
import expItem2 from 'assets/img/svg/experience/exp-item-05.svg';
import expItem3 from 'assets/img/svg/experience/exp-item-02.svg';

export const IntroTextNumbers2021 = [
    {
        img: expItem1,
        title: `<span>16</span>&nbsp;площадок`,
        text: `в&nbsp;регионах для<br/> внедрения инноваций`,
    },
    {
        img: expItem2,
        title: `Более <br/><span>85,6</span>&nbsp;тыс.&nbsp;км`,
        text: `эксплуатационная<br/> длина железных дорог`,
    },
    {
        img: expItem3,
        title: `Входит в&nbsp;<span>ТОП-3</span>`,
        text: `мировых лидеров<br/> железнодорожных<br/> компаний`,
    },
];
export const IntroTextNumbers2021En = [
    {
        img: expItem1,
        title: `<span>16&nbsp;</span>sites `,
        text: `in&nbsp;the regions<br/> to&nbsp;implement<br/> innovations`,
    },
    {
        img: expItem2,
        title: `More than <span>85,600</span>&nbsp;km&nbsp;&mdash;length`,
        text: `of&nbsp;railways in&nbsp;operation`,
    },
    {
        img: expItem3,
        title: `A&nbsp;<span>top-3</span>`,
        text: `global<br/> railway<br/> company`,
    },
];
