import partnersAlt1 from 'assets/pic/partnersAlt/partner-01.jpg';
import partnersAlt2 from 'assets/pic/partnersAlt/partner-02.jpg';
import partnersAlt32 from 'assets/pic/partnersAlt/rvca.jpg';
import technopark from 'assets/pic/partnersAlt/technopark.jpg';
import region_invest from 'assets/pic/partnersAlt/region_invest.jpg';
import b_update from 'assets/pic/partnersAlt/b-update.png';
import russoft from 'assets/pic/partnersAlt/russoft.png';
import berza from 'assets/pic/partnersAlt/berza.png';
import rb from 'assets/pic/partnersAlt/rb.png';
import technovery from 'assets/pic/partnersAlt/technovery.png';
import boiling_dot from 'assets/pic/partnersAlt/boiling_dot.png';
import inov from 'assets/pic/partnersAlt/inov.png';
import strategia from 'assets/pic/partnersAlt/strategia.png';

import auto from 'assets/pic/partnersAlt/auto.png';
import big_r from 'assets/pic/partnersAlt/big_r.png';
import nti from 'assets/pic/partnersAlt/nti.png';
import ekb from 'assets/pic/partnersAlt/ekb.png';
import my_biz from 'assets/pic/partnersAlt/my_biz.png';
import fasie from 'assets/pic/partnersAlt/fasie.png';
import startech from 'assets/pic/partnersAlt/startech.png';
import hse from 'assets/pic/partnersAlt/hse.png';
import unti from 'assets/pic/partnersAlt/unti.png';

import ai_com from 'assets/pic/partnersAlt/ai_com.jpeg';
import d_digital from 'assets/pic/partnersAlt/d_digital.png';
import rma from 'assets/pic/partnersAlt/rma.png';
import ucmc from 'assets/pic/partnersAlt/ucmc.png';
import most from 'assets/pic/partnersAlt/most.png';
import ckrsp from 'assets/pic/partnersAlt/ckrsp.png';

import naurr from 'assets/pic/partnersAlt/naurr.jpg';
import tech_moskov from 'assets/pic/partnersAlt/tech-moskov.png';
import har from 'assets/pic/partnersAlt/har.png';
import mfti from 'assets/pic/partnersAlt/mfti.png';
import fabrika from 'assets/pic/partnersAlt/fabrika.png';
import ckr from 'assets/pic/partnersAlt/ckr.png';
import sk from 'assets/pic/partnersAlt/sk.png';

import krijt from 'assets/pic/partnersAlt/krijt.png';
import choikhona from 'assets/pic/partnersAlt/choikhona.png';
import pnipu from 'assets/pic/partnersAlt/pnipu.png';
import vr from 'assets/pic/partnersAlt/vr.png';

import opjt from 'assets/pic/partnersAlt/opjt.jpg';
import ru_tech_park from 'assets/pic/partnersAlt/ru-tech-park.png';
import aprbot from 'assets/pic/partnersAlt/aprbot.png';
import technopark42 from 'assets/pic/partnersAlt/technopark42.png';
import statanly from 'assets/pic/partnersAlt/statanly.png';
import lpmtech from 'assets/pic/partnersAlt/lpmtech.jpg';

import itpark from 'assets/pic/partnersAlt/itpark.png';
import dm from 'assets/pic/partnersAlt/dm.png';
import mbm from 'assets/pic/partnersAlt/mbm.png';
import iato from 'assets/pic/partnersAlt/iato.png';

export const PartnersAltItems2021 = [
    { img: rb, link: 'www.rb.ru', href: 'https://rb.ru/' },
    { img: berza, link: 'www.berza.ru', href: 'https://berza.ru/' },
    { img: partnersAlt1, link: 'www.rzdtv.ru', href: 'http://www.rzdtv.ru/' },
    { img: partnersAlt2, link: 'www.gudok.ru', href: 'https://gudok.ru/' },
    // {
    //     img: partnersAlt4,
    //     link: 'www.vc-barometer.ru',
    //     href: 'https://vc-barometer.ru/',
    // },
    {
        img: partnersAlt32,
        link: 'www.rvca.ru',
        href: 'http://www.rvca.ru/rus/',
    },
    {
        img: b_update,
        link: 'business-update.ru',
        href: 'https://business-update.ru/',
    },
    {
        img: technopark,
        link: 'ingria-park.ru',
        href: 'https://ingria-park.ru/',
    },
    {
        img: russoft,
        link: 'www.russoft.org',
        href: 'https://russoft.org/',
    },
    {
        img: technovery,
        link: 'www.technovery.com',
        href: 'https://technovery.com/',
    },
    {
        img: boiling_dot,
        link: 'www.leader-id.ru',
        href: 'https://leader-id.ru/places',
    },
    {
        img: inov,
        link: 'www.inov8.kz',
        href: 'https://inov8.kz/',
    },
    {
        img: region_invest,
        link: 'www.region-invest.ru',
        href:
            'https://region-invest.ru/akselerator-oao-rjd-v-partnerstve-s-generations-obiavili-start-programmy-po-poisky-innovacionnyh-reshenii-v-sfere-transportnoi-logistiki/',
    },
    {
        img: strategia,
        link: 'www.strategyjournal.ru',
        href: 'https://strategyjournal.ru/',
    },
    {
        img: my_biz,
        link: 'мойбизнес.рф',
        href: 'https://xn--90aifddrld7a.xn--p1ai/',
    },
    {
        img: ekb,
        link: 'www.ekbiznes.ru',
        href: 'https://ekbiznes.ru/',
    },
    {
        img: nti,
        link: 'www.nti2035.ru',
        href: 'https://nti2035.ru/',
    },
    {
        img: big_r,
        link: 'www.pstu.ru',
        href: 'https://pstu.ru/tag_news/?tag=21',
    },
    {
        img: auto,
        link: 'www.avtprom.ru',
        href: 'http://avtprom.ru/',
    },
    {
        img: fasie,
        link: 'www.fasie.ru',
        href: 'https://fasie.ru/',
    },
    {
        img: startech,
        link: 'www.startech.vc',
        href: 'https://startech.vc/ru',
    },
    {
        img: hse,
        link: 'www.inc.hse.ru',
        href: 'https://inc.hse.ru/',
    },
    {
        img: unti,
        link: 'www.2035.university',
        href: 'https://2035.university/',
    },
    {
        img: d_digital,
        link: 'www.digital-dolina.ru',
        href:
            'https://digital-dolina.ru/?utm_source=generations&utm_medium=partner&utm_campaign=dolina',
    },
    {
        img: most,
        link: 'www.most.com.kz',
        href: 'http://most.com.kz/',
    },
    {
        img: rma,
        link: 'www.https://www.rma.ru/rma.ru',
        href: 'https://www.rma.ru/',
    },
    {
        img: ucmc,
        link: 'www.rusenter.com',
        href: 'http://rusenter.com/',
    },
    {
        img: ai_com,
        link: 'www.ai-community.com',
        href: 'https://ai-community.com/',
    },

    {
        img: tech_moskov,
        link: 'www.technomoscow.ru',
        href: 'https://technomoscow.ru/?utm_source=rzhd',
    },
    {
        img: har,
        link: 'www.hackagencyrussia.ru',
        href: 'https://hackagencyrussia.ru/',
    },
    {
        img: mfti,
        link: 'www.te-mipt.ru',
        href: 'http://te-mipt.ru/',
    },
    {
        img: fabrika,
        link: 'www.itpark-astrakhan.ru',
        href: 'https://www.itpark-astrakhan.ru/',
    },
    {
        img: naurr,
        link: 'www.robotunion.ru',
        href: 'https://robotunion.ru/',
    },
    {
        img: ckr,
        link: 'clustersnso',
        href: 'https://t.me/clustersnso',
    },
    {
        img: sk,
        link: 'www.sk.ru',
        href: 'https://sk.ru/technopark/',
    },
    {
        img: ckrsp,
        link: 'www.spbcluster.ru',
        href: 'https://spbcluster.ru/',
    },
    {
        img: vr,
        link: 'www.vrconcept.net',
        href: 'https://vrconcept.net/',
    },
    {
        img: pnipu,
        link: 'www.pstu.ru',
        href: 'https://pstu.ru/',
    },
    {
        img: choikhona,
        link: 'startupchoihona',
        href: 'https://www.facebook.com/startupchoihona/',
    },
    {
        img: krijt,
        link: 'www.irgups.ru',
        href: 'https://www.irgups.ru/krizht',
    },
    {
        img: opjt,
        link: 'www.opzt.ru',
        href: 'http://opzt.ru/',
    },
    {
        img: ru_tech_park,
        link: 'www.rutechpark.ru',
        href: 'http://rutechpark.ru/',
    },
    {
        img: aprbot,
        link: 'www.aprbot.com',
        href: 'http://aprbot.com/',
    },
    {
        img: technopark42,
        link: 'www.technopark42.ru',
        href: 'https://technopark42.ru/',
    },
    {
        img: statanly,
        link: 'www.statanly.com',
        href: 'https://statanly.com/',
    },
    {
        img: lpmtech,
        link: 'www.lpmtech.ru',
        href: 'https://lpmtech.ru/',
    },
    {
        img: dm,
        link: 'www.dmao.ru',
        href: 'http://dmao.ru/',
    },
    {
        img: itpark,
        link: 'www.itpark.tech',
        href: 'https://itpark.tech/?city=chelny',
    },
    {
        img: mbm,
        link: 'www.mbm.mos.ru',
        href:
            'https://mbm.mos.ru/?utm_source=partners&utm_medium=kvm&utm_campaign=rzd',
    },
    {
        img: iato,
        link: 'www.iato.ru',
        href: 'https://www.iato.ru/',
    },
];
