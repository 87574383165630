import criteria_2021_1 from 'assets/img/svg/criteria/cr-01-2021.svg';
import criteria_2021_2 from 'assets/img/svg/criteria/cr-02-2021.svg';
import criteria_2021_3 from 'assets/img/svg/criteria/cr-03-2021.svg';
import criteria_2021_4 from 'assets/img/svg/criteria/cr-04-2021.svg';

export const CriteriaItems2021 = [
    {
        img: criteria_2021_1,
        text: `Изготовлен и&nbsp;испытан<br/>экспериментальный<br/>образец`,
    },
    {
        img: criteria_2021_2,
        text: `Наличие юридического лица <br/><span>(или готовность его создать)</span>`,
    },
    {
        img: criteria_2021_3,
        text: `Соответствие направлениям отбора`,
    },
    {
        img: criteria_2021_4,
        text: `Готовность провести<br/>пилот с&nbsp;РЖД`,
    },
];
export const CriteriaItems2021En = [
    {
        img: criteria_2021_1,
        text: `Manufactured and tested<br/>experimental<br/>sample`,
    },
    {
        img: criteria_2021_2,
        text: `Legal entity presence <br/><span>(or&nbsp;willingness to&nbsp;create one)</span>`,
    },
    {
        img: criteria_2021_3,
        text: `Compliance with selection directions`,
    },
    {
        img: criteria_2021_4,
        text: `Willingness to&nbsp;conduct<br/>a&nbsp;pilot with Russian Railways`,
    },
];
