import profit_new_1 from 'assets/img/svg/profit/profit-new-1.svg';
import profit_new_2 from 'assets/img/svg/profit/profit-new-2.svg';
import profit_new_3 from 'assets/img/svg/profit/profit-new-3.svg';
import profit_new_4 from 'assets/img/svg/profit/profit-new-4.svg';

export const ProfitItems2021 = [
    {
        img: profit_new_1,
        title: 'Доступ к&nbsp;бизнес-заказчикам',
        text:
            'Работая с&nbsp;функциональными заказчиками из&nbsp;РЖД, вы&nbsp;сможете понять, что нужно компании и&nbsp;доработать свое решение под ее&nbsp;требования.',
    },
    {
        img: profit_new_2,
        title: `Отраслевая<br/> экспертиза`,
        text: `Вы&nbsp;получите развернутую обратную связь о&nbsp;своем продукте или решении от&nbsp;ведущих технологических экспертов отрасли.`,
    },
    {
        img: profit_new_3,
        title: `Ускоренный допуск<br/> к&nbsp;испытаниям`,
        text: `Вы&nbsp;сможете получить разрешение на&nbsp;проведение испытаний на&nbsp;полигонах и&nbsp;лабораториях ВНИИЖТ уже к&nbsp;концу 2021&nbsp;года. Обычно этот процесс занимает от&nbsp;полугода.`,
    },
    {
        img: profit_new_4,
        title: `Возможность стать поставщиком РЖД`,
        text: `Если ваша технология успешно пройдет испытания, вы&nbsp;сможете заключить контракт с&nbsp;РЖД.`,
    },
];
export const ProfitItems2021En = [
    {
        img: profit_new_1,
        title: 'Access to&nbsp;business customers',
        text:
            'Working with end customers from Russian Railways, you can identify what the company needs and modify your solution to&nbsp;suit its requirements.',
    },
    {
        img: profit_new_2,
        title: `Advice from industry experts`,
        text: `You will receive in-depth feedback on&nbsp;your product or&nbsp;solution from leading technology experts in&nbsp;the industry.`,
    },
    {
        img: profit_new_3,
        title: `Accelerated admission to&nbsp;testing`,
        text: `An opportunity to conduct accelerated tests at the Russian Railways infrastructure. The process usually takes six months or more.`,
    },
    {
        img: profit_new_4,
        title: `Opportunity to&nbsp;become a&nbsp;supplier of&nbsp;Russian Railways`,
        text: `If&nbsp;your technology is&nbsp;successfully tested, you will be&nbsp;able to&nbsp;conclude a&nbsp;contract with Russian Railways.`,
    },
];
