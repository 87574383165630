import React from 'react';
import { List } from 'react-admin';

import { RequestFilter } from './RequestFilter';
import { TabbedDatagrid } from './TabbedDatagrid';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';

const RequestList = ({ classes, ...props }) => {
    return (
        <>
            <ResourceTitlePage text="Заявки" />
            <List
                {...props}
                filterDefaultValues={{ status: 'На премодерации' }}
                sort={{ field: 'DATE_CREATE', order: 'DESC' }}
                perPage={25}
                filters={<RequestFilter />}
                className="experts-list"
                exporter={false}
            >
                <TabbedDatagrid classes={classes} {...props} />
            </List>
        </>
    );
};

export default RequestList;
