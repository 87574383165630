/**
 * переключает класс body для изменения цвета фона
 */
export const toggleBg = (bgColor: 'blue' | '' = '') => {
    const body = document.querySelector('body');
    const CLASS_BLUE_BG = 'blue-bg';
    if (bgColor === 'blue') {
        body!.classList.add(CLASS_BLUE_BG);
    } else {
        body!.classList.remove(CLASS_BLUE_BG);
    }
};
