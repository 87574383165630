import React from 'react';
import BasicTextField from '../../components/form/basic-components/BasicTextField';
import AbstractField from './AbstractField';
import { FieldArray } from 'formik';
import { log } from 'util';

class MultiTextField extends AbstractField {
    config: any;

    constructor(config: any) {
        super();
        this.config = config;
    }

    public renderWithFormik = (props: any) => {
        const { name } = this.config;
        const { values, handleChange, handleBlur, errors, touched } = props;
        const error = errors[name] && touched[name];

        const newProps: { [p: string]: any } = {
            onChange: handleChange,
            onBlur: handleBlur,
            error,
            currentValue: values[name] || this.config.value,
            helperText: error ? errors[name] : '',
        };
        const { label } = this.config;
        const rawData =
            typeof newProps.currentValue === 'string'
                ? [newProps.currentValue]
                : newProps.currentValue;
        const data = rawData[0].split(',');
        console.log(data);
        return (
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <div className="text-field">
                        {data &&
                            data.length > 0 &&
                            data.map((item: any, index: number) => (
                                <div style={{ width: '100%' }} key={index}>
                                    <BasicTextField
                                        {...props}
                                        onChange={handleChange}
                                        label={label}
                                        name={`social.${index}`}
                                    />
                                </div>
                            ))}
                        {data.length > 1 && (
                            <button
                                className="remove-social"
                                type="button"
                                onClick={() => arrayHelpers.pop()}
                            >
                                Удалить социальную сеть
                            </button>
                        )}

                        <button
                            className="add-social add-social--main"
                            type="button"
                            onClick={() => arrayHelpers.push('')}
                        >
                            {/* show this when user has removed all friends from the list */}
                            <span>+</span> Добавить социальную сеть
                        </button>
                    </div>
                )}
            />
        );
        // return <FieldArray
        //     name="friends"
        //     render={arrayHelpers => (
        //         //возвращать кастомное поле добавление соц сети
        //         //пропсами прокидывать пропсы из конфига и пропсы формика
        //     )}
        // />;
    };

    public render = (props: any) => {
        // const { name, rows, multiline, hintMessage } = this.config;
        // /**
        //  * те свойства из конфига, которые нужно прокинуть в компонент
        //  */
        // const propsFromConfig = {
        //     name,
        //     hintMessage,
        //     label: this.getLabel(),
        //     ...(() => (multiline ? { multiline, rows } : {}))(),
        // };
        //
        // return <BasicTextField key={name} {...propsFromConfig} {...props} />;
    };
}

export default MultiTextField;
