import React, { useState } from 'react';
import { Link } from 'react-scroll';
import cn from './style.module.sass';
import clsx from 'clsx';

const ScrollerItem = ({ name, label }) => {
    const [hover, setHover] = useState(false);

    return (
        <div
            className={cn.itemWrap}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Link
                activeClass={clsx(cn.active)}
                className={clsx(cn.item, hover && cn.active)}
                to={name}
                spy={true}
                smooth={true}
                duration={500}
                offset={-200}
            />
            <div
                className={cn.hint}
                dangerouslySetInnerHTML={{ __html: label }}
            />
        </div>
    );
};

export default ScrollerItem;
