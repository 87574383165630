import direction_2021_1 from 'assets/img/svg/direction/icon-01_2021.svg';
import direction_2021_2 from 'assets/img/svg/direction/icon-02_2021.svg';
import direction_2021_3 from 'assets/img/svg/direction/icon-03_2021.svg';
import direction_2021_4 from 'assets/img/svg/direction/icon-04_2021.svg';

export const IndustriesItems2021 = [
    {
        icon: direction_2021_1,
        text: `Транспортная<br/>логистика`,
    },
    {
        icon: direction_2021_2,
        text: `Информационная<br/>логистика`,
    },
    {
        icon: direction_2021_3,
        text: `Складская<br/>логистика`,
    },
    {
        icon: direction_2021_4,
        text: `Сервисная<br/>логистика`,
    },
];
export const IndustriesItems2021En = [
    {
        icon: direction_2021_1,
        text: `Transport<br/>logistics`,
    },
    {
        icon: direction_2021_2,
        text: `Informational<br/>logistics`,
    },
    {
        icon: direction_2021_3,
        text: `Warehouse<br/>logistics
`,
    },
    {
        icon: direction_2021_4,
        text: `Service<br/>logistics`,
    },
];
