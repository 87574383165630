export const FAQItems2021 = [
    {
        question: `Должен&nbsp;ли я&nbsp;делиться конфиденциальной информацией о&nbsp;своем проекте?`,
        answer: `Нет. Указывайте в&nbsp;заявке и&nbsp;презентации только те&nbsp;данные, которые можно разглашать без NDA (соглашение о&nbsp;неразглашении данных)`,
    },
    {
        question: `Я&nbsp;представляю 2&nbsp;проекта. Могу&nbsp;ли я&nbsp;подать несколько заявок?`,
        answer: `Да, вы&nbsp;можете подать две заявки. Зарегистрируйте второй профиль на&nbsp;сайте и&nbsp;подайте заявку со&nbsp;вторым проектом.`,
    },
    {
        question: `Можно&nbsp;ли встретиться и&nbsp;получить очную консультацию по&nbsp;моему проекту?`,
        answer: `Периодически команда акселератора участвует в&nbsp;мероприятиях партнеров или организовывает собственные мероприятия, на&nbsp;которых можно получить консультацию. Следите за&nbsp;новостями на&nbsp;нашем сайте или в&nbsp;социальных сетях.`,
    },
];
export const FAQItems2021En = [
    {
        question: `Should I&nbsp;share confidential information about my&nbsp;project?`,
        answer: `No. In&nbsp;your application and presentation please specify only the data that can be&nbsp;disclosed without an&nbsp;NDA (non-disclosure agreement)`,
    },
    {
        question: `I&nbsp;represent two projects. Can I&nbsp;submit multiple applications?`,
        answer: `Yes, you can submit two applications. Register a&nbsp;second profile on&nbsp;the website and apply with your second project.`,
    },
    {
        question: `Is&nbsp;it&nbsp;possible to&nbsp;get an&nbsp;in-person consultation on&nbsp;my&nbsp;project?`,
        answer: `The accelerator team sometimes participates in&nbsp;partner events or&nbsp;organizes its own events where you can get advice. Follow the news on&nbsp;our website or&nbsp;social networks.`,
    },
];
