import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import { toggleBg } from './utils/theme';
import './sass/project.sass';

function App() {
    return (
        <Switch>
            {routes.map(
                (
                    { path, title, bgBody, component: Component, ...rest },
                    index
                ) => (
                    <Route
                        key={index}
                        path={path}
                        exact={true}
                        sensitive={true}
                        render={props => {
                            toggleBg(bgBody);
                            return <Component {...props} {...rest} />;
                        }}
                    />
                )
            )}
        </Switch>
    );
}

export default App;
