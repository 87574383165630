import historyPreview1 from 'assets/pic/history/history-01-preview.jpg';
import history1 from 'assets/pic/history/history-01.png';
import modalImg1 from 'assets/pic/history/modal-img-01.jpg';
import historyPreview2 from 'assets/pic/history/history-02-preview.jpg';
import history2 from 'assets/pic/history/history-02.jpg';
import modalImg2 from 'assets/pic/history/modal-img-02.jpg';
import historyPreview3 from 'assets/pic/history/history-03-preview.jpg';
import history3 from 'assets/pic/history/history-03.jpg';
import modalImg3 from 'assets/pic/history/modal-img-03.jpg';
import historyPreview4 from 'assets/pic/history/history-04-preview.jpg';
import history4 from 'assets/pic/history/history-04.png';
import modalImg4 from 'assets/pic/history/modal-img-04.jpg';
import historyPreview_2021_1 from 'assets/pic/history/Imredi.jpg';
import history_2021_1 from 'assets/pic/history/Imredi-logo.png';
import modalImg_2021_1 from 'assets/pic/history/Imredi.jpg';

import historyPreview_2021_2 from 'assets/pic/history/tps.jpg';
import history_2021_2 from 'assets/pic/history/tps-logo.jpg';
import modalImg_2021_2 from 'assets/pic/history/tps.jpg';

import historyPreview_2021_3 from 'assets/pic/history/toir.jpg';
import history_2021_3 from 'assets/pic/history/toir-logo.jpg';
import modalImg_2021_3 from 'assets/pic/history/toir.jpg';

import history2021Preview1 from 'assets/pic/history/2021/history-01-preview.jpg';
import history2021Preview2 from 'assets/pic/history/2021/history-02-preview.jpg';
import history2021Preview3 from 'assets/pic/history/2021/history-03-preview.jpg';
import history2021Modal1 from 'assets/pic/history/2021/history-01-modal.jpg';
import history2021Modal2 from 'assets/pic/history/2021/history-02-modal.jpg';
import history2021Modal3 from 'assets/pic/history/2021/history-03-modal.jpg';

import history_2022_3 from 'assets/pic/history/digital-wave.jpg';
import history_2022_2 from 'assets/pic/history/axelot.png';
import history_2022_1 from 'assets/pic/history/callpy.png';


export const HistoryItems2021 = [
    {
        category: 'Программа&nbsp;2020',
        content: [
            {
                img: historyPreview_2021_1,
                modalLogo: history_2021_1,
                modalImg: modalImg_2021_1,
                subtitle: `Imredi`,
                text: `Digital-платформа для повышения эффективности и&nbsp;безопасности бизнес-процессов, качества, производительности и&nbsp;мотивации персонала`,
                id: 'modalContent_2020_1',
                modalDescription: `Digital-платформа для повышения эффективности и&nbsp;безопасности бизнес-процессов, качества, производительности и&nbsp;мотивации персонала`,
                modalClient: `- Дирекция скоростного сообщения`,
                modalResults: `Проведены тестовые испытания. Стартап-проект интегрирует свое решение в&nbsp;действующую систему бизнес-заказчика.`,
            },
            {
                img: historyPreview_2021_2,
                modalLogo: history_2021_2,
                modalImg: modalImg_2021_2,
                subtitle: `Titan Power Solution`,
                text: `Гибридные системы рекуперации энергии.`,
                id: 'modalContent_2020_2',
                modalDescription: `Гибридные системы рекуперации энергии.`,
                modalClient: `- Центральная дирекция по ремонту пути`,
                modalResults: `Проведены стендовые испытания суперконденсаторной системы накопления энергии для автоматизации процесса перемещения рельсошпальных звеньев на&nbsp;траверсе укладочного крана. Организована подготовительная работа для проведения эксплуатационных испытаний.`,
            },
            {
                img: historyPreview_2021_3,
                modalLogo: history_2021_3,
                modalImg: modalImg_2021_3,
                subtitle: `ТОиР по фактическому состоянию`,
                text: `Система управления техническим обслуживанием и&nbsp;ремонтом подвижного состава по&nbsp;фактическому состоянию.`,
                id: 'modalContent_2020_3',
                modalDescription: `Система управления техническим обслуживанием и&nbsp;ремонтом подвижного состава по&nbsp;фактическому состоянию.`,
                modalClient: `- Дирекция по ремонту тягового подвижного состава`,
                modalResults: `Проведено тестирование и&nbsp;демонстрация работы системы. Организована работа по&nbsp;внедрению технологии стартап-проекта в&nbsp;действующую автоматизированную систему бизнес-заказчика.`,
            },
        ],
    },
    {
        category: 'Программа&nbsp;2019',
        content: [
            {
                img: historyPreview2,
                modalLogo: history2,
                modalImg: modalImg2,
                subtitle: `O.Vision`,
                text: `Устройство для распознавания лица человека менее чем за&nbsp;1&nbsp;секунду с&nbsp;возможной установкой на&nbsp;турникеты и&nbsp;двери`,
                id: 'modalContent_2019_2',
                modalDescription: `Устройство для распознавания лица человека менее чем за&nbsp;1&nbsp;секунду с возможной установкой на турникеты и&nbsp;двери`,
                modalClient: `- Дирекция скоростного сообщения<br/>
        - Проектно-конструкторско-технологическое бюро по системам информатизации<br/>
        - Центральная станция связи<br/>
        - Департамент информатизации`,
                modalResults: `Более 100 устройств установлено в&nbsp;различных подразделениях ОАО &laquo;РЖД&raquo;, планируется закупка более 100 новых устройств в&nbsp;текущем году.`,
            },
            {
                img: historyPreview3,
                modalLogo: history3,
                modalImg: modalImg3,
                subtitle: `HintEd`,
                text: `Интерактивный помощник, который помогает быстрее адаптировать новых сотрудников 
к&nbsp;работе в&nbsp;корпоративных системах, снимает нагрузку по&nbsp;разработке больших инструкций 
и&nbsp;дорогостоящих тренажеров`,
                id: 'modalContent_2019_3',
                modalDescription: `Интерактивный помощник, который помогает быстрее адаптировать новых сотрудников к&nbsp;работе в корпоративных системах, снимает нагрузку по разработке больших инструкций и&nbsp;дорогостоящих тренажеров`,
                modalClient: `- Центральная станция связи<br/>
        - Центр инновационного развития`,
                modalResults: `Проведено несколько тестовых испытаний и&nbsp;демонстрация работы системы. Организована работа по&nbsp;внедрению технологий интерактивных инструкций в&nbsp;системы бизнес-заказчиков.`,
            },
            {
                img: historyPreview4,
                modalLogo: history4,
                modalImg: modalImg4,
                subtitle: `Турбоконтракт`,
                text: `Конструктор документов. В&nbsp;считанные минуты создает полностью заполненные документы (пакеты документов) на&nbsp;основании заранее подготовленных вариативных шаблонов`,
                id: 'modalContent_2019_4',
                modalDescription: `Конструктор документов. В считанные минуты создает полностью заполненные документы (пакеты документов) на основании заранее подготовленных вариативных шаблонов`,
                modalClient: `- ВНИИЖТ<br/>
                            - Центральная 
                            дирекция закупок и 
                            снабжения`,
                modalResults: `Проведены тестовые испытания и&nbsp;демонстрационные показы работы системы. Организована работа по&nbsp;внедрению технологии конструктора документов в&nbsp;существующую автоматизированную систему бизнес-заказчика Компании. С&nbsp;АО&nbsp;&laquo;ВНИИЖТ&raquo; организована работа по&nbsp;внедрению решений стартап-проекта в&nbsp;действующие системы, проект готовит демоверсию с&nbsp;последующим тестирования продукта.`,
            },
            {
                img: historyPreview1,
                modalLogo: history1,
                modalImg: modalImg1,
                subtitle: `РусСофт Эксперт`,
                text: `Замена никель-кадмиевых аккумуляторных батарей на&nbsp;литий- титанатные на&nbsp;локомотивах <br/>и&nbsp;электропоездах ОАО&nbsp;&laquo;РЖД&raquo;`,
                id: 'modalContent_2019_1',
                modalDescription: `Замена никель-кадмиевых аккумуляторных батарей на литий-титанатные на локомотивах и электропоездах ОАО&nbsp;&laquo;РЖД&raquo;`,
                modalClient: `- Дирекция тяги<br/>
                            - Центральная дирекция 
                            моторвагонного 
                            подвижного состава`,
                modalResults: `Проведены стендовые испытания, организована работа по&nbsp;подготовке к&nbsp;расширенным эксплуатационным испытаниям.`,
            },
        ],
    },
];
export const HistoryItems2021En = [
    {
        category: 'Program&nbsp;2020',
        content: [
            {
                img: historyPreview_2021_1,
                modalLogo: history_2021_1,
                modalImg: modalImg_2021_1,
                subtitle: `Imredi`,
                text: `Digital platform for improving the efficiency and safety of&nbsp;business processes, quality, productivity and staff motivation.`,
                id: 'modalContent_2020_1',
                modalDescription: `Digital platform for improving the efficiency and safety of&nbsp;business processes, quality, productivity and staff motivation.`,
                modalClient: `- Directorate of high-speed communication`,
                modalResults: `Test tests have been carried out. The startup project integrates its solution into the existing system of&nbsp;the business customer.`,
            },
            {
                img: historyPreview_2021_2,
                modalLogo: history_2021_2,
                modalImg: modalImg_2021_2,
                subtitle: `TITAN Power Solution`,
                text: `Hybrid energy recovery systems.`,
                id: 'modalContent_2020_2',
                modalDescription: `Hybrid energy recovery systems.`,
                modalClient: `- Central Directorate for Track Repair`,
                modalResults: `Bench tests of&nbsp;a&nbsp;supercapacitor energy storage system were carried out to&nbsp;automate the process of&nbsp;moving rail and sleepers on&nbsp;the traverse of&nbsp;a&nbsp;laying crane. Preparatory work has been organized for performance tests.`,
            },
            {
                img: historyPreview_2021_3,
                modalLogo: history_2021_3,
                modalImg: modalImg_2021_3,
                subtitle: `MRO according to the actual state`,
                text: `Management system for maintenance and repair of&nbsp;rolling stock based on&nbsp;the actual state.`,
                id: 'modalContent_2020_3',
                modalDescription: `Management system for maintenance and repair of&nbsp;rolling stock based on&nbsp;the actual state.`,
                modalClient: `- Traction Rolling Stock Repair Directorate`,
                modalResults: `The system was tested and demonstrated. Work has been organized to&nbsp;introduce the startup project technology into the existing automated system of&nbsp;the business customer.`,
            },
        ],
    },
    {
        category: 'Program&nbsp;2019',
        content: [
            {
                img: historyPreview2,
                modalLogo: history2,
                modalImg: modalImg2,
                subtitle: `O.Vision`,
                text: `Face recognition in&nbsp;less than 1&nbsp;second with installation of&nbsp;device possible on&nbsp;turnstiles and doors`,
                id: 'modalContent_2019_2',
                modalDescription: `Face recognition in&nbsp;less than 1&nbsp;second with installation of&nbsp;device possible on&nbsp;turnstiles and doors`,
                modalClient: `&mdash;&nbsp;High-speed service department<br/>
        &mdash;&nbsp;IT&nbsp;system design and engineering bureau<br/>
        &mdash;&nbsp;Central telecommunications station<br/>
        &mdash;&nbsp;IT&nbsp;department`,
                modalResults: `More than 100 devices have been installed in&nbsp;various divisions of&nbsp;Russian Railways, and it&nbsp;is&nbsp;planned to&nbsp;purchase more than 100 new devices this year.`,
            },
            {
                img: historyPreview1,
                modalLogo: history1,
                modalImg: modalImg1,
                subtitle: `RusSoft Expert`,
                text: `Replacement of&nbsp;nickel-cadmium storage batteries with lithium-titanate batteries on&nbsp;Russian Railways locomotives and electric trains`,
                id: 'modalContent_2019_1',
                modalDescription: `Replacement of&nbsp;nickel-cadmium storage batteries with lithium-titanate batteries on&nbsp;Russian Railways locomotives and electric trains:`,
                modalClient: `&mdash;&nbsp;Traction department<br/>
                            &mdash;&nbsp;Central railcar rolling stock department`,
                modalResults: `Bench tests were carried out, work was organized to&nbsp;prepare for extended operational tests.`,
            },
            {
                img: historyPreview3,
                modalLogo: history3,
                modalImg: modalImg3,
                subtitle: `HintEd`,
                text: `An&nbsp;interactive assistant that helps new employees to&nbsp;quickly adapt to&nbsp;working with corporate systems, relieves the burden of&nbsp;having to&nbsp;create large job instruction manuals and expensive simulators`,
                id: 'modalContent_2019_3',
                modalDescription: `An&nbsp;interactive assistant that helps new employees to&nbsp;quickly adapt to&nbsp;working with corporate systems, relieves the burden of&nbsp;having to&nbsp;create large job instruction manuals and expensive simulators`,
                modalClient: `&mdash;&nbsp;Central telecommunications station<br/>
       &mdash;&nbsp;Center for innovative development`,
                modalResults: `Several tests and a&nbsp;demonstration of&nbsp;the system&rsquo;s operation were carried out. Work has been organized to&nbsp;introduce interactive instruction technologies into the systems of&nbsp;business customers.`,
            },
            {
                img: historyPreview4,
                modalLogo: history4,
                modalImg: modalImg4,
                subtitle: `TurboContract`,
                text: `Designer of&nbsp;documents. In&nbsp;a&nbsp;matter of&nbsp;minutes, creates fully completed documents (sets of&nbsp;documents) based on&nbsp;pre-prepared variable templates`,
                id: 'modalContent_2019_4',
                modalDescription: `Designer of&nbsp;documents. In&nbsp;a&nbsp;matter of&nbsp;minutes, creates fully completed documents (sets of&nbsp;documents) based on&nbsp;pre-prepared variable templates`,
                modalClient: `&mdash;&nbsp;VNIIZhT<br/>
                           &mdash;&nbsp;Central procurement and supply department`,
                modalResults: `Tests and demonstrations of&nbsp;the system were carried out. Work has been organized to&nbsp;introduce the document designer technology into the existing automated system of&nbsp;the Company&rsquo;s business customer. With JSC VNIIZhT, work has been organized to&nbsp;introduce solutions from a&nbsp;startup project into existing systems, the project is&nbsp;preparing a&nbsp;demo version followed by&nbsp;testing the product.`,
            },
        ],
    },
];

export const HistoryItems2022 = [
    {
        category: 'Программа&nbsp;2021',
        content: [
            {
                img: history2021Preview3,
                modalLogo: history_2022_1,
                modalImg: history2021Modal3,
                subtitle: `«Callpy.com»`,
                text: `Коммуникационная SAAS-платформа для общения с посетителями на сайтах`,
                id: 'modalContent_2020_1',
                modalDescription: `Коммуникационная SAAS-платформа для общения с посетителями на сайтах`,
                modalClient: `- Центр фирменного транспортного-обслуживания`,
                modalResults: `Проведены тестовые испытания на полигоне Горьковского территориального центра фирменного транспортного обслуживания. Стартап-проект интегрирует свое решение в действующую систему бизнес-заказчика. Организована подготовительная работа для проведения эксплуатационных испытаний.`,
            },
            {
                img: history2021Preview1,
                modalLogo: history_2022_2,
                modalImg: history2021Modal1,
                subtitle: `«AXELOT WMS X5»`,
                text: `Автоматизация складских процессов с помощью программного обеспечения AXELOT WMS X5`,
                id: 'modalContent_2020_2',
                modalDescription: `Автоматизация складских процессов с помощью программного обеспечения AXELOT WMS X5`,
                modalClient: `- Центральная дирекция закупок и снабжения`,
                modalResults: `Проведено тестирование и демонстрация работы системы на материальном складе Москва-3 (Сокольники) Московско-Курского отдела материально-технического обеспечения. Организована работа по внедрению технологии стартап-проекта в действующую автоматизированную систему бизнес-заказчика. Организована подготовительная работа для проведения эксплуатационных испытаний.`,
            },
            {
                img: history2021Preview2,
                modalLogo: history_2022_3,
                modalImg: history2021Modal2,
                subtitle: `«Digital Wave»`,
                text: `Программно-аппаратный комплекс для вычисления объемов сыпучей продукции на закрытых складах, включая способ топографической съемки местности с помощью фотографирования и построения трехмерных цифровых моделей`,
                id: 'modalContent_2020_3',
                modalDescription: `Программно-аппаратный комплекс для вычисления объемов сыпучей продукции на закрытых складах, включая способ топографической съемки местности с помощью фотографирования и построения трехмерных цифровых моделей`,
                modalClient: `- Центральная дирекция по управлению терминально-складским комплексом`,
                modalResults: `Проведены эксплуатационные испытания системы на полигоне Свердловской дирекции по управлению терминально-складским комплексом. Организована работа по внедрению технологии стартап-проекта в действующую автоматизированную систему бизнес-заказчика.`,
            },
        ],
    },
    {
        category: 'Программа&nbsp;2020',
        content: [
            {
                img: historyPreview_2021_1,
                modalLogo: history_2021_1,
                modalImg: modalImg_2021_1,
                subtitle: `Imredi`,
                text: `Digital-платформа для повышения эффективности и&nbsp;безопасности бизнес-процессов, качества, производительности и&nbsp;мотивации персонала`,
                id: 'modalContent_2020_1',
                modalDescription: `Digital-платформа для повышения эффективности и&nbsp;безопасности бизнес-процессов, качества, производительности и&nbsp;мотивации персонала`,
                modalClient: `- Дирекция скоростного сообщения`,
                modalResults: `Проведены тестовые испытания. Стартап-проект интегрирует свое решение в&nbsp;действующую систему бизнес-заказчика.`,
            },
            {
                img: historyPreview_2021_2,
                modalLogo: history_2021_2,
                modalImg: modalImg_2021_2,
                subtitle: `Titan Power Solution`,
                text: `Гибридные системы рекуперации энергии.`,
                id: 'modalContent_2020_2',
                modalDescription: `Гибридные системы рекуперации энергии.`,
                modalClient: `- Центральная дирекция по ремонту пути`,
                modalResults: `Проведены эксплуатационные испытания суперконденсаторной системы накопления энергии для автоматизации процесса перемещения рельсошпальных звеньев на траверсе укладочного крана. Ведутся переговоры о серийной установке изделия на заводе изготовителя железнодорожной техники.`,
            },
            {
                img: historyPreview_2021_3,
                modalLogo: history_2021_3,
                modalImg: modalImg_2021_3,
                subtitle: `ТОиР по фактическому состоянию`,
                text: `Система управления техническим обслуживанием и&nbsp;ремонтом подвижного состава по&nbsp;фактическому состоянию.`,
                id: 'modalContent_2020_3',
                modalDescription: `Система управления техническим обслуживанием и&nbsp;ремонтом подвижного состава по&nbsp;фактическому состоянию.`,
                modalClient: `- Дирекция по ремонту тягового подвижного состава`,
                modalResults: `С проектом продолжается работа по внедрению решений в рамках плана цифровизации ОАО «РЖД».`,
            },
        ],
    },
    {
        category: 'Программа&nbsp;2019',
        content: [
            {
                img: historyPreview2,
                modalLogo: history2,
                modalImg: modalImg2,
                subtitle: `O.Vision`,
                text: `Устройство для распознавания лица человека менее чем за&nbsp;1&nbsp;секунду с&nbsp;возможной установкой на&nbsp;турникеты и&nbsp;двери`,
                id: 'modalContent_2019_2',
                modalDescription: `Устройство для распознавания лица человека менее чем за&nbsp;1&nbsp;секунду с возможной установкой на турникеты и&nbsp;двери`,
                modalClient: `- Дирекция скоростного сообщения<br/>
        - Проектно-конструкторско-технологическое бюро по системам информатизации<br/>
        - Центральная станция связи<br/>
        - Департамент информатизации`,
                modalResults: `Установлено 120 устройств контроля доступа, основанных на технологии распознавании лиц, в административных зданиях Компании. Внедрен сервис по оплате лицом услуг в вагонах-бистро высокоскоростных поездов «Сапсан» при помощи биометрических данных (сервис реализован на базе решения OPAY - технологии распознавания лиц от компании OVISION).`,
            },
//             {
//                 img: historyPreview3,
//                 modalLogo: history3,
//                 modalImg: modalImg3,
//                 subtitle: `HintEd`,
//                 text: `Интерактивный помощник, который помогает быстрее адаптировать новых сотрудников
// к&nbsp;работе в&nbsp;корпоративных системах, снимает нагрузку по&nbsp;разработке больших инструкций
// и&nbsp;дорогостоящих тренажеров`,
//                 id: 'modalContent_2019_3',
//                 modalDescription: `Интерактивный помощник, который помогает быстрее адаптировать новых сотрудников к&nbsp;работе в корпоративных системах, снимает нагрузку по разработке больших инструкций и&nbsp;дорогостоящих тренажеров`,
//                 modalClient: `- Центральная станция связи<br/>
//         - Центр инновационного развития`,
//                 modalResults: `Проведено несколько тестовых испытаний и&nbsp;демонстрация работы системы. Организована работа по&nbsp;внедрению технологий интерактивных инструкций в&nbsp;системы бизнес-заказчиков.`,
//             },
            {
                img: historyPreview4,
                modalLogo: history4,
                modalImg: modalImg4,
                subtitle: `Турбоконтракт`,
                text: `Конструктор документов. В&nbsp;считанные минуты создает полностью заполненные документы (пакеты документов) на&nbsp;основании заранее подготовленных вариативных шаблонов`,
                id: 'modalContent_2019_4',
                modalDescription: `Конструктор документов. В считанные минуты создает полностью заполненные документы (пакеты документов) на основании заранее подготовленных вариативных шаблонов`,
                modalClient: `- ВНИИЖТ<br/>
                            - Центральная 
                            дирекция закупок и 
                            снабжения`,
                modalResults: `С АО «ВНИИЖТ» проводится работа по внедрению решений стартап-проекта в действующие системы.`,
            },
            {
                img: historyPreview1,
                modalLogo: history1,
                modalImg: modalImg1,
                subtitle: `РусСофт Эксперт`,
                text: `Замена никель-кадмиевых аккумуляторных батарей на&nbsp;литий- титанатные на&nbsp;локомотивах <br/>и&nbsp;электропоездах ОАО&nbsp;&laquo;РЖД&raquo;`,
                id: 'modalContent_2019_1',
                modalDescription: `Замена никель-кадмиевых аккумуляторных батарей на литий-титанатные на локомотивах и электропоездах ОАО&nbsp;&laquo;РЖД&raquo;`,
                modalClient: `- Дирекция тяги<br/>
                            - Центральная дирекция 
                            моторвагонного 
                            подвижного состава`,
                modalResults: `Проведены стендовые испытания, организована работа по&nbsp;подготовке к&nbsp;расширенным эксплуатационным испытаниям.`,
            },
        ],
    },
];
export const HistoryItems2022En = [
    {
        category: 'Program&nbsp;2021',
        content: [
            {
                img: history2021Preview3,
                modalLogo: history_2022_1,
                modalImg: history2021Modal3,
                subtitle: `«Callpy.com»`,
                text: `Communication SAAS-platform for communication with visitors on sites`,
                id: 'modalContent_2020_1',
                modalDescription: `Communication SAAS-platform for communication with visitors on sites`,
                modalClient: `- Center for branded transport service`,
                modalResults: `Test tests were carried out at the test site of the Gorky Territorial Center for Corporate Transport Services. A startup project integrates its solution into the existing system of a business customer. Organized preparatory work for performance testing.`,
            },
            {
                img: history2021Preview1,
                modalLogo: history_2022_2,
                modalImg: history2021Modal1,
                subtitle: `«AXELOT WMS X5»`,
                text: `Automation of warehouse processes with AXELOT WMS X5 software`,
                id: 'modalContent_2020_2',
                modalDescription: `Automation of warehouse processes with AXELOT WMS X5 software`,
                modalClient: `- Central Directorate of Procurement and Supply`,
                modalResults: `The system was tested and demonstrated at the material warehouse Moscow-3 (Sokolniki) of the Moscow-Kursk Logistics Department. Work was organized to introduce the technology of a startup project into the existing automated system of a business customer. Organized preparatory work for performance testing.`,
            },
            {
                img: history2021Preview2,
                modalLogo: history_2022_3,
                modalImg: history2021Modal2,
                subtitle: `«Digital Wave»`,
                text: `Software and hardware complex for calculating the volumes of bulk products in closed warehouses, including the method of topographic survey of the area using photographing and building three-dimensional digital models`,
                id: 'modalContent_2020_3',
                modalDescription: `Software and hardware complex for calculating the volumes of bulk products in closed warehouses, including the method of topographic survey of the area using photographing and building three-dimensional digital models`,
                modalClient: `- Central Directorate for the management of the terminal and warehouse complex`,
                modalResults: `
Operational tests of the system were carried out at the test site of the Sverdlovsk Directorate for the management of the terminal and warehouse complex. Work was organized to introduce the technology of a startup project into the existing automated system of a business customer.`
            },
        ],
    },
    {
        category: 'Program&nbsp;2020',
        content: [
            {
                img: historyPreview_2021_1,
                modalLogo: history_2021_1,
                modalImg: modalImg_2021_1,
                subtitle: `Imredi`,
                text: `Automation of warehouse processes with AXELOT WMS X5 software`,
                id: 'modalContent_2020_1',
                modalDescription: `Digital platform for improving the efficiency and safety of&nbsp;business processes, quality, productivity and staff motivation.`,
                modalClient: `- Directorate of high-speed communication`,
                modalResults: `Test tests have been carried out. The startup project integrates its solution into the existing system of&nbsp;the business customer.`,
            },
            {
                img: historyPreview_2021_2,
                modalLogo: history_2021_2,
                modalImg: modalImg_2021_2,
                subtitle: `TITAN Power Solution`,
                text: `Hybrid energy recovery systems.`,
                id: 'modalContent_2020_2',
                modalDescription: `Hybrid energy recovery systems.`,
                modalClient: `- Central Directorate for Track Repair`,
                modalResults: `Operational tests of a supercapacitor energy storage system were carried out to automate the process of moving rail and sleeper units on the traverse of a laying crane. Negotiations are underway on the serial installation of the product at the plant of the manufacturer of railway equipment.`,
            },
            {
                img: historyPreview_2021_3,
                modalLogo: history_2021_3,
                modalImg: modalImg_2021_3,
                subtitle: `MRO according to the actual state`,
                text: `Management system for maintenance and repair of&nbsp;rolling stock based on&nbsp;the actual state.`,
                id: 'modalContent_2020_3',
                modalDescription: `Management system for maintenance and repair of&nbsp;rolling stock based on&nbsp;the actual state.`,
                modalClient: `- Traction Rolling Stock Repair Directorate`,
                modalResults: `Work continues with the project on the implementation of solutions as part of the digitalization plan of Russian Railways.`,
            },
        ],
    },
    {
        category: 'Program&nbsp;2019',
        content: [
            {
                img: historyPreview2,
                modalLogo: history2,
                modalImg: modalImg2,
                subtitle: `O.Vision`,
                text: `Face recognition in&nbsp;less than 1&nbsp;second with installation of&nbsp;device possible on&nbsp;turnstiles and doors`,
                id: 'modalContent_2019_2',
                modalDescription: `Face recognition in&nbsp;less than 1&nbsp;second with installation of&nbsp;device possible on&nbsp;turnstiles and doors`,
                modalClient: `&mdash;&nbsp;High-speed service department<br/>
        &mdash;&nbsp;IT&nbsp;system design and engineering bureau<br/>
        &mdash;&nbsp;Central telecommunications station<br/>
        &mdash;&nbsp;IT&nbsp;department`,
                modalResults: `120 access control devices based on face recognition technology were installed in the Company's administrative buildings. A service was introduced for payment by a person for services in bistro cars of Sapsan high-speed trains using biometric data (the service is implemented on the basis of the OPAY solution - face recognition technology from OVISION).`,
            },
            {
                img: historyPreview4,
                modalLogo: history4,
                modalImg: modalImg4,
                subtitle: `TurboContract`,
                text: `Designer of&nbsp;documents. In&nbsp;a&nbsp;matter of&nbsp;minutes, creates fully completed documents (sets of&nbsp;documents) based on&nbsp;pre-prepared variable templates`,
                id: 'modalContent_2019_4',
                modalDescription: `Designer of&nbsp;documents. In&nbsp;a&nbsp;matter of&nbsp;minutes, creates fully completed documents (sets of&nbsp;documents) based on&nbsp;pre-prepared variable templates`,
                modalClient: `&mdash;&nbsp;VNIIZhT<br/>
                           &mdash;&nbsp;Central procurement and supply department`,
                modalResults: `Work is underway with VNIIZhT JSC to implement the startup project solutions into existing systems.`,
            },
            {
                img: historyPreview1,
                modalLogo: history1,
                modalImg: modalImg1,
                subtitle: `RusSoft Expert`,
                text: `Replacement of&nbsp;nickel-cadmium storage batteries with lithium-titanate batteries on&nbsp;Russian Railways locomotives and electric trains`,
                id: 'modalContent_2019_1',
                modalDescription: `Replacement of&nbsp;nickel-cadmium storage batteries with lithium-titanate batteries on&nbsp;Russian Railways locomotives and electric trains:`,
                modalClient: `&mdash;&nbsp;Traction department<br/>
                            &mdash;&nbsp;Central railcar rolling stock department`,
                modalResults: `Bench tests were carried out, work was organized to&nbsp;prepare for extended operational tests.`,
            },
       //      {
       //          img: historyPreview3,
       //          modalLogo: history3,
       //          modalImg: modalImg3,
       //          subtitle: `HintEd`,
       //          text: `An&nbsp;interactive assistant that helps new employees to&nbsp;quickly adapt to&nbsp;working with corporate systems, relieves the burden of&nbsp;having to&nbsp;create large job instruction manuals and expensive simulators`,
       //          id: 'modalContent_2019_3',
       //          modalDescription: `An&nbsp;interactive assistant that helps new employees to&nbsp;quickly adapt to&nbsp;working with corporate systems, relieves the burden of&nbsp;having to&nbsp;create large job instruction manuals and expensive simulators`,
       //          modalClient: `&mdash;&nbsp;Central telecommunications station<br/>
       // &mdash;&nbsp;Center for innovative development`,
       //          modalResults: `Several tests and a&nbsp;demonstration of&nbsp;the system&rsquo;s operation were carried out. Work has been organized to&nbsp;introduce interactive instruction technologies into the systems of&nbsp;business customers.`,
       //      },
        ],
    },
];

