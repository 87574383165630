import React, { Fragment, useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import RedButton from '../../ui/RedButton';
import * as Yup from 'yup';

import FieldsCollection from '../../../models/FieldsCollection';
import estimateConfig from '../../../data/estimate-config.json';
import estimateConfigNTE from '../../../data/estimate-configNTE.json';
import User from '../../../models/User';
import './style.sass';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Divider } from '@material-ui/core';

const ExpertsEstimate = ({ id }) => {
    const [userData, setUserData] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    const estimates = {
        rating_one: 0,
        rating_two: 0,
        rating_three: 0,
        rating_four: 0,
        rating_five: 0,
    };
    let estimatesInfo = {
        UF_RATING_TOTAL: 0,
        UF_ID_REQUEST: id,
        UF_USER_ID: User.getId(),
    };
    const defaultUrl = '/bitrix/services/main/ajax.php?action=webpractik';

    const generateFormData = obj => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            formData.append(key, obj[key]);
        });
        return formData;
    };

    useEffect(() => {
        axios
            .post(
                `${defaultUrl}:main.forms.infouser`,
                generateFormData({ USERID: User.getId() })
            )
            .then(res => {
                setUserData(res.data.data);
            })
            .catch(err => console.log(err));
    }, []);

    const estimateCollection = new FieldsCollection(
        userData
            ? userData.type === 'Бизнесс'
                ? estimateConfig
                : userData.type === 'Технологии'
                ? estimateConfigNTE
                : estimateConfig
            : estimateConfig
    );

    const { getInitialValues, getScheme, collectionList } = estimateCollection;
    const initialData = getInitialValues();

    const calcEstimate = (name, value) => {
        // @ts-ignore
        estimates[name] = value;
        estimatesInfo['UF_RATING_TOTAL'] =
            Object.values(estimates).reduce(
                (accumulator, currentValue) => accumulator + currentValue
            ) /
            collectionList.filter(i => i.config.name.includes('UF_RATING'))
                .length;
    };

    const handleSubmit = values => {
        axios
            .post(
                `${defaultUrl}:main.forms.estimateadd`,
                generateFormData({ ...values, ...estimatesInfo })
            )
            .then(res => {
                setSuccess(true);
                setTimeout(() => {
                    history.push('/request');
                }, 1500);
            })
            .catch(err => console.log(err));
    };
    return (
        <div className="estimate">
            <div className="estimate__row">
                <div className="estimate__title">Критерий</div>
                <div className="estimate__title">Оценка</div>
            </div>
            <Divider />
            <Formik
                initialValues={{ ...initialData }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape(getScheme())}
            >
                {props => {
                    return (
                        <Fragment>
                            <Form noValidate={true} className="estimate-form">
                                {collectionList.map(item =>
                                    item.renderWithFormik({
                                        ...props,
                                        calcEstimate,
                                    })
                                )}
                                {(success && (
                                    <div className="lk-form-success">
                                        Оценка сохранена!
                                    </div>
                                )) || (
                                    <RedButton size="sm" type="submit">
                                        Оценить
                                    </RedButton>
                                )}
                            </Form>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ExpertsEstimate;
