import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';

const ExpertsSlider = ({ data }) => {
    const getScreenWidth = () => window.screen.width;
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());
    const setWidthToState = () => {
        setScreenWidth(getScreenWidth());
        console.log(screenWidth);
    };
    const customSlider = useRef();

    useEffect(() => {
        window.addEventListener('resize', setWidthToState);
        return () => {
            window.removeEventListener('resize', setWidthToState);
        };
    }, []);

    const sliderSettings = {
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };
    return (
        <>
            <div className="experts-navigation">
                <div
                    id="experts-nav-prev"
                    onClick={() => customSlider.current.slickPrev()}
                >
                    <img src={arrowPrev} alt="" />
                </div>
                <div
                    id="experts-nav-next"
                    onClick={() => customSlider.current.slickNext()}
                >
                    <img src={arrowNext} alt="" />
                </div>
            </div>
            {screenWidth < 751 ? (
                <div className="experts-slider experts-slider__margin">
                    <Slider
                        {...sliderSettings}
                        ref={slider => (customSlider.current = slider)}
                    >
                        {data.map((experts) => {
                            return (
                                experts.map((item, index) =>  {
                                    return (
                                        <div
                                            className={`experts__col ${
                                                item.show ? 'show' : 'hide'
                                            }`}
                                            key={index}
                                        >
                                            <div className="experts__item">
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="experts__img"
                                                />
                                                <div
                                                    className="experts__name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.name,
                                                    }}
                                                />
                                                <div
                                                    className="experts__position"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.position,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            )
                        })}
                    </Slider>
                </div>
            ) : (
                <>
                    {data.map(experts => {
                        return (
                            <div className="experts__row">
                                {experts.map((item, index) => {
                                    return (
                                        <div
                                            className={`experts__col ${
                                                item.show ? 'show' : 'hide'
                                            }`}
                                            key={index}
                                        >
                                            <div className="experts__item">
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="experts__img"
                                                />
                                                <div
                                                    className="experts__name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.name,
                                                    }}
                                                />
                                                <div
                                                    className="experts__position"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.position,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    })}
                </>
            )}
        </>
    );
};
export default ExpertsSlider;
