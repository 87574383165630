import React, { useEffect, useState } from 'react';
import './style.sass';
import ResourceTitlePage from '../../ui/ResourceTitlePage';
import { ReactComponent as SkypeIcon } from '../../../assets/img/svg/admin/skype.svg';
import MeetingTable from '../CustomerList/MeetingTable';
import ProjectMaterials from '../ProjectMaterials';
import axios from 'axios';
import LKRepository from '../../../models/LKRepository';

const FunctionalCustomersShowFZ = props => {
    const [fzInfo, setFzInfo] = useState(null);
    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.fzinfoforanketa`,
                LKRepository.generateFormData({
                    APP_ID: props.id,
                })
            )
            .then(res => {
                setFzInfo(res.data.data);
            })
            .catch(err => console.log(err));
    }, []);
    return (
        <div className="func-customers-show">
            {fzInfo && (
                <>
                    <MeetingTable
                        {...props}
                        record={fzInfo[0]?.meetingMinutes}
                    />
                    <ProjectMaterials
                        {...props}
                        fzLinkId={fzInfo[0].fzLinkId}
                        record={fzInfo[0]?.projectMaterials}
                    />
                </>
            )}
        </div>
    );
};

export default FunctionalCustomersShowFZ;
