import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';

const PhoneTextMask: FC<any> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                '+',
                '7',
                ' ',
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
        />
    );
};

export default PhoneTextMask;
