import React, { FC } from 'react';
import './style.sass';
import Button from '@material-ui/core/Button';

interface IProps {
    children: string;
    type?: 'submit' | 'button';
    size?: 'sm' | 'md' | 'md-m' | 'lg';
    [key: string]: any;
}

const RedButton: FC<IProps | any> = props => {
    const { size, ...restProps } = props;
    const classList = ['admin-red-btn'];

    if (size) {
        classList.push(`admin-red-btn--${size}`);
    }

    return (
        <Button
            {...restProps}
            className={classList.join(' ')}
            variant="contained"
        />
    );
};

export default RedButton;
