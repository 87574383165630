import React, { useEffect, useState } from 'react';
import './style.sass';
import { Divider } from '@material-ui/core';
import axios from 'axios';
import uniqBy from 'lodash.uniqby';

const ExpertiseShowView = props => {
    const [items, setItems] = useState('');
    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.questionnairebyidwithestimate`,
                props.generateFormData({ UF_ID_REQUEST: props.id })
            )
            .then(response => {
                const r = response.data.data.estimate.filter(
                    item => +item.TYPE_EXPERT === +props.type
                );

                const lll = uniqBy(r, 'UF_CRIT');
                lll.sort(function (a, b) {
                    if (a.UF_CRIT.includes('Итоговая оценка')) {
                        return 1;
                    } else {
                        return -1;
                    }
                });

                setItems(lll);
            })
            .catch(err => console.log(err));
    }, [props.id, props.type]);

    return (
        <div className="expertise-form-show">
            <div className="estimate__row">
                <div className="estimate__title">Критерий</div>
                <div className="estimate__title">Оценка</div>
            </div>
            <Divider />
            {items &&
                items.map(item => {
                    if (item.UF_CRIT) {
                        return (
                            <div className="expertise-form-show__row">
                                <div className="expertise-form-show__label">
                                    {item.UF_CRIT}
                                </div>
                                <div className="expertise-form-show-item">
                                    <div className="expertise-form-show-item__comment">
                                        <div className="expertise-form-show-item__comment-title">
                                            Комментарий
                                        </div>
                                        {item.UF_COMMENT}
                                    </div>
                                    <div className="expertise-form-show-item__rating">
                                        <div className="estimate-field__grade text-center">
                                            <span className="estimate-field__grade--big">
                                                {item.UF_RATING}
                                            </span>
                                            /5
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
        </div>
    );
};

export default ExpertiseShowView;
