import React, { FC, Fragment, useState } from 'react';
import { dataApplication } from '../../../types/application';
import { Form, Formik } from 'formik';
import RedButton from '../../ui/RedButton';
import * as Yup from 'yup';

import FieldsCollection from '../../../models/FieldsCollection';
import profileConfig from '../../../data/profile-config.json';
import User from '../../../models/User';
// @ts-ignore
import { ReactComponent as SaveIcon } from '../../../assets/img/svg/icon-save.svg';

import './style.sass';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

type AppFormProps = { data: dataApplication | null };
const profileCollection = new FieldsCollection(profileConfig);
const ExpertsProfileEditForm: FC<AppFormProps> = ({ data }) => {
    const [success, setSuccess] = useState<boolean>(false);
    const history = useHistory();

    const generateFormData = (obj: any) => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            formData.append(key, obj[key]);
        });
        return formData;
    };

    const { getInitialValues, getScheme, collectionList } = profileCollection;
    const initialData = getInitialValues();
    const defaultUrl = '/bitrix/services/main/ajax.php?action=webpractik';
    const handleSubmit = (values: any) => {
        axios
            .post(
                `${defaultUrl}:main.forms.updateuser`,
                generateFormData(values)
            )
            .then(res => {
                setSuccess(true);
                setTimeout(() => {
                    history.push('/profile');
                }, 1500);
            })
            .catch(err => console.log(err));
    };

    return (
        <Formik
            initialValues={{ ...initialData, ...User.getUserData() }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape(getScheme())}
        >
            {props => {
                return (
                    <Fragment>
                        <Form noValidate={true} className="profile-form">
                            {collectionList.map((item: any) =>
                                item.renderWithFormik(props)
                            )}
                            {(success && (
                                <div className="lk-form-success">
                                    Профиль обновлен!
                                </div>
                            )) || (
                                <RedButton size="sm" type="submit">
                                    <SaveIcon className="profile-form__save" />
                                    Сохранить
                                </RedButton>
                            )}
                        </Form>
                    </Fragment>
                );
            }}
        </Formik>
    );
};

export default ExpertsProfileEditForm;
