import React from 'react';
import { NewsItems } from '../../data/data';
import './style.sass';
import arrowPrev from '../../assets/img/svg/experts-arrow-prev.svg';
import arrowNext from '../../assets/img/svg/experts-arrow-next.svg';
import parse from 'html-react-parser/index';
import InnerNewsSlider from '../InnerNewsSlider';

const News = () => {
  return (
    <section
      className="news"
      data-aos="slide-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-duration="1500"
      id="video-news"
    >
      <div className="full-wrapper">
        <div className="news__title">Новости</div>
        <div className="news-navigation">
          <a href="" id="news-nav-prev">
            <img src={arrowPrev} alt="" />
          </a>
          <a href="" id="news-nav-next">
            <img src={arrowNext} alt="" />
          </a>
        </div>
        <div className="news__row news-slider">
          {NewsItems.map((item, index) => {
            return (
              <div className="news__col" key={index}>
                <a
                  data-fancybox
                  data-src={item.isYouTube ? null : `#${item.id}`}
                  href={item.isYouTube ? item.isYouTube : 'javascript:;'}
                  className="news__item"
                >
                  <div className="news__top">
                    <img src={item.img} alt="" className="news__img" />
                    <div
                      className="news__subtitle"
                      dangerouslySetInnerHTML={{
                        __html: item.subtitle,
                      }}
                    />
                  </div>
                  <div
                    className="news__text"
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                </a>
                <div style={{ display: 'none' }} id={item.id}>
                  <div className="history-modal">
                    <div className="history-modal__row">
                      <div className="history-modal__col history-modal__col--full history-modal__col--padding">
                        {item.isVideo ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.videoBody,
                            }}
                          />
                        ) : (
                          <div className="history-modal__item">
                            {item.textBody.map((item, index) => {
                              if (item.type === 'title') {
                                return (
                                  <div
                                    className="history-modal__title"
                                    key={index}
                                  >
                                    {parse(item.content)}
                                  </div>
                                );
                              } else if (item.type === 'text') {
                                return (
                                  <div
                                    className="history-modal__text"
                                    key={index}
                                  >
                                    {parse(item.content)}
                                  </div>
                                );
                              } else if (item.type === 'slider') {
                                return <InnerNewsSlider data={item.content} />;
                              } else if (item.type === 'img') {
                                return (
                                  <img
                                    className="history-modal__img"
                                    src={item.content}
                                    alt=""
                                    key={index}
                                  />
                                );
                              } else if (item.type === 'list') {
                                return (
                                  <div
                                    className="history-modal__text history-modal__text--list"
                                    key={index}
                                  >
                                    {item.content.map((item, index) => {
                                      return (
                                        <span key={index}>{parse(item)}</span>
                                      );
                                    })}
                                  </div>
                                );
                              } else if (item.type === 'text-red') {
                                return (
                                  <div
                                    className="history-modal__text history-modal__text--red"
                                    key={index}
                                  >
                                    {parse(item.content)}
                                  </div>
                                );
                              } else if (item.type === 'subtitle') {
                                return (
                                  <div
                                    className="history-modal__subtitle"
                                    key={index}
                                  >
                                    {parse(item.content)}
                                  </div>
                                );
                              } else if (item.type === 'text-italic') {
                                return (
                                  <div
                                    className="history-modal__text history-modal__text--italic"
                                    key={index}
                                  >
                                    {parse(item.content)}
                                  </div>
                                );
                              } else if (item.type === 'inner-video') {
                                return (
                                    <iframe
                                        className="history-modal__inner-video"
                                        src={item.content}
                                        allowFullScreen
                                        key={index}
                                    />
                                );
                              } else if (item.type === 'inner-video-desc') {
                                return (
                                    <div
                                        className="history-modal__inner-video-desc"
                                        key={index}
                                    >
                                      {parse(item.content)}
                                    </div>
                                );
                              }
                            })}
                            {item.borderedContent && (
                              <div className="history-modal__results">
                                {item.borderedContent.map((item, index) => {
                                  if (item.type === 'title') {
                                    return (
                                      <div
                                        className="history-modal__title"
                                        key={index}
                                      >
                                        {parse(item.content)}
                                      </div>
                                    );
                                  } else if (item.type === 'text') {
                                    return (
                                      <div
                                        className="history-modal__text"
                                        key={index}
                                      >
                                        {parse(item.content)}
                                      </div>
                                    );
                                  } else if (item.type === 'img') {
                                    return (
                                      <img
                                        className="history-modal__img"
                                        src={item.content}
                                        alt=""
                                        key={index}
                                      />
                                    );
                                  } else if (item.type === 'list') {
                                    return (
                                      <div
                                        className="history-modal__text history-modal__text--list"
                                        key={index}
                                      >
                                        {item.content.map((item, index) => {
                                          return (
                                            <span key={index}>
                                              {parse(item)}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    );
                                  } else if (item.type === 'text-red') {
                                    return (
                                      <div
                                        className="history-modal__text history-modal__text--red"
                                        key={index}
                                      >
                                        {parse(item.content)}
                                      </div>
                                    );
                                  } else if (item.type === 'subtitle') {
                                    return (
                                      <div
                                        className="history-modal__subtitle"
                                        key={index}
                                      >
                                        {parse(item.content)}
                                      </div>
                                    );
                                  } else if (item.type === 'text-italic') {
                                    return (
                                      <div
                                        className="history-modal__text history-modal__text--italic"
                                        key={index}
                                      >
                                        {parse(item.content)}
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                            {item.isLinks && (
                              <div className="history-modal__bottom">
                                <div className="history-modal__links">
                                  <a
                                    href={`http://${item.link1}`}
                                    target="_blank"
                                    className="history-modal__link"
                                  >
                                    {item.link1}
                                  </a>
                                  <a
                                      href={`http://${item.link3}`}
                                      target="_blank"
                                      className="history-modal__link"
                                  >
                                    {!item.linkTitle ? item.link3 : item.linkTitle}
                                  </a>
                                  {!item.noPress && (<div className="history-modal__text">
                                    <a
                                        href={`mailto:${item.link2}`}
                                        target="_blank"
                                        className="history-modal__link"
                                    >
                                      {item.link2}
                                      <span>
                                        &mdash;&nbsp;пресс-служба GenerationS
                                      </span>
                                    </a>
                                  </div>)}
                                </div>
                                <div className="history-modal__social">
                                  <a
                                    href="#"
                                    className="history-modal__social-link"
                                  >
                                    <img src={item.social1} alt="" />
                                  </a>
                                  <a
                                    href="#"
                                    className="history-modal__social-link"
                                  >
                                    <img src={item.social2} alt="" />
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default News;
