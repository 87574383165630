import React, { FC } from 'react';
import './style.sass';

interface IProps {}

const FBIcon: FC<IProps> = props => {
    return (
        <svg
            className="fb-svg-icon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.423 20v-7.298h2.464l.369-2.845h-2.832V8.042c0-.824.23-1.385 1.417-1.385h1.515V4.111A20.255 20.255 0 0 0 14.148 4c-2.183 0-3.678 1.326-3.678 3.76v2.097H8v2.845h2.47V20h2.953z"
                fill="#FFF"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default FBIcon;
