import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ModalWrap from '../../ManagerList/ExpertModal';
import FormControl from '@material-ui/core/FormControl';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import Statuses from '../../../../data/statusesId.json';
import { Notification, useNotify, useRefresh } from 'react-admin';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ActionField = ({ source, basePath, record = {} }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isOpenRevision, setToggleModalRevision] = useState(false);
    const [linkFile, setLinkFile] = useState('');

    const menuOpen = () => {
        setToggleMenu(true);
    };

    const modalHandleRevision = active => {
        setToggleMenu(false);
        setToggleModalRevision(active);
    };

    /*******/

    const ModalRevision = props => {
        const classes = useStyles();
        const [success, setSuccess] = React.useState('');
        const [error, setError] = React.useState('');
        const [comment, setComment] = React.useState('');

        const handleChange = event => {
            setComment(event.target.value);
        };

        const handleSaveComment = () => {
            if (comment) {
                setError('');
                axios
                    .post(
                        `/bitrix/services/main/ajax.php?action=webpractik:main.forms.expertrefuse
`,
                        LKRepository.generateFormData({
                            APP_ID: record.id,
                            EXPERT_COMMENT: comment,
                        })
                    )
                    .then(response => {
                        if (response.data.status === 'success') {
                            setSuccess('Заявка отклонена');
                            refresh();
                        } else {
                            notify('Что-то пошло не так');
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                setError('Заполните комментарий');
            }
        };

        return (
            <ModalWrap
                isOpen={isOpenRevision}
                toggleModal={modalHandleRevision}
            >
                <div
                    className={`expert-modal ${
                        success ? `expert-modal--success` : ``
                    }`}
                >
                    {!success ? (
                        <>
                            <div className="expert-modal__title">
                                Укажите причину отклонения заявки
                            </div>
                            <div className="expert-modal__row">
                                <div className="expert-modal__col-12">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="commentRevision"
                                            label="Комментарий"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {error && (
                                <div className="expert-modal__error">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="lg-m"
                                    type="button"
                                    onClick={() => handleSaveComment()}
                                >
                                    Отклонить
                                </RedButton>

                                <button
                                    className="expert-modal__cancel"
                                    onClick={() => {
                                        modalHandleRevision(false);
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="expert-modal__success">
                                {success}
                            </div>
                            <div className="expert-modal__bottom">
                                <RedButton
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        modalHandleRevision(false);
                                    }}
                                >
                                    Закрыть
                                </RedButton>
                            </div>
                        </>
                    )}
                </div>
            </ModalWrap>
        );
    };

    /**************/
    useEffect(() => {
        axios
            .post(
                `/bitrix/services/main/ajax.php?action=webpractik:main.forms.getarchive`,
                LKRepository.generateFormData({ ID: record.id })
            )
            .then(response => {
                setLinkFile(response.data.data);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <div className="action-field">
            <div
                className="action-field__title"
                onClick={e => {
                    e.stopPropagation();
                    menuOpen();
                }}
            >
                Действия
            </div>
            {toggleMenu && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setToggleMenu(false);
                    }}
                >
                    <div
                        className={`action-field-menu ${
                            toggleMenu && `active`
                        }`}
                    >
                        <div className="action-field-menu__item">
                            <a href={`/lk#${basePath}/${record.id}`}>Открыть</a>
                        </div>
                        <div className="action-field-menu__item">
                            <a href={linkFile ? linkFile : null} download>
                                Скачать анкету и файлы
                            </a>
                        </div>

                        <div
                            className="action-field-menu__item"
                            onClick={e => {
                                e.stopPropagation();
                                modalHandleRevision(true);
                            }}
                        >
                            Отклонить
                        </div>
                    </div>
                </OutsideClickHandler>
            )}
            {isOpenRevision && <ModalRevision {...record} />}
            <Notification />
        </div>
    );
};

export default ActionField;
