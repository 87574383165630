import React from 'react';
import cn from './style.module.sass';
import Tab from '../Tab';

const Tabs = ({ tabs, children, activeTab, handleTab }) => {
    return (
        <div className={cn.wrapper}>
            <div className={cn.tabs} data-length={tabs.length}>
                {tabs.map((item, index) => (
                    <Tab
                        key={index}
                        length={tabs.length}
                        label={item}
                        active={activeTab === item}
                        handleTab={() => handleTab(item)}
                    />
                ))}
            </div>
            <>{children}</>
        </div>
    );
};

export default Tabs;
