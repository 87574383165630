import React, { useRef, useState } from 'react';
import cn from './style.module.sass';
import InnerNewsSlider from '../InnerNewsSlider';
import parse from 'html-react-parser';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ModalWrap from '../resources/ManagerList/ExpertModal';

const NewsSliderItem = ({ item }) => {
    const [open, setOpen] = useState(false);
    const modalRef = useRef(null);
    useOnClickOutside(modalRef, () => setOpen(false));
    return (
        <div className={cn.col}>
            <div className={cn.item} onClick={() => setOpen(true)}>
                <div className={cn.top}>
                    <img src={item.img} alt="" className={cn.img} />
                    <div
                        className={cn.subtitle}
                        dangerouslySetInnerHTML={{
                            __html: item.subtitle,
                        }}
                    />
                </div>
                <div
                    className={cn.text}
                    dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}
                />
            </div>
            <ModalWrap isOpen={open} toggleModal={setOpen} redBorder>
                <div className="history-modal" ref={modalRef}>
                    <div className="history-modal__row">
                        <div className="history-modal__col history-modal__col--full history-modal__col--padding">
                            {item.isVideo ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.videoBody,
                                    }}
                                />
                            ) : (
                                <div className="history-modal__item">
                                    {item.textBody.map((item, index) => {
                                        if (item.type === 'title') {
                                            return (
                                                <div
                                                    className="history-modal__title"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        } else if (item.type === 'text') {
                                            return (
                                                <div
                                                    className="history-modal__text"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        } else if (item.type === 'slider') {
                                            return (
                                                <InnerNewsSlider
                                                    data={item.content}
                                                    key={index}
                                                />
                                            );
                                        } else if (item.type === 'img') {
                                            return (
                                                <img
                                                    className="history-modal__img"
                                                    src={item.content}
                                                    alt=""
                                                    key={index}
                                                />
                                            );
                                        } else if (item.type === 'list') {
                                            return (
                                                <div
                                                    className="history-modal__text history-modal__text--list"
                                                    key={index}
                                                >
                                                    {item.content.map(
                                                        (item, index) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                >
                                                                    {parse(
                                                                        item
                                                                    )}
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            );
                                        } else if (item.type === 'text-red') {
                                            return (
                                                <div
                                                    className="history-modal__text history-modal__text--red"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        } else if (item.type === 'subtitle') {
                                            return (
                                                <div
                                                    className="history-modal__subtitle"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        } else if (
                                            item.type === 'text-italic'
                                        ) {
                                            return (
                                                <div
                                                    className="history-modal__text history-modal__text--italic"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        } else if (
                                            item.type === 'inner-video'
                                        ) {
                                            return (
                                                <iframe
                                                    className="history-modal__inner-video"
                                                    src={item.content}
                                                    allowFullScreen
                                                    key={index}
                                                />
                                            );
                                        } else if (
                                            item.type === 'inner-video-desc'
                                        ) {
                                            return (
                                                <div
                                                    className="history-modal__inner-video-desc"
                                                    key={index}
                                                >
                                                    {parse(item.content)}
                                                </div>
                                            );
                                        }
                                    })}
                                    {item.borderedContent && (
                                        <div className="history-modal__results">
                                            {item.borderedContent.map(
                                                (item, index) => {
                                                    if (item.type === 'title') {
                                                        return (
                                                            <div
                                                                className="history-modal__title"
                                                                key={index}
                                                            >
                                                                {parse(
                                                                    item.content
                                                                )}
                                                            </div>
                                                        );
                                                    } else if (
                                                        item.type === 'text'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="history-modal__text"
                                                                key={index}
                                                            >
                                                                {parse(
                                                                    item.content
                                                                )}
                                                            </div>
                                                        );
                                                    } else if (
                                                        item.type === 'img'
                                                    ) {
                                                        return (
                                                            <img
                                                                className="history-modal__img"
                                                                src={
                                                                    item.content
                                                                }
                                                                alt=""
                                                                key={index}
                                                            />
                                                        );
                                                    } else if (
                                                        item.type === 'list'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="history-modal__text history-modal__text--list"
                                                                key={index}
                                                            >
                                                                {item.content.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <span
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {parse(
                                                                                    item
                                                                                )}
                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        );
                                                    } else if (
                                                        item.type === 'text-red'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="history-modal__text history-modal__text--red"
                                                                key={index}
                                                            >
                                                                {parse(
                                                                    item.content
                                                                )}
                                                            </div>
                                                        );
                                                    } else if (
                                                        item.type === 'subtitle'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="history-modal__subtitle"
                                                                key={index}
                                                            >
                                                                {parse(
                                                                    item.content
                                                                )}
                                                            </div>
                                                        );
                                                    } else if (
                                                        item.type ===
                                                        'text-italic'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="history-modal__text history-modal__text--italic"
                                                                key={index}
                                                            >
                                                                {parse(
                                                                    item.content
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                }
                                            )}
                                        </div>
                                    )}
                                    {item.isLinks && (
                                        <div className="history-modal__bottom">
                                            <div className="history-modal__links">
                                                <a
                                                    href={`http://${item.link1}`}
                                                    target="_blank"
                                                    className="history-modal__link"
                                                >
                                                    {item.link1}
                                                </a>
                                                <a
                                                    href={`http://${item.link3}`}
                                                    target="_blank"
                                                    className="history-modal__link"
                                                >
                                                    {!item.linkTitle
                                                        ? item.link3
                                                        : item.linkTitle}
                                                </a>
                                                {!item.noPress && (
                                                    <div className="history-modal__text">
                                                        <a
                                                            href={`mailto:${item.link2}`}
                                                            target="_blank"
                                                            className="history-modal__link"
                                                        >
                                                            {item.link2}
                                                            <span>
                                                                &mdash;&nbsp;пресс-служба
                                                                GenerationS
                                                            </span>
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="history-modal__social">
                                                <a
                                                    href="#"
                                                    className="history-modal__social-link"
                                                >
                                                    <img
                                                        src={item.social1}
                                                        alt=""
                                                    />
                                                </a>
                                                <a
                                                    href="#"
                                                    className="history-modal__social-link"
                                                >
                                                    <img
                                                        src={item.social2}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalWrap>
        </div>
    );
};

export default NewsSliderItem;
