import { connect } from 'formik';
import { useEffect, useRef, useCallback } from 'react';

const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const FormikEffect = ({ onChange, formik }) => {
    const { values } = formik;
    const prevValues = usePrevious(values);
    const debounceOnChange = useCallback(debounce(onChange, 1000), []);

    useEffect(() => {
        if (prevValues) {
            debounceOnChange({ prevValues, nextValues: values, formik });
        }
    }, [values]);

    return null;
};

export default connect(FormikEffect);
