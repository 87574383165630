import $ from 'jquery';
window.$ = window.jQuery = require('jquery');
const fancybox = require('@fancyapps/fancybox');

$(document).ready(function ($) {
    $('[data-fancybox="gallery"]').fancybox();
    $('.experts__link').click(e => {
        e.preventDefault();
        $('.experts__col.hide').slideToggle();
        $('.experts__link').hide();
    });
});
