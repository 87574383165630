import React, { useState } from 'react';
import Collapse from '@kunukn/react-collapse';
import './style.sass';
import parse from "html-react-parser";

const TechnoFocusItem = ({ data, btnText }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="techno-table-body__item">
            <div className="techno-table-body__row">
                <div className="techno-table-body__col">
                    {data.title}
                </div>

                <div className="techno-table-body__col">
                    <div className="techno-table-body__capture">
                        {data.valueMain.name}
                    </div>
                    <div className="techno-table-body__text">
                        {parse(data.valueMain.text)}
                    </div>

                    <Collapse isOpen={open} transition="height 300ms cubic-bezier(.4, 0, .2, 1)">
                    {
                        data.valueOther.map((item, idx) =>
                            <div key={idx} className="techno-table-body__box">
                                <div className="techno-table-body__capture">
                                    {item.name}
                                </div>
                                <div className="techno-table-body__text">
                                    {parse(item.text)}
                                </div>
                            </div>
                        )
                    }
                    </Collapse>

                    <button className={`techno-table-body__btn ${open ? 'btn-active' : ''}`} onClick={() => setOpen(val => !val)}>
                        { open ? btnText.hidden : btnText.shown}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TechnoFocusItem;
