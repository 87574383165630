import React, { useEffect, useState } from 'react';
import keyBy from 'lodash.keyby';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading,
    DatagridBody,
    usePermissions,
} from 'react-admin';
import './style.sass';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ResourceTitlePage from '../../../ui/ResourceTitlePage';
import ProtocolModal from './ProtocolModal';
import User from '../../../../models/User';

const MyDatagridRow = ({
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath,
}) => (
    <TableRow key={id}>
        {/*<TableCell padding="none">*/}
        {/*    <Checkbox*/}
        {/*        disabled={record.selectable}*/}
        {/*        checked={selected}*/}
        {/*        onClick={() => onToggleItem(id)}*/}
        {/*    />*/}
        {/*</TableCell>*/}

        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);

const MyDatagridBody = props => (
    <DatagridBody {...props} row={<MyDatagridRow />} />
);

const MeetingTable = ({ location, record, id, history }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [isOpen, setIsOpen] = React.useState(false);
    const { permissions } = usePermissions();
    const modalToggle = active => {
        setIsOpen(active);
    };
    const [appId, setAppId] = useState(null);
    useEffect(() => {
        const getParamsPage = location.search;
        if (getParamsPage.includes('id')) {
            const appId = new URLSearchParams(getParamsPage).get('id');
            setAppId(appId);
        }
    }, [location.search]);
    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'fc/request',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'uploadDate', order: 'ASC' },
            filter: {},
        },
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR: {error}</p>;
    }

    return (
        <>
            <div className="meeting-table">
                <ResourceTitlePage text="Встречи" />

                {appId && User.getRole(permissions) === 'manager' && (
                    <div className="customers-modal-btn__wr">
                        <span
                            className="customers-modal-btn"
                            onClick={() => {
                                modalToggle(true);
                            }}
                        >
                            Загрузить протокол
                        </span>
                        <ProtocolModal
                            isOpen={isOpen}
                            toggle={modalToggle}
                            id={appId}
                            {...history}
                        />
                    </div>
                )}

                {/*<Datagrid*/}
                {/*    data={keyBy(record, 'ID')}*/}
                {/*    ids={record.map(({ ID }) => ID)}*/}
                {/*    currentSort={{ field: 'ID', order: 'ASC' }}*/}
                {/*    selectedIds={[]}*/}
                {/*    // body={<MyDatagridBody />}*/}
                {/*>*/}
                {/*    <TextField source="UF_RATING" label="Дата встречи" />*/}
                {/*    <TextField source="UF_CRIT" label="Протокол встречи" />*/}
                {/*</Datagrid>*/}
                {/*<Pagination*/}
                {/*    page={page}*/}
                {/*    perPage={perPage}*/}
                {/*    setPage={setPage}*/}
                {/*    setPerPage={setPerPage}*/}
                {/*    total={total}*/}
                {/*/>*/}
                {record && (
                    <div className="func-customers-project-item-log">
                        <div className="func-customers-project-item-log__row func-customers-project-item-log__row--header">
                            <div className="func-customers-project-item-log__col">
                                Дата встречи
                            </div>
                            <div className="func-customers-project-item-log__col">
                                Протокол встречи
                            </div>
                        </div>
                        {record?.map(i => {
                            return (
                                <div className="func-customers-project-item-log__row">
                                    <div className="func-customers-project-item-log__col">
                                        {i.uploadDate}
                                    </div>
                                    <div className="func-customers-project-item-log__col">
                                        <a href={i.files[0].path} download>
                                            {i.files[0].name}
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
export default MeetingTable;
