import React, { FC } from 'react';
import './style.sass';
//@ts-ignore
import backgroundImg from '../../../assets/img/archive/main-screen/bg.jpg';
//@ts-ignore
import sparksImg from '../../../assets/img/svg/archive/main-screen/sparks.svg';
import { withRouter } from 'react-router';

interface IProps {
    title: string;
    text: string;
    link: string;
    location: any;
}

const MainScreen: FC<IProps> = ({ title, text, link, location }) => {
    const path = location.pathname;

    return (
        <div
            className="archive-main-screen"
            style={{ backgroundImage: `url(${backgroundImg})` }}
        >
            <img
                src={sparksImg}
                alt=""
                className="archive-main-screen__sparks"
            />
            <div className="full-wrapper">
                <div className="archive-main-screen__wrapper">
                    <div className="archive-main-screen__info archive-main-screen-info">
                        <div
                            className="archive-main-screen-info__title"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />

                        <div
                            className="archive-main-screen-info__text"
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                        {path === '/en/archive' && (
                            <div
                                className="archive-main-screen-info__text archive-main-screen-info__text--mb"
                                dangerouslySetInnerHTML={{
                                    __html: 'Аccelerator is completed',
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// @ts-ignore
export default withRouter(MainScreen);
