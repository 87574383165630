import React from 'react';
import './style.sass';
import { Route } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import LoginScreen from '../../components/admin/LoginScreen';
import MyLayout from '../../components/resources/Layout';
import ProfileEdit from '../../components/resources/ExpertsProfileEdit';
import { i18nProvider } from '../../providers/i18nProvider';
import { authProvider } from '../../providers/authProvider';
import { dataProvider } from '../../providers/dataProvider';
import { dataProviderManager } from '../../providers/dataProviderManager';
import FunctionalCustomersShow from '../../components/resources/FuncCustomersShow';
import User from '../../models/User';
import { resources } from './resources';

const AdminPanel = () => {
    return (
        <Admin
            loginPage={LoginScreen}
            authProvider={authProvider}
            dataProvider={
                User.shouldReceiveManagerProvider()
                    ? dataProviderManager
                    : dataProvider
            }
            i18nProvider={i18nProvider}
            layout={MyLayout}
            customRoutes={[
                <Route
                    key="profile-edit"
                    path="/profile-edit"
                    component={ProfileEdit}
                />,
                <Route
                    key="fc-show"
                    path="/fc-show"
                    component={FunctionalCustomersShow}
                />,
            ]}
        >
            {permissions => {
                const role = User.getRole(permissions);

                return resources[role].map(resource => (
                    <Resource {...resource} />
                ));
            }}
        </Admin>
    );
};

export default AdminPanel;
