import React, { FC } from 'react';
import './style.sass';

interface IProps {
    title: string;
    items: { date: string; text: string }[];
}

const Stages: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-stages" id="archive-stages">
            <div className="full-wrapper">
                <div
                    className="archive-stages__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="archive-stages__row">
                    {items?.length > 0 &&
                        items.map(({ date, text }, index) => (
                            <div className="archive-stages__col" key={index}>
                                <div className="archive-stages__item archive-stages-item">
                                    <div className="archive-stages-item__block">
                                        <div
                                            className="archive-stages-item__date"
                                            dangerouslySetInnerHTML={{
                                                __html: date,
                                            }}
                                        />
                                        <div
                                            className="archive-stages-item__text"
                                            dangerouslySetInnerHTML={{
                                                __html: text,
                                            }}
                                        />
                                    </div>
                                    <div className="archive-stages-item__num">
                                        {index + 1}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Stages;
