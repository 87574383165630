import axios, { AxiosResponse } from 'axios';
import { RestoreData } from '../types/registration';
import User from './User';
import { Repository } from './Repository';

class LKRepository extends Repository {
    public appId: string | number = '';

    public postApplication = async (values: any): Promise<AxiosResponse> => {
        for (const key in values) {
            values[key] === null && (values[key] = '');
        }
        const data = { ...values };
        const dataWithId = !this.appId ? data : { ID: this.appId, ...data };
        const formData = this.generateFormData(dataWithId);
        return await axios.post(
            `${this.defaultUrl}:main.forms.sendquestionnaire`,
            formData
        );
    };

    public getLKData = async (): Promise<AxiosResponse> => {
        const formData = this.generateFormData({
            ID: User.getId(),
        });
        return await axios.post(
            `${this.defaultUrl}:main.forms.questionnairelk`,
            formData
        );
    };

    public getApplicationData = async (): Promise<AxiosResponse> => {
        const formData = this.generateFormData({
            ID: this.appId,
        });
        return await axios.post(
            `${this.defaultUrl}:main.forms.questionnaire`,
            formData
        );
    };

    public restorePassword = async (
        values: RestoreData
    ): Promise<AxiosResponse> => {
        return await axios.post(
            `${this.defaultUrl}:main.forms.sendpass`,
            this.generateFormData(values)
        );
    };

    public getApplicationId = async (): Promise<void> => {
        await axios
            .post(
                `${this.defaultUrl}:main.forms.getidquestionerybyuserid`,
                this.generateFormData({
                    USERID: User.getId(),
                })
            )
            .then(res => this.saveId(res.data.data));
    };

    public saveId = (id: string | number): void => {
        this.appId = id;
    };
}

export default new LKRepository();
