import { ILocalData, IUser, UserData } from '../types/user';

class User implements IUser {
    public permissionsConfig: { [index: string]: string } = {
        '5': 'startup',
        '6': 'manager',
        '7': 'expert',
        '8': 'functional',
        '9': 'spectator',
    };

    public setUserData = (data: UserData) => {
        localStorage.setItem('userData', JSON.stringify(data));
        return this;
    };

    public checkUserData = () => {
        return !!localStorage.getItem('userData');
    };

    public setLocalData = ({ id }: ILocalData) => {
        localStorage.setItem('id', id + '');
        localStorage.setItem('token', Math.random().toString(36).substring(7));
        return this;
    };

    public removeLocalData = () => {
        const keys = ['id', 'token', 'permissions', 'userData'];
        keys.forEach(key => localStorage.removeItem(key));
        return this;
    };

    public getToken = (): string | null => {
        return localStorage.getItem('token');
    };

    public getId = (): string | null => {
        return localStorage.getItem('id');
    };

    public shouldReceiveManagerProvider = () => {
        const permissions = this.getUserData()?.permissions;
        const role = this.getRole(permissions);
        return ['manager', 'functional', 'spectator'].includes(role);
    };

    public getRole = (permissions: string[]): string => {
        const roleId = permissions?.find(role =>
            Object.keys(this.permissionsConfig).includes(role)
        );

        if (!roleId) return 'startup';

        return this.permissionsConfig[roleId];
    };

    public getUserData = (): UserData => {
        const localData = localStorage.getItem('userData') as string;
        return JSON.parse(localData);
    };
}

export default new User();
