import React from 'react';

import User from '../../../models/User';
import './style.sass';
import { MenuItemLink } from 'react-admin';
import RedButton from '../../ui/RedButton';
import { Link, Route } from 'react-router-dom';
import passEditIcon from '../../../assets/img/svg/icon-edit.svg';

const ExpertsProfileGrid = ({
    name,
    surname,
    organization,
    position,
    tel,
    email,
    website,
    social,
    about,
    exp,
}) => {
    return (
        <>
            <div className="profile">
                <div className="profile__row">
                    <div className="profile__col profile__col--half">
                        <div className="profile__title">Личные данные</div>
                        <div className="profile__label">Имя</div>
                        <div className="profile__text">
                            {name ? name : 'Нет данных'}
                        </div>
                        <div className="profile__label">Фамилия</div>
                        <div className="profile__text">
                            {surname ? surname : 'Нет данных'}
                        </div>
                        <div className="profile__label">Организация</div>
                        <div className="profile__text">
                            {organization ? organization : 'Нет данных'}
                        </div>
                        <div className="profile__label">Должность</div>
                        <div className="profile__text">
                            {position ? position : 'Нет данных'}
                        </div>
                    </div>
                    <div className="profile__col profile__col--half">
                        <div className="profile__title">Контактные данные</div>
                        <div className="profile__label">Телефон</div>
                        <div className="profile__text">
                            {tel ? tel : 'Нет данных'}
                        </div>
                        <div className="profile__label">E-mail</div>
                        <div className="profile__text">
                            {email ? email : 'Нет данных'}
                        </div>
                        <div className="profile__label">Сайт</div>
                        <div className="profile__text">
                            {website ? website : 'Нет данных'}
                        </div>
                        <div className="profile__label">Социальные сети</div>
                        <div className="profile__text">
                            {social ? social : 'Нет данных'}
                        </div>
                    </div>

                    <div className="profile__col profile__col--full">
                        <div className="profile__title">Пароль</div>

                        <div className="profile__change-pass">
                            <img src={passEditIcon} alt="" />
                            <MenuItemLink
                                className="profile__link"
                                primaryText="Сменить пароль"
                                to="/profile-edit#change-pass"
                            />
                        </div>
                    </div>
                    <div className="profile__col profile__col--full">
                        <div className="profile__title">Дополнительно</div>
                        <div className="profile__label">О себе</div>
                        <div className="profile__text">
                            {about ? about : 'Нет данных'}
                        </div>
                        <div className="profile__label">Коротко об опыте</div>
                        <div className="profile__text">
                            {exp ? exp : 'Нет данных'}
                        </div>
                    </div>
                </div>
            </div>

            <RedButton size="md" type="submit">
                <Link to="/profile-edit">Редактировать профиль</Link>
            </RedButton>
        </>
    );
};

export default ExpertsProfileGrid;
