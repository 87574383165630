import React, { useEffect, useState } from 'react';
// import { ScheduleItems, ScheduleItemsEn } from '../../../data/data';
import parse from 'html-react-parser';
import {
    ScheduleItems2022,
    ScheduleItems2022En,
} from '../../../data/2022/schedule';
import iconTrainNew from '../../../assets/img/svg/graph/train-freight.png';
import iconTrainMobile from '../../../assets/img/svg/graph/train-mobile-2022.svg';
import iconRail from '../../../assets/img/svg/graph/rail.svg';
import './style.sass';

const Schedule = ({ langRU, year }) => {
    const trainDesktop = iconTrainNew;
    const trainMobile = iconTrainMobile;
    const getData = () => {
        return langRU ? ScheduleItems2022 : ScheduleItems2022En;
    };

    const [activePar, setActivePar] = useState({
        station0: false,
        station1: false,
        station2: false,
        station3: false,
        station4: false,
    });

    const activeStep = 5;

    const handleScroll = () => {
        let screenWidth = window.screen.width;

        if (document.querySelector('.schedule-train__train')) {
            const trainBody = document.querySelector('.schedule-train__train');
            const scheduleItems = document.querySelectorAll('.schedule__item');
            // let distance = trainBody.getBoundingClientRect().y;
            let points = [];
            if (screenWidth < 1921 && screenWidth > 1610) {
                points = [-400, -150, 50, 250, 550, 800, -800];
            } else if (screenWidth > 1500 && screenWidth < 1610) {
                points = [-600, -350, -150, 50, 350, 550, -800];
            } else if (screenWidth < 1500 && screenWidth > 1320) {
                points = [-600, -350, -150, 50, 350, 500, -800];
            } else if (screenWidth < 1320 && screenWidth > 1200) {
                points = [-650, -450, -250, -50, 200, 400, -800];
            } else if (screenWidth < 1200) {
                points = [-650, -450, -250, -50, 200, 300, -800];
            }

            trainBody.style.left = points[activeStep] + 'px';
            scheduleItems.forEach(item => item.classList.remove('active'));
            scheduleItems[activeStep].classList.add('active');
            setActivePar({ [`station${activeStep}`]: true });

            // if (distance < 740 && distance > 640) {
            //     trainBody.style.left = points[0] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[0].classList.add('active');
            //     setActivePar({station0: true})
            // } else if (540 < distance && distance < 640) {
            //     trainBody.style.left = points[1] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[1].classList.add('active');
            //     setActivePar({station1: true})
            // } else if (440 < distance && distance < 540) {
            //     trainBody.style.left = points[2] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[2].classList.add('active');
            //     setActivePar({station2: true})
            // } else if (340 < distance && distance < 440) {
            //     trainBody.style.left = points[3] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[3].classList.add('active');
            //     setActivePar({station3: true})
            // } else if (240 < distance && distance < 340) {
            //     trainBody.style.left = points[4] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[4].classList.add('active');
            //     setActivePar({station4: true})
            // } else if (0 < distance && distance < 240) {
            //     trainBody.style.left = points[5] + 'px';
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            //     scheduleItems[5].classList.add('active');
            //     setActivePar({station5: true})
            // }
            // } else if (distance <= 0) {
            //     trainBody.style.left = points[6] + 'px';
            // }
            // if (screenWidth < 1200) {
            //     scheduleItems.forEach(item => item.classList.remove('active'));
            // }
        }
    };

    useEffect(() => {
        handleScroll();

        // window.addEventListener('scroll', handleScroll);
        // return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section
            className="schedule"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="schedule__title">
                    {langRU
                        ? parse('График мероприятий')
                        : parse('Event Schedule')}
                </div>
            </div>
            <div className="full-wrapper schedule-imgs">
                <div className="schedule-imgs__row">
                    {getData().map((item, index) => {
                        return (
                            <div className="schedule-imgs__col" key={index}>
                                <div className="schedule-imgs__item">
                                    <img
                                        src={
                                            activePar[`station${index}`]
                                                ? item.activeImg
                                                : item.img
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="schedule__train schedule-train">
                <img
                    src={trainDesktop}
                    alt=""
                    className="schedule-train__train schedule-train__train--size"
                    style={{ left: '-450px' }}
                />
                <img src={iconRail} alt="" className="schedule-train__rail" />
                <img
                    src={trainMobile}
                    alt=""
                    className="schedule-train__train-mobile2022 "
                />
            </div>
            <div className="full-wrapper">
                <div className="schedule__row">
                    {getData().map((item, index) => {
                        return (
                            <div className="schedule__col" key={index}>
                                <div className="schedule__item">
                                    <img
                                        className="schedule__item-img"
                                        src={item.img}
                                        alt=""
                                    />
                                    <div
                                        className="schedule__date"
                                        dangerouslySetInnerHTML={{
                                            __html: item.date,
                                        }}
                                    />
                                    <div
                                        className="schedule__text"
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Schedule;
