import React from 'react';
import cn from './style.module.sass';

const DecoredBlock = ({ title, text }) => {
    return (
        <div className={cn.wrapper}>
            <div className="full-wrapper">
                <div className={cn.content}>
                    <div
                        className={cn.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {text && (
                        <div
                            className={cn.text}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                </div>
            </div>
            <div className={cn.decor} />
        </div>
    );
};

export default DecoredBlock;
