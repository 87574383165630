import React from 'react';
import { IntroTextNumbers, IntroTextNumbersEn } from '../../data/data';
import parse from 'html-react-parser';
import rjdLogoRed from '../../assets/img/svg/rjd-logo-red.svg';
import IntroSlider from '../IntroSlider';
import './style.sass';
import {
    IntroTextNumbers2021,
    IntroTextNumbers2021En,
} from '../../data/2021/intro';

const IntroText = ({ langRU, year }) => {
    const regLink = langRU
        ? // ? '/lk#/login'
          // : 'https://www.f6s.com/russian-railways-accelerator-logistics/apply';
          'https://account.generation-startup.ru/'
        : 'https://account.generation-startup.ru/';

    const getData = () => {
        if (year === '2021') {
            return langRU ? IntroTextNumbers2021 : IntroTextNumbers2021En;
        }
        return langRU ? IntroTextNumbers : IntroTextNumbersEn;
    };
    return (
        <section
            id="about"
            className="introtext"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1500"
        >
            <div className="full-wrapper">
                <div className="introtext__row">
                    <div className="introtext__col introtext__col--left">
                        <a href="" className="introtext__logo">
                            <img src={rjdLogoRed} alt="" />
                        </a>
                        <div className="introtext__title">
                            {langRU
                                ? parse(
                                      'ОАО&nbsp;&laquo;РЖД&raquo; входит в&nbsp;мировую тройку лидеров железнодорожных компаний.'
                                  )
                                : parse(
                                      'Russian Railways is&nbsp;a&nbsp;top-3 global railway company.'
                                  )}
                        </div>
                        <div className="introtext__main-text">
                            {langRU
                                ? parse(`Железнодорожный комплекс имеет особое стратегическое
                            значение для России. Он&nbsp;является связующим
                            звеном единой экономической системы, обеспечивает
                            стабильную деятельность промышленных предприятий,
                            своевременную поставку жизненно важных грузов
                            в&nbsp;самые отдаленные уголки страны, а&nbsp;также
                            является самым доступным транспортом для миллионов
                            граждан.`)
                                : parse(
                                      `The railways are of&nbsp;particular strategic importance for Russia. They connect the economies of&nbsp;its many regions, ensure that manufacturers operate without interruption and the on-time delivery of&nbsp;vital goods to&nbsp;the most remote corners of&nbsp;the country, and provide the most affordable means of&nbsp;transport for millions of&nbsp;citizens.`
                                  )}
                        </div>
                        <div className="introtext__main-text">
                            {langRU
                                ? parse(`Железнодорожный транспорт был и&nbsp;остаётся
                            наиболее приоритетным и&nbsp;доступным для миллионов
                            граждан России.`)
                                : parse(
                                      `Railway transport has been and remains the most affordable and the first choice for millions of&nbsp;Russian citizens.`
                                  )}
                        </div>
                        <a
                            href="https://www.rzd.ru"
                            className="introtext__link"
                            target="_blank"
                        >
                            https://www.rzd.ru
                        </a>
                        {/*{year === '2022' &&*/}
                        {/*    <a className="introtext__btn"*/}
                        {/*       href={regLink}*/}
                        {/*       target="_blank"*/}
                        {/*    >*/}
                        {/*        {langRU ? 'ПРИНЯТЬ УЧАСТИЕ' : 'PARTICIPATE'}*/}
                        {/*    </a>*/}
                        {/*}*/}
                    </div>
                    <div className="introtext__col introtext__col--right">
                        <IntroSlider data={getData()} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntroText;
