import React, { FC } from 'react';
import './style.sass';
//@ts-ignore
import rzdLogo from '../../../assets/img/archive/advantages/logo.jpg';

interface IProps {
    title: string;
    items: { title: string; text: string; img: string }[];
}

const Advantages: FC<IProps> = ({ title, items }) => {
    return (
        <div className="archive-adv" id="archive-advantages">
            <div className="full-wrapper">
                <div className="archive-adv__wrapper">
                    <div className="archive-adv__logo">
                        <img src={rzdLogo} alt="rzd-logo" />
                    </div>
                    <div
                        className="archive-adv__title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>

                <div className="archive-adv__row">
                    {items?.length > 0 &&
                        items.map(({ title, text, img }, index) => (
                            <div className="archive-adv__col" key={index}>
                                <div className="archive-adv__item archive-adv-item">
                                    <div className="archive-adv-item__icon">
                                        <img
                                            className="archive-adv-item__img"
                                            src={img}
                                            alt=""
                                        />
                                    </div>
                                    <div className="archive-adv-item__info">
                                        <div
                                            className="archive-adv-item__title"
                                            dangerouslySetInnerHTML={{
                                                __html: title,
                                            }}
                                        />
                                        <div
                                            className="archive-adv-item__text"
                                            dangerouslySetInnerHTML={{
                                                __html: text,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Advantages;
