import React from 'react';
import cn from './style.module.sass';
import LineTrack from '../LineTrack';
import { useWindowWidth } from '../../hooks/useWindowWidth';
const FastTrackItemNew = ({ index, item, length }) => {
    const width = useWindowWidth();

    return (
        <div
            className={cn.col}
            data-aos="fade-right"
            data-aos-delay={(index + 1) * 500}
        >
            {index !== length && (
                <div className={cn.track}>
                    <LineTrack />
                </div>
            )}
            <div className={cn.item}>
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={cn.svg}
                >
                    <circle
                        cx="16"
                        cy="16"
                        r="13"
                        fill="white"
                        stroke="#E21A1A"
                        strokeWidth="6"
                    />
                    <circle cx="16" cy="16" r="5" fill="#E21A1A" />
                </svg>

                <div
                    className={cn.text}
                    dangerouslySetInnerHTML={{
                        __html: item,
                    }}
                />
            </div>
        </div>
    );
};

export default FastTrackItemNew;
