import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ModalWrap from '../ExpertModal';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LKRepository from '../../../../models/LKRepository';
import axios from 'axios';
import RedButton from '../../../ui/RedButton';
import { makeStyles } from '@material-ui/core/styles';
import Statuses from '../../../../data/statusesId.json';
import { Notification, useNotify, useRefresh } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import FCModal from '../FCModal/FCModal';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '100%',
    },
}));

const ActionFieldSpectator = ({ source, basePath, record = {} }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [toggleMenu, setToggleMenu] = useState(false);

    const menuOpen = () => {
        setToggleMenu(true);
    };

    return (
        <div className="action-field">
            <div className="action-field__title" onClick={menuOpen}>
                Действия
            </div>
            {toggleMenu && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setToggleMenu(false);
                    }}
                >
                    <div
                        className={`action-field-menu ${
                            toggleMenu && `active`
                        }`}
                    >
                        <div className="action-field-menu__item">
                            <a href={`/lk#${basePath}/${record.id}`}>Открыть</a>
                        </div>
                    </div>
                </OutsideClickHandler>
            )}
            <Notification />
        </div>
    );
};

export default ActionFieldSpectator;
