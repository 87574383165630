import React from 'react';
import { TextField } from '@material-ui/core';
import './style.sass';

const AdminTextField = props => {
    const { formikProps, type, name, mask = 'input', ...restProps } = props;

    const { values, handleChange, handleBlur, errors, touched } = formikProps;

    const error = errors[name] && touched[name];
    const params = { name, type, error };

    return (
        <TextField
            name={name}
            fullWidth
            size="small"
            {...restProps}
            variant="filled"
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={error ? errors[name] : ''}
            InputProps={{
                inputComponent: mask,
            }}
            {...params}
        />
    );
};

export default AdminTextField;
