import React, { FC } from 'react';
import './style.sass';

interface IProps {}

const ParticipantCabinetText: FC<IProps> = props => {
    return (
        <div className="participant-cabinet-text">
            <span className="participant-cabinet-text__md">
                Для стартапов стадии &laquo;от&nbsp;прототипа
                и&nbsp;выше&raquo;, разработки которых могут быть применены в
                области логистики в ОАО &laquo;РЖД&raquo;
            </span>
            <div className="participant-cabinet-text__sm">
                Акселератор РЖД&nbsp;&mdash; это программа, которая поможет вам
                быстро пройти путь к&nbsp;сотрудничеству с&nbsp;крупнейшей
                мировой железнодорожной компанией
            </div>
        </div>
    );
};

export default ParticipantCabinetText;
